import { UserInfo } from './user.info';
import AuthUserSession from './user.session';

export default class AuthUser {
  public userName: string;
  public userInfo: UserInfo;
  public userSession?: AuthUserSession;

  constructor(
    userName: string,
    userInfo: UserInfo,
    userSession?: AuthUserSession
  ) {
    this.userName = userName;
    this.userInfo = userInfo;
    this.userSession = userSession;
  }
}
