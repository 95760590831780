import { isEmpty } from '../utils/object.utils';
import IStorage from './storage.interface';

let memoryCache = new Map<string, string>();

export class MemoryStorage implements IStorage {
  private config: any;

  constructor(config: any) {
    this.config = config;
  }

  public setItem(key: string, value: string): string | null {
    if (!(value == null || value.length === 0)) {
      memoryCache.set(key, value);
      return this.getItem(value);
    }
    return null;
  }

  public getItem(key: string): string | null {
    return isEmpty(memoryCache.get(key))
      ? null
      : (memoryCache.get(key) as string);
  }

  public removeItem(key: string): boolean {
    return memoryCache.delete(key);
  }

  public clear(): {} {
    memoryCache = new Map<string, string>();
    return {};
  }
}
