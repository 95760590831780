import {
  put,
  all,
  fork,
  takeLatest,
  takeEvery,
  call,
  select,
} from 'redux-saga/effects';
import * as base from '../base';
import { EXECUTE_ACTION_FLOW } from '../../actions';

const getCard = (state: any) => state.appState.cardsData;

export default function* executeActionFlowSaga() {
  yield all([fork(watchExecuteActionFlow)]);
}

function* watchExecuteActionFlow() {
  yield takeEvery(EXECUTE_ACTION_FLOW, executeActionFlow);
}

function* executeActionFlow(action: any): Generator<any, any, any> {
  const response = yield call(
    base.executeActionFlow,
    action.flowId,
    action.data
  );
}
