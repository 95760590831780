import { parseStorageEnum, StorageEnum } from '../constants';
import { CookieStorage } from './cookie.storage';
import { LocalStorage } from './local.storage';
import { MemoryStorage } from './memory.storage';
import IStorage from './storage.interface';
import { SessionStorage } from './session.storage';

const createStorage = (config: any): IStorage => {
  if (!config.storage.type) {
    throw new Error('config.storage.type is not present in configuration');
  }
  const storageEnum: StorageEnum = parseStorageEnum(config.storage.type);
  switch (storageEnum) {
    case StorageEnum.COOKIE:
      return new CookieStorage(config.storage.cookie);
    case StorageEnum.MEMORY:
      return new MemoryStorage(config.storage.memory);
    case StorageEnum.WINDOW:
      return new LocalStorage(config.storage.window);
    case StorageEnum.SESSION:
      return new SessionStorage(config.storage.window);

    default:
      throw new Error(
        'Invalid or unsuported storage type ' + config.storage.type
      );
  }
};

export { createStorage };
