export const SET_CURRENT_ROOT_NODE = 'SET_CURRENT_ROOT_NODE';
export const SET_ERROR_DATA = 'SET_ERROR_DATA';
export const SET_STORE = 'SET_STORE';
export const SET_FILE_DROPPABLE_INDICATOR = 'SET_FILE_DROPPABLE_INDICATOR';
export const GET_MY_TASK_DATA = 'GET_MY_TASK_DATA';
export const GET_MY_PROCESS_INSTANCE_DATA = 'GET_MY_PROCESS_INSTANCE_DATA';
export const GET_SEND_FEEDBACK_DATA = 'GET_SEND_FEEDBACK_DATA';
export const UPDATE_WORKFLOW_STATUS = 'UPDATE_WORKFLOW_STATUS';
export const GET_CONFIGURATION_STRUCTURE = 'GET_CONFIGURATION_STRUCTURE';

export interface ISetRootNode {
  type: string;
  rootNodeId: string;
}

export interface ISetErrorData {
  type: string;
  errorData: {};
}

export interface ISetStore {
  type: string;
  store: any;
}

export interface ISetFileDroppableIndicator {
  type: string;
  droppableIndicator: boolean;
}

export interface IGetMyProcessInstanceData {
  type: string;
  gridId: string;
}

export interface IGetMyTaskData {
  type: string;
  gridId: string;
}

export interface IGetSendFeedbackData {
  type: string;
  gridId: string;
}

export interface IUpdateWorkflowStatus {
  type: string;
  reqBody: any;
  cardId: string;
}

export interface IGetConfiguarationStructure {
  type: string;
  applicationId: string;
  parentId: string;
}
export function setCurrentRootNode(rootNodeId: string): ISetRootNode {
  return {
    type: SET_CURRENT_ROOT_NODE,
    rootNodeId: rootNodeId,
  };
}

export function setErrorData(errorData: {}): ISetErrorData {
  return {
    type: SET_ERROR_DATA,
    errorData: errorData,
  };
}

export const setStore = (store: any): ISetStore => {
  return {
    type: SET_STORE,
    store: store,
  };
};

export function setFileDroppableIndicator(
  droppableIndicator: boolean
): ISetFileDroppableIndicator {
  return {
    type: SET_FILE_DROPPABLE_INDICATOR,
    droppableIndicator: droppableIndicator,
  };
}

export const getMyTaskData = (gridId: string): IGetMyProcessInstanceData => {
  return {
    type: GET_MY_TASK_DATA,
    gridId: gridId,
  };
};

export const getMyProcessInstanceData = (gridId: string): IGetMyTaskData => {
  return {
    type: GET_MY_PROCESS_INSTANCE_DATA,
    gridId: gridId,
  };
};

export const getSendFeedBackData = (gridId: string): IGetSendFeedbackData => {
  return {
    type: GET_SEND_FEEDBACK_DATA,
    gridId: gridId,
  };
};

export function updateWorkflowStatus(
  reqBody: any,
  cardId: any
): IUpdateWorkflowStatus {
  return {
    type: UPDATE_WORKFLOW_STATUS,
    reqBody: reqBody,
    cardId: cardId,
  };
}

export function getConfiguarationStructure(
  applicationId: string,
  parentId: string
): IGetConfiguarationStructure {
  return {
    type: GET_CONFIGURATION_STRUCTURE,
    applicationId: applicationId,
    parentId: parentId,
  };
}
