import * as React from 'react';
import {
  InlineErrorContainer,
  MessageComponentSpan,
} from './InlineMessageComponentStyle';

const InlineMsgComponent: React.FC<{ inlineMsg: string }> = (props) => {
  return (
    <InlineErrorContainer>
      <span className="exclamation">
        <svg
          aria-hidden="true"
          focusable="false"
          width="15px"
          height="16px"
          viewBox="0 0 24 24"
          fill="#e51c23"
        >
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z" />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
      </span>
      <MessageComponentSpan>{props.inlineMsg}</MessageComponentSpan>
    </InlineErrorContainer>
  );
};

export default InlineMsgComponent;
