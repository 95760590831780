export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SWITCH_LANGUAGE = 'SWITCH_LANGUAGE';
export const SET_CURRENT_FINANCIAL_YEAR = 'SET_CURRENT_FINANCIAL_YEAR';
export const SWITCH_PROJECT = 'SWITCH_PROJECT';
export const LOG_OUT = 'LOG_OUT';
export const CHANGE_USER_CONTEXT_KEY = 'CHANGE_USER_CONTEXT_KEY';

export interface IGetUserProfile {
  type: string;
  userId: string;
  userStatus: string;
  location: any;
  user?: any;
}

export interface ISetUserProfile {
  type: string;
  userDetails: string;
}

export interface ISwitchLanguage {
  type: string;
  language: string;
}

export interface ISetCurrentFinancialYear {
  type: string;
  currentFinancialYear: string;
}

export interface ISwicthProject {
  type: string;
  projectId: any;
}

export interface ILogout {
  type: string;
}

export interface IGetChangeUserContextKey {
  type: string;
  keyName: string;
  keyValue: any;
  location: any;
}

export const getUserProfile = (
  userId: string,
  userStatus: string,
  location: any,
  user?: any
): IGetUserProfile => {
  return {
    type: GET_USER_PROFILE,
    userId: userId,
    location: location,
    userStatus: userStatus,
    user: user,
  };
};

export const setUserProfile = (userDetails: string): ISetUserProfile => {
  return {
    type: SET_USER_PROFILE,
    userDetails: userDetails,
  };
};

export const switchLanguage = (language: string): ISwitchLanguage => {
  return {
    type: SWITCH_LANGUAGE,
    language,
  };
};

export const setCurrentFinancialYear = (
  currentFinancialYear: string
): ISetCurrentFinancialYear => {
  return {
    type: SET_CURRENT_FINANCIAL_YEAR,
    currentFinancialYear,
  };
};

export function switchProject(projectId: string): ISwicthProject {
  return {
    type: SWITCH_PROJECT,
    projectId: projectId,
  };
}

export function logOut(): ILogout {
  return {
    type: LOG_OUT,
  };
}

export function changeUserContextKey(
  keyName: string,
  keyValue: any,
  location: any
): IGetChangeUserContextKey {
  return {
    type: CHANGE_USER_CONTEXT_KEY,
    keyName: keyName,
    keyValue: keyValue,
    location: location,
  };
}
