import { put, all, fork, takeEvery, call, select } from 'redux-saga/effects';
import * as base from '../base';
import { CardData } from '../../models';
import {
  setFormData,
  setToggleMessages,
  HANDLE_REPEATABLE,
  deleteRecord,
} from '../../actions';
import { UpsertMessage, CardType } from '../../constants/appeng.enum';
import { getPrimaryDBCode } from '../../utils/ConfigUtils';
import { executeCompositeEntityPreprocessor } from '../executer/preprocessorExecuter';
const getCard = (state: any) => state.appState.cardsData;

export default function* handleRepeatableSaga() {
  yield all([fork(watchHandleRepeatable)]);
}

function* watchHandleRepeatable() {
  yield takeEvery(HANDLE_REPEATABLE, handleRepeatable);
}

function* handleRepeatable(action: any): Generator<any, any, any> {
  try {
    let existingCardData = yield select(getCard);
    let formData = { ...existingCardData[action.referralId].data };
    const referenceData = {
      ...existingCardData[action.referralId].referenceData,
    };
    const options = { ...existingCardData[action.referralId].options };
    const errorData = { ...existingCardData[action.referralId].errorData };
    let newKey =
      Object.keys(formData[0].child[action.formId]).length >= 1
        ? Number(
            Object.keys(formData[0].child[action.formId])
              .map(Number)
              .sort((a, b) => (a > b ? 1 : -1))[0]
          ) - 1
        : -1;
    if (newKey > 0 || newKey === 0) {
      newKey = -1;
    }

    const { data } = yield call(base.getConfigData, 'NodeQuery', action.nodeId);

    if (action.operationType === 'ADD') {
      if (
        data.CompositeEntityNode.combinedNodes &&
        action.parentFormId &&
        action.operationType === 'ADD'
      ) {
        base.setChildDefaultValues(
          data,
          action.formId,
          formData,
          newKey,
          action.formDataRepObject
        );
      } else {
        const parentForm = data.CompositeEntityNode.insertForm
          ? data.CompositeEntityNode.insertForm
          : data.CompositeEntityNode.editForm;
        if (
          !parentForm.maxRepeatation ||
          (parentForm.maxRepeatation &&
            parentForm.maxRepeatation > Object.keys(formData[0]).length)
        ) {
          formData[newKey] = action.formDataRepObject;

          let defaultParentFormData = base.getDefaultDataMap(parentForm);
          Object.assign(formData[newKey], defaultParentFormData);

          parentForm.formSections.map((formSection: any) => {
            if (formSection.formFields)
              Object.assign(
                formData[newKey],
                base.getDefaultFFMap(formSection.formFields, formData[newKey])
              );
            return null;
          });
        }
      }
    } else if (
      action.operationType === 'MINUS' ||
      action.operationType === 'DELETE'
    ) {
      if (
        action.operationType === 'DELETE' &&
        !formData[0].child[action.formId][action.occuranceNumber].isNew
      ) {
        if (data.CompositeEntityNode.combinedNodes && action.parentFormId) {
          for (const childNode of data.CompositeEntityNode.combinedNodes) {
            const childForm = base.getForm(childNode, 'Insert');
            if (childForm.configObjectId === action.formId) {
              const primaryDBCode = getPrimaryDBCode(
                childNode.entity.logicalColumns
              );
              const primaryKey =
                formData[0].child[action.formId][action.occuranceNumber][
                  primaryDBCode
                ];
              yield put(
                deleteRecord(
                  primaryKey,
                  childNode.entity.name,
                  '',
                  '',
                  '',
                  false,
                  undefined,
                  action.referralId,
                  action.parentId,
                  action.portalId,
                  action.usePortalReferenceData,
                  action.parentFormId
                )
              );
            }
          }
        } else {
          const primaryDBCode = getPrimaryDBCode(
            data.CompositeEntityNode.entity.logicalColumns
          );
          const primaryKey = formData[action.occuranceNumber][primaryDBCode];
          yield put(
            deleteRecord(
              primaryKey,
              data.CompositeEntityNode.entity.name,
              '',
              '',
              '',
              false,
              undefined,
              action.referralId,
              action.parentId,
              action.portalId,
              action.usePortalReferenceData,
              action.parentFormId
            )
          );
        }
      }
      if (
        formData[0].child[action.formId] &&
        formData[0].child[action.formId].length
      ) {
        formData[0].child[action.formId].splice(action.occuranceNumber, 1);
      } else {
        delete formData[0].child[action.formId][action.occuranceNumber];
      }
    }

    formData = yield call(
      executeCompositeEntityPreprocessor,
      data,
      formData,
      action.mode,
      true,
      [],
      action.referralId
    );
    if (formData.errors) {
      const messages: string[] = [];
      messages.push(formData.errors);
      yield put(setToggleMessages(messages));
    } else {
      let existing_CardData = yield select(getCard);
      const cardData = new CardData(
        action.referralId,
        CardType.FORM,
        formData,
        existingCardData[action.referralId].portalId,
        existingCardData[action.referralId].parentId,
        referenceData,
        existing_CardData[action.referralId].errorData,
        options
      );
      yield put(setFormData(cardData, action.referralId, true, false, false));
    }
  } catch {
    const messages: string[] = [];
    messages.push(UpsertMessage.UNSUCCESSFUL);
    yield put(setToggleMessages(messages));
  }
}
