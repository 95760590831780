import React, { useContext, useState, lazy } from 'react';
import { useDispatch } from 'react-redux';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { confirmAlert } from 'react-confirm-alert';
import { actionFlowRouting, removeCardDataByReferralId, removeRowFromGrid, updateInlineRecord } from '../../../actions';
import { StyledConfirModalDiv } from '../../../composite-entity/form/FormStyle';
import { ThemeContext } from '../../../theme/ThemeContext';
import { Button, DataGridContextMenu,getIconDef } from 'info-ui-library';
import { UserContext } from '../../../../src/user-context/UserContext';
import { isConfigObjectAccessible, isConfigObjectEditable } from '../../../composite-entity/form/CardGenerators';
import { getPrivilege } from '../../../../src/utils/ClientUtils';
import { ActionType } from '../../../constants/appeng.enum';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas }  from '@fortawesome/free-solid-svg-icons';
import { far }  from '@fortawesome/free-regular-svg-icons';
import {v4 as uuidv1} from 'uuid';
 import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
 
 
library.add(fas);
library.add(far);
 
const dataModifier = (nestedComponentType, row, dispatch,
    uniqueKey, themeContext, attribute) => {
    switch (nestedComponentType) {
        case 'Delete': {
            confirmAlert({
                customUI: ({ onClose, row }) => {
                    return (
                        <StyledConfirModalDiv ioTheme={themeContext.ioTheme}
                            ioMode={themeContext.ioMode}
                            styleName={themeContext.styleName} className='custom-ui'>
                            <h1>Are you sure?</h1>
                             {/* <p>You want to delete this file?</p> */}
                            <Button ioTheme={themeContext.ioTheme}
                                ioMode={themeContext.ioMode}
                                styleName={themeContext.styleName} onClick={onClose}
                                disabled={false}
                                name={'No'} />
                            <Button ioTheme={themeContext.ioTheme}
                                ioMode={themeContext.ioMode}
                                styleName={themeContext.styleName}
                                disabled={false}
                                name={'Yes'}
                                onClick={() => {
                                    dispatch(actionFlowRouting(ActionType.BUTTON_CLICK, attribute));
                                    onClose();
 
                                }}
                            />
                        </StyledConfirModalDiv>
                    );
                }
            });
            break;
        }
        default: {
            if (row.isExpanded) {
              dispatch(removeCardDataByReferralId(uniqueKey));
              if (nestedComponentType !== row.nestedComponentType && row.original?.ACTION_TYPE === row.nestedComponentType){
                row["nestedComponentType"] = nestedComponentType;
                row.toggleRowExpanded();
              }
            } else {
              row["nestedComponentType"] = nestedComponentType;
            }
            row.toggleRowExpanded();
            break;
          }
        }
 
}
 
const customHandleClick = (onClickHandler, parentProps, actionColumnType, hrefValue, rowDetails, dispatch, dbCode, label, configObjectId, auditColumnName,modalCss) => {
    let column = {};
    column["actionColumnType"] = actionColumnType;
    column["hrefValue"] = hrefValue;
    column["displayName"] = dbCode;
    column["label"] = label;
    column["configObjectId"] = configObjectId;
    column["auditColumnName"] = auditColumnName;
    column["modalCss"] = modalCss;
    onClickHandler(column, rowDetails, parentProps, dispatch);
}
 
const ExpanderForSubRows = (props) => {
    const themeContext = useContext(ThemeContext);
    const dispatch = useDispatch();
    const userContext = useContext(UserContext);
    const [uid,setUid] = useState(uuidv1())
    const row = props.row;
    let uniqueKey = row.original[props.cell.column.primaryDBCode] + "_" + props.cell.column.dataGridId;
 
    const onClickable = (dbCode, attribute) => {
        if (dbCode === "ROW_SAVE") {
            let data = { ...props.row.values };
            let pkvalue = uuidv1();
            Object.assign(data, props.cell.column.parentData);
            data[props.cell.column.primaryDBCode] = pkvalue;
            let rowData = {};
            rowData["valueMap"] = data;
            rowData["path"] = props.row.id;
            rowData["primaryDBCode"] = props.cell.column.primaryDBCode;
            rowData["referenceData"] = props.cell.column.parentData;
            rowData["dataGridId"] = props.cell.column.dataGridId;
            rowData["datagrid"] = props.cell.column.datagrid;
            rowData["location"] = props.cell.column.location;
            rowData["rowIndex"] = props.row.index;
            dispatch(updateInlineRecord(pkvalue, props.cell.column.entityId, props.cell.column.nodeId, props.cell.column.ceId, rowData, props.cell.column.referralId, 'Insert'))
        } else if (dbCode === "ROW_REMOVE") {
            dispatch(removeRowFromGrid(props.row.index, props.cell.column.referralId, props.row.id))
        } else {
            dataModifier(dbCode, row, dispatch, uniqueKey, themeContext, attribute)
        }
    }
 
    let independentColumns = props.cell.column.expandButtons.filter(dataGridColumn => dataGridColumn.actionColumn && !dataGridColumn.hyperLink && dataGridColumn.group !== 'First Column');
 
    let groupedColumns = props.cell.column.expandButtons.filter(dataGridColumn => dataGridColumn.actionColumn && !dataGridColumn.hyperLink && dataGridColumn.group === 'First Column');
    let rowDetails = props.cell.row.values;
 
    let incrementedOrder = groupedColumns[groupedColumns.length - 1] + 1;
    if (groupedColumns.length > 0) {
        let object = {
            "headerName": "Ellipses",
            "order": incrementedOrder,
            "visible": true,
            "configObjectId": props.cell.column.dataGridId,
            "name": "Ellipses",
            "configObjectType": "DataGridColumn",
            "dbCode": "ELLIPSES",
            "projectId": 100,
            "toolTip": "",
            "actionColumn": true,
            "actionColumnType": "ExpandableComponent",
            "group": "",
            "icon": "fa-solid fa-ellipsis-vertical",
            "collapseIcon": "fa-solid fa-ellipsis-vertical"
        };
        independentColumns.push(object);
    }
 
    let expandButtons = row.original && row.original["expander"] === "NewRow" && props.row.values[props.cell.column.primaryDBCode] === "" ? <div>
        <FontAwesomeIcon style={{paddingLeft: "10px" }}
            icon={"fa-regular fa-floppy-disk"}
            title={"Save"}
            onClick={(e) => onClickable("ROW_SAVE", {})} />
        <FontAwesomeIcon style={{ paddingLeft: "10px", color: "red" }}
            icon={"fa-solid fa-rectangle-xmark "}
            title={"Remove"}
            onClick={(e) => onClickable("ROW_REMOVE", {})} />
    </div> : independentColumns.map((expandButton,index) => {
        let privilege = getPrivilege(expandButton.privileges, userContext.getCurrentRole());
        let isAccessibleRegex = isConfigObjectAccessible(expandButton.accessibilityRegex, Object.assign({}, row.original, userContext.getUserDetail()));
        let isAccessible = !expandButton.visible ? false : privilege === "NOPRIVILEGE" ? false : true;
        let attribute = {}
        if (props.cell.column) {
            attribute = {
                nodeId: props.cell.column.nodeId, actionName: expandButton.dbCode,
                referenceData: row.values,
                referralId: props.cell.column.referralId,
                portalId: props.cell.column.portalId,
                usePortalReferenceData: false,
                compositeEntityKey: row.original[props.cell.column.primaryDBCode],
                entityId: props.cell.column.entityId,
                buttonType: expandButton.dbCode,
                location: props.cell.column.location
            };
        }
        if (expandButton.collapseIcon === "fa-solid fa-ellipsis-vertical" && groupedColumns.length > 0) {
            return <DataGridContextMenu  key = {expandButton.configObjectId} id={uid} configId={expandButton.configObjectId} title={"More"} itemList={groupedColumns} onClick={onClickable} attribute={attribute} handleClick={customHandleClick} cellPropsDetails={props.cell.column} rowDetails={row} getPrivilege={getPrivilege} isConfigObjectAccessible={isConfigObjectAccessible} getCurrentRole={userContext.getCurrentRole()} />
        } else {
            if (isAccessibleRegex && isAccessible) {
               
                return <FontAwesomeIcon  key = {expandButton.configObjectId} {...row.getToggleRowExpandedProps({
                    style: {
                        "padding": "5px"
                    }
                })}
                    id={expandButton.configObjectId}
                    onClick={(e) => {
                        expandButton.actionColumnType !== 'ExpandableComponent' ? customHandleClick(props.cell.column.onClickHandler, props.cell.column.parentProps, expandButton.actionColumnType, expandButton.hrefValue, row, dispatch, expandButton.dbCode, expandButton.HeaderName, expandButton.configObjectId,expandButton.modalCss) : dataModifier(expandButton.dbCode, row, dispatch, uniqueKey, themeContext, attribute)
                    }}
 
                    icon={(row.isExpanded && (row.nestedComponentType === expandButton.dbCode || row.original["ACTION_TYPE"] === expandButton.dbCode) ? getIconDef(expandButton.collapseIcon) : getIconDef(expandButton.icon))}
                    title={expandButton.toolTip} />
            }
        }
    })
 
    return (
        <div
        style={{
            display:"flex",
            padding:"inherit"
             }} >
            {expandButtons}
        </div>
    )
}
 
export default ExpanderForSubRows;