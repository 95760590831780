import React, { useContext } from 'react';
import { ThemeContext } from '../theme/ThemeContext';
import { Skeletons, FormErrorContainers } from 'info-ui-library';
interface ISkeletonsCore {
  count: number;
}
const SkeletonCore: React.FC<ISkeletonsCore> = (props: ISkeletonsCore) => {
  const themeContext = useContext(ThemeContext);
  try {
    return (
      <Skeletons
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={themeContext.styleName}
        count={props.count}
      />
    );
  } catch {
    const error = ['Error occurred in Skeleton'];
    return (
      <FormErrorContainers
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};

export default SkeletonCore;
