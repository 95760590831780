import * as React from 'react';
import styled from 'styled-components';
const StyledIcon = styled.i`
  color: ${(p) => p.color};
`;
const CheckBoxBuilder: React.FC<{
  checked: boolean;
  label: string;
  intl: any;
}> = (props: any) => {
  let color = props.checked ? '#5cb85c' : '#d9534f';
  return (
    <p>
      <StyledIcon
        color={color}
        className={props.checked ? 'fa-solid fa-check' : 'fa-solid fa-xmark'}
      >
        &nbsp; {props.intl.formatMessage({ id: props.label })}
      </StyledIcon>
    </p>
  );
};

export default CheckBoxBuilder;
