export const UPLOAD_ATTACHMENT = 'UPLOAD_ATTACHMENT';
export const DELETE_ATTACHMENT = 'DELETE_ATTACHMENT';
export const FETCH_ATTACHMENT = 'FETCH_ATTACHMENT';
export const SAVE_ATTACHMENT = 'SAVE_ATTACHMENT';
export const REMOVE_ATTACHMENT = 'REMOVE_ATTACHMENT';
export const CHANGE_ATTACHMENT_TYPE = 'CHANGE_ATTACHMENT_TYPE';
export const DOWNLOAD_ATTACHMENT = 'DOWNLOAD_ATTACHMENT';
export const DOWNLOAD_ALL_ATTACHMENTS = 'DOWNLOAD_ALL_ATTACHMENTS';

export interface IUploadAttachment {
  type: string;
  attachmentDetails: string;
  parentId: string;
  data: any;
  formId: string;
  location: any;
}

export interface IDeleteAttachment {
  type: string;
  id: string;
  data: string;
  parentId: string;
  formId: string;
}

export interface IFetchAttachment {
  type: string;
  entityName: string;
  parentId: string;
  referralId: string;
}

export interface ISaveAttachment {
  type: string;
  attachmentData: any;
  parentId: string;
}

export interface IRemoveAttachment {
  type: string;
  data: any;
  formId: string;
  location: any;
}

export interface IChangeAttachmentType {
  data: any;
  type: string;
  attachmentType: string;
  attachmentDetails: string;
  parentId: string;
  formId: string;
  location: any;
}

export interface IDownloadAttachment {
  type: string;
  id: string;
  name: string;
  data: any;
  cardId: string;
}

export interface IDownloadAllAttachments {
  type: string;
  fileData: any;
  cardId: string;
}

export function uploadAttachment(
  attachmentDetails: any,
  parentId: string,
  data: any,
  formId: string,
  location: any
): IUploadAttachment {
  return {
    type: UPLOAD_ATTACHMENT,
    attachmentDetails: attachmentDetails,
    parentId: parentId,
    data: data,
    formId: formId,
    location: location,
  };
}

export function deleteAttachment(
  id: string,
  name: string,
  parentId: string,
  formId: string
): IDeleteAttachment {
  return {
    type: DELETE_ATTACHMENT,
    id: id,
    data: name,
    parentId: parentId,
    formId: formId,
  };
}

export function fetchAttachment(
  entityName: string,
  parentId: string,
  referralId: string
): IFetchAttachment {
  return {
    type: FETCH_ATTACHMENT,
    entityName: entityName,
    parentId: parentId,
    referralId: referralId,
  };
}

export function saveAttachment(
  attachmentData: any,
  parentId: string
): ISaveAttachment {
  return {
    type: SAVE_ATTACHMENT,
    attachmentData: attachmentData,
    parentId: parentId,
  };
}

export function removeAttachment(
  data: any,
  formId: string,
  location: any
): IRemoveAttachment {
  return {
    type: REMOVE_ATTACHMENT,
    data: data,
    formId: formId,
    location: location,
  };
}

export function changeAttachmentType(
  attachmentDetails: any,
  parentId: string,
  data: any,
  attachmentType: string,
  formId: string,
  location: any
): IChangeAttachmentType {
  return {
    type: CHANGE_ATTACHMENT_TYPE,
    attachmentDetails: attachmentDetails,
    parentId: parentId,
    data: data,
    attachmentType: attachmentType,
    formId: formId,
    location: location,
  };
}

export function downloadAttachment(
  id: string,
  name: string,
  data: any,
  cardId: string
): IDownloadAttachment {
  return {
    type: DOWNLOAD_ATTACHMENT,
    id: id,
    name: name,
    data: data,
    cardId: cardId,
  };
}

export function downloadAllAttachments(
  fileData: any[],
  cardId: string
): IDownloadAllAttachments {
  return {
    type: DOWNLOAD_ALL_ATTACHMENTS,
    fileData: fileData,
    cardId: cardId,
  };
}
