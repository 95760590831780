import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { InfoAuth } from '../auth';

const Home: React.FC = () => {
  const [userName, setUserName] = useState('');
  const navigate = useNavigate();
  useEffect(() => {}, [userName]);

  return (
    <div>
      <h1>Welcome1 {userName}</h1>
      <p
        onClick={() => {
          InfoAuth.signOut()
            .then(() => {
              navigate('/auth');
            })
            .catch(() => console.log('error signing out...'));
        }}
      >
        Sign Out
      </p>
    </div>
  );
};

export default Home;
