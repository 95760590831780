import React, { useCallback, useContext, useEffect } from 'react';

import { RadioButton, FormErrorContainers } from 'info-ui-library';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';

import { extractLabel } from '../utils/intlutils';
import { ThemeContext } from '../theme/ThemeContext';
import { useFormData } from '../custom-hooks/useFormData';
import { changeFormData, getOptions } from '../actions/index';
import { IState } from '../store/index';
import { useErrorData } from '../custom-hooks/useErrorData';
import { IRadioButtonCore } from './interfaces/index';
import {
  processRadioButtonOptions,
  processErrorData,
} from './ComponentsCommonUtils';
import { useLocation } from 'react-router';

const RadioButtonCore: React.FC<IRadioButtonCore> = (
  props: IRadioButtonCore
) => {
  const currentLocation = useLocation();

  const existingCards = useSelector((state: any) => state.appState.cardsData);
  const options = existingCards[props.referralId].options;
  const dbCode = props.configProp.logicalColumn
    ? props.configProp.logicalColumn.dbCode
    : props.configProp.dbCode;
  const id = props.parentFormId ? props.parentFormId : props.formId;
  const key =
    id + '_' + props.occuranceNumber + '_' + props.configProp.configObjectId;
  const selectedOptions = options[key];

  let parsedOption = processRadioButtonOptions(
    props.configProp.multivalueList,
    props.intl.locale,
    selectedOptions
  );

  useEffect(() => {
    if (
      props.configProp.selectItemsReferenceID &&
      !props.configProp.multivalueList
    ) {
      dispatch(
        getOptions(
          props.parentFormId,
          props.formId,
          props.configProp,
          props.occuranceNumber,
          props.nodeId,
          props.referralId,
          currentLocation
        )
      );
    }
  }, [props.occuranceNumber]);

  let initialvalue = useFormData(
    props.formId,
    props.occuranceNumber,
    dbCode,
    props.parentFormId,
    props.referralId
  );
  const themeContext = useContext(ThemeContext);

  let errorData: any = useErrorData(props.location, props.referralId);
  let parsedError = processErrorData(errorData, props.intl.locale);

  const dispatch = useDispatch();
  try {
    const handleChange = useCallback(
      (key: string, props: any) => {
        dispatch(
          changeFormData(
            props.formId,
            props.occuranceNumber,
            dbCode,
            key,
            props.parentFormId,
            props.mode,
            props.nodeId,
            props.configProp.configObjectId,
            props.configProp.refreshFormOnChange,
            props.configProp.resetOnRefresh,
            props.configProp.placeHolder,
            props.referralId,
            currentLocation
          )
        );
      },
      [currentLocation, props]
    );

    return (
      <RadioButton
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={themeContext.styleName}
        label={extractLabel(props.configProp.label, props.intl.locale)}
        key={props.configProp.configObjectId}
        value={initialvalue}
        $displayType={themeContext.componentType}
        options={parsedOption}
        onChange={(key: any) => handleChange(key, props)}
        $errorMessage={parsedError}
        $hasError={errorData ? true : false}
        $configId={props.configProp.configObjectId}
        $mandatory={
          props.configProp.isMandatory && props.mandatoryIconAccessibilityRegex
        }
        disabled={!props.privilegesMap.isEditable}
      />
    );
  } catch {
    const error = ['Error occurred in Radio Button'];
    return (
      <FormErrorContainers
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};

export default React.memo(injectIntl(RadioButtonCore));
