import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from 'react-bootstrap';
export const LightTheme = {
  '$btn-primary-color': 'white',
  '$btn-primary-bg': '#004269',
};

export const DarkTheme = {
  '$btn-primary-color': 'white',
  '$btn-primary-bg': '#000000bf',
  color: '#000000bf',
  border: '1.4px solid #000000bf',
  bordercolor: '#000000bf',
  textDecoration: 'underline',
};

export const LoginContainer: any = styled.div`
  width: 100% !important;
  height: 93vh;
  justify-content: center;
  align-items: center;
  background: url(${(props: any) => (props.image ? props.image : null)})
    no-repeat fixed center;
  background-size: 100% 100%;
`;

export const WrapperContainer: any = styled.div`
  width: 29%;
  top: 18%;
  height: auto;
  display: block;
  position: relative;
  left: 35%;
  background: rgba(0, 0, 0, 0.4) !important;
  border-radius: 6px;
  border: none;
  overflow: hidden;
  padding: 20px 35px;
  justify-content: space-between;

  @media (max-width: 424px) {
    width: 96%;
    left: 2%;
  }

  @media (min-width: 425px) and (max-width: 639px) {
    width: 70%;
    left: 15%;
  }
  @media (min-width: 640px) and (max-width: 789px) {
    width: 50%;
    left: 25%;
  }
  @media (min-width: 790px) and (max-width: 990px) {
    width: 40%;
    left: 30%;
  }
  @media (min-width: 991px) and (max-width: 1140px) {
    width: 36%;
    left: 32%;
  }
`;

export const Form: any = styled.form`
  width: auto;
`;

export const LoginSpanContainer: any = styled.span`
  font-family: sans-serif;
  font-size: 23px;
  color: white;
  text-shadow: 2px 2px 4px #000000;
  line-height: 1.2;
  text-align: center;
  width: 100% !important;
  display: block;
  padding-bottom: ${(p: any) => (p.showMessage ? '40px' : '0px')};
`;

export const CustomButton: any = styled(Button)`
  margin-bottom: 20px;
  margin-top: 13px;
  width: 100%;
  background: #004269 !important;
  text-shadow: 2px 2px 4px #000000;
  border: 1px solid #004269 !important;
`;

export const RedirectContainer: any = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LinkSpan: any = styled.span`
  font-weight: 550;
  font-size: 17.5px;
  color: white;
  text-shadow: 2px 2px 4px #000000;
  text-decoration: ${(p) => p.theme.textDecoration};
  cursor: pointer;
`;

export const LinkComponent: any = styled(Link)`
  text-decoration: none;
`;
