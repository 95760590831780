import {
  put,
  all,
  fork,
  takeLatest,
  takeEvery,
  call,
  select,
} from 'redux-saga/effects';
import * as base from '../base';
import { CardData } from '../../models';
import {
  setToggleMessages,
  GATEWAY_FOR_UPSERT_OPERATION,
  upsertEntityRecord,
  upsertFormRecord,
} from '../../actions';
import { UpsertMessage, ROOT_NODE_ID } from '../../constants/appeng.enum';

export default function* gateWayForUpsertOperationSaga() {
  yield all([fork(watchGateWayForUpsertOperation)]);
}

function* watchGateWayForUpsertOperation() {
  yield takeEvery(GATEWAY_FOR_UPSERT_OPERATION, gateWayForUpsertOperation);
}

export function* gateWayForUpsertOperation(
  action: any
): Generator<any, any, any> {
  try {
    if (action.nodeId && action.nodeId !== ROOT_NODE_ID) {
      yield put(
        upsertEntityRecord(
          action.nodeId,
          action.actionName,
          action.transactionName,
          action.keys,
          action.referenceData,
          action.displayName,
          action.nodeType,
          action.ceId,
          action.mode,
          action.location,
          action.modalRequired,
          action.referralId,
          action.resetForm,
          action.portalForm,
          action.portalId,
          action.parentId,
          action.usePortalReferenceData
        )
      );
    } else {
      yield put(
        upsertFormRecord(
          action.parentFormId,
          action.actionName,
          action.transactionName,
          action.keys,
          action.referenceData,
          action.displayName,
          action.nodeType,
          action.ceId,
          action.mode,
          action.location,
          action.modalRequired,
          action.referralId,
          action.resetForm,
          action.portalForm,
          action.portalId,
          action.parentId,
          action.usePortalReferenceData
        )
      );
    }
  } catch (e) {
    console.log('gatwayforupsert===========', e);
    const messages: string[] = [];
    messages.push(UpsertMessage.UNSUCCESSFUL);
    yield put(setToggleMessages(messages));
  }
}
