export enum AuthMessageEnum {
  WEAK_PASSWORD = 'Weak Password',
  INVALID_PWD_RESET_CODE = 'Invalid Code',
  INVALID_CREDENTIAL = 'Invalid Email or Password',
  PENDING_VERIFICATION = 'Pending Verification',
  PENDING_CHANGE_PASSWORD = 'Pending Password Change',
  MISSING_PARAMETER = 'Missing mandatory parameters',
  LOCKED = '3 unsuccessful login attempts.Your Account is Locked.Reset Password using Forgot Password link.',
  USER_NOT_LOGGEDIN = 'User is not logged in',
  DEACTIVATED = 'User has been deactivated',
  INVALID_PHONE_NUMBER_FORMAT = 'Invalid Phone Number Format',
  INVALID_EMAIL_FORMAT = 'Invalid Email Address Format',
  USER_ALREADY_EXISTS = 'User Already Exists',
}
