import { useState, useEffect, useMemo } from 'react';

import { CompositeEntityNode } from '../api/metadata-query/CompositeEntityNode';
import { MenuGroup } from '../api/metadata-query/MenuGroup';
import { CompositeEntity } from '../api/metadata-query/CompositeEntity';
import { RootNode } from '../api/metadata-query/RootNode';
import { ChildNodesInTree } from '../api/metadata-query/ChildNodesInTree';
import { ConfigItemByType } from '../api/metadata-query/ConfigItemTypeQuery';
import { ReportForm } from '../api/metadata-query/ReportForm';
import { Portal } from '../api/metadata-query/Portal';
import { SubPortal } from '../api/metadata-query/SubPortalQuery';
import { Widget } from '../api/metadata-query/Widget';
import { PortalDataGrid } from '../api/metadata-query/PortalDataGrid';
import { PortalFilterForm } from '../api/metadata-query/PortalFilterForm';
import { PortalForm } from '../api/metadata-query/PortalForm';
import { FormQuery } from '../api/metadata-query/FormQuery';
import { DataGrid } from '../api/metadata-query/DataGrid';
import { PortalCard } from '../api/metadata-query/PortalCardQuery';
import {
  AUTH_TOKEN,
  QueryType,
  USER_SESSION_JWT,
} from '../constants/appeng.enum';
import { FormField } from '../api/metadata-query/FormFieldQuery';
import { LogicalEntity } from '../api/metadata-query/LogicalEntityQueryForName';
import { Form } from '../api/metadata-query/Form';
import { createStorage } from '../storage';
const { useQuery } = await import('@apollo/client');
let graphqlquery: any;

if (process.env.REACT_APP_PLATFORM === 'desktop') {
}

const getAuthToken = () => {
  const storage = createStorage();
  const auth = storage.getItem(AUTH_TOKEN);
  return auth;
};

const CallServer = (query: any, id: string, userDetails: any) => {
  const variables = useMemo(() => ({ id: id }), [id]);
  return useQuery(query, {
    variables,
    context: {
      headers: {
        authorization: getAuthToken(),
        userDetails: userDetails,
        provider: JSON.parse(process.env.REACT_APP_AUTH_DETAILS!).provider.type,
      },
    },
    fetchPolicy: 'cache-first',
  });
};

const CallServerForConfigItems = (query: any, id: string, userDetails: any) => {
  const variables = useMemo(() => ({ type: id }), [id]);
  return useQuery(query, {
    variables,
    context: {
      headers: {
        authorization: getAuthToken(),
        userDetails: userDetails,
        provider: JSON.parse(process.env.REACT_APP_AUTH_DETAILS!).provider.type,
      },
    },
    fetchPolicy: 'cache-first',
  });
};

const isServerEnv = process.env.REACT_APP_PLATFORM !== 'desktop';

export const useConfigQuery = (queryType: string, id: string) => {
  const storage = createStorage();
  id = id.includes('_') ? id.split('_')[1] : id;
  let query: any;
  let loading: boolean = true;
  let error: any = undefined;
  let data: any = {};
  const [result, setResult] = useState({
    loading: loading,
    error: error,
    data: data,
  });
  const [coreId, setCoreId] = useState(id);

  useEffect(() => {
    if (coreId !== id) {
      setResult({ loading: true, error: undefined, data: {} });
      setCoreId(id);
    }
  }, [id]);

  switch (queryType) {
    case 'NodeQuery':
      query = CompositeEntityNode;
      break;
    case 'MenuQuery':
      query = MenuGroup;
      break;
    case 'CEQuery':
      query = CompositeEntity;
      break;
    case 'RootNodeQuery':
      query = RootNode;
      break;
    case 'ChildNodeInTreeQuery':
      query = ChildNodesInTree;
      break;
    case 'ReportFormQuery':
      query = ReportForm;
      break;
    case 'PortalQuery':
      query = Portal;
      break;
    case 'WidgetQuery':
      query = Widget;
      break;
    case 'PortalDataGridQuery':
      query = PortalDataGrid;
      break;
    case 'PortalFilterFormQuery':
      query = PortalFilterForm;
      break;
    case 'PortalFormQuery':
      query = PortalForm;
      break;
    case 'FormQuery':
      query = FormQuery;
      break;
    case 'DataGridQuery':
      query = DataGrid;
      break;
    case 'ConfigItemByType':
      query = ConfigItemByType;
      break;
    case 'PortalCardQuery':
      query = PortalCard;
      break;
    case 'SubPortalQuery':
      query = SubPortal;
      break;
    case QueryType.FORMFIELD:
      query = FormField;
      break;
    case QueryType.LOGICAL_ENTITY:
      query = LogicalEntity;
      break;
    case QueryType.FORM:
      query = Form;
  }

  if (isServerEnv) {
    if (queryType !== 'ConfigItemByType') {
      const { loading, error, data } = CallServer(
        query,
        id,
        storage.getItem(USER_SESSION_JWT)
      );
      if (data !== result.data && !loading) {
        setResult({ loading, error, data });
      }
    } else {
      const { loading, error, data } = CallServerForConfigItems(
        query,
        id,
        storage.getItem(USER_SESSION_JWT)
      );
      if (data !== result.data && !loading) {
        setResult({ loading, error, data });
      }
    }
  } else {
    if (result.loading) {
      query = query.loc.source.body;
      query = query.trim();
      query = query.substring(query.indexOf('{'));
      query = query.substring(0, query.length);
      query = query.replace('$id', '"' + id + '"');
      const data = graphqlquery(query);
      data.then((config: any) => {
        setResult({ loading: false, error, data: config.data });
      });
    }
  }

  return result;
};
