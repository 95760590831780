import React from 'react';
import { Bar } from 'info-ui-library';

const InternalGridCall = (props: any) => {
  try {
    const GridSubComponent = props.gridSubComponent;
    return (
      <React.Fragment>
        <div
          style={{
            display: 'flex',
            width: '100%',
            alignItems: 'stretch',
            justifyContent: 'space-around',
          }}
        >
          <Bar
            styleName={props.styleName}
            ioTheme={props.ioTheme}
            ioMode={props.ioMode}
          />
          {props.gridSubComponent ? (
            <div style={{ width: '100%' }}>
              <GridSubComponent {...props} />
            </div>
          ) : (
            <div style={{ width: '100%' }}>
              {' '}
              <pre
                style={{
                  fontSize: '10px',
                }}
              >
                <code>
                  {JSON.stringify({ values: props.row.values }, null, 2)}
                </code>
              </pre>
            </div>
          )}
        </div>
      </React.Fragment>
    );
  } catch {
    return <div>No data available...</div>;
  }
};

export default InternalGridCall;
