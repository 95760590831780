export const ADD_MODALS_SAGA = 'ADD_MODALS_SAGA';
export const ADD_MODALS = 'ADD_MODALS';
export const CLOSE_MODALS = 'CLOSE_MODALS';
export const CLOSE_MODALS_SAGA = 'CLOSE_MODALS_SAGA';
export const SET_OPEN_MODAL = 'SET_OPEN_MODAL';
export const SET_OPEN_MODAL_SAGA = 'SET_OPEN_MODAL_SAGA';
export const ADD_MODAL_RELOAD = 'ADD_MODAL_RELOAD';
export const RELOAD_CLEAN_MODAL_DATA = 'RELOAD_CLEAN_MODAL_DATA';

export interface IAddModalsSaga {
  type: string;
  modalName: {};
}

export interface IAddModals {
  type: string;
  modalName: {};
}

export interface ICloseModals {
  type: string;
  occuranceNumber: number;
  formId: string;
}

export interface ICloseModalsSaga {
  type: string;
  modalType: string;
  occuranceNumber: number;
  nodeId: string;
}

export interface IOpenModal {
  type: string;
  portalId: string;
  isRemove: boolean;
  data?: any;
}

export interface IOpenModalSaga {
  type: string;
  portalId: string;
  isRemove: boolean;
  isBeforeSave: boolean;
  refreshData?: string[];
  cleanData?: string[];
  referenceData?: any;
  cssAttribute?: any;
}

export interface IAddModalReload {
  type: string;
  portalId: string;
}

export interface IReloadCleanModalData {
  type: string;
  reloadCards: any;
  cleanCards: any;
  portalId: string;
}

export function addModalsSaga(modalName: {}): IAddModalsSaga {
  return {
    type: ADD_MODALS_SAGA,
    modalName: modalName,
  };
}

export function addModals(modalName: {}): IAddModals {
  return {
    type: ADD_MODALS,
    modalName: modalName,
  };
}

export function closeModals(
  occuranceNumber: number,
  formId: string
): ICloseModals {
  return {
    type: CLOSE_MODALS,
    occuranceNumber: occuranceNumber,
    formId: formId,
  };
}

export function closeModalsSaga(
  modalType: string,
  occuranceNumber: number,
  nodeId: string
): ICloseModalsSaga {
  return {
    type: CLOSE_MODALS_SAGA,
    modalType: modalType,
    occuranceNumber: occuranceNumber,
    nodeId: nodeId,
  };
}

export function setOpenModal(
  portalId: string,
  isRemove: boolean,
  data?: any
): IOpenModal {
  return {
    type: SET_OPEN_MODAL,
    portalId: portalId,
    isRemove: isRemove,
    data: data,
  };
}

export function setOpenModalSaga(
  portalId: string,
  isRemove: boolean,
  isBeforeSave: boolean,
  refreshData?: string[],
  cleanData?: string[],
  referenceData?: any,
  cssAttribute?: any
): IOpenModalSaga {
  return {
    type: SET_OPEN_MODAL_SAGA,
    portalId: portalId,
    isRemove: isRemove,
    isBeforeSave: isBeforeSave,
    refreshData: refreshData,
    cleanData: cleanData,
    referenceData: referenceData,
    cssAttribute: cssAttribute,
  };
}

export function addModalReload(portalId: string): IAddModalReload {
  return {
    type: ADD_MODAL_RELOAD,
    portalId: portalId,
  };
}

export function reloadCleanModalData(
  reloadCards: any,
  cleanCards: any,
  portalId: string
): IReloadCleanModalData {
  return {
    type: RELOAD_CLEAN_MODAL_DATA,
    reloadCards: reloadCards,
    cleanCards: cleanCards,
    portalId: portalId,
  };
}
