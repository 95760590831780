import React from 'react';
import Portal from './Portal';
import { isConfigObjectAccessible } from '../composite-entity/form/CardGenerators';

const SubPortalGenerator = (props: any) => {
    let subportals = props.subPortals.map((subPortal: any,index:any) => {
        const routerParameter = {
            state: { ...props.routerParameter.state },
            pathName: props.routerParameter.pathName
        }
        routerParameter.state[props.subComponentParentId + subPortal.configObjectId] = { ...props.routerParameter.referenceData };
        let isAccessible = true;
        if (props.routerParameter.referenceData && props.routerParameter.referenceData.data) {
            isAccessible = isConfigObjectAccessible(subPortal.accessibilityRegex, props.routerParameter.referenceData.data);
        }
        return isAccessible && <Portal key={props.tabGroupId +"__"+props.tabPortalId} routerParameter={routerParameter} isSubPortal={true} subportalId={subPortal.configObjectId} subComponentParentId={props.subComponentParentId}
            tabGroupId={props.tabGroupId}
            tabPortalId={props.tabPortalId}
            parentCardId={props.parentCardId} 
           />
    })

  return <React.Fragment>{subportals}</React.Fragment>;
};

function isPropsAreEqual(prevProps: any, nextProps: any) {
  return (
    Object.is(prevProps.routerParameter, nextProps.routerParameter) &&
    Object.is(
      JSON.stringify(prevProps.routerParameter.referenceData),
      JSON.stringify(nextProps.routerParameter.referenceData)
    )
  );
}

export default React.memo(SubPortalGenerator, isPropsAreEqual);
