import { jwtVerify } from 'jose';
import { Credentials, TokenData } from '../auth/info.credentials';
import { AuthMessageEnum, ErrorCodeEnum } from '../constants';
import api from '../info-auth-api/api';
import AuthError from '../model/auth.error';
import AuthUser from '../model/auth.user';
import { ISignUpParams, IUserInfo, UserInfo } from '../model/user.info';
import UserSession from '../model/user.session';
import { createStorage } from '../storage';
import { SignUpResult } from '../types';
import { CodeDeliveryInfo } from '../types/delivery';
import { CurrentUserOpts } from '../types/user.opts';
import AuthProvider from './auth.provider';
import { SECRET_KEY } from '../constants';
import { v4 as uuidv4 } from 'uuid';

const decodeJWT = async (token: string, secret: string) => {
  const { payload } = await jwtVerify(token, new TextEncoder().encode(secret));
  return payload;
};

const createAuthUserFromToken = async (idToken: string) => {
  const jwtdata = await decodeJWT(idToken, SECRET_KEY);

  const objToMap: any = new Map(Object.entries(jwtdata));
  const userInfo: IUserInfo = {
    preferred_username: objToMap.get('email'),
    email: objToMap.get('email'),
    profile: objToMap.get('profile'),
    phone_number: objToMap.get('phone_number'),
    name: objToMap.get('name'),
    family_name: objToMap.get('family_name'),
    given_name: objToMap.get('given_name'),
    customAttributes: {},
  };
  const user: AuthUser = new AuthUser(
    objToMap.get('name'),
    new UserInfo(objToMap.get('sub'), objToMap.get('sub'), userInfo)
  );
  return user;
};

const createCredentialData = (userDetails: any, locale: any) => {
  const data: TokenData = {
    id: userDetails.id_token,
    access: userDetails.access_token,
    refresh: userDetails.refresh_token,
    language: locale,
  };
  return data;
};

const parseUserAuthSignUpParams = (params: ISignUpParams, config: any) => {
  const userAuthAttributesMap: any = config.provider.infoauth.userAttributeMap;

  const userAuthParams: any = {
    userName: params[userAuthAttributesMap.preferred_username],
  };
  for (const [key, value] of Object.entries(params)) {
    if (
      userAuthAttributesMap.hasOwnProperty(key) &&
      key !== 'preferred_username'
    ) {
      userAuthParams[userAuthAttributesMap[key]] = value;
    }
  }
  return userAuthParams;
};

const parseUserProfileSignUpParams = (
  params: ISignUpParams,
  res: SignUpResult,
  config: any
) => {
  const userProfileAttributesMap =
    config.provider.infoauth.userProfileAttributeMap;

  let userData: any = {};
  try {
    userData = JSON.parse(res.sub);
  } catch (e) {
    userData.aeUserAuthUuid = res.sub;
  }

  const userProfileParams: any = {
    aeUserProfileUuid: userData.aeUserAuthUuid,
    aeTransactionId: userData.aeTransactionId
      ? userData.aeTransactionId
      : uuidv4(),
  };

  for (const [key, value] of Object.entries(params)) {
    if (userProfileAttributesMap.hasOwnProperty(key)) {
      userProfileParams[userProfileAttributesMap[key]] = value;
    }
  }

  return userProfileParams;
};

export class InfoAuthProvider implements AuthProvider {
  private config: any;
  private credentials: Credentials;

  public constructor(config: any) {
    this.config = config;
    const storage = createStorage(config);
    this.credentials = new Credentials(storage, config);
  }

  public async signIn(
    userName: string,
    password: string,
    locale: string
  ): Promise<AuthUser | any> {
    try {
      const userMap = {
        userName,
        password,
      };
      const userDetails = await api.signIn(
        this.config.provider.infoauth.redirectUri,
        userMap
      );
      this.credentials.setCredential(createCredentialData(userDetails, locale));
      return createAuthUserFromToken(userDetails.id_token);
    } catch (e) {
      const apiError = (e as { errorResponse?: { errors: any[] } })
        .errorResponse?.errors?.[0];
      if (!apiError) {
        throw e;
      }
      switch (apiError.reason) {
        case ErrorCodeEnum.INVALID_CREDENTIAL:
          throw new AuthError(
            apiError.message,
            ErrorCodeEnum.INVALID_CREDENTIAL
          );
        case ErrorCodeEnum.PENDING_VERIFICATION:
          throw new AuthError(
            AuthMessageEnum.PENDING_VERIFICATION,
            ErrorCodeEnum.PENDING_VERIFICATION,
            apiError.extendedHelp
          );
        case ErrorCodeEnum.PENDING_CHANGE_PASSWORD:
          throw new AuthError(
            AuthMessageEnum.PENDING_VERIFICATION,
            ErrorCodeEnum.PENDING_CHANGE_PASSWORD
          );
        case ErrorCodeEnum.MISSING_PARAMETER:
          throw new AuthError(
            AuthMessageEnum.MISSING_PARAMETER,
            ErrorCodeEnum.PENDING_CHANGE_PASSWORD
          );
        case ErrorCodeEnum.LOCKED:
          throw new AuthError(AuthMessageEnum.LOCKED, ErrorCodeEnum.LOCKED);
        case ErrorCodeEnum.DEACTIVATED:
          throw new AuthError(
            AuthMessageEnum.DEACTIVATED,
            ErrorCodeEnum.DEACTIVATED
          );
        default:
          throw new AuthError(e.message, ErrorCodeEnum.SYSTEM_ERROR);
      }
      throw e;
    }
  }

  public async signUp(params: ISignUpParams): Promise<SignUpResult> {
    try {
      const userAuthSignupParam = parseUserAuthSignUpParams(
        params,
        this.config
      );
      const userDetails = await api.signUp(
        this.config.provider.infoauth.redirectUri,
        userAuthSignupParam
      );
      const res: SignUpResult = {
        sub: JSON.stringify(userDetails),
        preferred_username: userDetails.userName,
        isUserConfirmed: false,
      };
      return res;
    } catch (e) {
      const apiError = (e as { errorResponse?: { errors: any[] } })
        .errorResponse?.errors?.[0];
      if (!apiError) {
        throw e;
      }
      switch (apiError.reason) {
        case ErrorCodeEnum.INVALID_EMAIL_FORMAT:
          throw new AuthError(
            apiError.message,
            ErrorCodeEnum.INVALID_EMAIL_FORMAT
          );
        case ErrorCodeEnum.WEAK_PASSWORD:
          throw new AuthError(apiError.message, ErrorCodeEnum.WEAK_PASSWORD);
        default:
          throw new AuthError(e.message, ErrorCodeEnum.SYSTEM_ERROR);
      }
      throw e;
    }
  }

  public async signUpUserProfile(params: ISignUpParams, res: SignUpResult) {
    try {
      const userProfileSignupParam = parseUserProfileSignUpParams(
        params,
        res,
        this.config
      );
      await api.signUpUserProfile(
        this.config.provider.infoauth.redirectUri,
        userProfileSignupParam
      );
    } catch (e) {
      throw e;
    }
  }

  public async confirmSignUp(
    userName: string,
    code: string,
    transactionId: string
  ): Promise<string> {
    try {
      const userMap = {
        userName,
        code,
        transactionId,
      };
      const userDetails = await api.confirmSignUp(
        this.config.provider.infoauth.redirectUri,
        userMap
      );

      return 'SUCCESS';
    } catch (e) {
      throw e;
    }
  }

  public async insertDefaultUserMapping(
    userId: string,
    transactionId: string
  ): Promise<string> {
    try {
      const userMapping = await api.insertDefaultUserMapping(
        this.config.provider.infoauth.redirectUri,
        userId,
        transactionId
      );

      return 'SUCCESS';
    } catch (e) {
      throw e;
    }
  }

  public async resendSignUp(username: string): Promise<CodeDeliveryInfo> {
    try {
      const userMap = {
        userName: username,
      };
      const userDetails = await api.resendVerificationCode(
        this.config.provider.infoauth.redirectUri,
        userMap
      );
      const delivery: CodeDeliveryInfo = {
        attributeName: userDetails.codeDeliveries[0].attributeName,
        deliveryMedium: userDetails.codeDeliveries[0].deliveryMedium,
        destination: userDetails.codeDeliveries[0].destination,
      };
      return delivery;
    } catch (e) {
      throw e;
    }
  }

  public async signOut(): Promise<string> {
    this.credentials.removeCredential();
    return 'SUCCESS';
  }

  public async changePassword(
    userName: string,
    oldPassword: string,
    newPassword: string
  ): Promise<string> {
    try {
      const userMap = {
        userName,
        oldPassword,
        newPassword,
      };
      const userDetails = await api.changePassword(
        this.config.provider.infoauth.redirectUri,
        userMap
      );

      return 'SUCCESS';
    } catch (e) {
      throw e;
    }
    return 'SUCCESS';
  }

  public async forgotPassword(userName: string): Promise<CodeDeliveryInfo> {
    try {
      const userMap = {
        userName,
      };
      const result = await api.forgotPassword(
        this.config.provider.infoauth.redirectUri,
        userMap
      );

      const delivery: CodeDeliveryInfo = {
        attributeName: result.codeDeliveries[0].attributeName,
        deliveryMedium: result.codeDeliveries[0].deliveryMedium,
        destination: result.codeDeliveries[0].destination,
      };
      return delivery;
    } catch (e) {
      throw e;
    }
  }

  public async forgotPasswordSubmit(
    userName: string,
    code: string,
    password: string
  ): Promise<string> {
    try {
      const userMap = {
        userName,
        code,
        password,
      };
      const result = await api.passwordReset(
        this.config.provider.infoauth.redirectUri,
        userMap
      );

      return 'SUCCESS';
    } catch (e) {
      throw e;
    }
  }

  public async currentAuthenticatedUser(
    params?: CurrentUserOpts
  ): Promise<AuthUser | any> {
    const userSession: UserSession = await this.currentSession();
    if (userSession.idToken) {
      return createAuthUserFromToken(userSession.idToken.token);
    } else {
      throw new AuthError(
        AuthMessageEnum.USER_NOT_LOGGEDIN,
        ErrorCodeEnum.USER_NOT_LOGGEDIN
      );
    }
  }

  public async currentSession(): Promise<UserSession> {
    const userSession: UserSession | undefined =
      await this.credentials.getSession();
    if (userSession && userSession.isValid()) {
      return userSession;
    } else {
      throw new AuthError(
        AuthMessageEnum.USER_NOT_LOGGEDIN,
        ErrorCodeEnum.USER_NOT_LOGGEDIN
      );
    }
  }

  public async updateUserAttributes(
    user: AuthUser,
    attributes: object
  ): Promise<string> {
    return 'SUCCESS';
  }
}

let infoAuthProvider: InfoAuthProvider;

const createInfoAuthProvider = (config: any): InfoAuthProvider => {
  if (infoAuthProvider === undefined) {
    configureInfoAuth(config);
    infoAuthProvider = new InfoAuthProvider(config);
    Object.freeze(infoAuthProvider);
  }
  return infoAuthProvider;
};

const configureInfoAuth = (config: any) => {};

export default createInfoAuthProvider;
