export const SET_FORM_DATA = 'SET_FORM_DATA';
export const CHANGE_FORM_DATA = 'CHANGE_FORM_DATA';
export const GET_FORM_DATA = 'GET_FORM_DATA';
export const GET_REPORT_FORM_DATA = 'GET_REPORT_FORM_DATA';
export const GET_FORMDATA_USING_FORMCONFIG = 'GET_FORMDATA_USING_FORMCONFIG';
export const UPSERT_FORM_RECORD = 'UPSERT_FORM_RECORD';
export const SET_ROUTER_FORM_DATA = 'SET_ROUTER_FORM_DATA';
export const UPSERT_ENTITY_RECORD = 'UPSERT_ENTITY_RECORD';
export const UPSERT_ENTITY_USING_LE = 'UPSERT_ENTITY_USING_LE';
export const HANDLE_REPEATABLE = 'HANDLE_REPEATABLE';
export const DELETE_RECORD = 'DELETE_RECORD';
export const EXECUTE_ACTION_FLOW = 'EXECUTE_ACTION_FLOW';
export const GET_OPTIONS = 'GET_OPTIONS';
export const SET_OPTIONS = 'SET_OPTIONS';
export const SET_OPTIONS_IN_BULK = 'SET_OPTIONS_IN_BULK';
export const CUSTOM_FILTER = 'CUSTOM_FILTER';
export const SUBMIT_ACTION = 'SUBMIT_ACTION';

export interface ISetFormData {
  type: string;
  formData: any;
  formId: string;
  resetOtherForms: boolean;
  loading: boolean;
  hasReloadData: boolean;
}

export interface IChangeFormData {
  type: string;
  formId: string;
  occuranceNumber: number;
  dbCode: string;
  value: string | number | Date;
  parentFormId: string;
  mode: string;
  nodeId: string;
  formFieldId: string;
  refreshRequired: boolean;
  resetOnRefresh: string;
  placeHolder: any;
  referralId: string;
  location: any;
}

export interface IGetFormData {
  type: string;
  nodeId: string;
  mode: string;
  nodeType: string;
  ceId: string;
  primaryKey: any;
  referralId: string;
  resetForm: boolean;
  location: any;
  referenceData?: any;
  usePortalReferenceData?: boolean;
  portalId: string;
  parentId: string;
  parentCardId?: string;
  gridButtonAction?: boolean;
}

export interface IReportFormData {
  type: string;
  formId: string;
  referralId: string;
  resetOtherForms: boolean;
  cardPortalId: string;
}

export interface ISetRouterFormData {
  type: string;
  routerParameter: any;
  formData: any;
  formId: string;
}

export interface IGetFormDataUsingFormConfig {
  type: string;
  formId: string;
  mode: string;
  nodeType: string;
  primaryKey: any;
  referralId: string;
  resetForm: boolean;
  location: any;
  referenceData?: any;
  usePortalReferenceData?: boolean;
  portalId: string;
  rowData?: any;
  parentId: string;
  tabGroupId?: string;
  entityName?: string;
  tabPortalId?: string;
  entityId?: string;
  parentCardId?: string;
}

export interface IUpsertFormRecord {
  type: string;
  formId: string;
  actionName: string;
  transactionName: string;
  keys: any;
  referenceData: any;
  displayName: string;
  nodeType: string;
  ceId: string;
  mode: string;
  location: any;
  modalRequired: boolean;
  referralId: string;
  resetForm: boolean;
  portalForm: boolean;
  usePortalReferenceData?: boolean;
  portalId: string;
  parentId: string;
}

export interface IUpsertEntityRecord {
  type: string;
  nodeId: string;
  actionName: string;
  transactionName: string;
  keys: any;
  referenceData: any;
  displayName: string;
  nodeType: string;
  ceId: string;
  mode: string;
  location: any;
  modalRequired: boolean;
  referralId: string;
  resetForm: boolean;
  portalForm: boolean;
  usePortalReferenceData?: boolean;
  portalId: string;
  parentId: string;
}

export interface IUpsertEntityUsingLE {
  type: string;
  reqBody: any;
  leId: string;
  primaryKey: any;
  cardId: string;
}

export interface IHandleRepeatable {
  type: string;
  formDataRepObject: {};
  formId: string;
  parentFormId: string;
  operationType: string;
  occuranceNumber: number;
  nodeId: string;
  mode: string;
  referralId: string;
  location: any;
  parentId: string;
  portalId: string;
  usePortalReferenceData: boolean;
}

export interface IDeleteRecord {
  type: string;
  primaryKey: string | null;
  logicalEntityIdOrName: string;
  ceId: string;
  nodeId: string;
  gridId: string;
  redirectToGrid: boolean;
  location: any;
  referralId: string;
  parentId: string;
  portalId: string;
  usePortalReferenceData: boolean;
  parentFormId?: any;
}

export interface IGetOption {
  type: string;
  parentFormId: string;
  formId: string;
  occuranceNumber: number;
  formFieldConfiData: any;
  nodeId: string;
  referralId: string;
  location: any;
  referenceData: any;
}

export interface ICustomFilter {
  type: string;
  id: any;
  cardId: string;
}

export interface IExecuteActionFlow {
  type: string;
  flowId: string;
  data: any;
  histroy: any;
}

export interface ISetOption {
  type: string;
  options: {};
  key: string;
}

export interface ISetOptionInBulk {
  type: string;
  options: {};
}

export interface ISubmitAction {
  type: string;
  tabGroupId: string;
}

export function setFormData(
  formData: any,
  formId: string,
  resetOtherForms: boolean,
  loading: boolean,
  hasReloadData: boolean
): ISetFormData {
  return {
    type: SET_FORM_DATA,
    formData: formData,
    formId: formId,
    resetOtherForms: resetOtherForms,
    loading: loading,
    hasReloadData: hasReloadData,
  };
}

export function changeFormData(
  formId: string,
  occuranceNumber: number,
  dbCode: string,
  value: string | number | Date,
  parentFormId: string,
  mode: string,
  nodeId: string,
  formFieldId: string,
  refreshRequired: boolean,
  resetOnRefresh: string,
  placeHolder: any,
  referralId: string,
  location: any
): IChangeFormData {
  return {
    type: CHANGE_FORM_DATA,
    formId: formId,
    occuranceNumber: occuranceNumber,
    dbCode: dbCode,
    value: value,
    parentFormId: parentFormId,
    mode: mode,
    nodeId: nodeId,
    formFieldId: formFieldId,
    refreshRequired: refreshRequired,
    resetOnRefresh: resetOnRefresh,
    placeHolder: placeHolder,
    referralId: referralId,
    location: location,
  };
}

export function getFormData(
  nodeId: string,
  mode: string,
  nodeType: string,
  ceId: string,
  primaryKey: any,
  referralId: string,
  resetForm: boolean,
  location: any,
  parentId: string,
  portalId: string,
  referenceData?: any,
  usePortalReferenceData?: boolean,
  parentCardId?: string,
  gridButtonAction?: boolean
): IGetFormData {
  return {
    type: GET_FORM_DATA,
    nodeId: nodeId,
    mode: mode,
    nodeType: nodeType,
    ceId: ceId,
    primaryKey: primaryKey,
    referralId: referralId,
    resetForm: resetForm,
    location: location,
    referenceData: referenceData,
    usePortalReferenceData: usePortalReferenceData,
    portalId: portalId,
    parentId: parentId,
    parentCardId: parentCardId,
    gridButtonAction: gridButtonAction,
  };
}

export const getReportFormData = (
  formId: string,
  referralId: string,
  resetOtherForms: boolean,
  cardPortalId: string
): IReportFormData => {
  return {
    type: GET_REPORT_FORM_DATA,
    formId: formId,
    referralId: referralId,
    resetOtherForms: resetOtherForms,
    cardPortalId: cardPortalId,
  };
};

export function getFormDataUsingFormConfig(
  formId: string,
  mode: string,
  nodeType: string,
  primaryKey: any,
  referralId: string,
  resetForm: boolean,
  location: any,
  parentId: string,
  portalId: string,
  referenceData?: any,
  usePortalReferenceData?: boolean,
  rowData?: any,
  tabGroupId?: string,
  entityName?: string,
  tabPortalId?: string,
  entityId?: string,
  parentCardId?: string
): IGetFormDataUsingFormConfig {
  return {
    type: GET_FORMDATA_USING_FORMCONFIG,
    formId: formId,
    mode: mode,
    nodeType: nodeType,
    primaryKey: primaryKey,
    referralId: referralId,
    resetForm: resetForm,
    location: location,
    referenceData: referenceData,
    usePortalReferenceData: usePortalReferenceData,
    portalId: portalId,
    rowData: rowData,
    parentId: parentId,
    tabGroupId: tabGroupId,
    entityName: entityName,
    tabPortalId: tabPortalId,
    entityId: entityId,
    parentCardId: parentCardId,
  };
}

export function upsertFormRecord(
  formId: string,
  actionName: string,
  transactionName: string,
  keys: any,
  referenceData: any,
  displayName: string,
  nodeType: string,
  ceId: string,
  mode: string,
  location: any,
  modalRequired: boolean,
  referralId: string,
  resetForm: boolean,
  portalForm: boolean,
  portalId: string,
  parentId: string,
  usePortalReferenceData?: boolean
): IUpsertFormRecord {
  return {
    type: UPSERT_FORM_RECORD,
    formId: formId,
    actionName: actionName,
    transactionName: transactionName,
    keys: keys,
    referenceData: referenceData,
    displayName: displayName,
    nodeType: nodeType,
    ceId: ceId,
    mode: mode,
    location: location,
    modalRequired: modalRequired,
    referralId: referralId,
    resetForm: resetForm,
    portalForm: portalForm,
    usePortalReferenceData: usePortalReferenceData,
    portalId: portalId,
    parentId: parentId,
  };
}

export function setRouterAndFormData(
  routerParameter: any,
  formData: any,
  formId: string
): ISetRouterFormData {
  return {
    type: SET_ROUTER_FORM_DATA,
    routerParameter: routerParameter,
    formData: formData,
    formId: formId,
  };
}

export function upsertEntityRecord(
  nodeId: string,
  actionName: string,
  transactionName: string,
  keys: any,
  referenceData: any,
  displayName: string,
  nodeType: string,
  ceId: string,
  mode: string,
  location: any,
  modalRequired: boolean,
  referralId: string,
  resetForm: boolean,
  portalForm: boolean,
  portalId: string,
  parentId: string,
  usePortalReferenceData?: boolean
): IUpsertEntityRecord {
  return {
    type: UPSERT_ENTITY_RECORD,
    nodeId: nodeId,
    actionName: actionName,
    transactionName: transactionName,
    keys: keys,
    referenceData: referenceData,
    displayName: displayName,
    nodeType: nodeType,
    ceId: ceId,
    mode: mode,
    location: location,
    modalRequired: modalRequired,
    referralId: referralId,
    resetForm: resetForm,
    portalForm: portalForm,
    usePortalReferenceData: usePortalReferenceData,
    portalId: portalId,
    parentId: parentId,
  };
}

export function upsertEntityUsingLE(
  reqBody: any,
  leId: string,
  primaryKey: any,
  cardId: string
): IUpsertEntityUsingLE {
  return {
    type: UPSERT_ENTITY_USING_LE,
    reqBody: reqBody,
    leId: leId,
    primaryKey: primaryKey,
    cardId: cardId,
  };
}

export function handleRepeatable(
  formDataRepObject: {},
  formId: string,
  parentFormId: string,
  operationType: string,
  occuranceNumber: number,
  nodeId: string,
  mode: string,
  referralId: string,
  location: any,
  parentId: string,
  portalId: string,
  usePortalReferenceData: boolean
): IHandleRepeatable {
  return {
    type: HANDLE_REPEATABLE,
    formDataRepObject: formDataRepObject,
    formId: formId,
    parentFormId: parentFormId,
    operationType: operationType,
    occuranceNumber: occuranceNumber,
    nodeId: nodeId,
    mode: mode,
    referralId: referralId,
    location: location,
    parentId: parentId,
    portalId: portalId,
    usePortalReferenceData: usePortalReferenceData,
  };
}

export const deleteRecord = (
  primaryKey: string | null,
  logicalEntityIdOrName: string,
  ceId: string,
  nodeId: string,
  gridId: string,
  redirectToGrid: boolean,
  location: any,
  referralId: string,
  parentId: string,
  portalId: string,
  usePortalReferenceData: boolean,
  parentFormId?: any
): IDeleteRecord => {
  return {
    type: DELETE_RECORD,
    primaryKey: primaryKey,
    logicalEntityIdOrName: logicalEntityIdOrName,
    ceId: ceId,
    nodeId: nodeId,
    gridId: gridId,
    redirectToGrid: redirectToGrid,
    location: location,
    referralId: referralId,
    parentId: parentId,
    portalId: portalId,
    usePortalReferenceData: usePortalReferenceData,
    parentFormId: parentFormId,
  };
};

export function executeActionFlow(
  flowId: string,
  data: any,
  histroy: any
): IExecuteActionFlow {
  return {
    type: EXECUTE_ACTION_FLOW,
    flowId: flowId,
    data: data,
    histroy: histroy,
  };
}

export function getOptions(
  parentFormId: string,
  formId: string,
  formFieldConfiData: any,
  occuranceNumber: number,
  nodeId: string,
  referralId: string,
  location: any,
  referenceData?: any
): IGetOption {
  return {
    type: GET_OPTIONS,
    parentFormId: parentFormId,
    formId: formId,
    formFieldConfiData: formFieldConfiData,
    occuranceNumber: occuranceNumber,
    nodeId: nodeId,
    referralId: referralId,
    location: location,
    referenceData: referenceData,
  };
}

export function setOptions(key: string, options: {}): ISetOption {
  return {
    type: SET_OPTIONS,
    key: key,
    options: options,
  };
}

export function setOptionsInBulk(options: {}): ISetOptionInBulk {
  return {
    type: SET_OPTIONS_IN_BULK,
    options: options,
  };
}

export function customFilter(id: string, cardId: string): ICustomFilter {
  return {
    type: CUSTOM_FILTER,
    id: id,
    cardId: cardId,
  };
}

export function submitAction(tabGroupId: string): ISubmitAction {
  return {
    type: SUBMIT_ACTION,
    tabGroupId: tabGroupId,
  };
}
