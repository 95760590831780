export enum StorageEnum {
  COOKIE = 'cookie',
  MEMORY = 'memory',
  WINDOW = 'window',
  SESSION = 'session',
}

export const parseStorageEnum = (storage: string) => {
  switch (storage) {
    case StorageEnum.COOKIE:
      return StorageEnum.COOKIE;
    case StorageEnum.MEMORY:
      return StorageEnum.MEMORY;
    case StorageEnum.WINDOW:
      return StorageEnum.WINDOW;
    case StorageEnum.SESSION:
      return StorageEnum.SESSION;
    default:
      throw new Error('Invalid or Unsupported Storage type ' + storage);
  }
};
