import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IState } from '../store/index';
import { gateWayToGetFormData } from '../actions';
import { useLocation } from 'react-router';

export const useFetchAppFormData = (
  nodeId: string,
  mode: string,
  nodeType: string,
  ceId: string,
  rowData: any,
  formId: string,
  primaryDBCode: string,
  referralId: string,
  resetForm: boolean,
  referenceData: any,
  refReload: any,
  parentId: string,
  portalId: string,
  usePortalReferenceData?: boolean,
  tabGroupId?: string,
  entityName?: string,
  tabPortalId?: string,
  entityId?: string,
  parentCardId?: string
) => {
  const dispatch = useDispatch();
  let formLoading: boolean = true;
  let appData: any = {};

  const cardsData = useSelector(
    (state: any) => state.appState.cardsData[referralId]
  );
  const userDetails = useSelector((state: any) => state.appState.userDetails);
  const location = useLocation();
  let card = cardsData;
  const formData = card && card.type === 'PortalForm' ? card.data : {};

  const reloadId = refReload.ID;
  const primaryKey =
    rowData && rowData[primaryDBCode]
      ? rowData[primaryDBCode]
      : referenceData && referenceData[primaryDBCode]
        ? referenceData[primaryDBCode]
        : undefined;
  const [result, setResult] = useState({
    formLoading: formLoading,
    appData: appData,
  });

  useEffect(() => {
    if (referenceData.AE_RELOAD && referenceData.AE_RELOAD.STATUS !== 'RT') {
      dispatch(
        gateWayToGetFormData(
          nodeId,
          primaryKey ? 'Edit' : 'Insert',
          nodeType,
          ceId,
          primaryKey,
          referralId,
          resetForm,
          location,
          formId,
          parentId,
          portalId,
          referenceData,
          usePortalReferenceData,
          rowData,
          tabGroupId,
          entityName,
          tabPortalId,
          entityId,
          parentCardId
        )
      );
      setResult({ formLoading: true, appData: {} });
    }
  }, [ceId, primaryKey, reloadId, formId, userDetails]);

  useEffect(() => {
    if (
      card &&
      !card.cardLoading &&
      Object.entries(formData).length > 0 &&
      formData !== result.appData
    ) {
      setResult({ formLoading: false, appData: formData });
    }
  }, [formData]);
  return result;
};
