import gql from 'graphql-tag';

export const Form = gql`
  query Form($id: ID!) {
    Form(id: $id) {
      isRepeatable
      filterFormReloadControl
      defaultDataString
      tabRequiredInFormsection
      tab
      order
      formType
      privileges {
        privilegeId
        privilegeType
        itemId
        roleId
      }
      expressionAvailable
      accessibilityRegex
      editabilityRegex
      formLabel
      maxRepeatation
      defaultRepeatation
      repeatationStyle
      isDeletionAllowed
      configObjectId
      name
      configObjectType
      projectId
      formSections {
        configObjectId
        headerLabel
        order
        displayName
        accessibilityRegex
        editabilityRegex
        expressionAvailable
        tabGroup
        requiredFormfields
        componentsPerRow
        privileges {
          privilegeId
          privilegeType
          itemId
          roleId
        }
        name
        configObjectType
        projectId
        formFields {
          mandatoryIconAccessibilityRegex
          formatDate
          isApplyDateFormat
          entityName
          parentDBCode
          dbCode
          layout
          label
          label
          order
          defaultValue
          colspan
          type
          editorType
          height
          multivalueList
          resetOnRefresh
          isMandatory
          selectItemsReferenceID
          isAsyncOption
          isMulti
          placeHolder
          expressionAvailable
          refreshFormOnChange
          accessibilityRegex
          editabilityRegex
          configObjectId
          showYearDropdown
          showMonthDropdown
          maxDate
          minDate
          dropdownMode
          timeFormat
          showTimeSelect
          showTimeSelectOnly
          timeCaption
          timeIntervals

          privileges {
            privilegeId
            privilegeType
            itemId
            roleId
          }
          name
          configObjectType
          itemDescription
          projectId
          logicalColumn {
            isPrimaryKey
            dataType
            isVerticalFieldKey
            dbCode
            length
            dbType
            isMandatory
            jsonName
            mode
            isUnique
            isDerived
            configObjectId
            name
          }
          buttons {
            label
            order
            type
            dbCode
            toolTip
            buttonStyle
            icon
            modalRequired
            componentId
            buttonClass
            warningMessage
            portalId
            buttonAlignment
            accessibilityRegex
            editabilityRegex
            expressionAvailable
            configObjectId
            name
            configObjectType
            projectId
            modalCss
            transactionName
            childRelations {
              relationType
              parentItemId
              childItemId
            }
            privileges {
              privilegeId
              privilegeType
              itemId
              roleId
            }
          }
          fontSize
          hasSupportingLabel
          hasValueInSupportingLabel
          supportingLabel
          supportingLabelFontSize
          labelColor
        }
      }
    }
  }
`;
