import React, { useContext } from 'react';
import { Button } from 'info-ui-library';
import { useDispatch } from 'react-redux';
import { UserContext } from '../../../user-context/UserContext';
import { isConfigObjectAccessible } from '../../../composite-entity/form/CardGenerators';
import styled from 'styled-components';
import {v4 as uuid} from 'uuid';


const StyledButton = styled(Button)`
  &.btn {
    width: ${(p) => (p.name ? 'auto' : '25px')};
    margin-top: 8px !important;
    padding-top: 0rem !important;
    padding-right: 0rem !important;
    padding-bottom: 0rem !important;
    padding-left: 0rem !important;
  }
`;

function handleClick(
  onClickHandler,
  parentProps,
  actionColumnType,
  hrefValue,
  rowDetails,
  dispatch,
  dbCode,
  label,
  configObjectId,
  button
) {
  let column = {};
  column['actionColumnType'] = actionColumnType;
  column['hrefValue'] = hrefValue;
  column['displayName'] = dbCode;
  column['label'] = label;
  column['configObjectId'] = configObjectId;
  column['modalCss'] = button.modalCss;
  if (onClickHandler) {
    onClickHandler(column, rowDetails, parentProps, dispatch);
  }
}

const ActionButton = ({
    column,
    row
}) => {
    const dispatch = useDispatch();
    const userContext = useContext(UserContext);
    let showButton = row.original.expander && row.original.expander === 'NewRow' ? false : true;
    return column.buttons.map(button => {
        let rowDetails = row.original;
        let isAccessibleRegex = isConfigObjectAccessible(button.accessibilityRegex, Object.assign({}, rowDetails, userContext.getCurrentRole()));
        if (button.isAccessible && showButton && isAccessibleRegex) {
            let label = button.HeaderName ? button.HeaderName : button.toolTip ? button.toolTip : button && button.icon && button.icon.toUpperCase()
            return <StyledButton
                style={ {padding: '0.3rem ',
                paddingTop: '2px',
                paddingBottom:'2px'
                }}
                key={uuid()}
                title={button.toolTip}
                className={button.icon}
                icon={button.icon}
                name={button.HeaderName}
                id={button.id}
                styleName={column.styleName}
                ioTheme={column.ioTheme}
                ioMode={column.ioMode}
                onClick={(e) => handleClick(button.onClickHandler, button.parentProps, button.actionColumnType, button.hrefValue, row, dispatch, button.dbCode, label, button.configObjectId,button)}
                size="sm" ></StyledButton>
        }
    })
}

export default React.memo(ActionButton);
