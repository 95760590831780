import gql from 'graphql-tag';

export const ChildNodesInTree = gql`
  query ChildNodesInTree($id: ID!) {
    ChildNodesInTree(id: $id) {
      configObjectId
      displayNodeName
      accessibilityRegex
      order
      expressionAvailable
      name
      childRelations {
        relationType
        parentItemId
        childItemId
      }
    }
  }
`;
