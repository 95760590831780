import { lazy } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ParentWrapperContainer: any = styled.div`
  width: 100% !important;
  min-height: 93vh;
  display: flex;
  flexwrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: url(${(props: any) => (props.image ? props.image : null)})
    no-repeat fixed center;
  background-size: 100% 100%;
`;
export const StyleIcon = styled(FontAwesomeIcon)`
  font-size: 0.7em;
`;
export const WrapperContainer: any = styled.div`
  width: auto;
  background: rgba(0, 0, 0, 0.4) !important;
  border-radius: 6px;
  border: none;
  overflow: hidden;
  padding: 20px 35px;
  display: flex;
  flexwrap: wrap;
  justify-content: space-between;
`;

export const Form: any = styled.form`
  width: 320px !important;
`;

export const SignUpTitleSpanContainer: any = styled.span`
  font-family: sans-serif;
  font-size: 23px;
  color: #fff;
  line-height: 1.2;
  text-align: center;
  width: 100% !important;
  display: block;
  padding-bottom: ${(p: any) => (p.showMessage ? '100px' : '0px')};
`;

export const ConfirmationTitleSpanContainer: any = styled.span`
  font-family: sans-serif;
  font-size: 23px;
  color: #fff;
  line-height: 1.2;
  text-align: center;
  width: 100% !important;
  display: block;
  padding-bottom: ${(p: any) => (p.showMessage ? '40px' : '0px')};
`;

export const CustomButton: any = styled(Button)`
  margin-bottom: 20px;
  margin-top: 13px;
  width: 100%;
  background: #004269 !important;
`;

export const RedirectContainer: any = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LinkSpan: any = styled.span`
  font-weight: 550;
  color: ${(p: any) => (p.theme.color ? p.theme.color : '#fff')} !important;
  text-decoration: ${(p: any) => p.theme.textDecoration};
  cursor: pointer;
`;
export const ConfirmPasswordStartMsgOuterContainer: any = styled.div`
  flex-wrap: wrap;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: flex;
`;
export const ConfirmationMsg: any = styled.span`
  color: #0c5460;
  background-color: #d1ecf1;
  border: 1px solid;
  padding: 10px 50px 10px 50px;
  border-color: rgb(80, 158, 227);
  border-radius: 4px;
  width: 100%;
  text-align: center;
`;

export const LinkComponent: any = styled(Link)`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;
