import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { resetCardData } from '../actions';

export function useCleanPortalData(portalId: string, isSubPortal: any) {
  const [resetting, setResetting] = useState(true);
  const dispatch = useDispatch();
  const existingCards = useSelector((state: any) => state.appState.cardsData);

  useEffect(() => {
    if (!isSubPortal && existingCards && existingCards[portalId]) {
      const emptyCardData = {};
      dispatch(resetCardData(emptyCardData, ''));
      setResetting(true);
    } else {
      setResetting(false);
    }
  }, [portalId, isSubPortal]);

  useEffect(() => {
    if (Object.keys(existingCards).length == 0) {
      setResetting(false);
    }
  }, [Object.keys(existingCards).length]);

  return { resetting };
}
