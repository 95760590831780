import React from 'react';
import styled from 'styled-components';
import { IBTButton } from '../../infoui-components/components/Button';
import { resolveColor } from '../../ThemeOptions';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';

library.add(fas);
library.add(far);
const StyledButton: any = styled(Button)`
    color: ${(p: any) =>
        p.isLink ? 'initial' :
            resolveColor(p.$ioTheme, p.$ioMode, p.$styleName, 'color') +
            '!important'};
    background: ${(p: any) => p.$isLink ? 'initial' : resolveColor(p.$ioTheme, p.$ioMode, p.$styleName, 'background') +
        '!important'};
    border-color: ${(p: any) => p.$isLink ? 'initial' :
        resolveColor(p.$ioTheme, p.$ioMode, p.$styleName, 'borderColor') +
        '!important'};
    border-radius: ${(p: any) => p.$radius + '!important'};
    margin-bottom: 5px!important;
    margin-top: 3px !important;
    padding: 0.1rem 1.6rem;
    font-size: 1vw !important;
    margin-left: 5px !important;
    @media(max-width:700px){
        font-size:11px !important;
   
    }
`;

const BTButton: React.FC<IBTButton> = ({ioTheme,ioMode,styleName,...props}: IBTButton) => {
    let newProps = { ...props };
    delete newProps?.className;
    return <StyledButton $ioTheme={ioTheme} $ioMode={ioMode} $styleName={styleName}
        color={props.isLink ? 'link' : ''} $radius={props.radius} $isLink={props.isLink} {...newProps}>
        {props.className && <FontAwesomeIcon icon={getIconDef(props.icon ? props.icon : props.className)} />}
        {props.children}
        {props.name}
    </StyledButton>;


};

export const getIconDef = (iconClass: any): any => {
    const icon = typeof (iconClass) === 'string' ? iconClass.trim() : iconClass;
    let result = iconClass;
    switch (icon) {
        case 'fa fa-fast-forward': {
            result = "fa-solid fa-forward-fast";
            break;
        }
        case 'fa fa-step-backward': {
            result = "fa-solid fa-backward-step";
            break;
        }
        case 'fa fa-step-forward': {
            result = "fa-solid fa-forward-step";
            break;
        }
        case 'fa fa-fast-backward': {
            result = "fa-solid fa-backward-fast";
            break;
        }
        case 'fa fa-caret-square-o-right': {
            result = 'fa-solid fa-square-caret-right';
            break;
        }
        case 'fa fa-pencil-square-o': {
            result = 'fa-solid fa-square-pen';
            break;
        }
        case 'fa fa-ellipsis-v': {
            result = 'fa-solid fa-ellipsis-vertical';
            break;
        }

        case 'fa fa-refresh fa-spin': {
            result = 'fa-solid fa-arrows-rotate';
            break;
        }
        case 'fa fa-user-plus': {
            result = "fa-solid fa-user-plus";
            break;
        }
        case 'fa fa-close': {
            result = "fa-solid fa-xmark";
            break;
        }
        case 'fa fa-calendar': {
            result = "fa-solid fa-calendar-days";
            break;
        }
        case 'fa fa-language fa-language': {
            result = "fa-solid fa-language";
            break;
        }
        case 'fa fa-list': {
            result = "fa-solid fa-list";
            break;
        }
        case 'fa fa-circle': {
            result = "fa-solid fa-circle";
            break;
        }
        case 'fa fa-bars': {
            result = "fa-solid fa-bars";
            break;
        }
        case 'fa fa-plus': {
            result = "fa-solid fa-plus";
            break;
        }
        case 'fa fa-minus': {
            result = "fa-solid fa-minus";
            break;
        }
        case 'fa fa-trash-o': {
            result = "fa-solid fa-trash";
            break;
        }
        case 'fa fa-arrow-left': {
            result = "fa-solid fa-arrow-left";
            break;
        }
        case 'fa fa-arrow-right': {
            result = "fa-solid fa-arrow-right";
            break;
        }
        case 'fa fa-times-circle': {
            result = "fa-solid fa-circle-xmark";
            break;
        }
        case 'fa fa-check-circle': {
            result = "fa-solid fa-circle-check";
            break;
        }
        case 'fa fa-window-close-o': {
            result = "fa-solid fa-rectangle-xmark";
            break;
        }

        case 'fa fa-filter': {
            result = 'fa-solid fa-filter';
            break;
        }

        case 'fa fa-plus-circle': {
            result = 'fa-solid fa-circle-plus';
            break;
        }
       
        case 'fa fa-caret-square-o-down': {
            result = "fa-solid fa-square-caret-down";
            break;
        }
        case 'fa fa-share-square-o': {
            result = "fa-solid fa-share-from-square";
            break;
        }
    }
    return result;
}

export default React.memo(BTButton);
