import gql from 'graphql-tag';

export const RootNode = gql`
  query CompositeEntityNode($id: ID!) {
    CompositeEntityNode(id: $id) {
      configObjectId
      displayNodeName
      accessibilityRegex
      order
      expressionAvailable
      name
      children {
        configObjectId
        displayNodeName
        accessibilityRegex
        order
        expressionAvailable
        name
        childRelations {
          relationType
          parentItemId
          childItemId
        }
      }
    }
  }
`;
