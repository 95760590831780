// @ts-nocheck
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext } from 'react';
import { FormErrorContainers } from 'info-ui-library';
import { useSelector } from 'react-redux';
import { ThemeContext } from '../../theme/ThemeContext';
import SideNavigationButton from './SideNavigationButton';
import { useConfigQuery } from '../../custom-hooks/useConfigQuery';
import GridConfigButtonComponent from './GridConfigButtonComponent';
import { IGridButtonComponent, IGridButtonGateWay } from '../interfaces/index';
import { QueryType, NodeType } from '../../constants/appeng.enum';

const GridButtonGateWay: React.FC<IGridButtonGateWay> = (
  props: IGridButtonGateWay
) => {
  if (props.routerParameter.ceId && props.nodeType === NodeType.PARENTNODE) {
    const { loading, error, data } = useConfigQuery(
      QueryType.COMPOSITE_ENTITY_QUERY,
      props.routerParameter.ceId
    );

    if (loading && props.routerParameter.ceId) {
      return <div></div>;
    }

    const newProps = {
      ...props,
      isNodeTreeRequired: data.CompositeEntity.isNodeTreeRequired,
      nodeId: data.CompositeEntity.rootCompositeEntityNode
        ? data.CompositeEntity.rootCompositeEntityNode.configObjectId
        : props.routerParameter.nodeId,
      ceId: props.routerParameter.ceId,
      nodeType: props.nodeType,
      gridId: props.routerParameter.gridId,
      routerParametr: props.routerParameter,
      setCardButtons: props.setCardButtons,
      setCardLabel: props.setCardLabel,
      portalId: props.portalId,
      parentId: props.parentId,
      editableGridEnable: props.editableGridEnable,
      setEditableGridEnable: props.setEditableGridEnable,
      subComponentParentId: props.subComponentParentId,
    };
    return <GridButtonComponent {...newProps} />;
  } else if (
    props.routerParameter.ceId &&
    props.nodeType === NodeType.CHILDNODE
  ) {
    const newProps = {
      ...props,
      isNodeTreeRequired: true,
      nodeId: props.routerParameter.nodeId,
      ceId: props.routerParameter.ceId,
      nodeType: props.nodeType,
      gridId: props.routerParameter.gridId,
      routerParametr: props.routerParameter,
      setCardButtons: props.setCardButtons,
      setCardLabel: props.setCardLabel,
      portalId: props.portalId,
      parentId: props.parentId,
      editableGridEnable: props.editableGridEnable,
      setEditableGridEnable: props.setEditableGridEnable,
      subComponentParentId: props.subComponentParentId,
    };
    return <GridButtonComponent {...newProps} />;
  } else {
    const newProps = {
      ...props,
      isNodeTreeRequired: false,
      nodeId: '',
      ceId: '',
      nodeType: props.nodeType,
      gridId: props.routerParameter.gridId,
      routerParameter: props.routerParameter,
      setCardButtons: props.setCardButtons,
      portalId: props.portalId,
      parentId: props.parentId,
      editableGridEnable: props.editableGridEnable,
      setEditableGridEnable: props.setEditableGridEnable,
      subComponentParentId: props.subComponentParentId,
    };
    return <GridButtonComponent {...newProps} />;
  }
};

const GridButtonComponent: React.FC<IGridButtonComponent> = (
  props: IGridButtonComponent
) => {
  const themeContext = useContext(ThemeContext);
  try {
    return (
      <React.Fragment>
        {props.isNodeTreeRequired &&
        props.routerParameter.pathname.includes('/app/ce/child/grid') ? (
          <SideNavigationButton />
        ) : null}
        <GridConfigButtonComponent
          gridId={props.gridId}
          nodeId={props.nodeId}
          ceId={props.ceId}
          nodeType={props.nodeType}
          routerParameter={props.routerParameter}
          handleClick={props.handleClick}
          setCardButtons={props.setCardButtons}
          setCardLabel={props.setCardLabel}
          portalId={props.portalId}
          parentId={props.parentId}
          editableGridEnable={props.editableGridEnable}
          setEditableGridEnable={props.setEditableGridEnable}
          subComponentParentId={props.subComponentParentId}
        />
      </React.Fragment>
    );
  } catch {
    const error = ['Error occurred in Form Button'];
    return (
      <FormErrorContainers
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};

function isPropsAreEqual(prevProps: any, nextProps: any) {
  return (
    JSON.stringify(prevProps.referenceData) ===
      JSON.stringify(nextProps.referenceData) &&
    prevProps.editableGridEnable === nextProps.editableGridEnable
  );
}

export default React.memo(GridButtonGateWay, isPropsAreEqual);
