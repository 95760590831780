import React, { useContext, useCallback } from 'react';
import { Button, FormErrorContainers } from 'info-ui-library';
import { useDispatch, useSelector } from 'react-redux';

import { ThemeContext } from '../../theme/ThemeContext';
import { setLeftMenuIndicator } from '../../actions/index';
import { IState } from '../../store/index';
import {
  LeftMenuIndicator,
  LEFT_ARROW_ICON,
  RIGHT_ARROW_ICON,
  SWITCH_ICON,
} from '../../constants/appeng.enum';

const SideNavigationButton: React.FC = () => {
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const leftMenuIndicator = useSelector(
    (state: any) => state.appState.leftMenuIndicator
  );
  try {
    const navigateButtonIcon =
      leftMenuIndicator === LeftMenuIndicator.LEFTMENU
        ? SWITCH_ICON
        : leftMenuIndicator === LeftMenuIndicator.SIDENODETREE
          ? LEFT_ARROW_ICON
          : RIGHT_ARROW_ICON;
    const sideNavigationClick = (sideIndicator: any) => {
      dispatch(setLeftMenuIndicator(sideIndicator));
    };
    return (
      <React.Fragment>
        <Button
          styleName={themeContext.styleName}
          ioTheme={themeContext.ioTheme}
          ioMode={themeContext.ioMode}
          id={'switch'}
          name={''}
          onClick={() => sideNavigationClick(LeftMenuIndicator.SIDENODETREE)}
          className={navigateButtonIcon}
        />
      </React.Fragment>
    );
  } catch {
    const error = ['Error occurred in Side Navigation Button'];
    return (
      <FormErrorContainers
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};

export default SideNavigationButton;
