interface IUserProfile {
  name?: string;
  family_name?: string;
  given_name?: string;
  middle_name?: string;
  nickname?: string;
  preferred_username: any;
  profile?: string;
  picture?: string;
  website?: string;
  gender?: string;
  birthdate?: string;
  zoneinfo?: string;
  locale?: string;
}

interface IEmail {
  email?: string;
}

interface IAddress {
  formatted?: string;
  street_address?: string;
  locality?: string;
  region?: string;
  postal_code?: string;
  country?: string;
}

interface IPhone {
  phone_number?: string;
}

export interface ICustomUserAttributes {
  role?: string;
  customAttributes?: { [key: string]: any };
}

interface IServerParams {
  updated_at?: number;
  email_verified?: boolean;
  phone_number_verified?: string;
}
export interface IUserInfo
  extends IUserProfile,
    IEmail,
    IAddress,
    IPhone,
    ICustomUserAttributes,
    IServerParams {
  [index: string]: string | boolean | number | undefined | object;
}

export interface ISignUpParams
  extends IUserProfile,
    IEmail,
    IAddress,
    IPhone,
    ICustomUserAttributes {
  [index: string]: string | boolean | undefined | object;
  password: string;
}

export class UserInfo implements IUserInfo {
  [index: string]: string | boolean | number | undefined | object;
  readonly sub: string;
  readonly oid: string;

  readonly name?: string;
  readonly family_name?: string;
  readonly given_name?: string;
  readonly middle_name?: string;
  readonly nickname?: string;
  readonly preferred_username: string;
  readonly profile?: string;
  readonly picture?: string;
  readonly website?: string;
  readonly gender?: string;
  readonly birthdate?: string;
  readonly zoneinfo?: string;
  readonly locale?: string;
  readonly updated_at?: number;

  readonly email?: string;
  readonly email_verified?: boolean;

  readonly phone_number?: string;
  readonly phone_number_verified?: string;

  readonly formatted?: string;
  readonly street_address?: string;
  readonly locality?: string;
  readonly region?: string;
  readonly postal_code?: string;
  readonly country?: string;

  readonly role?: string;
  readonly customAttributes: object;

  constructor(sub: string, oid: string, userInfo: IUserInfo) {
    let fullName: string | undefined;
    if (userInfo.given_name || userInfo.family_name) {
      fullName = userInfo.given_name
        ? userInfo.given_name
        : '' + ' ' + userInfo.family_name
          ? userInfo.family_name
          : '';
    }
    this.sub = sub;
    this.oid = oid;
    this.name = userInfo.name ? userInfo.name : fullName;
    this.family_name = userInfo.family_name;
    this.given_name = userInfo.given_name;
    this.middle_name = userInfo.middle_name;
    this.nickname = userInfo.nickname;
    this.preferred_username = userInfo.preferred_username;

    this.profile = userInfo.profile;
    this.picture = userInfo.picture;
    this.website = userInfo.website;
    this.gender = userInfo.gender;
    this.birthdate = userInfo.birthdate;
    this.zoneinfo = userInfo.zoneinfo;
    this.locale = userInfo.locale;
    this.updated_at = userInfo.updated_at;

    this.email = userInfo.email;
    this.email_verified = userInfo.email_verified;

    this.phone_number = userInfo.phone_number;
    this.phone_number_verified = userInfo.phone_number_verified;

    this.formatted = userInfo.formatted;
    this.street_address = userInfo.street_address;
    this.locality = userInfo.locality;
    this.region = userInfo.region;
    this.postal_code = userInfo.postal_code;
    this.country = userInfo.country;

    this.role = userInfo.role;
    this.customAttributes = userInfo.customAttributes
      ? userInfo.customAttributes
      : {};
  }
}
