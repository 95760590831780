import DefaultColumnFilter from './filterGridComponents/DefaultColumnFilter';
import { fuzzyTextFilterFn } from './filterGridComponents/FilterType';
import DefaultCell from './editableGridComponents/DefaultCell';
import MobileViewCell from './editableGridComponents/MobileViewCell';
export const deleteActionHandler = (e) => {};

export const viewEditActionHandler = (e) => {};

export const filterTypes = {
  fuzzyText: fuzzyTextFilterFn,
  text: (rows, id, filterValue) => {
    return rows.filter((row) => {
      const rowValue = row.values[id];
      return rowValue !== undefined
        ? String(rowValue)
            .toLowerCase()
            .startsWith(String(filterValue).toLowerCase())
        : true;
    });
  },
};

export const defaultColumn = {
  Filter: DefaultColumnFilter,
  Cell: MobileViewCell,
};
