import * as React from 'react';
import Router from './Router';
import { IntlProvider } from 'react-intl';
import { flattenMessages } from '../lang/utils';
import en from '../lang/English';
import hi from '../lang/Hindi';
import gu from '../lang/Gujarati';
import { useLoaderData } from 'react-router-dom';

interface ContextProps {
  language: string;
  setLanguage: (language: string) => void;
  message: any;
  setMessage: (message: any) => void;
}
const createDefaultLanguage = (): ContextProps => ({
  language: 'en',
  setLanguage: () => {},
  message: {},
  setMessage: () => {},
});
export const LanguageContext = React.createContext<ContextProps>(
  createDefaultLanguage()
);
const App: React.FC<any> = (props: any) => {
  const [language, setLanguage] = React.useState('en');
  const [message, setMessage] = React.useState(flattenMessages(en));
  const loaderData: any = useLoaderData();
  const setGlobalLanguage = (language: any) => {
    setLanguage(language);
  };

  const setMessages = (language: any) => {
    let message = {};
    if (language === 'en') {
      message = flattenMessages(en);
    } else if (language === 'gu') {
      message = flattenMessages(gu);
    } else {
      message = flattenMessages(hi);
    }
    setMessage(message);
  };

  return (
    <div className="App">
      <LanguageContext.Provider
        value={{
          language,
          setLanguage: setGlobalLanguage,
          message,
          setMessage: setMessages,
        }}
      >
        <IntlProvider locale={language} messages={message}>
          <Router
            locale={language}
            message={message}
            setMessages={setMessages}
            loaderData={loaderData}
            {...props}
          />
        </IntlProvider>
      </LanguageContext.Provider>
    </div>
  );
};

export default App;
