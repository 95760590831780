export default {
  advanceSearch: {
    search: 'Search',
    reset: 'Reset',
    close: 'Close',
  },
  mandatory: 'Please fill following Mandatory Fields',
  invalid: 'Please correct the following Invalid data',
  addForm: 'Add Form',
  reportForm: 'Report Form',
  save: 'Save',
  pleaseWait: 'Please Wait...',
  filterForm: 'Filter Form',
  filter: 'Filter',
  select: '-select-',
  chooseOption: 'choose a option...',
  uploadClick: 'Click here to Upload',
  resize: 'Resize',
  action: 'Action',
  history: 'History',
  editor: 'Editor',
  totalRecords:
    '{recordCount} {recordCount, plural,one {record} other {records}}',
};
