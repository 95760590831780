import React, { useCallback, useContext } from 'react';
import { injectIntl } from 'react-intl';
import { TextArea, FormErrorContainers } from 'info-ui-library';
import { useDispatch } from 'react-redux';

import { ThemeContext } from '../theme/ThemeContext';
import { extractLabel } from '../utils/intlutils';
import { changeFormData } from '../actions/index';
import { useFormData } from '../custom-hooks/useFormData';
import { useErrorData } from '../custom-hooks/useErrorData';
import { processErrorData } from './ComponentsCommonUtils';
import { ITextAreaCore } from './interfaces/index';
import { useLocation } from 'react-router';

const TextAreaCore: React.FC<ITextAreaCore> = (props: ITextAreaCore) => {
  const currentLocation = useLocation();

  const dbCode = props.configProp.logicalColumn
    ? props.configProp.logicalColumn.dbCode
    : props.configProp.dbCode;
  let initialvalue = useFormData(
    props.formId,
    props.occuranceNumber,
    dbCode,
    props.parentFormId,
    props.referralId
  );

  let errorData: any = useErrorData(props.location, props.referralId);

  let parsedError = processErrorData(errorData, props.intl.locale);

  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);

  const handleChange = useCallback(
    (updatedValue: string) => {
      dispatch(
        changeFormData(
          props.formId,
          props.occuranceNumber,
          dbCode,
          updatedValue && updatedValue.trim(),
          props.parentFormId,
          props.mode,
          props.nodeId,
          props.configProp.configObjectId,
          props.configProp.refreshFormOnChange,
          props.configProp.resetOnRefresh,
          props.configProp.placeHolder,
          props.referralId,
          currentLocation
        )
      );
    },
    [currentLocation, props]
  );

  const handleBlur = useCallback(
    (updatedValue: any) => {
      if (updatedValue !== initialvalue) {
        dispatch(
          changeFormData(
            props.formId,
            props.occuranceNumber,
            dbCode,
            updatedValue?.trim(),
            props.parentFormId,
            props.mode,
            props.nodeId,
            props.configProp.configObjectId,
            props.configProp.refreshFormOnChange,
            props.configProp.resetOnRefresh,
            props.configProp.placeHolder,
            props.referralId,
            currentLocation
          )
        );
      }
    },
    [currentLocation, props]
  );
  try {
    return (
      <TextArea
        ioTheme={themeContext.ioTheme}
        configId={props.configProp.configObjectId}
        ioMode={themeContext.ioMode}
        styleName={themeContext.styleName}
        label={extractLabel(props.configProp.label, props.intl.locale)}
        height={props.configProp.height}
        key={props.configProp.configObjectId}
        value={initialvalue}
        displayType={themeContext.componentType}
        mandatory={
          props.configProp.isMandatory && props.mandatoryIconAccessibilityRegex
        }
        errorMessage={parsedError}
        hasError={errorData ? true : false}
        handleChange={(updatedValue: any) => handleChange(updatedValue)}
        disabled={!props.privilegesMap.isEditable}
        handleBlur={(updatedValue: any) => handleBlur(updatedValue)}
        occuranceNumber={props.occuranceNumber}
      />
    );
  } catch {
    const error = ['Error occurred in TextBox'];
    return (
      <FormErrorContainers
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};

export default React.memo(injectIntl(TextAreaCore));
