export const GET_CHART_DATA = 'GET_CHART_DATA';
export const SET_CHART_DATA = 'SET_CHART_DATA';

export interface IGetChartData {
  type: string;
  chartId: string;
  referralId: string;
  data: any;
  portalId: string;
  parentId: string;
}

export interface ISetChartData {
  type: string;
  chartData: any;
  chartId: string;
}

export function getChartData(
  chartId: string,
  referralId: string,
  data: any,
  portalId: string,
  parentId: string
): IGetChartData {
  return {
    type: GET_CHART_DATA,
    chartId: chartId,
    referralId: referralId,
    data: data,
    portalId: portalId,
    parentId: parentId,
  };
}

export function setChartData(chartData: any, chartId: string): ISetChartData {
  return {
    type: SET_CHART_DATA,
    chartData: chartData,
    chartId: chartId,
  };
}
