interface ApiErrorDetails {
  domain: string;
  reason: string;
  message: string;
  locationType: string;
  location: string;
  extendedHelp: string;
}

export interface ApiErrorResponse {
  code: number;
  message: string;
  errors?: ApiErrorDetails[] | null;
}

export class ApiError extends Error {
  public errorResponse?: ApiErrorResponse;
  constructor(m?: string, errorResponse?: ApiErrorResponse) {
    super(m);

    Object.setPrototypeOf(this, ApiError.prototype);
    this.errorResponse = errorResponse;
  }
}
