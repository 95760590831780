import gql from 'graphql-tag';

export const LogicalEntityOperationQuery = gql`
  query DataGrid($id: ID!) {
    DataGrid(id: $id) {
      configObjectId
      isServerPaginationEnable 
      logicalEntityOperations {
        configObjectId
      }
      actionFlows {
        configObjectId
      }
    }
  }
`;
