export const UPDATE_PORTAL_CARD = 'UPDATE_PORTAL_CARD';
export const SET_PORTAL_DETAILS = 'SET_PORTAL_DETAILS';
export const SET_PORTAL_LAYOUT = 'SET_PORTAL_LAYOUT';
export const HANDLE_PORTAL_LAYOUT_CHANGE = 'HANDLE_PORTAL_LAYOUT_CHANGE';
export const GET_OPTIONS_IN_PORTAL_CARD = 'GET_OPTIONS_IN_PORTAL_CARD';
export const HANDLE_RELOAD = 'HANDLE_RELOAD';
export const RESET_CARD_DATA = 'RESET_CARD_DATA';
export const RESET_CARD_REFERENCE_DATA = 'RESET_CARD_REFERENCE_DATA';
export const REMOVE_CARD_DATA_BY_REFERRAL_ID =
  'REMOVE_CARD_DATA_BY_REFERRAL_ID';
export const REMOVE_ACCORDION_CARDS = 'REMOVE_ACCORDION_CARDS';

export interface IUpdatePortalCard {
  type: string;
  params: any;
}

export interface IsetPortalDetails {
  type: string;
  details: any;
}

export interface ISetPortalLayout {
  type: string;
  layout: any;
}

export interface IHandlePortalLayoutChange {
  type: string;
  layout: any;
}

export interface IGetOptionsInPortalCard {
  type: string;
  data: any;
  formfieldId: string;
  cardId: string;
}

export interface IHandleReload {
  type: string;
  cardId: string;
}

export interface IResetCardData {
  type: string;
  cardData: any;
  portalId: string;
}

export interface IResetCardReferenceData {
  type: string;
  cardId: string;
}

export interface IRemoveCardDataByReferralId {
  type: string;
  referralId: string;
}

export interface IRemoveAccordionCards {
  type: string;
  id: any;
}

export function updatePortalCard(params: any): IUpdatePortalCard {
  return {
    type: UPDATE_PORTAL_CARD,
    params: params,
  };
}

export const setPortalDetails = (details: any): IsetPortalDetails => {
  return {
    type: SET_PORTAL_DETAILS,
    details: details,
  };
};

export const setPortalLayout = (layout: any): ISetPortalLayout => {
  return {
    type: SET_PORTAL_LAYOUT,
    layout: layout,
  };
};

export const handlePortalLayoutChange = (
  layout: any
): IHandlePortalLayoutChange => {
  return {
    type: HANDLE_PORTAL_LAYOUT_CHANGE,
    layout: layout,
  };
};

export function getOptionsInPortalCard(
  data: any,
  formfieldId: string,
  cardId: string
): IGetOptionsInPortalCard {
  return {
    type: GET_OPTIONS_IN_PORTAL_CARD,
    data: data,
    formfieldId: formfieldId,
    cardId: cardId,
  };
}

export const handleReload = (cardId: string): IHandleReload => {
  return {
    type: HANDLE_RELOAD,
    cardId: cardId,
  };
};

export function resetCardData(cardData: any, portalId: string): IResetCardData {
  return {
    type: RESET_CARD_DATA,
    cardData: cardData,
    portalId: portalId,
  };
}

export const resetCardReferenceData = (
  cardId: string
): IResetCardReferenceData => {
  return {
    type: RESET_CARD_REFERENCE_DATA,
    cardId: cardId,
  };
};

export const removeCardDataByReferralId = (
  referralId: string
): IRemoveCardDataByReferralId => {
  return {
    type: REMOVE_CARD_DATA_BY_REFERRAL_ID,
    referralId: referralId,
  };
};

export function removeAccordionCards(id: any): IRemoveAccordionCards {
  return {
    type: REMOVE_ACCORDION_CARDS,
    id: id,
  };
}
