import React, { useContext, useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { DateTimePicker, FormErrorContainers } from 'info-ui-library';
import { injectIntl } from 'react-intl';
import moment from 'moment';

import { extractLabel } from '../utils/intlutils';
import { ThemeContext } from '../theme/ThemeContext';
import { useFormData } from '../custom-hooks/useFormData';
import { useErrorData } from '../custom-hooks/useErrorData';
import { changeFormData } from '../actions/index';
import { ITimePickerCore } from './interfaces/index';
import { processErrorData } from './ComponentsCommonUtils';
import { useLocation } from 'react-router';

const TimePickerCore: React.FC<ITimePickerCore> = (props: ITimePickerCore) => {
  const currentLocation = useLocation();

  const dbCode = props.configProp.logicalColumn
    ? props.configProp.logicalColumn.dbCode
    : props.configProp.dbCode;
  const initial_value = useFormData(
    props.formId,
    props.occuranceNumber,
    dbCode,
    props.parentFormId,
    props.referralId
  );
  var today = moment.utc(new Date()).local().format('YYYY-MM-DD');
  const initialvalue =
    initial_value &&
    Object.prototype.toString.call(initial_value) === '[object Date]'
      ? initial_value
      : initial_value
        ? new Date(today + ' ' + initial_value).toISOString()
        : null;
  let utcToLocalDate = initialvalue
    ? moment.utc(initialvalue).local().format()
    : null;

  let errorData: any = useErrorData(props.location, props.referralId);
  let parsedError = processErrorData(errorData, props.intl.locale);

  const themeContext = useContext(ThemeContext);
  const [selectedDateTime, setDateTime] = useState(
    initialvalue ? new Date(initialvalue) : new Date()
  );
  useEffect(() => {
    if (
      initialvalue &&
      new Date(initialvalue) &&
      selectedDateTime &&
      selectedDateTime.getTime() !== new Date(initialvalue).getTime()
    ) {
      let formattedDate: any;
      if (initialvalue) {
        formattedDate = new Date(initialvalue);
      }
      setDateTime(formattedDate);
    }
  });
  const dispatch = useDispatch();
  try {
    const handleChange = useCallback(
      (updatedValue: any) => {
        let timeExtracted: any = updatedValue
          ? moment.utc(updatedValue).local().format('HH:mm:ss')
          : null;
        dispatch(
          changeFormData(
            props.formId,
            props.occuranceNumber,
            dbCode,
            timeExtracted,
            props.parentFormId,
            props.mode,
            props.nodeId,
            props.configProp.configObjectId,
            props.configProp.refreshFormOnChange,
            props.configProp.resetOnRefresh,
            props.configProp.placeHolder,
            props.referralId,
            currentLocation
          )
        );
      },
      [currentLocation, props]
    );

    return (
      <DateTimePicker
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        displayType={themeContext.componentType}
        styleName={themeContext.styleName}
        label={extractLabel(props.configProp.label, props.intl.locale)}
        onChange={(updatedValue: any) => handleChange(updatedValue)}
        mandatory={
          props.configProp.isMandatory && props.mandatoryIconAccessibilityRegex
        }
        showTimeSelect={true}
        showTimeSelectOnly={true}
        timeIntervals={15}
        dateFormat="h:mm aa"
        selected={utcToLocalDate ? new Date(utcToLocalDate) : null}
        errorMessage={parsedError}
        disabled={!props.privilegesMap.isEditable}
        hasError={errorData ? true : false}
        id={props.configProp.configObjectId}
      />
    );
  } catch {
    const error = ['Error occurred in TimePicker'];
    return (
      <FormErrorContainers
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};

export default React.memo(injectIntl(TimePickerCore));
