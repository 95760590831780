import { IState } from '../store/index';
import {
  RESET_CARD_DATA,
  ADD_MODALS,
  CLOSE_MODALS,
  SET_GRID_DATA,
  SET_TREE_DATA,
  SET_FORM_DATA,
  SET_LEFT_MENU_INDICATOR,
  SET_FILE_DROPPABLE_INDICATOR,
  SET_CURRENT_ROOT_NODE,
  SET_OPTIONS,
  SET_OPTIONS_IN_BULK,
  SET_ERROR_DATA,
  SET_TOGGLE_MESSAGES,
  SWITCH_LANGUAGE,
  SET_USER_PROFILE,
  SET_CURRENT_FINANCIAL_YEAR,
  SET_STORE,
  SET_CHART_DATA,
  SET_PORTAL_GRID_SELECTED_ROW_DATA,
  RESET_CARD_REFERENCE_DATA,
  SET_PORTAL_DETAILS,
  REMOVE_CARD_DATA_BY_REFERRAL_ID,
  SET_GRID_PAGE_INDEX,
  SET_PORTAL_LAYOUT,
  SET_OPEN_MODAL,
  RELOAD_CLEAN_MODAL_DATA,
  SET_EDITABLE_GRID_STATUS,
  REMOVE_ACCORDION_CARDS,
  SET_WARNING_MESSAGES,
  NAVIGATION_REQUESTED,
  NAVIGATION_COMPLETED,
} from '../actions/index';
import { CardType, DUMMY_PORTAL_ID } from '../constants/appeng.enum';
import { getLocaleMessages } from '../utils/intlutils';
import { v4 as uuid } from 'uuid';

export default function reducer(
  state: IState | null | undefined,
  actions: any
) {
  if (!state) {
    return null;
  }

  switch (actions.type) {
    case NAVIGATION_COMPLETED: {
      return {
        ...state,
        navigation: '',
      };
    }

    case NAVIGATION_REQUESTED: {
      return { ...state, navigation: actions.navigateTo };
    }

    case RESET_CARD_DATA: {
      return { ...state, cardsData: actions.cardData };
    }
    case ADD_MODALS: {
      let existingModals = [...state.addModals];
      existingModals.push(actions.modalName);
      return {
        ...state,
        addModals: existingModals,
      };
    }

    case SET_GRID_DATA: {
      const existingPortalDetails = { ...state.portalDetails };
      existingPortalDetails.loading = false;

      if (actions.isReloadRequired) {
        Object.assign(actions.cardData.referenceData, {
          AE_RELOAD: {
            ID: uuid(),
            TS: new Date().getTime(),
            STATUS: 'RT',
          },
        });
      }

      if (actions.resetOtherGrids) {
        const newCardData: any = {};
        newCardData[actions.cardData.id] = actions.cardData;
        return {
          ...state,
          cardsData: newCardData,
          errorData: {},
          portalDetails: existingPortalDetails,
        };
      } else {
        let existingcards = { ...state.cardsData };
        existingcards[actions.cardData.id] = actions.cardData;

        return {
          ...state,
          cardsData: existingcards,
          errorData: {},
          portalDetails: existingPortalDetails,
        };
      }
    }

    case CLOSE_MODALS: {
      let openedModals = [...state.addModals];
      let existingcards = { ...state.cardsData };
      Object.keys(existingcards).map((key) => {
        if (existingcards[key].id === actions.formId) {
          delete existingcards[key];
        }
        return null;
      });
      const ele = state.addModals
        .map((modal) => {
          return modal.occuranceNumber;
        })
        .indexOf(actions.occuranceNumber);
      openedModals.splice(ele, 1);
      return {
        ...state,
        addModals: openedModals,
        cardsData: existingcards,
        errorData: {},
      };
    }

    case SET_TREE_DATA: {
      if (!actions.treeData) {
        const existingdata = { ...state.treeDatas };
        delete existingdata[actions.nodeId];
        return {
          ...state,
          treeDatas: existingdata,
        };
      } else if (actions.treeData.returnData.LevelCount !== 0) {
        const existingdata = { ...state.treeDatas };
        existingdata[actions.nodeId] = actions.treeData;
        return {
          ...state,
          treeDatas: existingdata,
        };
      } else return state;
    }

    case SET_CHART_DATA: {
      const existingcards = { ...state.cardsData };
      Object.assign(actions.chartData.referenceData, {
        AE_RELOAD: {
          ID: uuid(),
          TS: new Date().getTime(),
          STATUS: 'RT',
        },
      });
      existingcards[actions.chartId] = actions.chartData;
      return {
        ...state,
        cardsData: existingcards,
      };
    }
    case SET_PORTAL_GRID_SELECTED_ROW_DATA: {
      const existingPortalDetails = { ...state.portalDetails };
      existingPortalDetails.loading = false;
      const existingcards = { ...state.cardsData };
      Object.assign(actions.cardData.referenceData, {
        AE_RELOAD: {
          ID: uuid(),
          TS: new Date().getTime(),
          STATUS: 'RT',
        },
      });
      existingcards[actions.cardId] = actions.cardData;
      return {
        ...state,
        cardsData: existingcards,
        portalDetails: existingPortalDetails,
      };
    }
    case SET_FORM_DATA: {
      const existingcards = { ...state.cardsData };
      const existingPortalDetails = { ...state.portalDetails };
      existingPortalDetails.loading = actions.loading;
      if (actions.resetOtherForms) {
        Object.keys(existingcards).map((key) => {
          if (
            existingcards[key].type === CardType.FORM &&
            actions.formData.id === existingcards[key].id
          ) {
            delete existingcards[key];
          }
          return null;
        });
      }
      const previousCardData = existingcards[actions.formId];
      const newCardData = { ...actions.formData };
      newCardData.previousReferenceData = {};
      try {
        if (previousCardData) {
          if (
            previousCardData.referenceData &&
            previousCardData.referenceData.componentType &&
            newCardData.referenceData &&
            newCardData.referenceData.componentType &&
            previousCardData.referenceData.componentType !==
              newCardData.referenceData.componentType
          ) {
            newCardData.previousReferenceData = previousCardData.referenceData;
          } else {
            newCardData.previousReferenceData =
              previousCardData.previousReferenceData
                ? previousCardData.previousReferenceData
                : {};
          }
          if (
            previousCardData.options &&
            newCardData.options &&
            JSON.stringify(previousCardData.options) !==
              JSON.stringify(newCardData.options)
          ) {
            const mergedOptions = {};
            Object.assign(
              mergedOptions,
              previousCardData.options,
              newCardData.options
            );
            Object.assign(newCardData.options, mergedOptions);
          }

          if (
            !newCardData.workflowActions &&
            (previousCardData.workflowActions ||
              (previousCardData.workflowActions &&
                previousCardData.workflowActions.length))
          ) {
            newCardData.workflowActions = [];
            Object.assign(
              newCardData.workflowActions,
              previousCardData.workflowActions
            );
          }
        }
      } catch (err) {
        console.log('setform data catch', err);
      }
      if (!actions.hasReloadData) {
        if (!newCardData.referenceData) {
          newCardData.referenceData = {};
        }
        Object.assign(newCardData.referenceData, {
          AE_RELOAD: {
            ID: uuid(),
            TS: new Date().getTime(),
            STATUS: 'RT',
          },
        });
      }
      existingcards[actions.formId] = newCardData;

      if (!actions.formData.id.includes('0_')) {
        return {
          ...state,
          cardsData: existingcards,
          portalDetails: existingPortalDetails,
        };
      } else {
        return {
          ...state,
          cardsData: existingcards,
          portalDetails: existingPortalDetails,
        };
      }
    }

    case SET_LEFT_MENU_INDICATOR: {
      return {
        ...state,
        leftMenuIndicator: actions.indicator,
        toggleMessages: [],
      };
    }
    case SET_FILE_DROPPABLE_INDICATOR: {
      return {
        ...state,
        droppableIndicator: actions.droppableIndicator,
      };
    }
    case SET_CURRENT_ROOT_NODE: {
      return {
        ...state,
        rootNodeId: actions.rootNodeId,
      };
    }

    case SET_OPTIONS: {
      let existingOptions: any = { ...state.options };
      existingOptions[actions.key] = actions.options;
      return {
        ...state,
        options: existingOptions,
      };
    }

    case SET_OPTIONS_IN_BULK: {
      let existingOptions = { ...state.options };
      Object.assign(existingOptions, actions.options);
      return {
        ...state,
        options: existingOptions,
      };
    }

    case SET_ERROR_DATA: {
      return {
        ...state,
        errorData: actions.errorData,
      };
    }
    case SET_TOGGLE_MESSAGES: {
      return {
        ...state,
        toggleMessages: [...actions.messages],
      };
    }
    case SWITCH_LANGUAGE: {
      return {
        ...state,
        locale: actions.language,
        messages: getLocaleMessages(actions.language),
      };
    }

    case SET_USER_PROFILE: {
      return {
        ...state,
        userDetails: actions.userDetails,
      };
    }

    case SET_CURRENT_FINANCIAL_YEAR: {
      return {
        ...state,
        currentFinancialYear: actions.currentFinancialYear,
      };
    }

    case SET_STORE: {
      return {
        ...state,
        ...actions.store,
      };
    }

    case RESET_CARD_REFERENCE_DATA: {
      const existingcards = { ...state.cardsData };
      const previousCardData = existingcards[actions.cardId];
      const newCardData = { ...previousCardData };
      newCardData.referenceData = previousCardData
        ? previousCardData.previousReferenceData
          ? previousCardData.previousReferenceData
          : {}
        : {};
      Object.assign(newCardData.referenceData, {
        AE_RELOAD: {
          ID: uuid(),
          TS: new Date().getTime(),
          STATUS: 'RT',
        },
      });
      existingcards[actions.cardId] = newCardData;

      const currentPortalDetails = { ...state.portalDetails };
      currentPortalDetails.loading = true;
      return {
        ...state,
        cardsData: existingcards,
        portalDetails: currentPortalDetails,
      };
    }

    case SET_PORTAL_DETAILS: {
      const currentPortalDetails = { ...state.portalDetails };
      let existingcards = { ...state.cardsData };
      if (currentPortalDetails.portalId !== actions.details.portalId) {
        existingcards = {};
      }
      return {
        ...state,
        portalDetails: { ...actions.details },
        cardsData: existingcards,
      };
    }
    case REMOVE_CARD_DATA_BY_REFERRAL_ID: {
      let existingcards = { ...state.cardsData };
      Object.keys(existingcards).map((key) => {
        if (key.includes(actions.referralId)) {
          delete existingcards[key];
        }
      });
      return {
        ...state,
        cardsData: existingcards,
      };
    }
    case SET_GRID_PAGE_INDEX: {
      let existingGridIndexes = { ...state.gridIndexes };
      const previousCardData = existingGridIndexes[actions.referralId];
      if (actions.isRemove && previousCardData) {
        delete existingGridIndexes[actions.referralId];
      } else {
        existingGridIndexes[actions.referralId] = actions.pageIndex;
      }
      return {
        ...state,
        gridIndexes: existingGridIndexes,
      };
    }

    case SET_PORTAL_LAYOUT: {
      return {
        ...state,
        portalLayout: actions.layout,
      };
    }
    case SET_OPEN_MODAL: {
      let existingOpenModals = { ...state.openModals };
      if (actions.isRemove) {
        delete existingOpenModals[actions.portalId];
      } else {
        existingOpenModals[actions.portalId] = actions.data;
      }
      const existingcards = { ...state.cardsData };
      if (existingcards[actions.portalId] && actions.isRemove) {
        delete existingcards[actions.portalId];
      }
      for (const key of Object.keys(existingcards)) {
        if (
          (existingcards[key].portalId === actions.portalId ||
            existingcards[key].parentId === actions.portalId) &&
          actions.isRemove
        ) {
          delete existingcards[key];
        }
      }

      return {
        ...state,
        openModals: existingOpenModals,
        cardsData: existingcards,
      };
    }

    case RELOAD_CLEAN_MODAL_DATA: {
      let existingOpenModals = { ...state.openModals };
      delete existingOpenModals[actions.portalId];
      actions.cleanCards.map((cardId: any) => {
        if (existingOpenModals && existingOpenModals.hasOwnProperty(cardId)) {
          delete existingOpenModals[cardId];
        }
      });
      const existingcards = { ...state.cardsData };
      if (actions.cleanCards && actions.cleanCards.length) {
        actions.cleanCards.map((cardId: any) => {
          const cardPortalId = existingcards[cardId]
            ? existingcards[cardId].portalId
            : '';
          const cardParentId = existingcards[cardId]
            ? existingcards[cardId].parentId
            : '';
          const reloadData = {
            AE_RELOAD: {
              ID: uuid(),
              TS: new Date().getTime(),
              STATUS: 'R',
            },
          };
          if (existingcards[cardPortalId]) {
            Object.assign(
              existingcards[cardPortalId].referenceData,
              reloadData
            );
          }
          if (existingcards[cardParentId] && cardId !== cardParentId) {
            Object.assign(
              existingcards[cardPortalId].referenceData,
              reloadData
            );
          }
          if (existingcards[cardId]) {
            delete existingcards[cardId];
          }
          for (const key of Object.keys(existingcards)) {
            if (
              existingcards[key].portalId === cardId ||
              existingcards[key].parentId === cardId
            ) {
              delete existingcards[key];
            }
          }
        });
      }
      if (actions.reloadCards) {
        Object.keys(actions.reloadCards).map((cardId) => {
          existingcards[cardId] = actions.reloadCards[cardId];
        });
      }
      return {
        ...state,
        cardsData: existingcards,
        openModals: existingOpenModals,
      };
    }

    case REMOVE_ACCORDION_CARDS: {
      const existingcards = { ...state.cardsData };
      actions.id.map((id: any) => {
        if (existingcards[id]) {
          delete existingcards[id];
        }
        for (const key of Object.keys(existingcards)) {
          if (
            existingcards[key].portalId === id ||
            existingcards[key].parentId === id
          ) {
            delete existingcards[key];
          }
        }
      });

      return {
        ...state,
        cardsData: existingcards,
      };
    }

    case SET_EDITABLE_GRID_STATUS:
      let existingcards = { ...state.cardsData };
      Object.assign(actions.cardData.referenceData, {
        AE_RELOAD: {
          ID: uuid(),
          TS: new Date().getTime(),
          STATUS: 'RT',
        },
      });
      existingcards[actions.cardId] = actions.cardData;
      return {
        ...state,
        cardsData: existingcards,
      };
    case SET_WARNING_MESSAGES: {
      return {
        ...state,
        warningMessages: { ...actions.messages },
      };
    }
    default:
      return state;
  }
}
