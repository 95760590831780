export enum ErrorCodeEnum {
  MISSING_PARAMETER = 'MISSING_PARAMETER',
  INVALID_PARAMETER = 'INVALID_PARAMETER',
  INVALID_USERNAME = 'INVALID_USERNAME',
  MISSING_PARAMETER_VALUE = 'MISSING_PARAMETER_VALUE',
  PENDING_VERIFICATION = 'PENDING_CODE_VERIFICATION',
  INVALID_PWD_RESET_CODE = 'INVALID_PASSWORD_RESET_CODE',
  INVALID_OLD_PWD = 'INVALID_OLD_PASSWORD',
  INVALID_CODE = 'INVALID_CODE',
  INVALID_TOKEN = 'INVALID_TOKEN',
  DEACTIVATED = 'DEACTIVATED',
  MISSING_TOKEN = 'MISSING_TOKEN',
  PENDING_CHANGE_PASSWORD = 'PENDING_CHANGE_PASSWORD',
  LOCKED = 'LOCKED',
  INVALID_EMAIL_FORMAT = 'INVALID_EMAIL_FORMAT',
  WEAK_PASSWORD = 'WEAK_PASSWORD',
  INVALID_CREDENTIAL = 'INVALID_CREDENTIAL',
  SYSTEM_ERROR = 'SYSTEM_ERROR',
  USER_NOT_LOGGEDIN = 'USER_NOT_LOGGEDIN',
  INVALID_PHONE_NUMBER_FORMAT = 'INVALID_PHONE_NUMBER_FORMAT',
  USER_ALREADY_EXISTS = 'USER_ALREADY_EXISTS',
}
