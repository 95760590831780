import React from 'react';
import { createStorage } from '../storage';
import cloneDeep from 'lodash/cloneDeep';
import {
  APP_LOGGED_IN_ROLE_ID,
  USER_SESSION_LOCAL_STORAGE_KEY,
  APP_LOGGED_IN_YEAR,
} from '../constants/appeng.enum';

interface IUserContextProps {
  getCurrentRole: any;
  getCurrentFinacialYear: any;
  getUserDetail: any;
  getUserApplicationSpecialMapping: any;
}

const getUserSession = () => {
  const storage = createStorage();
  const userProfile = storage.getItem(USER_SESSION_LOCAL_STORAGE_KEY);
  const userProfileMap = JSON.parse(userProfile ? userProfile : '{}');
  const usersession = cloneDeep(userProfileMap);
  delete usersession.AdditionalDetails;
  delete usersession.SpecialMappingDetails;
  usersession.APP_CURRENT_DATE = new Date();
  if (
    userProfileMap.AdditionalDetails &&
    userProfileMap.AdditionalDetails.UserContext
  ) {
    Object.assign(usersession, userProfileMap.AdditionalDetails.UserContext);
  } else {
    Object.assign(usersession, userProfileMap.AdditionalDetails);
  }
  return usersession;
};

const getCurrentRoleContext = () => {
  const usersession = getUserSession();
  return String(usersession[APP_LOGGED_IN_ROLE_ID]).split(',');
};

const getCurrentFinacialYearContext = () => {
  const usersession = getUserSession();
  return usersession[APP_LOGGED_IN_YEAR];
};

const getUserDetailContext = () => {
  const usersession = getUserSession();
  return usersession;
};

const getUserApplicationSpecialMappingContext = () => {
  const storage = createStorage();
  const userProfile = storage.getItem(USER_SESSION_LOCAL_STORAGE_KEY);
  const userProfileMap = JSON.parse(userProfile ? userProfile : '{}');
  return userProfileMap.SpecialMappingDetails;
};

const createDefaultTheme = (): IUserContextProps => ({
  getCurrentRole: getCurrentRoleContext,
  getCurrentFinacialYear: getCurrentFinacialYearContext,
  getUserDetail: getUserDetailContext,
  getUserApplicationSpecialMapping: getUserApplicationSpecialMappingContext,
});

export const UserContext =
  React.createContext<IUserContextProps>(createDefaultTheme());

export const UserContextProvider: React.FC<any> = (props: any) => {
  return (
    <UserContext.Provider
      value={{
        getCurrentRole: getCurrentRoleContext,
        getCurrentFinacialYear: getCurrentFinacialYearContext,
        getUserDetail: getUserDetailContext,
        getUserApplicationSpecialMapping:
          getUserApplicationSpecialMappingContext,
      }}
    >
      {props.children}
    </UserContext.Provider>
  );
};
