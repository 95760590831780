import React, { useState, useContext } from 'react';
import { processPortalDatagridDataParser } from '../portalLayout/parser/portalDatagrid.parser';
import { IPortalGridDetails } from '../portalLayout/interface/index';
import PortalGridCard from '../portalLayout/PortalGridCard';
import CardData from '../models/carddata.model';
import {
  setFormData,
  setPortalDetails,
  actionFlowRouting,
  setEditableGridStatusSaga,
  downloadAttachment,
  setOpenModalSaga,
  addRowInGrid,
  setGridFilterStatus,
} from '../actions';
import {
  CardType,
  INSERT_MODE,
  EDIT_MODE,
  GridType,
  NodeType,
  ActionType,
  FormNavigationType,
} from '../constants/appeng.enum';
import { useDispatch, useSelector } from 'react-redux';
import { useConfigQuery } from '../custom-hooks/useConfigQuery';
import SkeletonCore from '../components/SkeletonCore';
import GridButtonGateWay from '../composite-entity/grid/GridButtonGateWay';
import PortalModalGenerator from './PortalModalGenerator';
import { ThemeContext } from '../theme/ThemeContext';
import { Button } from 'info-ui-library';
import { v4 as uuid } from 'uuid';
import { useLocation } from 'react-router';

const GridComponent = (props: any) => {
  const dispatch = useDispatch();
  const currentLocation = useLocation();

  const addModalList = useSelector((state: any) => state.appState.cardsData);
  const { data, loading } = useConfigQuery(
    'PortalDataGridQuery',
    props.componentId
  );
  const existingCardsData = useSelector(
    (state: any) => state.appState.cardsData
  );
  const openCardModals = useSelector((state: any) => state.appState.openModals);
  const themeContext = useContext(ThemeContext);
  const editableGridEnable = existingCardsData[props.referralId]
    ? existingCardsData[props.referralId].editableGrid
    : false;
  const [cardPortalId, setCardPortalId] = useState('');
  const location = useLocation();
  function gridEditButtonClick() {
    dispatch(setEditableGridStatusSaga(props.referralId, !editableGridEnable));
  }

  if (loading) return <SkeletonCore count={1} />;
  let portalGrid: IPortalGridDetails = processPortalDatagridDataParser(
    data,
    props
  );
  const newProps = {
    nodeId: portalGrid.nodeId,
    ceId: portalGrid.ceId,
    pathname: '',
    data: {
      ...portalGrid.portalGridData.referenceData.filterMap,
      ...portalGrid.portalGridData.referenceData,
    },
    gridId: portalGrid.gridId,
    subComponentParentId: props.subComponentParentId,
  };
  const chartModalHeader = getCardModalButtonsInHeader(
    themeContext,
    cardPortalId,
    setOpenModalSaga,
    dispatch,
    props.parentId,
    props.subComponentParentId
  );
  const openModalRouteParam: any = {};
  Object.assign(openModalRouteParam, props.routerParameter);
  if (
    openCardModals.hasOwnProperty(props.subComponentParentId + cardPortalId) &&
    openCardModals[props.subComponentParentId + cardPortalId].referenceData[
      props.subComponentParentId + cardPortalId
    ]
  ) {
    openModalRouteParam['state'][props.subComponentParentId + cardPortalId] =
      openCardModals[props.subComponentParentId + cardPortalId].referenceData[
        props.subComponentParentId + cardPortalId
      ];
  }
  return (
    <React.Fragment>
      {openCardModals.hasOwnProperty(
        props.subComponentParentId + cardPortalId
      ) && (
        <PortalModalGenerator
          header={chartModalHeader}
          subportalId={cardPortalId}
          portalId={cardPortalId}
          routerParameter={openModalRouteParam}
          subComponentParentId={props.subComponentParentId}
          modalAttribute={
            openCardModals[props.subComponentParentId + cardPortalId]
              .cssAttribute
          }
        />
      )}
      <GridButtonGateWay
        nodeType={'ParentNode'}
        routerParameter={newProps}
        setCardButtons={props.setCardButtons}
        setCardLabel={props.setCardLabel}
        parentId={props.parentId}
        portalId={props.portalId}
        handleClick={(button: any, newProps: any) => {
          gridButtonClick(
            button,
            newProps,
            dispatch,
            props,
            addModalList,
            currentLocation,
            setCardPortalId,
            props.subComponentParentId
          );
        }}
        editableGridEnable={editableGridEnable}
        setEditableGridEnable={gridEditButtonClick}
        subComponentParentId={props.subComponentParentId}
      />
      <PortalGridCard
        tabRefresh={props.tabRefresh}
        portalGrid={portalGrid}
        {...props}
        isPortalGrid={false}
        setCardPortalId={setCardPortalId}
        nodeId={portalGrid.nodeId}
        editableGridEnable={editableGridEnable}
        handleClick={(
          actionColumn: any,
          rowDetails: any,
          props: any,
          setCardPortalId: any
        ) => {
          handleClick(
            actionColumn,
            rowDetails,
            props,
            dispatch,
            currentLocation,
            setCardPortalId
          );
        }}
        gridButtonClick={(button: any, newProps: any) => {
          gridButtonClick(
            button,
            newProps,
            dispatch,
            props,
            addModalList,
            currentLocation,
            setCardPortalId,
            props.subComponentParentId
          );
        }}
      />
    </React.Fragment>
  );
};

export function gridButtonClick(
  button: any,
  props: any,
  dispatch: any,
  cardProps: any,
  addModalList: any,
  currentLocation: any,
  setCardPortalId?: any,
  subComponentParentId?: any
) {
  const referenceData = {
    componentType: cardProps.type === 'parent' ? 'ParentForm' : 'ChildForm',
    nodeId: props.routerParameter.nodeId,
    ceId: props.routerParameter.ceId,
    nodeType: props.routerParameter.nodeType,
    editFormId: props.routerParameter.editFormId
      ? props.routerParameter.editFormId
      : props.parentFormId,
    primaryDBCode: props.routerParameter.primaryDBCode
      ? props.routerParameter.primaryDBCode
      : props.displayName,
    displayName: props.displayName
      ? props.displayName
      : props.routerParameter.primaryDBCode,
    insertFormId: props.routerParameter.editFormId
      ? props.routerParameter.editFormId
      : props.parentFormId,
    data: props.routerParameter.data,
    mode: INSERT_MODE,
    referenceData: props.routerParameter.data,
    AE_RELOAD: {
      ID: uuid(),
      TS: new Date().getTime(),
      STATUS: 'R',
    },
  };

  const actionFlowRelation =
    button.childRelations && button.childRelations.length
      ? button.childRelations.find(
          (rel: any) =>
            rel.relationType === 'Button_ActionFlow' ||
            rel.relationType === 'MenuButton_ActionFlow'
        )
      : null;
  const actionFlowId =
    actionFlowRelation && actionFlowRelation.childItemId
      ? actionFlowRelation.childItemId
      : '';
  if (actionFlowId) {
    const attribute: any = {
      nodeId: props.nodeId,
      actionName: button.label,
      keys: props.keys ? props.keys : {},
      referenceData: Object.keys(props.data).length
        ? props.data
        : Object.keys(props.routerParameter).length &&
            Object.keys(props.routerParameter.data).length
          ? props.routerParameter.data
          : {},
      displayName: props.displayName,
      nodeType: props.nodeType ? props.nodeType : NodeType.PARENTNODE,
      ceId: props.ceId,
      mode: props.mode,
      modalRequired: button.modalRequired,
      referralId: cardProps.referralId,
      resetForm: props.resetForm,
      portalForm: props.portalForm,
      parentFormId: props.parentFormId,
      portalId: props.portalId,
      parentId: props.parentId,
      usePortalReferenceData: false,
      compositeEntityKey: props.compositeEntityKey,
      entityId: props.entityId,
      buttonType: button.buttonClass,
      buttonId: button.configObjectId,
      gridButtonAction: true,
      routerReferenceData: props.routerParameter.data,
      actionFlowId,
      location: currentLocation,
    };
    dispatch(actionFlowRouting(ActionType.BUTTON_CLICK, attribute));
  } else if (button.buttonClass === 'sideNodeAddForm') {
    const routerParam: any = {
      url: '/app/ce/portal/' + uuid(),
      buttonType: FormNavigationType.SIDE_NODE,
      resetReferenceData: false,
      routeStateParams: {},
      location: currentLocation,
    };

    routerParam.routeStateParams['portalId'] = cardProps.portalId
      ? cardProps.portalId
      : cardProps.parentId;
    routerParam.routeStateParams['parentId'] = cardProps.parentId;
    routerParam.routeStateParams['referralId'] = cardProps.referralId;
    routerParam.routeStateParams[cardProps.parentId] = referenceData;
    dispatch(actionFlowRouting(ActionType.BUTTON_CLICK, routerParam));
  } else if (button.buttonClass === 'addRowInGrid') {
    dispatch(
      addRowInGrid(
        cardProps.referralId,
        props.gridId ? props.gridId : props.portalGrid.gridId
      )
    );
  } else if (button.buttonClass === 'gridFilterActionButton') {
    dispatch(
      setGridFilterStatus(
        cardProps.referralId,
        props.cardsData ? !props.cardsData.enableFilterInGrid : undefined
      )
    );
  } else if (button.modalRequired) {
    const id = subComponentParentId + button.portalId;
    setCardPortalId(button.portalId);
    let existingCardList: any = [];
    existingCardList.push(props.parentId);
    const modalAttribute =
      button.modalCss == 'Left Align Full Vertical View'
        ? 'left:0;right:auto;width:65%'
        : button.modalCss == 'Right Align Full Vertical View'
          ? 'left:auto;right:0;width:65%'
          : button.modalCss == 'Center Align Partial View'
            ? 'left:10%;right:10%;width:80%;height:80%;margin-top:3.8%'
            : '';
    dispatch(
      setOpenModalSaga(
        id,
        false,
        true,
        existingCardList,
        [id],
        '',
        modalAttribute
      )
    );
  } else if (button.portalId) {
    const routerParam: any = {
      url: '/app/ce/portal/' + uuid(),
      buttonType: FormNavigationType.PORTAL_FORM,
      resetReferenceData: false,
      routeStateParams: {},
      location: currentLocation,
    };

    const subComponentId = props.portalId.includes('_')
      ? props.portalId.split('_')[0] + '_'
      : 0 + '_';
    routerParam.routeStateParams['portalId'] = subComponentId + button.portalId;
    routerParam.routeStateParams['parentId'] = subComponentId + button.portalId;
    routerParam.routeStateParams['referralId'] =
      subComponentId + button.portalId;
    routerParam.routeStateParams[subComponentId + button.portalId] =
      referenceData;
    const details = {
      portalId: button.portalId,
      data: referenceData,
      loading: true,
    };
    dispatch(setPortalDetails(details));
    dispatch(actionFlowRouting(ActionType.BUTTON_CLICK, routerParam));
  } else {
    if (!cardProps.usePortalReferenceData) {
      const cardData = new CardData(
        cardProps.referralId,
        CardType.FORM,
        {},
        props.portalId,
        props.parentId,
        referenceData
      );

      const routerParam: any = {
        url: '/app/ce/portal/' + uuid(),
        buttonType: FormNavigationType.PORTAL_FORM,
        resetReferenceData: false,
        routeStateParams: {},
        location: currentLocation,
      };

      routerParam.routeStateParams['portalId'] = props.portalId
        ? props.portalId
        : props.parentId;
      routerParam.routeStateParams['parentId'] = props.parentId;
      routerParam.routeStateParams['referralId'] = cardProps.referralId;
      routerParam.routeStateParams[cardProps.referralId] = referenceData;
      dispatch(actionFlowRouting(ActionType.BUTTON_CLICK, routerParam));
    } else {
      if (!cardProps.portalId.includes('0_')) {
        const cardData = new CardData(
          cardProps.parentId,
          CardType.PORTAL,
          {},
          cardProps.portalId,
          cardProps.parentId,
          referenceData
        );
        dispatch(setFormData(cardData, cardProps.parentId, false, true, false));
      } else {
        const routerParam: any = {
          url: '/app/ce/portal/' + uuid(),
          buttonType: FormNavigationType.SIDE_NODE,
          resetReferenceData: false,
          routeStateParams: {},
          location: currentLocation,
        };

        routerParam.routeStateParams['portalId'] = cardProps.portalId
          ? cardProps.portalId
          : cardProps.parentId;
        routerParam.routeStateParams['parentId'] = cardProps.parentId;
        routerParam.routeStateParams['referralId'] = cardProps.referralId;
        routerParam.routeStateParams[cardProps.parentId] = referenceData;
        dispatch(actionFlowRouting(ActionType.BUTTON_CLICK, routerParam));
      }
    }
  }
}

export function handleClick(
  column: any,
  rowDetails: any,
  props: any,
  dispatch: any,
  currentLocation: any,
  setCardPortalId?: any
) {
  const rowDetail = rowDetails;
  const componentType =
    props.gridType === GridType.PARENT ? 'ParentForm' : 'ChildForm';
  switch (column.actionColumnType) {
    case 'Tree_View/Edit':
      const reference_Data = {
        componentType: componentType,
        nodeId: props.nodeId,
        ceId: props.portalGrid
          ? props.portalGrid.ceId
          : props.routerParameter.ceId,
        editFormId: props.editFormId,
        primaryDBCode: props.primaryDBCode,
        displayName: column.displayName
          ? column.displayName
          : props.primaryDBCode,
        insertFormId: props.insertFormId,
        data: rowDetail,
        mode: EDIT_MODE,
        referenceData: props.portalGrid
          ? {}
          : props.routerParameter.referenceData,
        nodeType: props.routerParameter
          ? props.routerParameter.nodeType
          : 'ParentNode',
        AE_RELOAD: {
          ID: uuid(),
          TS: new Date().getTime(),
          STATUS: 'R',
        },
      };
      const routerParam: any = {
        url: '/app/ce/portal/' + uuid(),
        buttonType: FormNavigationType.SIDE_NODE,
        resetReferenceData: false,
        routeStateParams: {},
        location: currentLocation,
      };

      routerParam.routeStateParams['portalId'] = props.portalId
        ? props.portalId
        : props.parentId;
      routerParam.routeStateParams['parentId'] = props.parentId;
      routerParam.routeStateParams['referralId'] = props.referralId;
      routerParam.routeStateParams[props.parentId] = reference_Data;
      const cardData = new CardData(
        props.parentId,
        CardType.PORTAL,
        [],
        props.portalId,
        props.parentId,
        reference_Data
      );

      dispatch(actionFlowRouting(ActionType.BUTTON_CLICK, routerParam));
      break;
    case 'View/Edit':
      const referenceData = {
        componentType: componentType,
        nodeId: props.nodeId,
        ceId: props.portalGrid
          ? props.portalGrid.ceId
          : props.routerParameter.ceId,
        editFormId: props.editFormId,
        primaryDBCode: props.primaryDBCode,
        displayName: column.displayName
          ? column.displayName
          : props.primaryDBCode,
        insertFormId: props.insertFormId,
        data: rowDetail,
        mode: EDIT_MODE,
        referenceData: props.portalGrid
          ? {}
          : props.routerParameter.referenceData,
        nodeType: props.routerParameter
          ? props.routerParameter.nodeType
          : 'ParentNode',
        AE_RELOAD: {
          ID: uuid(),
          TS: new Date().getTime(),
          STATUS: 'R',
        },
      };
      if (!props.usePortalReferenceData) {
        const routerParam: any = {
          url: '/app/ce/portal/' + uuid(),
          buttonType: FormNavigationType.PORTAL_FORM,
          resetReferenceData: false,
          routeStateParams: {},
          location: currentLocation,
        };

        routerParam.routeStateParams['portalId'] = props.portalId
          ? props.portalId
          : props.parentId;
        routerParam.routeStateParams['parentId'] = props.parentId;
        routerParam.routeStateParams['referralId'] = props.referralId;
        routerParam.routeStateParams[props.referralId] = referenceData;
        const cardData = new CardData(
          props.referralId,
          CardType.FORM,
          [],
          props.portalId,
          props.parentId,
          referenceData
        );

        dispatch(actionFlowRouting(ActionType.BUTTON_CLICK, routerParam));
      } else {
        const routerParam: any = {
          url: '/app/ce/portal/' + uuid(),
          buttonType: FormNavigationType.SIDE_NODE,
          resetReferenceData: false,
          routeStateParams: {},
          location: currentLocation,
        };

        routerParam.routeStateParams['portalId'] = props.portalId
          ? props.portalId
          : props.parentId;
        routerParam.routeStateParams['parentId'] = props.parentId;
        routerParam.routeStateParams['referralId'] = props.referralId;
        routerParam.routeStateParams[props.parentId] = referenceData;
        const cardData = new CardData(
          props.parentId,
          CardType.PORTAL,
          [],
          props.portalId,
          props.parentId,
          referenceData
        );

        dispatch(actionFlowRouting(ActionType.BUTTON_CLICK, routerParam));
      }
      break;
    case 'SelectRow':
      const referenceDatas = {
        componentType: 'ParentGrid',
        nodeId: props.nodeId,
        ceId: props.portalGrid
          ? props.portalGrid.ceId
          : props.routerParameter.ceId,
        editFormId: props.editFormId,
        primaryDBCode: props.primaryDBCode,
        insertFormId: props.insertFormId,
        data: rowDetail,
        mode: EDIT_MODE,
        referenceData: props.portalGrid
          ? {}
          : props.routerParameter.referenceData,
        nodeType: props.routerParameter
          ? props.routerParameter.nodeType
          : 'ParentNode',
        filterRow: rowDetail,
      };
      if (!props.usePortalReferenceData) {
        const cardData = new CardData(
          props.referralId,
          CardType.DATAGRID,
          props.gridData,
          props.portalId,
          props.parentId,
          referenceDatas
        );
        dispatch(setFormData(cardData, props.referralId, false, true, false));
      } else {
        const cardData = new CardData(
          props.parentId,
          CardType.PORTAL,
          {},
          props.portalId,
          props.parentId,
          referenceDatas
        );
        dispatch(setFormData(cardData, props.parentId, false, true, false));
      }
      break;
    case 'Custom': {
      const href = column.hrefValue;
      const params = href.split('=');
      if (params[0] === 'portalID') {
        const referenceData = {
          componentType: componentType,
          referenceData: props.gridData,
          data: rowDetail,
          ceId: props.portalGrid && props.portalGrid.ceId,
          nodeId: props.portalGrid ? props.portalGrid.nodeId : props.nodeId,
          nodeType: props.portalGrid
            ? NodeType.PARENTNODE
            : props.routerParameter.nodeType,
          insertFormId: props.insertFormId,
          editFormId: props.editFormId,
          primaryDBCode: column.hyperlinkDBCode
            ? column.hyperlinkDBCode
            : props.primaryDBCode,
          displayName: column.displayName
            ? column.displayName
            : props.primaryDBCode,
          mode: EDIT_MODE,
          previousPortalId: currentLocation.state.portalId,
          AE_RELOAD: {
            ID: uuid(),
            TS: new Date().getTime(),
            STATUS: 'R',
          },
        };
        const routerParam: any = {
          url: '/app/ce/portal/' + params[1],
          buttonType: FormNavigationType.PORTAL_FORM,
          resetReferenceData: false,
          routeStateParams: {},
          location: currentLocation,
        };

        const subComponentId = props.portalId.includes('_')
          ? props.portalId.split('_')[0] + '_'
          : 0 + '_';
        routerParam.routeStateParams['portalId'] = subComponentId + params[1];
        routerParam.routeStateParams['parentId'] = subComponentId + params[1];
        routerParam.routeStateParams['referralId'] = subComponentId + params[1];
        routerParam.routeStateParams[subComponentId + params[1]] =
          referenceData;
        const details = {
          portalId: params[1],
          data: referenceData,
          loading: true,
        };
        dispatch(setPortalDetails(details));
        dispatch(actionFlowRouting(ActionType.BUTTON_CLICK, routerParam));
      }
      break;
    }
    case 'Modal': {
      const href = column.hrefValue;
      const params = href.split('=');
      if (params[0] === 'portalID') {
        const id = props.subComponentParentId + params[1];
        setCardPortalId(params[1]);
        let existingCardList: any = [];
        existingCardList.push(props.parentId);
        const referenceData = {
          data: rowDetail,
          AE_RELOAD: {
            ID: uuid(),
            TS: new Date().getTime(),
            STATUS: 'R',
          },
          primaryDBCode: column.hyperlinkDBCode
            ? column.hyperlinkDBCode
            : props.primaryDBCode,
        };

        if (
          rowDetail.hasOwnProperty('AE_OLD_NEW_COMPARISION_DETAILS') &&
          column.auditColumnName
        ) {
          const columnNameAsList = column.auditColumnName.split(',');
          var jsonArray = JSON.parse(rowDetail.AE_OLD_NEW_COMPARISION_DETAILS);
          const optionObject: any = [];
          for (let key in jsonArray) {
            if (columnNameAsList.includes(key)) {
              for (var i = 0; i < columnNameAsList.length; i++) {
                if (columnNameAsList[i] === key) {
                  var childArray = jsonArray[key];
                  childArray['dbCode'] = key;
                  optionObject.push(childArray);
                }
              }
            }
          }
          referenceData.data = optionObject;
        }
        const modalAttribute =
          column.modalCss == 'Left Align Full Vertical View'
            ? 'left:0;right:auto;width:65%'
            : column.modalCss == 'Right Align Full Vertical View'
              ? 'left:auto;right:0;width:65%'
              : column.modalCss == 'Center Align Partial View'
                ? 'left:10%;right:10%;width:80%;height:80%;margin-top:3.8%'
                : '';
        dispatch(
          setOpenModalSaga(
            id,
            false,
            true,
            existingCardList,
            [id],
            referenceData,
            modalAttribute
          )
        );
      }
      break;
    }
    case 'AuditModal': {
      const href = column.hrefValue;
      const params = href.split('=');
      if (params[0] === 'portalID') {
        const id = props.subComponentParentId + params[1];
        setCardPortalId(params[1]);
        let existingCardList: any = [];
        existingCardList.push(props.parentId);
        const referenceData = {
          data: column.auditExtractedData,
          AE_RELOAD: {
            ID: uuid(),
            TS: new Date().getTime(),
            STATUS: 'RT',
          },
          primaryDBCode: column.hyperlinkDBCode
            ? column.hyperlinkDBCode
            : props.primaryDBCode,
        };
        const modalAttribute =
          column.modalCss == 'Right Align Full Vertical View'
            ? 'left:auto;right:0;width:65%'
            : column.modalCss == 'Center Align Partial View'
              ? 'left:10%;right:10%;width:80%;height:80%;margin-top:3.8%'
              : 'left:auto;right:0;width:65%';
        dispatch(
          setOpenModalSaga(
            id,
            false,
            true,
            existingCardList,
            [id],
            referenceData,
            modalAttribute
          )
        );
      }
      break;
    }
    case 'DownloadAttachment':
      let nameID: string[] = column.hrefValue
        ? column.hrefValue.split(',')
        : [];
      if (nameID.length === 2) {
        let attachmentKey = rowDetail[nameID[0]];
        let fileName: string = rowDetail[nameID[1]];

        let modifileName: string[] = fileName.split('.');
        let modifiedfileName: string = modifileName[0] + '.png';
        dispatch(
          downloadAttachment(
            '44cee0a0-336a-11ea-9370-d986d0f08496',
            modifiedfileName,
            {},
            null
          )
        );
      }
      break;
    case 'CallActionFlow': {
      let actionFlowId = column.hrefValue;
      if (actionFlowId) {
        try {
          const attribute: any = {
            nodeId: props.nodeId,
            actionName: column.label,
            keys: props.keys ? props.keys : {},
            referenceData:
              props.data && Object.keys(props.data).length
                ? props.data
                : props.routerParameter &&
                    Object.keys(props.routerParameter).length &&
                    props.routerParameter.data &&
                    Object.keys(props.routerParameter.data).length
                  ? props.routerParameter.data
                  : {},
            displayName: props.displayName,
            nodeType: props.nodeType ? props.nodeType : NodeType.PARENTNODE,
            ceId: props.portalGrid.ceId,
            mode: rowDetail[props.primaryDBCode] ? EDIT_MODE : INSERT_MODE,
            modalRequired: false,
            primaryDBCode: props.primaryDBCode,
            referralId: props.referralId,
            resetForm: props.resetForm,
            portalForm: props.portalForm,
            parentFormId: props.parentFormId
              ? props.parentFormId
              : props.editFormId
                ? props.editFormId
                : props.portalGrid.gridId,
            portalId: props.portalId,
            parentId: props.parentId,
            usePortalReferenceData: false,
            compositeEntityKey: props.compositeEntityKey,
            entityId: props.entityId,
            buttonType: '',
            buttonId: column.configObjectId,
            gridButtonAction: true,
            rowDetail: rowDetail,
            callActionFlow: true,
            routerReferenceData: props.routerParameter.data,
            actionFlowId,
            location: currentLocation,
          };
          dispatch(actionFlowRouting(ActionType.BUTTON_CLICK, attribute));
        } catch (e) {
          console.log(
            'Error occured while preparing the attribute for CallActionFlow :::::::::',
            e
          );
        }
      }
    }
  }
}

export const getCardModalButtonsInHeader = (
  themeContext: any,
  cardPortalId: any,
  setOpenModalSaga: any,
  dispatch: any,
  parentId: any,
  subComponentParentId: any
) => {
  return (
    <Button
      styleName={themeContext.styleName}
      ioTheme={themeContext.ioTheme}
      ioMode={themeContext.ioMode}
      id={1}
      disabled={false}
      name={''}
      className={'fa fa-close'}
      onClick={() =>
        handleCardButtonClick(
          cardPortalId,
          setOpenModalSaga,
          dispatch,
          parentId,
          subComponentParentId
        )
      }
    />
  );
};

const handleCardButtonClick = (
  cardPortalId: any,
  setOpenModalSaga: any,
  dispatch: any,
  existingCardId: any,
  subComponentParentId: any
) => {
  const id = subComponentParentId + cardPortalId;
  let existingCardList: any = [];
  existingCardList.push(existingCardId);
  dispatch(setOpenModalSaga(id, true, true, existingCardList, [id]));
};

function isPropsAreEqual(prevProps: any, nextProps: any) {
  return (
    JSON.stringify(prevProps.referenceData) ===
    JSON.stringify(nextProps.referenceData)
  );
}

export default React.memo(GridComponent, isPropsAreEqual);
