import gql from 'graphql-tag';

export const SubPortal = gql`
  query SubPortal($id: ID!) {
    SubPortal(id: $id) {
      configObjectId
      name
      configObjectType
      portalType
      archived
      favorite
      subPortalType
      layoutType
      portalDataSharing
      portalStyle
      configObjectId
      name
      configObjectType
      portalType
      archived
      favorite
      subPortalType
      modalRequired
      layoutType
      portalDataSharing
      portalStyle
      portalCards {
        responsiveLayout
        childRelations {
          relationType
          parentItemId
          childItemId
        }
        privileges {
                    privilegeId
                    privilegeType
                    itemId
                    roleId
                    createdBy
                    isDeleted
                    insert_ts
                    updatedBy
                    update_ts
                    deletionDate
         }
        buttonPanels {
          configObjectId
          defaultType
          buttonPanelPosition
          mode
          privileges {
            privilegeId
            privilegeType
            itemId
            roleId
            createdBy
            isDeleted
            insert_ts
            updatedBy
            update_ts
            deletionDate
          }
          name
          configObjectType
          itemDescription
          projectId
          buttons {
            label
            order
            buttonClass
            warningMessage
            modalRequired
            buttonAlignment
            portalId
            modalCss
            transactionName
            privileges {
              privilegeId
              privilegeType
              itemId
              roleId
              createdBy
              isDeleted
              insert_ts
              updatedBy
              update_ts
              deletionDate
            }
            accessibilityRegex
            editabilityRegex
            expressionAvailable
            configObjectId
            name
            configObjectType
            projectId
          }
        }
        cardStyle
        cardBodyStyle
        cardHeaderStyle
        cardDataSharing
        referDataFromPortal
        configObjectId
        name
        configObjectType
        label
        reloadRequired
        archived
        favorite
        x
        y
        displayHeader
        boxShadow
        w
        h
        isStatic
        type
        uiComponentId
        isAccessibile
        accessibilityRegex
        referenceData
        order
        initialComponent
        isFullScreenRequired
        subPortals {
          configObjectId
          name
          configObjectType
          portalType
          archived
          favorite
          modalRequired
          subPortalType
          layoutType
          portalDataSharing
          portalStyle
          tabGroup
          order
          accessibilityRegex
          portalCards {
            cardStyle
            cardBodyStyle
            cardHeaderStyle
            configObjectId
            name
            configObjectType
            label
            reloadRequired
            archived
            favorite
            x
            displayHeader
            boxShadow
            y
            w
            h
            order
            isStatic
            type
            uiComponentId
            isAccessibile
            accessibilityRegex
            referenceData
            initialComponent
            isFullScreenRequired
            privileges {
              privilegeId
              privilegeType
              itemId
              roleId
              createdBy
              isDeleted
              insert_ts
              updatedBy
              update_ts
              deletionDate
            }
          }
        }
        tabGroups {
          type
          configObjectId
          name
          configObjectType
          tabPortals {
            configObjectId
            name
            configObjectType
            order
            tabName
            isDefaultOpen
            cardStyle
            cardBodyStyle
            cardHeaderStyle
            expressionAvailable
            accessibilityRegex
            buttonPanels {
              configObjectId
              defaultType
              buttonPanelPosition
              mode
              name
              configObjectType
              itemDescription
              projectId
              privileges {
                privilegeId
                privilegeType
                itemId
                roleId
                createdBy
                isDeleted
                insert_ts
                updatedBy
                update_ts
                deletionDate
              }
              buttons {
                label
                order
                buttonClass
                warningMessage
                modalRequired
                buttonAlignment
                portalId
                modalCss
                transactionName
                privileges {
                  privilegeId
                  privilegeType
                  itemId
                  roleId
                  createdBy
                  isDeleted
                  insert_ts
                  updatedBy
                  update_ts
                  deletionDate
                }
                accessibilityRegex
                editabilityRegex
                expressionAvailable
                configObjectId
                name
                configObjectType
                projectId
              }
              subPortals {
                configObjectId
                name
                configObjectType
                portalType
                archived
                favorite
                modalRequired
                subPortalType
                layoutType
                portalDataSharing
                portalStyle
                tabGroup
                order
                accessibilityRegex
                portalCards {
                  cardStyle
                  cardBodyStyle
                  cardHeaderStyle
                  configObjectId
                  name
                  configObjectType
                  label
                  reloadRequired
                  archived
                  favorite
                  x
                  displayHeader
                  boxShadow
                  y
                  w
                  h
                  order
                  isStatic
                  type
                  uiComponentId
                  isAccessibile
                  accessibilityRegex
                  referenceData
                  initialComponent
                  isFullScreenRequired
                  privileges {
                    privilegeId
                    privilegeType
                    itemId
                    roleId
                    createdBy
                    isDeleted
                    insert_ts
                    updatedBy
                    update_ts
                    deletionDate
                  }
                }
              }
            }
            subPortals {
              configObjectId
              name
              configObjectType
              portalType
              archived
              favorite
              modalRequired
              subPortalType
              layoutType
              portalDataSharing
              portalStyle
              tabGroup
              order
              accessibilityRegex
              portalCards {
                cardStyle
                cardBodyStyle
                cardHeaderStyle
                configObjectId
                name
                configObjectType
                label
                reloadRequired
                archived
                favorite
                x
                displayHeader
                boxShadow
                y
                w
                h
                order
                isStatic
                type
                uiComponentId
                isAccessibile
                accessibilityRegex
                referenceData
                initialComponent
                isFullScreenRequired
                privileges {
                  privilegeId
                  privilegeType
                  itemId
                  roleId
                  createdBy
                  isDeleted
                  insert_ts
                  updatedBy
                  update_ts
                  deletionDate
                }
              }
            }
          }
        }
      }
    }
  }
`;
