import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ParentWrapperContainer: any = styled.div`
  flex: 1 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  flex-direction: column !important;
  padding-top: 15px !important;
  background: url(${(props: any) => (props.image ? props.image : null)})
    no-repeat fixed center;
  background-size: 100% 100%;
`;

export const ForgotPasswordContainer: any = styled.div`
  width: 100% !important;
  min-height: 92vh !important;
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 15px !important;
`;

export const WrapperContainer: any = styled.div`
  width: auto !important;
  background: rgba(0, 0, 0, 0.4) !important;
  border-radius: 6px !important;
  border: 1px solid #84898e69 !important;
  overflow: hidden !important;
  padding: 20px 35px !important;
  display: flex !important;
  flexwrap: wrap !important;
  justify-content: space-between !important;
`;

export const Form: any = styled.form`
  width: 320px !important;
  @media (max-width: 345px) {
    width: 100% !important;
  }
`;

export const ForgotPassTitleSpanContainer: any = styled.span`
  font-family: sans-serif !important;
  font-size: 23px !important;
  color: #fff !important;
  line-height: 1.2 !important;
  text-align: center !important;
  width: 100% !important;
  display: block !important;
  padding-bottom: ${(p: any) =>
    p.showMessage ? '60px !important' : '0px !important'};
`;

export const ChangePassTitleSpanContainer: any = styled.span`
  font-family: sans-serif !important;
  font-size: 23px !important;
  color: #fff !important;
  line-height: 1.2 !important;
  text-align: center !important;
  width: 100% !important;
  display: block !important;
  padding-bottom: ${(p: any) =>
    p.showMessage ? '60px !important' : '0px !important'};
`;

export const CustomButton: any = styled(Button)`
  margin-bottom: 20px !important;
  margin-top: 13px !important;
  width: 100% !important;
  background: #004269 !important;
  @media (max-width: 345px) {
    font-size: 0.9em !important;
  }
`;

export const RedirectContainer: any = styled.div`
  display: flex !important;
  justify-content: space-between !important;
`;

export const LinkSpan: any = styled.span`
  font-weight: 550 !important;
  color: ${(p: any) => (p.theme.color ? p.theme.color : '#fff')} !important;
  cursor: pointer !important;
`;

export const LinkComponent: any = styled(Link)`
  text-decoration: none !important;
`;
