import React from 'react';
import { createStorage } from '../../../storage';
import { nestedTabComponentParser } from '../../../portalLayout/parser/portal.parser';
import {
  ROUTE_STATE_LOCAL_STORAGE_KEY,
  PATHNAME,
} from '../../../constants/appeng.enum';
import { Bar } from 'info-ui-library';
import SubPortalGenerator from '../../../new-portal-layout/SubPortalGenerator';

const NestedComponent = (props: any) => {
  const storage = createStorage();
  const routerParameter: any = {};
  if (props.location.state) {
    storage.setItem(
      ROUTE_STATE_LOCAL_STORAGE_KEY,
      JSON.stringify(props.location.state)
    );
    Object.assign(routerParameter, { state: props.location.state });
  } else {
    const routeState = storage.getItem(ROUTE_STATE_LOCAL_STORAGE_KEY);
    props.location.state = routeState ? JSON.parse(routeState) : {};
    Object.assign(routerParameter, { state: props.location.state });
  }
  routerParameter[PATHNAME] = props.location.pathname;
  let cardsLayout: any = [];
  let portalList: any = [];
  let rowData = props.row.original;
  rowData['ACTION_TYPE'] = props.row.nestedComponentType
    ? props.row.nestedComponentType
    : props.row.original['ACTION_TYPE'];
  let referenceData = {
    data: rowData,
    primaryDBCode: props.pkDBCode,
    AE_RELOAD: props.referenceData.AE_RELOAD,
  };
  Object.assign(routerParameter, { referenceData: referenceData });
  let pk = props.row.original[props.pkDBCode];
  let subPortals: any = '';
  if (props.portalGrid.subPortals) {
    subPortals = (
      <SubPortalGenerator
        routerParameter={routerParameter}
        subPortals={props.portalGrid.subPortals}
        subComponentParentId={pk + '_' + props.datagrid.configObjectId + '_'}
        parentCardId={props.referralId}
      />
    );
  } else {
    subPortals = <div>No View attach to Data Table</div>;
  }

  try {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'stretch',
          justifyContent: 'space-around',
        }}
      >
      
        <div style={{ width: '98.5%' }}>{subPortals}</div>
      </div>
    );
  } catch {
    return <div>No data available...</div>;
  }
};

export default NestedComponent;