import gql from 'graphql-tag';

export const Languages = gql`
  query Languages {
    Languages {
      configObjectId
      defaultLanguage
      order
      code
      name
    }
  }
`;
