import { useSelector } from 'react-redux';

const valueExtraction = (data: any, occuranceNumber: any, dbCode: any) => {
  if (data[occuranceNumber]) {
    return data[occuranceNumber][dbCode];
  } else {
    return null;
  }
};

export function useFormData(
  formId: any,
  occuranceNumber: any,
  dbCode: any,
  parentFormId: any,
  referralId: any
) {
  const existingCards = useSelector((state: any) => state.appState.cardsData);
  let formData: any;
  let value;

  if (
    parentFormId &&
    existingCards[referralId] &&
    formId !== existingCards[referralId].id
  ) {
    formData = existingCards[referralId].data;
    Object.keys(formData[0]['child']).map((childFormId) => {
      if (formId === childFormId) {
        value = valueExtraction(
          formData[0]['child'][childFormId],
          occuranceNumber,
          dbCode
        );
      }
      return;
    });
  } else {
    formData = existingCards[referralId].data;
    value = valueExtraction(formData, occuranceNumber, dbCode);
  }
  return value;
}
