import gql from 'graphql-tag';

export const MenuGroup = gql`
  query MenuGroup($id: ID!) {
    MenuGroup(id: $id) {
      name
      configObjectId
      configObjectType
      type
      leftMenuDefaultOpen
      appHomeId
      privileges {
        privilegeId
        privilegeType
        itemId
        roleId
        createdBy
        isDeleted
        insert_ts
        updatedBy
        update_ts
        deletionDate
      }
      childRelations {
        relationType
        parentItemId
        childItemId
      }
      buttonPanels {
        configObjectId
        defaultType
        buttonPanelPosition
        mode
        name
        projectId
        configObjectType
        privileges {
          privilegeId
          privilegeType
          itemId
          roleId
        }
        menuButtons {
          defaultType
          menuLabel
          position
          buttonClass
          warningMessage
          order
          accessibilityRegex
          editabilityRegex
          expressionAvailable
          transactionName
          modalRequired
          portalId
          configObjectId
          name
          configObjectType
          childRelations {
            relationType
            parentItemId
            childItemId
          }
          privileges {
            privilegeId
            privilegeType
            itemId
            roleId
          }
          buttons {
            label
            order
            buttonClass
            warningMessage
            portalId
            buttonAlignment
            accessibilityRegex
            editabilityRegex
            expressionAvailable
            modalRequired
            configObjectId
            name
            configObjectType
            projectId
            modalCss
            transactionName
            childRelations {
              relationType
              parentItemId
              childItemId
            }
            privileges {
              privilegeId
              privilegeType
              itemId
              roleId
            }
          }
        }
        buttons {
          label
          order
          buttonClass
          warningMessage
          portalId
          buttonAlignment
          accessibilityRegex
          editabilityRegex
          expressionAvailable
          modalRequired
          configObjectId
          name
          configObjectType
          projectId
          modalCss
          transactionName
          childRelations {
            relationType
            parentItemId
            childItemId
          }
          privileges {
            privilegeId
            privilegeType
            itemId
            roleId
          }
        }
      }

      menus {
        displayLabel
        hrefBaseUrl
        menuClass
        icon
        header
        childRelations {
          relationType
          parentItemId
          childItemId
        }
        privileges {
          privilegeId
          privilegeType
          itemId
          roleId
          createdBy
          isDeleted
          insert_ts
          updatedBy
          update_ts
          deletionDate
        }

        order
        objectId
        isOpenInNewTab
        submenuContainerClass
        report
        customUrl
        componentsPerRow
        linkType
        accessibilityRegex
        configObjectId
        name
        configObjectType
        menus {
          displayLabel
          hrefBaseUrl
          icon
          menuClass
          childRelations {
            relationType
            parentItemId
            childItemId
          }
          privileges {
            privilegeId
            privilegeType
            itemId
            roleId
            createdBy
            isDeleted
            insert_ts
            updatedBy
            update_ts
            deletionDate
          }

          header
          order
          objectId
          isOpenInNewTab
          submenuContainerClass
          report
          customUrl
          componentsPerRow
          linkType
          accessibilityRegex
          configObjectId
          name
          configObjectType
          menus {
            displayLabel
            hrefBaseUrl
            menuClass
            icon
            header
            childRelations {
              relationType
              parentItemId
              childItemId
            }
            privileges {
              privilegeId
              privilegeType
              itemId
              roleId
              createdBy
              isDeleted
              insert_ts
              updatedBy
              update_ts
              deletionDate
            }

            order
            objectId
            isOpenInNewTab
            submenuContainerClass
            report
            customUrl
            componentsPerRow
            linkType
            accessibilityRegex
            configObjectId
            name
            configObjectType
            menus {
              displayLabel
              hrefBaseUrl
              menuClass
              icon
              header
              childRelations {
                relationType
                parentItemId
                childItemId
              }
              privileges {
                privilegeId
                privilegeType
                itemId
                roleId
                createdBy
                isDeleted
                insert_ts
                updatedBy
                update_ts
                deletionDate
              }

              order
              objectId
              isOpenInNewTab
              submenuContainerClass
              report
              customUrl
              componentsPerRow
              linkType
              accessibilityRegex
              configObjectId
              name
              configObjectType
              menus {
                displayLabel
                hrefBaseUrl
                menuClass
                icon
                header
                childRelations {
                  relationType
                  parentItemId
                  childItemId
                }
                privileges {
                  privilegeId
                  privilegeType
                  itemId
                  roleId
                  createdBy
                  isDeleted
                  insert_ts
                  updatedBy
                  update_ts
                  deletionDate
                }

                order
                objectId
                isOpenInNewTab
                submenuContainerClass
                report
                customUrl
                componentsPerRow
                linkType
                accessibilityRegex
                configObjectId
                name
                configObjectType
                menus {
                  displayLabel
                  hrefBaseUrl
                  menuClass
                  icon
                  header
                  childRelations {
                    relationType
                    parentItemId
                    childItemId
                  }
                  privileges {
                    privilegeId
                    privilegeType
                    itemId
                    roleId
                    createdBy
                    isDeleted
                    insert_ts
                    updatedBy
                    update_ts
                    deletionDate
                  }

                  order
                  objectId
                  isOpenInNewTab
                  submenuContainerClass
                  report
                  customUrl
                  componentsPerRow
                  linkType
                  accessibilityRegex
                  configObjectId
                  name
                  configObjectType
                  menus {
                    displayLabel
                    hrefBaseUrl
                    menuClass
                    icon
                    header
                    childRelations {
                      relationType
                      parentItemId
                      childItemId
                    }
                    privileges {
                      privilegeId
                      privilegeType
                      itemId
                      roleId
                      createdBy
                      isDeleted
                      insert_ts
                      updatedBy
                      update_ts
                      deletionDate
                    }

                    order
                    objectId
                    isOpenInNewTab
                    submenuContainerClass
                    report
                    customUrl
                    componentsPerRow
                    linkType
                    accessibilityRegex
                    configObjectId
                    name
                    configObjectType
                    menus {
                      displayLabel
                      hrefBaseUrl
                      menuClass
                      icon
                      header
                      childRelations {
                        relationType
                        parentItemId
                        childItemId
                      }
                      privileges {
                        privilegeId
                        privilegeType
                        itemId
                        roleId
                        createdBy
                        isDeleted
                        insert_ts
                        updatedBy
                        update_ts
                        deletionDate
                      }

                      order
                      objectId
                      isOpenInNewTab
                      submenuContainerClass
                      report
                      customUrl
                      componentsPerRow
                      linkType
                      accessibilityRegex
                      configObjectId
                      name
                      configObjectType
                      menus {
                        displayLabel
                        hrefBaseUrl
                        menuClass
                        icon
                        header
                        childRelations {
                          relationType
                          parentItemId
                          childItemId
                        }
                        privileges {
                          privilegeId
                          privilegeType
                          itemId
                          roleId
                          createdBy
                          isDeleted
                          insert_ts
                          updatedBy
                          update_ts
                          deletionDate
                        }

                        order
                        objectId
                        isOpenInNewTab
                        submenuContainerClass
                        report
                        customUrl
                        componentsPerRow
                        linkType
                        accessibilityRegex
                        configObjectId
                        name
                        configObjectType
                        menus {
                          displayLabel
                          hrefBaseUrl
                          menuClass
                          icon
                          header
                          childRelations {
                            relationType
                            parentItemId
                            childItemId
                          }
                          privileges {
                            privilegeId
                            privilegeType
                            itemId
                            roleId
                            createdBy
                            isDeleted
                            insert_ts
                            updatedBy
                            update_ts
                            deletionDate
                          }

                          order
                          objectId
                          isOpenInNewTab
                          submenuContainerClass
                          report
                          customUrl
                          componentsPerRow
                          linkType
                          accessibilityRegex
                          configObjectId
                          name
                          configObjectType
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
