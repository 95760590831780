import { put, call, select } from 'redux-saga/effects';
import * as base from './base';
import cloneDeep from 'lodash/cloneDeep';
import {
  setFormData,
  setToggleMessages,
  setStore,
  addModalReload,
  getFormDataUsingFormConfig,
  setWarningMessages,
} from '../actions';

import {
  UpsertMessage,
  CardType,
  RECORD_INDEX,
} from '../constants/appeng.enum';
import { getPrimaryDBCode } from '../utils/ConfigUtils';
import DummyNode from '../composite-entity/form/dummyNodeConfigStructure';
import CardData from '../models/carddata.model';
import { v4 as uuid } from 'uuid';
import { router } from '../routes/Routes';
const getOpenModals = (state: any) => state.appState.openModals;
const getCard = (state: any) => state.appState.cardsData;

export function* getDynamicOptionParentFFList(
  existingCardData: any,
  forms: any,
  formData: any,
  existingOptions: any
): Generator<any, any, any> {
  try {
    let ffList: any = [];
    let parentId = '';
    let occuranceNumber = '';
    if (existingCardData && Object.keys(existingCardData.options).length > 0) {
      for (const option of Object.keys(existingCardData.options)) {
        parentId = option.split('_')[0];
        occuranceNumber = option.split('_')[1];
        for (const form of forms) {
          for (const formsection of form.formSections) {
            for (const formfield of formsection.formFields) {
              if (
                (formfield.type === 'SelectOption' ||
                  formfield.type === 'MultiSelect') &&
                option.split('_')[2] === formfield.configObjectId
              ) {
                const needSelectCall = existingCardData.options[option].filter(
                  (optionObj: any) => {
                    return (
                      optionObj.id ===
                      formData[0][formfield.logicalColumn.dbCode]
                    );
                  }
                );
                if (needSelectCall.length === 0) {
                  ffList.push(formfield.configObjectId);
                }
              }
            }
          }
        }
      }
      if (ffList.length > 0) {
        let data: any = {};
        data['formData'] = formData[0];
        data['configIds'] = ffList;
        const optionsData = yield call(
          base.getAllOptionsData,
          data,
          parentId,
          occuranceNumber
        );
        Object.assign(existingOptions, optionsData);
      }
    }
  } catch (e) {
    console.log(
      'Error occured in getDynamicOptionParentFFList ::::',
      e.message
    );
  }
}

export function* validateCompositeEntityRecord(
  requestBody: any,
  compositeEntityNode: any,
  mode: any,
  referralId: any
): Generator<any, any, any> {
  let parentForm =
    mode === 'Edit'
      ? compositeEntityNode.editForm
        ? compositeEntityNode.editForm
        : compositeEntityNode.insertForm
      : compositeEntityNode.insertForm
        ? compositeEntityNode.insertForm
        : compositeEntityNode.editForm;
  let errorMap = {
    formFieldValidation: {},
    formLeavelMessage: '',
  };
  let formValidation = {};
  errorMap = base.validateCompositeEntityRecord(
    requestBody.baseEntity.records[0],
    compositeEntityNode.entity,
    mode,
    errorMap,
    '',
    parentForm,
    errorMap.formLeavelMessage
  );
  if (compositeEntityNode.combinedNodes) {
    compositeEntityNode.combinedNodes.map((childNode: any) => {
      let recordList = requestBody.baseEntity.childEntities.filter(
        (row: any) => {
          return childNode.entity.configObjectId === row.configId;
        }
      );
      if (recordList[0]) {
        recordList[0].records.map((record: any) => {
          let childForm =
            mode === 'Edit'
              ? childNode.editForm
                ? childNode.editForm
                : childNode.insertForm
              : childNode.insertForm
                ? childNode.insertForm
                : childNode.editForm;
          errorMap = base.validateCompositeEntityRecord(
            record,
            childNode.entity,
            mode,
            errorMap,
            compositeEntityNode.entity.configObjectId,
            childForm,
            errorMap.formLeavelMessage
          );
        });
      }
    });
  }
  if (errorMap.formLeavelMessage) {
  }
  let existingCardsData = yield select(getCard);
  let cardsData = cloneDeep(existingCardsData);
  let cardData = cardsData[referralId];
  if (
    Object.keys(errorMap.formFieldValidation).length ||
    Object.keys(errorMap.formLeavelMessage).length
  ) {
    if (Object.keys(cardData.errorData).length === 0) {
      const appError = {
        code: 406,
        errorData: {
          formFieldValidation: {},
          formValidation: {},
        },
      };
      appError.errorData.formFieldValidation = errorMap.formFieldValidation;
      appError.errorData.formValidation = formValidation;
      cardData.errorData = appError;
      cardData.buttonClicked = '';
      const updateStore: any = {
        cardsData: cardsData,
      };
      yield put(setStore(updateStore));
    } else {
      Object.assign(
        cardData.errorData.errorData.formFieldValidation,
        errorMap.formFieldValidation
      );
      Object.assign(
        cardData.errorData.errorData.formValidation,
        formValidation
      );
      cardData.buttonClicked = '';
      const updateStore: any = {
        cardsData: cardsData,
      };
      yield put(setStore(updateStore));
    }
  } else if (
    !(
      Object.keys(errorMap.formFieldValidation).length ||
      Object.keys(errorMap.formLeavelMessage).length
    ) &&
    Object.keys(cardData.errorData).length > 0 &&
    Object.keys(cardData.errorData.errorData.formFieldValidation).length > 0
  ) {
    cardData.errorData.errorData.formFieldValidation = {};
    cardData.errorData.errorData.formValidation = {};
    cardData.buttonClicked = '';
    const updateStore: any = {
      cardsData: cardsData,
    };
    yield put(setStore(updateStore));
  }
}

export function* validateFormRecord(
  data: any,
  logicalEntity: any,
  referralId: any,
  mode: any,
  form: any
): Generator<any, any, any> {
  let hiddenFeildDbCode: any = [];
  form.formSections.map((formSection: any, index: any) => {
    formSection.formFields.map((formFieldMap: any, index: any) => {
      if (formFieldMap.type === 'Hiddenfield') {
        hiddenFeildDbCode.push(formFieldMap.logicalColumn.dbCode);
      }
    });
  });

  let errorMapKey: string = logicalEntity.configObjectId + '=>0=>';
  let errorMap: any = {};
  let formLevel = {};
  let formLeavelMessage = '';
  let isErrorOccured: boolean = false;
  logicalEntity.logicalColumns.map(async (logicalColumn: any, index: any) => {
    let componentErrorMessage = '';
    const { isValidPhoneNumber } = await import('react-phone-number-input');
    if (
      logicalColumn.dataType === 'PHONE_NUMBER' &&
      data[logicalColumn.dbCode]
    ) {
      componentErrorMessage = isValidPhoneNumber(data[logicalColumn.dbCode])
        ? ''
        : 'Invalid Phone Number';
    }
    let mandatoryError: any = base.clientSideMandatoryValidation(
      data[logicalColumn.dbCode],
      logicalColumn
    );
    let regexTypeError: any = base.clientSideRegexValidation(
      data[logicalColumn.dbCode],
      logicalColumn,
      data,
      mode
    );
    if (mandatoryError || regexTypeError || componentErrorMessage) {
      isErrorOccured = true;
      let mandatoryErrorMessage = mandatoryError ? mandatoryError : '';
      let regexTypeErrorMessage = regexTypeError ? regexTypeError : '';
      if (hiddenFeildDbCode.includes(logicalColumn.dbCode)) {
        formLeavelMessage =
          formLeavelMessage +
          ' ' +
          logicalColumn.dbCode +
          ' ' +
          mandatoryErrorMessage +
          regexTypeErrorMessage +
          componentErrorMessage;
      }
      errorMap[errorMapKey + logicalColumn.dbCode] =
        mandatoryErrorMessage + regexTypeErrorMessage + componentErrorMessage;
    }
  });
  if (formLeavelMessage) {
  }
  let existingCardsData = yield select(getCard);
  let cardsData = cloneDeep(existingCardsData);
  let cardData = cardsData[referralId];
  if (isErrorOccured) {
    if (Object.keys(cardData.errorData).length === 0) {
      const appError = {
        code: 406,
        errorData: {
          formFieldValidation: {},
          formValidation: {},
        },
      };
      appError.errorData.formFieldValidation = errorMap;
      appError.errorData.formValidation = formLevel;
      cardData.errorData = appError;
      cardData.buttonClicked = '';
      const updateStore: any = {
        cardsData: cardsData,
      };
      yield put(setStore(updateStore));
    } else {
      Object.assign(cardData.errorData.errorData.formFieldValidation, errorMap);
      Object.assign(cardData.errorData.errorData.formValidation, formLevel);
      cardData.buttonClicked = '';
      const updateStore: any = {
        cardsData: cardsData,
      };
      yield put(setStore(updateStore));
    }
  } else if (
    !isErrorOccured &&
    Object.keys(cardData.errorData).length > 0 &&
    Object.keys(cardData.errorData.errorData.formFieldValidation).length > 0
  ) {
    cardData.errorData.errorData.formFieldValidation = {};
    cardData.buttonClicked = '';
    const updateStore: any = {
      cardsData: cardsData,
    };
    yield put(setStore(updateStore));
  }
}

export function* createRequestBody(
  action: any,
  node: any,
  formId: any
): Generator<any, any, any> {
  try {
    const existingCardData = yield select(getCard);

    let parentFormData =
      action.gridButtonAction && action.rowDetail
        ? action.rowDetail
        : action.gridButtonAction
          ? {}
          : cloneDeep(existingCardData[formId]['data']['0']);
    if (
      existingCardData[formId].data.attachmentData &&
      existingCardData[formId].data.attachmentData.length > 0
    ) {
      parentFormData.attachmentData = cloneDeep([
        ...existingCardData[formId].data.attachmentData,
      ]);
    }
    if (action.referenceData) {
      Object.assign(parentFormData, action.referenceData, base.getUserDetail());
    }
    let processDefId;
    let userTaskId;
    if (existingCardData[formId].referenceData) {
      processDefId = existingCardData[formId].referenceData.processDefinitionId;
      userTaskId = existingCardData[formId].referenceData.userTaskId;
    }
    const childData = parentFormData.child;
    delete parentFormData.child;
    parentFormData[RECORD_INDEX] = 0;
    const requestBody: any = {};
    requestBody.action = action.actionName;
    requestBody.transactionName = action.transactionName
      ? action.transactionName
      : node.insertForm
        ? node.insertForm.formLabel + '_' + action.actionName
        : node.editForm
          ? node.editForm.formLabel + '_' + action.actionName
          : action.actionName;
    requestBody.baseEntity = {};
    requestBody.baseEntity.nodeId = action.nodeId;
    requestBody.baseEntity.configId = node.entity.configObjectId;
    requestBody.baseEntity.records = [];
    requestBody.baseEntity.records.push(parentFormData);
    requestBody.baseEntity.childEntities = [];
    if (processDefId && userTaskId && action.actionName !== 'Update') {
      requestBody.bpmnRequest = { processDefId, taskId: userTaskId };
    } else if (processDefId && action.actionName !== 'Update') {
      requestBody.bpmnRequest = { processDefId };
    }
    if (childData && Object.keys(childData).length && node.combinedNodes) {
      node.combinedNodes.sort((a: any, b: any) => (a.order > b.order ? 1 : -1));
      node.combinedNodes.map((node: any) => {
        const childBody: any = {};
        childBody.nodeId = node.configObjectId;
        childBody.configId = node.entity.configObjectId;
        childBody.childEntities = [];
        const childFormId = base.getFormId(node, action.mode);
        const childFormData = childData[childFormId];
        const childRecords: any = [];
        if (childFormData && Object.keys(childFormData).length) {
          Object.entries(childFormData).forEach(([key, value]: [any, any]) => {
            Object.assign(value, base.getUserDetail());
            value[RECORD_INDEX] = key;
            Object.assign(value, base.getUserDetail());
            childRecords.push(value);
          });
        }
        childBody.records = childRecords;
        requestBody.baseEntity.childEntities.push(childBody);
        return null;
      });
    }
    return requestBody;
  } catch (e) {
    const messages: string[] = [];
    messages.push(UpsertMessage.UNSUCCESSFUL);
    yield put(setToggleMessages(messages));
  }
}

export function* processAppengMetaOperation(
  action: any,
  data: any
): Generator<any, any, any> {
  const pkDBCode = getPrimaryDBCode(data.Form.logicalEntity.logicalColumns);

  Object.assign(DummyNode.data.CompositeEntityNode.insertForm, data.Form);
  Object.assign(
    DummyNode.data.CompositeEntityNode.entity,
    data.Form.logicalEntity
  );
  data = DummyNode.data;

  const existing_CardData = yield select(getCard);

  let parentFormData = cloneDeep({
    ...existing_CardData[action.referralId]['data']['0'],
    attachmentData:
      existing_CardData[action.referralId]['data']['attachmentData'],
    transactionName:
      action.transactionName && action.transactionName.length > 0
        ? action.transactionName
        : data.CompositeEntityNode.insertForm.formLabel +
          '_' +
          action.actionName,
  });

  parentFormData[RECORD_INDEX] = 0;

  yield call(
    validateFormRecord,
    parentFormData,
    data.CompositeEntityNode.entity,
    action.referralId,
    action.mode,
    data.CompositeEntityNode.insertForm
  );

  const existingCardData = yield select(getCard);

  if (
    Object.keys(existingCardData[action.referralId].errorData).length === 0 ||
    (existingCardData[action.referralId].errorData &&
      Object.keys(
        existingCardData[action.referralId].errorData.errorData
          .formFieldValidation
      ).length === 0)
  ) {
    const response = yield call(
      base.upsertFormRecord,
      parentFormData,
      data.CompositeEntityNode.entity.name,
      action.keys[pkDBCode]
    );

    const existingOpenedModals = yield select(getOpenModals);

    if (!response.errors) {
      const messages: string[] = [];
      if (response.mode === 'Insert') {
        messages.push(
          response.message ? response.message : UpsertMessage.SAVESUCCESSFUL
        );
      } else if (response.mode === 'Delete') {
        messages.push(
          response.message ? response.message : UpsertMessage.DELETESUCCESSFUL
        );
      } else {
        messages.push(
          response.message ? response.message : UpsertMessage.UPDATESUCCESSFUL
        );
      }

      switch (data.CompositeEntityNode.insertForm.formType) {
        case 'insertForm':
        case 'editForm':
        case 'form':
          if (response.mode === 'Insert') {
            if (action.parentId && !action.portalForm) {
              const layoutData = {};
              const existingCardData = yield select(getCard);
              const formData = existingCardData[action.referralId].data;

              if (!action.usePortalReferenceData) {
                const cardData = new CardData(
                  action.referralId,
                  CardType.FORM,
                  formData,
                  action.portalId,
                  action.parentId,
                  undefined,
                  layoutData,
                  existingCardData[action.referralId].options
                );

                const routerParam: any = {};
                Object.assign(routerParam, action.location.state);
                routerParam['portalId'] = action.portalId
                  ? action.portalId
                  : action.parentId;
                routerParam['parentId'] = action.parentId;
                routerParam['referralId'] = action.referralId;
                routerParam[action.referralId] = {
                  resetReferenceData: true,
                  AE_RELOAD: {
                    ID: uuid(),
                    TS: new Date().getTime(),
                    STATUS: 'R',
                  },
                };

                router.navigate(-1);
              } else {
                const cardData = new CardData(
                  action.referralId,
                  CardType.FORM,
                  formData,
                  action.portalId,
                  action.parentId,
                  layoutData,
                  {},
                  existingCardData[action.referralId].options
                );
                const previousData = existingCardData[action.parentId]
                  ? existingCardData[action.parentId].referenceData
                  : {};
                Object.assign(previousData, layoutData);
                const portalData = new CardData(
                  action.parentId,
                  CardType.PORTAL,
                  {},
                  action.portalId,
                  action.parentId,
                  previousData
                );

                const routerParam: any = {};
                Object.assign(routerParam, action.location.state);
                routerParam['portalId'] = action.portalId
                  ? action.portalId
                  : action.parentId;
                routerParam['parentId'] = action.parentId;
                routerParam['referralId'] = action.referralId;
                routerParam[action.referralId] = layoutData;
                routerParam[action.parentId] = previousData;
                Object.assign(routerParam[action.parentId], {
                  resetReferenceData: true,
                });
                yield router.navigate('/app/ce/portal/' + uuid(), {
                  state: routerParam,
                });
              }
            } else if (
              action.portalForm &&
              Object.keys(existingOpenedModals).length === 0
            ) {
              let existingCardData = yield select(getCard);
              let formData = existingCardData[action.referralId];
              let cardData = new CardData(
                action.referralId,
                CardType.FORM,
                formData.data,
                formData.portalId,
                formData.parentId,
                { INSERT_TRANSACTION_ID: uuid() },
                {},
                formData.options
              );
              yield put(
                setFormData(cardData, action.referralId, false, false, true)
              );
            } else {
              yield put(addModalReload(action.parentId));
            }
          } else {
            let refferalIDArr = action.referralId.split('_');
            const existingCardData = yield select(getCard);
            let pkDBCode =
              existingCardData[action.referralId].referenceData.primaryDBCode;
            let primaryKey = response.pk
              ? response.pk
              : existingCardData[action.referralId].data[0][pkDBCode];
            yield put(
              getFormDataUsingFormConfig(
                action.formId,
                'Update',
                action.nodeType,
                primaryKey,
                action.referralId,
                action.resetForm,
                action.location,
                existingCardData[action.referralId].parentId,
                existingCardData[action.referralId].portalId,
                {
                  transactionId: response.transactionId,
                  primaryDBCode: pkDBCode,
                  actionType: 'UPSERT',
                  gridId: refferalIDArr[1],
                },
                action.usePortalReferenceData,
                existingCardData[action.referralId].rowData,
                existingCardData[action.referralId].tabGroupId,
                existingCardData[action.referralId].entityName,
                existingCardData[action.referralId].tabPortalId,
                existingCardData[action.referralId].entityId,
                existingCardData[action.referralId].parentCardId
              )
            );
          }
          break;

        case 'singleRecordForm': {
          const existingCardData = yield select(getCard);
          yield put(
            getFormDataUsingFormConfig(
              action.formId,
              'Update',
              action.nodeType,
              response.pk,
              action.referralId,
              action.resetForm,
              action.location,
              existingCardData[action.referralId].parentId,
              existingCardData[action.referralId].portalId,
              { transactionId: response.transactionId },
              action.usePortalReferenceData
            )
          );
          break;
        }

        case 'onlyInsertForm': {
          const existingCardData = yield select(getCard);
          yield put(
            getFormDataUsingFormConfig(
              action.formId,
              'Insert',
              action.nodeType,
              undefined,
              action.referralId,
              action.resetForm,
              action.location,
              existingCardData[action.referralId].parentId,
              existingCardData[action.referralId].portalId,
              { transactionId: response.transactionId },
              action.usePortalReferenceData
            )
          );
          break;
        }

        case 'displayInsertFormAftetMenuClick': {
          const existingCardData = yield select(getCard);
          yield put(
            getFormDataUsingFormConfig(
              action.formId,
              'Update',
              action.nodeType,
              response.pk,
              action.referralId,
              action.resetForm,
              action.location,
              existingCardData[action.referralId].parentId,
              existingCardData[action.referralId].portalId,
              { transactionId: response.transactionId },
              action.usePortalReferenceData
            )
          );
          break;
        }
      }
      yield put(setToggleMessages(messages));
    } else if (response.errors && response.code === 512) {
      const messages: string[] = [];
      messages.push(response.errors);
      yield put(setToggleMessages(messages));
    } else {
      const messages: string[] = [];
      messages.push(UpsertMessage.VALIDATION_FAILED);
      const appError: any = {
        code: response.code,
        errorData: {
          formFieldValidation: {},
          formValidation: {},
        },
      };
      let warningMessages: string[] = [];
      response.errors.map((err: any) => {
        const lastElement =
          err.location.split('=>')[err.location.split('=>').length - 1];
        if (isNaN(Number(lastElement))) {
          if (err.message) {
            appError.errorData.formFieldValidation[err.location] = err.message;
          } else if (err.warningMessage) {
            warningMessages.push(err.warningMessage);
          }
        } else {
          if (err.message) {
            appError.errorData.formValidation[err.location] = err.message;
          } else if (err.warningMessage) {
            warningMessages.push(err.warningMessage);
          }
        }
        return null;
      });
      if (warningMessages.length > 0) {
        let warningMessage: any = {};
        warningMessage[action.referralId] = warningMessages;
        yield put(setWarningMessages(warningMessage));
      }
      let existingCardsData = yield select(getCard);
      const cardsData = cloneDeep(existingCardsData);
      const currentCardData = cardsData[action.referralId];
      currentCardData.errorData = appError;
      currentCardData.buttonClicked = '';
      const updateStore: any = {
        cardsData: cardsData,
        toggleMessages: messages,
      };

      yield put(setStore(updateStore));
    }
  }
}
