import api from '../../../api';
import { call, put } from 'redux-saga/effects';
import * as base from '../../base';
import { UpsertMessage } from '../../../constants/appeng.enum';
import { setToggleMessages } from '../../../actions';

export function* executeDatabasePreProcessors(
  formData: any,
  configId: any,
  configType: any
): Generator<any, any, any> {
  const requestObject = {
    formData: formData,
    configId: configId,
    configType: configType,
  };
  const authToken = yield call(base.authJwtKey);
  const response = yield call(
    api.executePreprocessors,
    requestObject,
    authToken
  );
  if (response.code && (response.code === 417 || response.code === 512)) {
    return response;
  } else if (response.formData) {
    return response.formData;
  }
}

export function* executeDatabasePreProcessorsList(
  formData: any,
  configIds: any,
  configType: any
): Generator<any, any, any> {
  const requestObject = {
    formData: formData,
    configIds: configIds,
    configType: configType,
  };
  const authToken = yield call(base.authJwtKey);
  const response = yield call(
    api.executePreprocessorsOnLoad,
    requestObject,
    authToken
  );
  if (response.code && (response.code === 417 || response.code === 512)) {
    return response;
  } else if (response.formData) {
    return response.formData;
  }
}
