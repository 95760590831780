export default {
  advanceSearch: {
    search: 'શોધો',
    reset: 'ફરીથી સેટ કરો',
    close: 'બંધ',
  },
  mandatory: 'કૃપા કરીને નીચેના ફરજિયાત ફીલ્ડ્સ ભરો',
  invalid: 'કૃપા કરીને નીચેના અમાન્ય ડેટાને ઠીક કરો',
  addForm: 'ફોર્મ ઉમેરો',
  reportForm: 'રિપોર્ટ ફોર્મ',
  save: 'સાચવો',
  pleaseWait: 'મહેરબાની કરી રાહ જુવો...',
  filterForm: 'ફિલ્ટર ફોર્મ',
  filter: 'ફિલ્ટર',
  select: 'પસંદ કરો',
  chooseOption: 'વિકલ્પ પસંદ કરો ...',
  uploadClick: 'અપલોડ કરવા માટે અહીં ક્લિક કરો',
  resize: 'માપ બદલો',
  action: 'ક્રિયા',
  history: 'ઇતિહાસ',
  editor: 'સંપાદક',
  totalRecords:
    '{recordCount} {recordCount, plural,one {રેકોર્ડ} other {રેકોર્ડ}}',
};
