import React from 'react';
import { Button } from 'info-ui-library';

import ChildUITable from './grid/ChildUITable';
import { NOPRIVILEGE, EDIT, VIEW } from '../constants/appeng.enum';
import { extractLabel } from '../utils/intlutils';
import { getPrivilege, isExpressionResolved } from '../utils/ClientUtils';
import SimpleFormGenerator from './form/SimpleFormGenerator';
import ComplexFormGenerator from './form/ComplexFormGenerator';
import { isConfigObjectAccessible, isConfigObjectEditable } from './form/CardGenerators';
import FormButton from '../components/FormButton';
import ConfigButtonGenerator from './grid/ConfigButtonGenerator';
import ButtonCore from '../components/ButtonCore';
import {
    StyledSimpleFormButtonWrapper
} from './form/FormStyle';
import SplitButtonCore from '../components/SplitButtonCore';
import FloatingButtonCore from '../components/FloatingButtonCore'
import cloneDeep from 'lodash/cloneDeep';

export const processButtonPrivileges = (privilegeBP: any, buttonPrivileges: any, currentRole: any,
    expressionAvailable: any, accessibilityRegex: any, editabilityRegex: any, formData: any) => {
    let isAccessible = true;
    let buttonPrivilege = privilegeBP;
    buttonPrivilege = getPrivilege(buttonPrivileges, currentRole)

    if (buttonPrivilege !== NOPRIVILEGE && expressionAvailable) {
        isAccessible = isConfigObjectAccessible(accessibilityRegex, formData);
    } else if (buttonPrivilege === NOPRIVILEGE) {
        isAccessible = false;
    }
    return isAccessible;
}

export const getMenuButtons = (themeContext: any, menuButtonList: any, handleClick: any,
    userContext: any, formDataObject: any, buttonPanelPrivilege: any, props: any, loading: any) => {
    let menuButtons: any = [];
    if (menuButtonList) {
        const sortedMenuButtonList = [...menuButtonList].sort((a: any, b: any) => (a.order > b.order) ? 1 : -1);
        menuButtons = sortedMenuButtonList.map((menuBtn: any) => {
            const splitbutton = cloneDeep(menuBtn);
            let isMenuButtonAccessible = true;
            let isMenuButtonEditable = true;

            let formData = {};
            if (formDataObject) {
                formData = { ...formDataObject.data[0] };
                Object.assign(formData, userContext.getUserDetail())
            } else {
                Object.assign(formData, userContext.getUserDetail())
            }

            if (props.formButton && formDataObject) {
                isMenuButtonAccessible = processButtonPrivileges(buttonPanelPrivilege, splitbutton.privileges,
                    userContext.getCurrentRole(), splitbutton.expressionAvailable, splitbutton.accessibilityRegex,
                    splitbutton.editabilityRegex, formData);
            } else {
                isMenuButtonAccessible = getPrivilege(splitbutton.privileges, userContext.getCurrentRole()) !== 'NOPRIVILEGE' ? true : false;
            }
            if (!props.nodeEditable) {
                isMenuButtonEditable = false;
            } else {
                const buttonPriv = getPrivilege(splitbutton.privileges, userContext.getCurrentRole());
                isMenuButtonEditable = isConfigObjectEditable(isMenuButtonAccessible, buttonPriv,
                    splitbutton.editabilityRegex, formData, props.buttonPanelPrivilege, isMenuButtonEditable);
                splitbutton.isDisabled = buttonPriv === VIEW ? true : false;
            }

            if (isMenuButtonAccessible) {
                splitbutton.menuLabel = extractLabel(splitbutton.menuLabel, props.intl);
                splitbutton.buttonPriv = !isMenuButtonEditable;
            }
            let buttonList: any[] = splitbutton.buttons ? splitbutton.buttons : [];
            buttonList.sort((a, b) => (a.order > b.order) ? 1 : -1)
            let buttons: any[] = buttonList.map(button => {
                let isAccessible = true;
                let isEditable = true;
                if (props.formButton && formDataObject) {
                    isAccessible = processButtonPrivileges(buttonPanelPrivilege, button.privileges,
                        userContext.getCurrentRole(), button.expressionAvailable, button.accessibilityRegex,
                        button.editabilityRegex, formData);
                } else {
                    isAccessible = getPrivilege(button.privileges, userContext.getCurrentRole()) !== NOPRIVILEGE ? true : false;
                }
                if (!props.nodeEditable) {
                    isEditable = false;
                } else {
                    const buttonPriv = getPrivilege(button.privileges, userContext.getCurrentRole());
                    isEditable = isConfigObjectEditable(isAccessible, buttonPriv,
                        button.editabilityRegex, formData, props.buttonPanelPrivilege, isEditable);
                }
                if (isAccessible) {
                    button.label = extractLabel(button.label, props.intl);
                    button.buttonPriv = !isEditable;
                    return button;
                } else {
                    return null;
                }
            });
            buttons = buttons.filter(x => x != null);
            if (isMenuButtonAccessible && splitbutton.defaultType == 'floatingButton') {
                return (
                    <FloatingButtonCore
                        styleName={themeContext.styleName}
                        ioTheme={themeContext.ioTheme}
                        ioMode={themeContext.ioMode}
                        configId={splitbutton.configObjectId}
                        disabled={loading.status}
                        name={splitbutton.menuLabel}
                        buttonClass={splitbutton.buttonClass}
                        handleClick={handleClick}
                        buttons={buttons}
                        showloadingSymbol={loading.status && loading.id === splitbutton.configObjectId} />
                )
            }
            else if (isMenuButtonAccessible && splitbutton.defaultType == 'dropdownButton') {
                return (<SplitButtonCore
                    styleName={themeContext.styleName}
                    ioTheme={themeContext.ioTheme}
                    ioMode={themeContext.ioMode}
                    configId={splitbutton.configObjectId}
                    disabled={(loading.status && !splitbutton.portalId) || splitbutton.isDisabled}
                    name={splitbutton.menuLabel}
                    buttonClass={splitbutton.buttonClass}
                    handleClick={handleClick}
                    buttons={buttons}
                    childRelations={splitbutton.childRelations}
                    showloadingSymbol={loading.status && loading.id === splitbutton.configObjectId && !splitbutton.portalId}
                    disableMainMenuButton={splitbutton.buttonPriv || splitbutton.isDisabled}
                    modalRequired={splitbutton.modalRequired}
                    portalId={splitbutton.portalId} />)
            }
        });
    }
    return menuButtons;
}

export const getButtons = (themeContext: any, buttonList: any, handleClick: any,
    userContext: any, formDataObject: any, buttonPanelPrivilege: any, props: any, loading: any) => {
    let buttons: any = [];
    const copiedButtonList = cloneDeep(buttonList);
    copiedButtonList.sort((a: any, b: any) => (a.order > b.order) ? 1 : -1)   //sorting in asc order
    if (buttonPanelPrivilege !== NOPRIVILEGE) {
        let formData: any;
        if (formDataObject) {
            formData = { ...formDataObject.data[0] };
            Object.assign(formData, userContext.getUserDetail())
        }
        buttons = copiedButtonList.map((button: any) => {
            let isAccessible = true;
            let isEditable = true;
            if (props.formButton && formDataObject) {
                isAccessible = processButtonPrivileges(buttonPanelPrivilege, button.privileges,
                    userContext.getCurrentRole(), button.expressionAvailable, button.accessibilityRegex,
                    button.editabilityRegex, formData);
            } else {
                isAccessible = getPrivilege(button.privileges, userContext.getCurrentRole()) !== NOPRIVILEGE ? true : false;
            }
            if (!props.nodeEditable) {
                isEditable = false;
            } else {
                const buttonPriv = getPrivilege(button.privileges, userContext.getCurrentRole());
                isEditable = isConfigObjectEditable(isAccessible, buttonPriv,
                    button.editabilityRegex, formData, props.buttonPanelPrivilege, isEditable);
            }
            if (isAccessible) {
                switch (button.buttonClass) {
                    case 'updateFormDataButton':

                        return (
                            <React.Fragment>
                                <ButtonCore button={button}
                                    isEditable={isEditable}
                                    loading={loading.status}
                                    showloadingSymbol={loading.status && loading.id === button.configObjectId}
                                    handleClick={() => {
                                        handleClick(button.buttonClass, extractLabel(button.label, "en"), false,
                                            button.childRelations, button.configObjectId)
                                    }
                                    }
                                />
                            </React.Fragment>
                        );
                    case 'btn singleEntityModalSave':
                        return (<Button styleName={themeContext.styleName}
                            ioTheme={themeContext.ioTheme}
                            ioMode={themeContext.ioMode}
                            id={button.configObjectId}
                            disabled={!isEditable || loading.status}
                            name={extractLabel(button.label, props.intl.locale)}
                            onClick={() => handleClick(button.buttonClass, extractLabel(button.label, "en"), false,
                                button.childRelations, button.configObjectId)}>
                            {loading.status && loading.id === button.configObjectId && (
                                <i
                                    className="fa fa-refresh fa-spin"
                                    style={{ marginRight: "5px" }}
                                />
                            )}
                        </Button>
                        );
                    case 'formActionDataButton':
                        return (<Button styleName={themeContext.styleName}
                            ioTheme={themeContext.ioTheme}
                            ioMode={themeContext.ioMode}
                            id={button.configObjectId}
                            disabled={!isEditable}
                            name={extractLabel(button.label, props.intl.locale)}
                            onClick={() => handleClick(button.buttonClass, extractLabel(button.label, "en"), false)} />);
                    case 'cancelCompositeEntity':
                        return (<Button styleName={themeContext.styleName}
                            ioTheme={themeContext.ioTheme}
                            ioMode={themeContext.ioMode}
                            id={button.configObjectId}
                            disabled={false}
                            className='fa fa-close'
                            name={''}
                            onClick={() => props.handleClick ? props.handleClick(props) :
                                handleClick(button.buttonClass, extractLabel(button.label, "en"), false)} />);

                    case 'single-entity-insert':
                        return <Button styleName={themeContext.styleName}
                            ioTheme={themeContext.ioTheme}
                            ioMode={themeContext.ioMode}
                            id={button.configObjectId}
                            disabled={!isEditable}
                            name={extractLabel(button.label, props.intl.locale)}
                            onClick={() => {
                                props.handleClick ?
                                    props.handleClick(button, props) : handleClick(button.buttonClass, extractLabel(button.label, "en"), false)
                            }}>
                        </Button>
                    case 'download-report':
                        return <Button styleName={themeContext.styleName}
                            ioTheme={themeContext.ioTheme}
                            ioMode={themeContext.ioMode}
                            id={button.configObjectId}
                            disabled={!isEditable}
                            name={extractLabel(button.label, props.intl.locale)}
                            onClick={() => { handleClick(button.buttonClass, extractLabel(button.label, "en"), false) }}
                        />
                    case 'deleteFormCompositeEntity':
                        return (
                            <React.Fragment>
                                {props.mode !== 'Insert' &&
                                    <Button styleName={themeContext.styleName}
                                        ioTheme={themeContext.ioTheme}
                                        ioMode={themeContext.ioMode}
                                        id={button.configObjectId}
                                        disabled={!isEditable}
                                        name={extractLabel(button.label, props.intl.locale)}
                                        onClick={() => handleClick(button.buttonClass, extractLabel(button.label, "en"), false, button.childRelations, button.configObjectId, button)} />
                                }
                            </React.Fragment>
                        );
                    case 'addRowInGrid':
                        let showInlineAddButton = window.innerWidth > 750 ? true : false;
                        return button.dbCode != 'expander' && showInlineAddButton && < Button styleName={themeContext.styleName}
                            ioTheme={themeContext.ioTheme}
                            ioMode={themeContext.ioMode}
                            id={button.configObjectId}
                            disabled={!isEditable
                            }
                            name={extractLabel(button.label, props.intl.locale)
                            }
                            onClick={() => {
                                props.handleClick ?
                                    props.handleClick(button, props) : handleClick(button.buttonClass, extractLabel(button.label, "en"), false)
                            }}
                        />
                    case 'gridFilterActionButton':
                        return <Button styleName={themeContext.styleName}
                            ioTheme={themeContext.ioTheme}
                            ioMode={themeContext.ioMode}
                            id={button.configObjectId}
                            disabled={!isEditable}
                            name={extractLabel(button.label, props.intl.locale)}
                            onClick={() => {
                                props.handleClick ?
                                    props.handleClick(button, props) : handleClick(button.buttonClass, extractLabel(button.label, "en"), false)
                            }}
                            className={button.icon}
                        />
                    case 'sideNodeAddForm':
                        return <Button styleName={themeContext.styleName}
                            ioTheme={themeContext.ioTheme}
                            ioMode={themeContext.ioMode}
                            id={button.configObjectId}
                            disabled={!isEditable}
                            name={extractLabel(button.label, props.intl.locale)}
                            onClick={() => {
                                props.handleClick ?
                                    props.handleClick(button, props) : handleClick(button.buttonClass, extractLabel(button.label, "en"), false)
                            }}
                        />
                    case 'openPortalInModal':
                        return (
                            <React.Fragment>
                                <ButtonCore button={button}
                                    isEditable={isEditable}
                                    loading={loading.status}
                                    showloadingSymbol={loading.status && loading.id === button.configObjectId}
                                    handleClick={() => {
                                        handleClick(button.buttonClass, extractLabel(button.label, "en"), false,
                                            button.childRelations, button.configObjectId, button)
                                    }}
                                />
                            </React.Fragment>
                        );
                    default:
                        return (<Button styleName={themeContext.styleName}
                            ioTheme={themeContext.ioTheme}
                            ioMode={themeContext.ioMode}
                            disabled={!isEditable}
                            id={button.configObjectId}
                            name={extractLabel(button.label, props.intl.locale)}
                            onClick={() => handleClick(button.buttonClass, extractLabel(button.label, "en"), false,
                                button.childRelations, button.configObjectId, button)} />);
                }

            }
            return null;
        });

        if (props.isEditButtonEnable) {
            let buttonEdit = <Button styleName={themeContext.styleName}
                ioTheme={themeContext.ioTheme}
                ioMode={themeContext.ioMode}
                id={"EDITTT"}
                disabled={false}
                name={props.editableGridEnable ? "Done" : "Edit"}
                onClick={() => props.setEditableGridEnable()} />
            buttons.push(buttonEdit);
        }

    }

    return buttons;
}

export const getSimpleOrComplexForm = (propsToPass: any,
    formPrivilege: any, isFormAccessible: any, isFormEditable: any, nodeEditable: any, routerParameter: any, resetForm: any, portalForm: any) => {
    return propsToPass.parentForm.tabRequiredInFormsection || propsToPass.combinedNodes != null ?
        <ComplexFormGenerator
            {...propsToPass}
            formPrivilege={formPrivilege}
            isFormAccessible={isFormAccessible}
            isFormEditable={isFormEditable}
            nodeEditable={nodeEditable}
            resetForm={resetForm}
            portalForm={portalForm}
        />
        : <SimpleFormGenerator
            {...propsToPass}
            formPrivilege={formPrivilege}
            isFormAccessible={isFormAccessible}
            isFormEditable={isFormEditable}
            nodeEditable={nodeEditable}
            resetForm={resetForm}
            portalForm={portalForm} />
}

export const processNodeEditibility = (nodeExpressionAvailble: any, nodeEditabilityRegex: any, formData: any) => {
    let nodeEditable = true;
    if (nodeExpressionAvailble && nodeEditabilityRegex
        && !isExpressionResolved(nodeEditabilityRegex,
            formData)) {
        nodeEditable = false;
    }
    return nodeEditable;
}

export const processFormPrivilegeAndAccessibilityAndEditibilty = (nodeEditable: any,
    userContext: any, props: any, formData: any) => {
    let isFormAccessible = true;
    let isFormEditable = nodeEditable;
    let formPrivilege = EDIT;
    const currentRole = userContext.getCurrentRole();
    formPrivilege = getPrivilege(props.parentForm.privileges, currentRole);
    if (formPrivilege !== NOPRIVILEGE && props.parentForm.expressionAvailable) {
        let updatedFormData = { ...formData };
        Object.assign(updatedFormData, userContext.getUserDetail())
        isFormAccessible = isConfigObjectAccessible(props.parentForm.accessibilityRegex,
            updatedFormData);
        isFormEditable = isConfigObjectEditable(isFormAccessible, formPrivilege,
            props.parentForm.editabilityRegex, updatedFormData, formPrivilege, isFormEditable)
    }
    return { formPrivilege, isFormAccessible, isFormEditable }
}

export const getEditFormButtons = (nodeId: any, mode: any, displayName: any, nodeType: any
    , keys: any, referenceData: any, ceId: any, buttonList: any, buttonPanelPrivilege: any,
    parentFormId: any, themeContext: any, nodeEditable: any, gridId: any, referralId: any,
    routerParameter: any, resetForm: any, portalForm: any, entityId: any, compositeEntityKey: any,
    setCardButtons: any, portalId: any, parentId: any, usePortalReferenceData?: any, handleClick?: any, menuButtons?: any) => {
    if (setCardButtons) {
        return (
            <React.Fragment>
                <ConfigButtonGenerator
                    buttonList={buttonList}
                    nodeId={nodeId}
                    mode={mode}
                    displayName={displayName}
                    nodeType={nodeType}
                    keys={keys}
                    data={referenceData}
                    ceId={ceId}
                    buttonPanelPrivilege={buttonPanelPrivilege}
                    parentFormId={parentFormId}
                    formButton={true}
                    nodeEditable={nodeEditable}
                    gridId={gridId}
                    routerParameter={routerParameter}
                    referralId={referralId}
                    resetForm={resetForm}
                    portalForm={portalForm}
                    entityId={entityId}
                    compositeEntityKey={compositeEntityKey}
                    portalId={portalId}
                    usePortalReferenceData={usePortalReferenceData}
                    handleClick={handleClick}
                    parentId={parentId}
                    menuButtons={menuButtons}
                />
                {ceId && <FormButton nodeId={nodeId}
                    mode={mode} displayName={displayName}
                    nodeType={nodeType}
                    keys={keys} data={referenceData}
                    ceId={ceId} themeContext={themeContext} />}
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
                <StyledSimpleFormButtonWrapper>
                    <ConfigButtonGenerator
                        buttonList={buttonList}
                        nodeId={nodeId}
                        mode={mode}
                        displayName={displayName}
                        nodeType={nodeType}
                        keys={keys}
                        data={referenceData}
                        ceId={ceId}
                        buttonPanelPrivilege={buttonPanelPrivilege}
                        parentFormId={parentFormId}
                        formButton={true}
                        nodeEditable={nodeEditable}
                        gridId={gridId}
                        routerParameter={routerParameter}
                        referralId={referralId}
                        resetForm={resetForm}
                        portalForm={portalForm}
                        entityId={entityId}
                        compositeEntityKey={compositeEntityKey}
                        portalId={portalId}
                        usePortalReferenceData={usePortalReferenceData}
                        handleClick={handleClick}
                        parentId={parentId}
                        menuButtons={menuButtons}
                    />
                </StyledSimpleFormButtonWrapper>
                {ceId && <FormButton nodeId={nodeId}
                    mode={mode} displayName={displayName}
                    nodeType={nodeType}
                    keys={keys} data={referenceData}
                    ceId={ceId} themeContext={themeContext} />}
            </React.Fragment>
        )
    }


}

export const getFormValidationMessage = (location: any, errorDataFromStore: any, locale: any) => {
    let errorData: any = errorDataFromStore.errorData.formValidation[location];
    let parsedError = '';
    if (errorData) {
        errorData.split(',').map((message: any) => {
            if (parsedError !== '') {
                parsedError = parsedError + ' , ' + extractLabel(message, locale)
            } else {
                parsedError = extractLabel(message, locale);
            }
            return null;
        })
    }
    return parsedError
}