import styled from 'styled-components';

export const InlineErrorContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 2px;
  font-size: 0.8em;
  color: #ffdc2c;
`;

export const MessageComponentSpan = styled.span`
  padding-top: 2px;
`;
