import * as React from 'react';
import { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from 'react-router-dom';

import { InfoAuth } from '../auth';
import Authenticator from './Authenticator';
import ChangePassword from './ChangePassword';
import Home from './Home';
import ForgotPassword from './ForgotPassword';
import ResetPassword from './ResetPassword';
import SignUp from './SignUp';
import SignIn from './SignIn';
import ConfirmSignUp from './ConfirmSignUp';

interface PrivateRouteProps {
  component: React.ComponentType<any>;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component: PrivateComponent,
  ...rest
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    InfoAuth.currentAuthenticatedUser()
      .then((user) => {
        setIsAuthenticated(true);
        setLoading(false);
      })
      .catch(() => {
        navigate('/auth');
      });
  }, [navigate]);

  if (loading) {
    return null;
  }

  return isAuthenticated ? (
    <PrivateComponent {...rest} />
  ) : (
    <Navigate to="/auth1" />
  );
};

interface IRoutesProps {
  locale: any;
  setMessages: any;
  message: any;
  loaderData: any;
}

interface LocationState {
  email?: string;
  showConfirmation?: boolean;
  userName?: string;
  userId?: string;
  showToast?: boolean;
}

const RoutesComponent: React.FC<IRoutesProps> = (props: IRoutesProps) => {
  const location = useLocation();
  const state: any = location.state as LocationState;
  if (props.loaderData.checkAuth.isAuthenticated) {
  }

  return (
    <Routes>
      <Route path="/" element={<Authenticator {...props} />} />
      <Route
        path="/login"
        element={
          <SignIn
            {...props}
            email={state?.email}
            navigateTo={
              props?.loaderData?.path ? props?.loaderData?.path : '/app/ce'
            }
          />
        }
      />
      <Route path="/forgotPassword" element={<ForgotPassword {...props} />} />
      <Route
        path="/resetPassword"
        element={<ResetPassword {...props} email={state?.email} />}
      />
      <Route
        path="/signUp"
        element={
          <SignUp
            {...props}
            showConfirmation={state?.showConfirmation ?? false}
            userName={state?.userName ?? ''}
          />
        }
      />
      <Route
        path="/confirmSignUp"
        element={
          <ConfirmSignUp
            {...props}
            email={state?.email}
            userId={state?.userId}
            showToast={state?.showToast ?? false}
          />
        }
      />
      <Route
        path="/changePassword"
        element={<ChangePassword {...props} userName={state?.userName} />}
      />
      <Route path="/home" element={<PrivateRoute component={Home} />} />
    </Routes>
  );
};

export default RoutesComponent;
