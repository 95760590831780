import { createProvider } from '../provider';
import AuthProvider from '../provider/auth.provider';
import InfoAuth from './info.auth';
import { InfoAuthProvider } from '../provider';
import createInfoAuthProvider from '../provider/info.auth.provider';

export default class InfoAuthConfig {
  private static configInstance: InfoAuthConfig;

  private constructor() {}

  public static get INSTANCE() {
    if (InfoAuthConfig.configInstance === undefined) {
      InfoAuthConfig.configInstance = new InfoAuthConfig();
    }
    return InfoAuthConfig.configInstance;
  }

  public static configure(config: any) {
    const provider: AuthProvider = createProvider(config);
    const infoAuthProvider: InfoAuthProvider = createInfoAuthProvider(config);

    const infoAuth = InfoAuth.INSTANCE;
    infoAuth.setConfig(config);
    infoAuth.setAuthProvider(provider);
    infoAuth.setInfoAuthProvider(infoAuthProvider);
  }
}
