import React, { useCallback, useContext, useEffect, useState } from 'react';
import { ThemeContext } from '../theme/ThemeContext';
import { Button, SelectOption, FormErrorContainers } from 'info-ui-library';
import { extractLabel } from '../utils/intlutils';
import { IMultiSelectCore } from './interfaces/index';
import { useDispatch, useSelector } from 'react-redux';
import { injectIntl } from 'react-intl';
import { useFormData } from '../custom-hooks/useFormData';
import { changeFormData, getOptions, setOpenModalSaga } from '../actions/index';
import { useErrorData } from '../custom-hooks/useErrorData';
import { processErrorData, processDropDownValues, getMultiValueObject } from './ComponentsCommonUtils';
import PortalModalGenerator from '../new-portal-layout/PortalModalGenerator';
import { useLocation, useNavigationType } from 'react-router';
import styled from 'styled-components';
const StyledDivAddButton = styled.div`
    float:left;
    width:0.5%;
`;
 
const StyleDiv = styled.div`
    margin-right: 48px;
`;
 
const StyledDivSelectOption = styled.div`
    float:left;
    width:99.5%;
`;
 
 
const MultiSelectCore: React.FC<IMultiSelectCore> = (props: IMultiSelectCore) => {
    const currentLocation= useLocation();
    const navigateAction= useNavigationType();
    const themeContext = useContext(ThemeContext);
    const dbCode = props.configProp.logicalColumn ? props.configProp.logicalColumn.dbCode : props.configProp.dbCode;
    //process error data
    let errorData: any = useErrorData(props.location, props.referralId);
    let parsedError = processErrorData(errorData, props.intl.locale);
    const existingCards = useSelector((state: any) => state.appState.cardsData);
    const options = existingCards[props.referralId].options;
    const id = props.parentFormId ? props.parentFormId : props.formId;
    const key = id + '_' + props.occuranceNumber + '_' + props.configProp.configObjectId;
    const selectedOptions = options[key];
    let parsedOption = processDropDownValues(props.configProp.multivalueList,
        props.intl.locale, selectedOptions);
 
    let parentPathList = props.referralId.split("_");
    let subComponentParentId = props.referralId;
    if (parentPathList.length > 1 && subComponentParentId && subComponentParentId != "0_") {
        parentPathList = parentPathList.slice(0, -1);
        subComponentParentId = parentPathList.join("_") + "_";
    }
 
    useEffect(() => {
        if (existingCards[props.referralId].type !== "PortalDataGrid" &&
            props.configProp.selectItemsReferenceID && !props.configProp.multivalueList) {
            dispatch(getOptions(props.parentFormId, props.formId, props.configProp,
                props.occuranceNumber, props.nodeId, props.referralId, currentLocation));
        }
    }, [props.configProp.configObjectId, props.occuranceNumber, existingCards[props.referralId].type]);
    const [cardPortalId, setCardPortalId] = useState('');
    const openCardModals = useSelector((state: any) => state.appState.openModals);
    const routerparams = {
        action: navigateAction,
        pathname: currentLocation.pathname,
        state: currentLocation.state
    }
 
    const getCardModalButtonsInHeader = (themeContext: any, cardPortalId: any,
        setOpenModalSaga: any, dispatch: any, parentId: any, subComponentParentId: any) => {
        return (<Button
            styleName={themeContext.styleName}
            ioTheme={themeContext.ioTheme}
            ioMode={themeContext.ioMode}
            id={1}
            disabled={false}
            name={''}
            className={'fa fa-close'}
            onClick={() => handleCardButtonClick(cardPortalId, setOpenModalSaga,
                dispatch, subComponentParentId)}
        />
        )
    }
    const openModalRouteParam: any = {};
    Object.assign(openModalRouteParam, routerparams);
    if (openCardModals.hasOwnProperty(subComponentParentId + cardPortalId) &&
        openCardModals[subComponentParentId + cardPortalId].referenceData[subComponentParentId + cardPortalId]) {
        openModalRouteParam['state'][subComponentParentId + cardPortalId] = openCardModals[subComponentParentId + cardPortalId].referenceData[subComponentParentId + cardPortalId]
    }
 
    let modalAttribute: any;
    if (props.configProp.buttons && props.configProp.buttons.length > 0) {
        modalAttribute = props.configProp.buttons[0].modalCss == "Left Align Full Vertical View" ? "left:0;right:auto;width:65%" : props.configProp.buttons[0].modalCss == "Right Align Full Vertical View" ? "left:auto;right:0;width:65%" : props.configProp.buttons[0].modalCss == "Center Align Partial View" ? "left:10%;right:10%;width:80%;height:80%;margin-top:3.8%" : "";
    }
 
    const OpenModal = (e: any) => {
        e.preventDefault();
        if (props.configProp.buttons[0].modalRequired) {
            setCardPortalId(props.configProp.buttons[0].portalId);
            const id_portal = subComponentParentId + props.configProp.buttons[0].portalId;
            dispatch(setOpenModalSaga(id_portal, false, true, [], [id_portal], {}, modalAttribute));
        }
    }
 
    const handleCardButtonClick = (cardPortalId: any, setOpenModalSaga: any, dispatch: any, subComponentParentId: any) => {
        const id = subComponentParentId + cardPortalId;
        dispatch(setOpenModalSaga(id, true, true, [], [id]));
    }
 
    let initialvalue = useFormData(props.formId, props.occuranceNumber,
        dbCode, props.parentFormId, props.referralId)
    const valueAsAnObject = getMultiValueObject(initialvalue, parsedOption);
    const dispatch = useDispatch();
    const chartModalHeader = getCardModalButtonsInHeader(themeContext, cardPortalId, setOpenModalSaga, dispatch, existingCards[props.referralId].parentId, subComponentParentId);
    const handleChange = useCallback((selectedOption: any) => {
        let multiValue = ""
        if (selectedOption) {
            const optionAsList = selectedOption.map((option: any) => {
                if (!multiValue) {
                    multiValue = option.id;
                } else {
                    multiValue = multiValue + ',' + option.id
                }
            }
        )
        }
 
        dispatch(changeFormData(props.formId, props.occuranceNumber,
            dbCode, multiValue,
            props.parentFormId, props.mode, props.nodeId, props.configProp.configObjectId,
            props.configProp.refreshFormOnChange, props.configProp.resetOnRefresh,
            props.configProp.placeHolder, props.referralId,currentLocation));
    },[]);
 
   
 
    try {
        if (props.configProp.buttons && props.configProp.buttons.length > 0 && props.buttonPrivilegesMap.isAccessible) {
            return (
                <StyleDiv>
                    {openCardModals.hasOwnProperty(subComponentParentId + cardPortalId) && <PortalModalGenerator
                        header={chartModalHeader}
                        subportalId={cardPortalId}
                        portalId={cardPortalId}
                        routerParameter={openModalRouteParam}
                        subComponentParentId={subComponentParentId}
                        modalAttribute={modalAttribute}
                    />
                    }
                    <StyledDivSelectOption>
                        <SelectOption
                            ioTheme={themeContext.ioTheme}
                            ioMode={themeContext.ioMode}
                            value={valueAsAnObject}
                            styleName={themeContext.styleName}
                            label={extractLabel(props.configProp.label, props.intl.locale)}
                            key={props.configProp.configObjectId}
                            options={parsedOption}
                            displayType={themeContext.componentType}
                            errorMessage={parsedError}
                            hasError={errorData ? true : false}
                            onChange={handleChange}
                            isSearchable={props.configProp.isSearchable}
                            isDisabled={!props.privilegesMap.isEditable}
                            isMulti={true}
                            mandatory={props.configProp.isMandatory && props.mandatoryIconAccessibilityRegex}
                            id={props.configProp.configObjectId}
                        />
                    </StyledDivSelectOption>
                    <StyledDivAddButton>
                        <button
                            style={{ "marginTop": "12px", "marginLeft": "10px", "color": "white", "backgroundColor": (!props.buttonPrivilegesMap.isEditable) ? "#cecece" : (themeContext.styleName == "primary") ? "#004269" : "#4caf50", "border": "none", "borderRadius": "50%", "height": "41.5px", "width": "41.5px" }}
                            className={props.configProp.buttons[0].icon}
                            id={props.configProp.buttons[0].configObjectId}
                            name={extractLabel(props.configProp.buttons[0].label, props.intl.locale)}
                            onClick={(e) => OpenModal(e)}
                            disabled={!props.buttonPrivilegesMap.isEditable}
                        />
                    </StyledDivAddButton>
                </StyleDiv>
            )
        }
        else {
            return (
                <React.Fragment>
                    <SelectOption
                        ioTheme={themeContext.ioTheme}
                        ioMode={themeContext.ioMode}
                        value={valueAsAnObject}
                        styleName={themeContext.styleName}
                        label={extractLabel(props.configProp.label, props.intl.locale)}
                        key={props.configProp.configObjectId}
                        options={parsedOption}
                        displayType={themeContext.componentType}
                        errorMessage={parsedError}
                        hasError={errorData ? true : false}
                        onChange={handleChange}
                        isSearchable={props.configProp.isSearchable}
                        isDisabled={!props.privilegesMap.isEditable}
                        isMulti={true}
                        mandatory={props.configProp.isMandatory && props.mandatoryIconAccessibilityRegex}
                        id={props.configProp.configObjectId}
                    />
                </React.Fragment>
            )
        }
    } catch {
        const error = ['Error occurred in MultiSelect']
        return <FormErrorContainers ioTheme={themeContext.ioTheme}
            ioMode={themeContext.ioMode}
            styleName={'danger'}
            lists={error} />
    }
}
 
export default React.memo(injectIntl(MultiSelectCore));