import { PublicClientApplication, AccountInfo } from '@azure/msal-browser';

export const refreshMicrosoftAdToken = async (config: any) => {
  try {
    const microsoftADConfig = config.provider[config.provider.type];

    if (
      !microsoftADConfig ||
      !microsoftADConfig.auth ||
      !microsoftADConfig.auth.clientId
    ) {
      throw new Error('Invalid Microsoft AD configuration');
    }

    const pca: any = new PublicClientApplication(microsoftADConfig);
    await pca.initialize();

    const accounts: AccountInfo[] = await pca.controller.getAllAccounts();
    if (accounts.length === 0) {
      throw new Error('No accounts found');
    }

    const account = accounts[0];

    let scopes = microsoftADConfig.customScope
      ? [...microsoftADConfig.customScope]
      : [];
    scopes.push('openid');
    scopes.push('profile');

    const resp = await pca.acquireTokenSilent({
      scopes: scopes,
      account,
      forceRefresh: true,
    });

    if (config.storage.type === 'session') {
      sessionStorage.setItem('REFRESH_AUTH_TOKEN', resp.accessToken);
    } else if (config.storage.type === 'window') {
      localStorage.setItem('REFRESH_AUTH_TOKEN', resp.accessToken);
    } else {
      throw new Error('Invalid storage type specified');
    }
  } catch (e) {
    console.error('Error in refreshMicrosoftAdToken:', e);
  }
};
