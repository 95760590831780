import * as React from 'react';
import { useState } from 'react';
import {
  BuilderContainer,
  FloatingLabelInputWrapper,
  LabelContainer,
  Props,
} from './FloatingLabelInputStyle';
import InlineMsgComponent from './InlineMessageComponent';

export const FloatingLabelInput = (props: Props) => {
  const [fieldActivated, setFieldActivated] = useState(false);

  const callingOnChange = (event: any, props: any) => {
    props.onChange(event.target.value);
    if (props.inlineError) {
      props.setInLineError(false);
    }
    if (props.handleChange) {
      props.handleChange(event.target.value);
    }
  };

  const activateField = () => {
    setFieldActivated(true);
  };

  const disableFocus = (props: any) => {
    if (props.value === '') {
      setFieldActivated(false);
    }
  };

  return (
    <BuilderContainer>
      <LabelContainer fieldActivated={true} inlineError={props.inlineError}>
        {props.dispalyLabel}
      </LabelContainer>
      <FloatingLabelInputWrapper
        id={props.id}
        type={props.type}
        onChange={(e: any) => callingOnChange(e, props)}
        onFocus={activateField}
        onBlur={(e: any) => disableFocus(props)}
        fieldActivated={true}
        inlineError={props.inlineError}
        autoComplete="new-password"
      />
      {props.inlineError ? (
        <InlineMsgComponent inlineMsg={props.inlineMsg} />
      ) : (
        ''
      )}
    </BuilderContainer>
  );
};
