import gql from 'graphql-tag';

export const CompositeEntity = gql`
  query CompositeEntity($id: ID!) {
    CompositeEntity(id: $id) {
      configObjectId
      isNodeTreeRequired
      rootCompositeEntityNode {
        configObjectId
        childRelations {
          relationType
          parentItemId
          childItemId
        }
      }
      processDefinitionMapping {
        id
        processDefinitionId
        status
        roleId
        objectId
        operationType
      }
    }
  }
`;
