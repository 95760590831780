import gql from 'graphql-tag';

export const ConfigItemByType = gql`
  query ConfigItemByType($type: String!) {
    ConfigItemByType(type: $type) {
      configObjectId
      name
      configObjectType
    }
  }
`;
