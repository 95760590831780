import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { FormErrorContainers } from 'info-ui-library';
import {
  getSimpleOrComplexForm,
  processNodeEditibility,
  processFormPrivilegeAndAccessibilityAndEditibilty,
} from '../CompositeEntityCommonUtils';
import { IFormGenerator } from '../interfaces/index';
import { NOPRIVILEGE } from '../../constants/appeng.enum';
import { UserContext } from '../../user-context/UserContext';
import { IState } from '../../store/index';
import { ThemeContext } from '../../theme/ThemeContext';

const Form: React.FC<IFormGenerator> = (props: IFormGenerator) => {
  const userContext = useContext(UserContext);
  const themeContext = useContext(ThemeContext);

  const formDataObject = useSelector(
    (state: any) => state.appState.cardsData[props.referralId]
  );
  try {
    let formData = {};
    if (formDataObject) {
      formData = formDataObject.data[0];
    }

    let nodeEditable = props.nodeEditabilityRegex
      ? processNodeEditibility(
          props.nodeExpressionAvailble,
          props.nodeEditabilityRegex,
          formData
        )
      : props.portalFormPrivilege && props.portalFormPrivilege === 'VIEW'
        ? false
        : true;

    const { formPrivilege, isFormAccessible, isFormEditable } =
      processFormPrivilegeAndAccessibilityAndEditibilty(
        nodeEditable,
        userContext,
        props,
        formData
      );

    let displayComponent;
    if (formPrivilege !== NOPRIVILEGE && isFormAccessible) {
      displayComponent = getSimpleOrComplexForm(
        props,
        formPrivilege,
        isFormAccessible,
        isFormEditable,
        nodeEditable,
        props.routerParameter,
        props.resetForm,
        props.portalForm
      );
    }
    return <React.Fragment>{displayComponent}</React.Fragment>;
  } catch {
    const error = ['Error occurred in Form Generator'];
    return (
      <FormErrorContainers
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};

function isPropsAreEqual(prevProps: any, nextProps: any) {
  return (
    JSON.stringify(prevProps.routerParameter[prevProps.referralId]) ===
    JSON.stringify(nextProps.routerParameter[nextProps.referralId])
  );
}

export default React.memo(Form, isPropsAreEqual);
