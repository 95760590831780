import { all, call, put, select } from 'redux-saga/effects';
import * as base from '../../base';
import cloneDeep from 'lodash/cloneDeep';
import {
  setStore,
  setToggleMessages,
  setChartData,
} from '../../../actions/index';
import { UpsertMessage, CardType } from '../../../constants/appeng.enum';
import CardData from '../../../models/carddata.model';
import { v4 as uuid } from 'uuid';
const getCard = (state: any) => state.appState.cardsData;

export function* executeActionFlowPreprocessor(
  actionFlowId: any,
  formData: any,
  cdp: any,
  referralId: string
): Generator<any, any, any> {
  const response = yield call(base.executeActionFlow, actionFlowId, {
    data: formData,
  });
  let existingCardsData = yield select(getCard);
  const cardsData = cloneDeep(existingCardsData);
  const currentCardData = cardsData[referralId];
  if (
    response.errors ||
    (typeof response === 'string' && response.toLowerCase().includes('error'))
  ) {
    if (typeof response === 'string') {
      const messages: string[] = [];
      messages.push(response);
      yield put(setToggleMessages(messages));
    } else {
      const messages: string[] = [];
      if (response.errors.length > 0 && response.errors[0].message !== null) {
        messages.push(UpsertMessage.VALIDATION_FAILED);
      }
      const appError: any = {
        code: response.code,
        errorData: {
          formFieldValidation: {},
          formValidation: {},
        },
      };
      response.errors.map((err: any) => {
        const lastElement =
          err.location.split('=>')[err.location.split('=>').length - 1];
        if (isNaN(Number(lastElement))) {
          appError.errorData.formFieldValidation[err.location] = err.message;
        } else {
          appError.errorData.formValidation[err.location] = err.message;
        }
        return null;
      });
      currentCardData.errorData = appError;
      currentCardData.buttonClicked = '';
      yield all([
        put(setChartData(currentCardData, referralId)),
        put(setToggleMessages(messages)),
      ]);
    }
    return formData;
  } else if (response && response.keyToRemove) {
    let keyList = response.keyToRemove.split(',');
    let updatecardData = false;
    for (let key of keyList) {
      if (key && currentCardData?.errorData?.errorData?.formValidation) {
        for (const cardKey in currentCardData.errorData.errorData
          .formValidation) {
          if (
            key == currentCardData.errorData.errorData.formValidation[cardKey]
          ) {
            updatecardData = true;
            delete currentCardData.errorData.errorData.formValidation[cardKey];
          }
        }
      }
      if (key && currentCardData?.errorData?.errorData?.formFieldValidation) {
        for (const cardKey in currentCardData.errorData.errorData
          .formFieldValidation) {
          if (key == cardKey) {
            updatecardData = true;
            delete currentCardData.errorData.errorData.formFieldValidation[
              cardKey
            ];
          }
        }
      }
    }
    if (updatecardData) {
      currentCardData.buttonClicked = '';
      yield put(setChartData(currentCardData, referralId));
    }

    return response.formData;
  } else {
    yield call(processingForRefresheOtherCards, response, cdp.jsCode);
    if (response.message) {
      const messages: string[] = [];
      typeof response.message === 'string'
        ? messages.push(response.message)
        : messages.push(response.message.errors);
      yield put(setToggleMessages(messages));
    }
    return response.formData;
  }
}

export function* processingForRefresheOtherCards(
  response: any,
  refreshDetails: any,
  action?: any
): Generator<any, any, any> {
  let refreshCardSelectOptionDetails = refreshDetails;
  if (response.modifyOtherCard) {
    let existingCardsData = yield select(getCard);
    let cardsData = cloneDeep(existingCardsData);
    for (const key of Object.keys(response.modifyOtherCard)) {
      if (existingCardsData[key]) {
        let cardData = cardsData[key];
        let formFeild_Occurance_list: any = [];
        for (const details of response.modifyOtherCard[key]) {
          if (details.type === 'RefreshGrid') {
            cardData.referenceData.AE_RELOAD = {
              ID: uuid(),
              TS: new Date().getTime(),
              STATUS: 'R',
            };
            const parameter = details['parameter'];
            const parameterKey = details['parameterKey'];
            const parameterKeyValue = details['parameterKeyValue'];
            let indexRemove = 0;
            cardsData[key].data.map((dataObj: any, index: any) => {
              if (dataObj[parameterKey] === parameterKeyValue) {
                indexRemove = index;
              }
            });
            let arrayDatas = cardsData[key].data
              .slice(0, indexRemove)
              .concat(
                cardsData[key].data.slice(
                  indexRemove + 1,
                  cardsData[key].data.length
                )
              );
            cardsData[key].data = arrayDatas;
            if (
              indexRemove.toString() &&
              Object.entries(cardsData[key].datagridErrorData).length > 0
            ) {
              cardsData[key].datagridErrorData = {};
            }
            delete cardsData[action.referralId];
            delete cardsData[action.parentId];
          } else if (
            details.hasOwnProperty('parameter') &&
            details.hasOwnProperty('parameterKey') &&
            details.parameterKey === 'AE_RELOAD'
          ) {
            cardData.referenceData.AE_RELOAD = {
              ID: uuid(),
              TS: new Date().getTime(),
              STATUS: 'R',
            };
          } else if (
            details.hasOwnProperty('parameter') &&
            details.hasOwnProperty('parameterKey') &&
            details.type === 'PortalDataGrid' &&
            details.hasOwnProperty('parameterKeyValue')
          ) {
            const parameter = details['parameter'];
            const parameterKey = details['parameterKey'];
            const parameterKeyValue = details['parameterKeyValue'];
            if (response.mode === 'Delete') {
              let indexRemove = 0;
              cardsData[key].data.map((dataObj: any, index: any) => {
                if (dataObj[parameterKey] === parameterKeyValue) {
                  indexRemove = index;
                }
              });
              let arrayDatas = cardsData[key].data
                .slice(0, indexRemove)
                .concat(
                  cardsData[key].data.slice(
                    indexRemove + 1,
                    cardsData[key].data.length
                  )
                );
              cardsData[key].data = arrayDatas;
              if (
                indexRemove.toString() &&
                Object.entries(cardsData[key].datagridErrorData).length > 0
              ) {
                cardsData[key].datagridErrorData = {};
              }
              delete cardsData[action.referralId];
              delete cardsData[action.parentId];
            } else {
              let modifiedRecords: any = [];
              for (const rowData of cardData[parameter]) {
                if (rowData[parameterKey] === details['parameterKeyValue']) {
                  let object = {};
                  if (details && details['changedData']) {
                    for (const keydetails of Object.keys(
                      details['changedData']
                    )) {
                      if (rowData.hasOwnProperty(keydetails)) {
                        const value = details['changedData'][keydetails];
                        Object.assign(object, rowData, { [keydetails]: value });
                      }
                    }
                  }
                  modifiedRecords.push(object);
                } else {
                  modifiedRecords.push(rowData);
                }
              }
              cardData[parameter] = modifiedRecords;
            }
          } else if (
            details.hasOwnProperty('parameter') &&
            details.hasOwnProperty('parameterKey') &&
            details.hasOwnProperty('child')
          ) {
            const parameter = details['parameter'];
            const parameterKey = details['parameterKey'];
            const childID = details['child'];
            const childParameterKey = details['childParameterKey'];

            if (details.hasOwnProperty('persistOldData')) {
              for (const keydetails of Object.keys(details['changedData'])) {
                const oldValue =
                  cardData[parameter][parameterKey]['child'][childID][
                    childParameterKey
                  ];
                const newValue = details['changedData'][keydetails];
                let value = newValue;
                for (const column of details['persistOldData']) {
                  if (keydetails == column) {
                    if (oldValue && oldValue.length > 0) {
                      value = oldValue.concat(',', newValue);
                    }
                  }
                }
                Object.assign(
                  cardData[parameter][parameterKey]['child'][childID][
                    childParameterKey
                  ],
                  { [keydetails]: value }
                );
              }
            } else {
              Object.assign(
                cardData[parameter][parameterKey]['child'][childID][
                  childParameterKey
                ],
                details['changedData']
              );
            }
          } else if (
            details.hasOwnProperty('parameter') &&
            details.hasOwnProperty('parameterKey')
          ) {
            const parameter = details['parameter'];
            const parameterKey = details['parameterKey'];

            if (details.hasOwnProperty('persistOldData')) {
              for (const keydetails of Object.keys(details['changedData'])) {
                const oldValue = cardData[parameter][parameterKey][keydetails];
                const newValue = details['changedData'][keydetails];
                let value = newValue;
                for (const column of details['persistOldData']) {
                  if (keydetails == column) {
                    if (oldValue && oldValue.length > 0) {
                      value = oldValue.concat(',', newValue);
                    }
                  }
                }
                Object.assign(cardData[parameter][parameterKey], {
                  [keydetails]: value,
                });
              }
            } else {
              Object.assign(
                cardData[parameter][parameterKey],
                details['changedData']
              );
            }
          } else if (details.hasOwnProperty('parameter')) {
            let cardData = cardsData[key];
            const parameter = details['parameter'];
            Object.assign(cardData[parameter], details['changedData']);
          } else if (details.hasOwnProperty('refreshDetails')) {
            refreshCardSelectOptionDetails = details['refreshDetails'];
            refreshDetails = details['refreshDetails'];
          }

          if (details.hasOwnProperty('keyToRemove')) {
            let keyList = details.keyToRemove.split(',');
            for (let key of keyList) {
              if (key && cardData?.errorData?.errorData?.formValidation) {
                for (const cardKey in cardData.errorData.errorData
                  .formValidation) {
                  if (
                    key == cardData.errorData.errorData.formValidation[cardKey]
                  ) {
                    delete cardData.errorData.errorData.formValidation[cardKey];
                  }
                }
              }
              if (key && cardData?.errorData?.errorData?.formFieldValidation) {
                for (const cardKey in cardData.errorData.errorData
                  .formFieldValidation) {
                  if (key == cardKey) {
                    delete cardData.errorData.errorData.formFieldValidation[
                      cardKey
                    ];
                  }
                }
              }
            }
          }

          if (refreshDetails) {
            let cardRefreshDetails =
              refreshCardSelectOptionDetails.split('sEpArAtOr');
            const cardIdExtraction = key.split('_');
            cardRefreshDetails.map((dataMap: any) => {
              const dataMapChange = dataMap.split('_');
              if (
                dataMapChange[0] ===
                cardIdExtraction[cardIdExtraction.length - 1]
              ) {
                let dataChange = dataMap.replace(dataMapChange[0] + '_', '');
                if (!formFeild_Occurance_list.includes(dataChange)) {
                  formFeild_Occurance_list.push(dataChange);
                }
              }
            });
          }
        }

        if (formFeild_Occurance_list.length > 0) {
          let updateOptions = { ...cardData.options };
          yield call(
            getOptionsDataInBulk,
            formFeild_Occurance_list,
            cardData.data[0],
            updateOptions
          );
          cardData.options = updateOptions;
        }
      } else if (!existingCardsData[key]) {
        for (const details of response.modifyOtherCard[key]) {
          if (
            details['type'] === 'SubPortal' &&
            details.eventType === 'uploadGridData'
          ) {
            let referenceData: any = {};
            referenceData[details['parameterKey']] =
              details[details['parameterKey']];
            referenceData['AE_RELOAD'] = {
              ID: uuid(),
              TS: new Date().getTime(),
              STATUS: 'R',
            };
            let cardData = new CardData(
              key,
              details['type'],
              [],
              details['portalId'],
              details['parentId'],
              referenceData,
              {},
              {},
              {},
              false,
              false,
              {},
              '',
              '',
              '',
              '',
              ''
            );
            cardsData[key] = cardData;
          }
        }
      }
    }
    const messages: string[] = [];
    if (response.mode === 'Delete') {
      messages.push(
        response.message ? response.message : UpsertMessage.DELETESUCCESSFUL
      );
    }

    if (cardsData && Object.keys(cardsData).length) {
      const updateStore: any = {
        cardsData: cardsData,
        toggleMessages: messages,
      };
      yield put(setStore(updateStore));
    }
  }
}

function* getOptionsDataInBulk(
  FFListNeedOption: any,
  formData: any,
  updateOptions: any
): Generator<any, any, any> {
  try {
    yield all(
      FFListNeedOption.map((changeString: any) => {
        const changeStringList = changeString.split('_');
        const formId = changeStringList[0];
        const ffId = changeStringList[2];
        const occuranceNumber = changeStringList[1];
        const selectedData =
          formData.child &&
          formData.child[formId] &&
          formData.child[formId][occuranceNumber] !== formId
            ? formData.child[formId][occuranceNumber]
            : formData;
        return call(
          base.getOptionsDataInBulk,
          ffId,
          selectedData,
          occuranceNumber,
          formId,
          updateOptions
        );
      })
    );
  } catch (e) {
    console.log('getOptionsDataInBulk=====', e);
    const messages: string[] = [];
    messages.push(UpsertMessage.UNSUCCESSFUL);
    yield put(setToggleMessages(messages));
  }
}
