import InfoJWTToken from './jwt.token';

export default class UserSession {
  readonly idToken: InfoJWTToken;
  readonly accessToken: InfoJWTToken;
  readonly refreshToken?: InfoJWTToken;
  readonly localClockDiff: number;
  readonly language: string;

  constructor(
    idToken: InfoJWTToken,
    accessToken: InfoJWTToken,
    language: string,
    refreshToken?: InfoJWTToken
  ) {
    this.idToken = idToken;
    this.accessToken = accessToken;
    this.language = language;
    this.refreshToken = refreshToken;
    this.localClockDiff = this.calculateLocalClockDiff();
  }
  public isValid() {
    const date = new Date();
    const now = Math.floor(date.getTime() / 1000);
    const adjusted = now - this.localClockDiff;
    return this.accessToken && adjusted < this.accessToken.expiration;
  }

  private calculateLocalClockDiff() {
    return 0;
  }
}
