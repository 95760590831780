import styled from 'styled-components';

export const PopUpText: any = styled.span`
  width: 100%;
  border: 0.5px solid;
  background: #fff;
  border-left-width: 5px;
  color: #686c73;
  text-align: center;
  border-radius: 3px;
  padding: 8px 0;
  position: absolute;
  font-size: 14px;
  z-index: 2;
  bottom: 125%;
  border-color: ${(p: any) => (p.pass ? '#0cce0ca6' : '#fd7366a1')};
  border-left-color: ${(p: any) => (p.pass ? '#4bc84b' : '#ff6759')};
`;

export const MessageContainer: any = styled.div`
    position: relative;
    display: block;
    width: 98%;
    webkit-user-select: none;
    moz-user-select: none 
    webkit-animation: fadeIn 1s;
    animation: fadeIn 1s;
`;
