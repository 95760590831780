import { StarRating } from 'info-ui-library';
import React, { useContext } from 'react';
import { ThemeContext } from '../../../theme/ThemeContext';
const StarRatingComponent = ({
  cell,
  row,
  column: { id },
  updateMyData,
}: any) => {
  const themeContext = useContext(ThemeContext);
  const [value, setValue] = React.useState(cell.value);
  const [previousValue, setPreviousValue] = React.useState(cell.value);

  React.useEffect(() => {
    setValue(cell.value);
    setPreviousValue(cell.value);
  }, [cell.value]);

  const onChange = (e: any) => {
    setPreviousValue(value);
    setValue(e);
    if (e !== previousValue && updateMyData) {
      updateMyData(row.index, id, e, row.id);
    }
  };

  return (
    <StarRating
      id={id}
      ioTheme={themeContext.ioTheme}
      ioMode={themeContext.ioMode}
      styleName={themeContext.styleName}
      value={value}
      onStarClick={onChange}
      isGridComponent={true}
    />
  );
};

export default React.memo(StarRatingComponent);
