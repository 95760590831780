import { useState, useContext } from 'react';
import * as React from 'react';
import { InfoAuth } from '../auth';
import { FloatingLabelInput } from './FloatingLabelInput';
import { FormattedMessage, injectIntl } from 'react-intl';
import CommonHeader from './CommonHeader';
import ReactPasswordStrength from './ReactPasswordStrength';
import {
  ChangePasswordStartMsgOuterContainer,
  ChangePasswordMsgContainer,
  ChangePassTitleSpanContainer,
  ChangePasswordContainer,
  CustomButton,
  Form,
  ParentWrapperContainer,
  WrapperContainer,
} from './ChangePasswordStyle';
import { LanguageContext } from './App';
import ErrorMsgComponent from './ErrorMessageComponent';

interface IChangePassword {
  userName: string;
  locale: any;
  setMessages: any;
  message: any;
  intl: any;
}
import { useNavigate } from 'react-router-dom';
const ChangePassword: React.FC<IChangePassword> = (props: IChangePassword) => {
  const navigate = useNavigate();
  const context = useContext(LanguageContext);
  const [preferred_username, setPreferredUsername] = useState(props.userName);
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [inlineErrorOldPassword, setInlineOldPassword] = useState(false);
  const [inlineErrorNewPassword, setInlineErrorNewPassword] = useState(false);
  const [inlineErrorConfirmPassword, setInlineErrorConfirmPassword] =
    useState(false);
  const [pass, setPass] = useState(false);
  const [showMessage, setshowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const updatePassword = async () => {
    if (oldPassword === '') {
      setInlineOldPassword(true);
      setPass(false);
      setshowMessage(false);
      setMessage('');
      setLoading(false);
    }
    if (newPassword === '') {
      setInlineErrorNewPassword(true);
      setPass(false);
      setshowMessage(false);
      setMessage('');
      setLoading(false);
    }

    if (confirmPassword === '') {
      setInlineErrorConfirmPassword(true);
      setPass(false);
      setshowMessage(false);
      setMessage('');
      setLoading(false);
    }

    if (oldPassword == newPassword) {
      setPass(false);
      setshowMessage(true);
      setMessage('Old Password and New Password Should not be same');
      setLoading(false);
    }
    if (
      oldPassword !== '' &&
      newPassword !== '' &&
      oldPassword !== newPassword
    ) {
      try {
        setLoading(true);
        await InfoAuth.changePassword(
          preferred_username,
          oldPassword,
          newPassword
        );
        navigate('/resetPassword', { state: { email: preferred_username } });
      } catch (err) {
        setLoading(false);
        if (err instanceof Error && err.message != null) {
          setPass(false);
          setshowMessage(true);
          setMessage(err.message);
        }
      }
    }
  };

  return (
    <div>
      <CommonHeader setLanguage={context.setLanguage} {...props} />
      <ChangePasswordStartMsgOuterContainer>
        <ChangePasswordMsgContainer>
          <FormattedMessage id="changePassword.changePasswordStart" />
        </ChangePasswordMsgContainer>
      </ChangePasswordStartMsgOuterContainer>
      <ParentWrapperContainer>
        <ChangePasswordContainer>
          <WrapperContainer>
            <Form>
              <ChangePassTitleSpanContainer showMessage={showMessage}>
                <strong>
                  <FormattedMessage id="changePassword.title" />
                </strong>
              </ChangePassTitleSpanContainer>
              <ErrorMsgComponent
                pass={pass}
                showMessage={showMessage}
                message={message}
              />

              <FloatingLabelInput
                id="oldPassword"
                dispalyLabel={
                  <FormattedMessage id="changePassword.oldPassword" />
                }
                onChange={setOldPassword}
                value={oldPassword}
                inlineError={inlineErrorOldPassword}
                setInLineError={setInlineOldPassword}
                type="password"
                inlineMsg={
                  <FormattedMessage id="changePassword.inlineMsgOldPwd" />
                }
              />
              <ReactPasswordStrength
                locale={props.locale}
                intl={props.intl}
                id="newPassword"
                dispalyLabel={
                  <FormattedMessage id="changePassword.newPassword" />
                }
                onChange={setNewPassword}
                value={newPassword}
                inlineError={inlineErrorNewPassword}
                setInLineError={setInlineErrorNewPassword}
                type="password"
                inlineMsg={
                  <FormattedMessage id="changePassword.inlineMsgNewPwd" />
                }
              />
              <CustomButton
                size="lg"
                loading={loading}
                onClick={updatePassword}
              >
                {' '}
                <FormattedMessage id="changePassword.btnChangePassword" />{' '}
              </CustomButton>
            </Form>
          </WrapperContainer>
        </ChangePasswordContainer>
      </ParentWrapperContainer>
    </div>
  );
};

export default injectIntl(ChangePassword);
