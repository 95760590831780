export enum StorageEnum {
  COOKIE = 'cookie',
  MEMORY = 'memory',
  WINDOW = 'window',
  SESSION = 'session',
}

export const MUST_HAVE_REFERENCE_DATA = ['AE_RELOAD_ID'];

export const parseStorageEnum = (storage: string) => {
  switch (storage) {
    case StorageEnum.COOKIE:
      return StorageEnum.COOKIE;
    case StorageEnum.MEMORY:
      return StorageEnum.MEMORY;
    case StorageEnum.WINDOW:
      return StorageEnum.WINDOW;
    case StorageEnum.SESSION:
      return StorageEnum.SESSION;
    default:
      throw new Error('Invalid or Unsupported Storage type ' + storage);
  }
};

export enum LeftMenuIndicator {
  LEFTMENU = 'LeftMenu',
  SIDENODETREE = 'SideNodeTree',
}

export enum GridType {
  PARENT = 'Parent',
  CHILD = 'Child',
}

export enum NodeType {
  PARENTNODE = 'ParentNode',
  CHILDNODE = 'ChildNode',
  DATANODE = 'DataNode',
}

export enum CardType {
  TABGROUP = 'TabGroup',
  SUBPORTAL = 'SubPortal',
  DATAGRID = 'PortalDataGrid',
  FORM = 'PortalForm',
  WIDGET = 'Widget',
  FILTERFORM = 'FilterForm',
  MYTASK = 'MyTask',
  SEND_FEEDBACK = 'SendFeedback',
  PROCESSINSTANCE = 'ProcessInstance',
  PORTAL = 'Portal',
  PORTALFORM = 'PortalForm',
  SIDENODENAVIGATION = 'SideNodeNavigation',
  SIDENODETREE = 'SideNodeTree',
  HYBRID_ENTITY = 'HybridEntity',
  BUTTON_GROUP = 'ButtonGroup',
  FM_SORTABLE_TREE = 'FeatureManagementSortableTree',
}

export enum ChartType {
  CONTACTS = 'Contacts',
  CARD_WITH_CUSTOM_LIST = 'CardWithCustomList',
  BREAD_CRUMB = 'BreadCrumb',
  INLINEFORM = 'InlineForm',
  BARCHART = 'BarChart',
  COUNTERCHART = 'CounterChart',
  AREACHART = 'AreaChart',
  LINECHART = 'LineChart',
  PIECHART = 'PieChart',
  DOUGHNUTCHART = 'DoughnutChart',
  STATIC = 'Static',
  DATE_FILTER_CHART = 'DateFilterChart',
  SELECTABLE_CARDS = 'SelectableCard',
  BT_SELECTABLE_CARDS = 'BTSelectableCard',
  CHARTLIST_WITH_FILTER = 'ChartListWithFilter',
  STATUS_CHART = 'StatusChart',
  SINGLE_ROW_COL_CARD = 'SingleRowColCard',
  FORMFIELD = 'FormField',
  MATRIX = 'Matrix',
  FORM = 'Form',
}

export enum QueryType {
  REPORT_QUERY = 'ReportFormQuery',
  MENUGROUP_QUERY = 'MenuQuery',
  COMPOSITE_ENTITY_QUERY = 'CEQuery',
  COMPOSITE_ENTITY_NODE_QUERY = 'NodeQuery',
  CHILD_NODE_IN_TREE_QUERY = 'ChildNodeInTreeQuery',
  ROOT_NODE_QUERY = 'RootNodeQuery',
  DATA_GRID_QUERY = 'DataGridQuery',
  PORTAL_QUERY = 'PortalQuery',
  PORTAL_CARD_QUERY = 'PortalCardQuery',
  SUBPORTAL_QUERY = 'SubPortalQuery',
  BUTTON_GROUP = 'ButtonGroupQuery',
  FORMFIELD = 'FormField',
  LOGICAL_ENTITY = 'LogicalEntity',
  FORM = 'Form',
}

export enum ScreenSize {
  LARGE = 'lg',
  MEDIUM = 'md',
  SMALL = 'sm',
  EXTRA_SMALL = 'xs',
  X_EXTRA_SMALL = 'xxs',
}

export const PATHNAME = 'pathName';
export const ACTION = 'action';
export const DELETE = 'Delete';
export const LEFT_ARROW_ICON: string = 'fa fa-arrow-left';
export const RIGHT_ARROW_ICON: string = 'fa fa-arrow-right';
export const SWITCH_ICON: string = 'fa fa-bars';
export const EDIT: string = 'EDIT';
export const VIEW: string = 'VIEW';
export const NOPRIVILEGE: string = 'NOPRIVILEGE';
export const PrivilegeEnum: any = {
  EDIT: 2,
  VIEW: 1,
  NOPRIVILEGE: 0,
};

export enum UpsertMessage {
  SAVESUCCESSFUL = 'Saved Successfully',
  UPDATESUCCESSFUL = 'Updated Successfully',
  UNSUCCESSFUL = 'Something Went Wrong',
  VALIDATION_FAILED = 'Please correct the validation errors',
  ATTACHMENT_TYPE_REQUIRED = 'Attachment type is required',
  FILE_SIZE_EXCEEDED = 'Attachment cannot be greater than 5MB',
  EXE_FILES_NOT_ALLOWED = "Cannot upload '.exe' file",
  FINANCIAL_YEAR_SWITCH = 'Financial Year Switched Successfully',
  APPLICATION_SWITCH = 'Application Switched Successfully',
  LANGUAGE_SWITCH = 'Language Switched Successfully',
  DELETESUCCESSFUL = 'Deleted Successfully',
  JAVASCRIPT_PREPROCESSOR = ' Script Execution Failed',
  DEPLOYSUCCESSFUL = 'Deployed Successfully',
  DEPLOYUNSUCCESSFUL = 'Deploy is not done properly Please make sure everything is correct',
}

export const RECORD_INDEX: string = '946ea30c-caf6-11e9-8548-0ee72c6ddce6';
export const FINANCIAL_YEAR_OPTIONS_KEY: string = 'financial_year';
export const USER_SESSION_LOCAL_STORAGE_KEY: string = 'userDetails';
export const USER_SESSION_JWT: string = 'userSessionJWT';
export const SECRET_KEY: any = 'defaultsecret';
export const LANGUAGE_OPTIONS_KEY: string = 'languages';
export const PROJECTS: string = 'projects';
export const APP_LOGGED_IN_ROLE_ID: string = 'APP_LOGGED_IN_ROLE_ID';
export const APP_LOGGED_IN_YEAR: string = 'APP_LOGGED_IN_YEAR';
export const ROUTE_STATE_LOCAL_STORAGE_KEY: string = 'routeState';
export const SELECTED_THEME_LOCAL_STORAGE_KEY: string = 'selectedTheme';
export const AUTH_TOKEN: string = 'AUTH_TOKEN';

export const MODE = 'Edit';
export const NODE_TYPE = 'ParentNode';
export const USER_CEID = 'fb0f5138-803e-496a-8995-c440a886f431';
export const USER_NODEID = 'ce677d27-24b1-4040-aa29-93f158f9f609';
export const USER_PARENT_FORMID = 'd895dd58-aeb3-48ab-b5de-b5fd3bac9f5d';
export const USER_PRIMARY_DBCODE = 'AE_USER_PROFILE_UUID';
export const USER_FORM_TYPE = 'Edit';
export const USER_MODAL_REQUIRED = false;
export const DUMMY_PORTAL_ID = 'dummyPortalId';
export const INSERT_MODE = 'Insert';
export const EDIT_MODE = 'Edit';
export const BUTTON_PANNEL_TOP_POSITION = 'TOP';
export const BUTTON_PANNEL_BOTTOM_POSITION = 'BOTTOM';
export const ADD_FORM = 'AddForm';
export const UPDATE_BUTTON_CLASS = 'updateFormDataButton';
export const DELETE_BUTTON_CLASS = 'deleteFormCompositeEntity';
export const SAVE_BUTTON_CLASS = 'btn singleEntityModalSave';
export const CANCEL_BUTTON_CLASS = 'cancelCompositeEntity';
export const CUSTOM_BUTTON_CLASS = 'formActionDataButton';
export const GRID_ADD_BUTTON_CLASS = 'single-entity-insert';
export const DOWNLOAD_REPORT_BUTTON_CLASS = 'download-report';
export const WORKFLOW_BUTTON_CLASS = 'workflowFormButton';
export const DELETE_FORM_DATA_CLASS = 'deleteFormData';
export const OPEN_PORTAL_IN_MODAL_CLASS = 'openPortalInModal';

export enum FormNavigationType {
  PORTAL_FORM = 'Portal Form',
  SIDE_NODE = 'Side Node',
}
export enum ActionType {
  MENU_CLICK = 'Menu Click',
  BUTTON_CLICK = 'Button Click',
  ON_BLUR = 'On Blur',
}

export enum ErrorMessage {
  ACTION_FLOW_NOT_MAPPED = 'Action Flow is not mapped',
}
export const ROOT_NODE_ID = '5c134a06-465e-11ea-9337-0ebe64e900df';
export const ACTION_FLOW_CLASS = 'action-flow';

export enum DataType {
  NUMBER = 'NUMBER',
  EMAIL = 'EMAIL',
  ALPHABET = 'ALPHABET',
  DECIMAL = 'DECIMAL',
}

export const NUMBER_REGEX = /^[0-9+-]\d*(\d+)?$/;
export const EMAIL_REGEX = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
export const DECIMAL_REGEX = /^(null|\d+(\.\d+)?)$/;
export const ALPHABETS_ONLY_REGEX = /[A-Z]/gi;
