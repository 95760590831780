import {
  put,
  all,
  fork,
  takeLatest,
  takeEvery,
  call,
  select,
} from 'redux-saga/effects';
import * as base from '../base';
import { setToggleMessages, UPSERT_FORM_RECORD } from '../../actions';
import { UpsertMessage } from '../../constants/appeng.enum';
import { processAppengMetaOperation } from '../utilitySags';

const getToggleMessages = (state: any) => state.appState.toggleMessages;
export default function* upsertFormRecord() {
  yield all([fork(watchUpsertFormRecord)]);
}

function* watchUpsertFormRecord() {
  yield takeEvery(UPSERT_FORM_RECORD, upsertFormRecordSaga);
}

function* upsertFormRecordSaga(action: any): Generator<any, any, any> {
  try {
    const toggleMessages = yield select(getToggleMessages);
    if (toggleMessages.length) yield put(setToggleMessages([]));
    let { data } = yield call(base.getConfigData, 'FormQuery', action.formId);
    if (data.Form.logicalEntity) {
      yield call(processAppengMetaOperation, action, data);
    } else {
      const messages: string[] = [];
      messages.push('Please include your custom api');
      yield put(setToggleMessages(messages));
    }
  } catch (e: any) {
    console.log('e ::::::::::::::::::::::::', e);
    const messages: string[] = [];
    messages.push(UpsertMessage.UNSUCCESSFUL);
    yield put(setToggleMessages(messages));
  }
}
