export default {
  data: {
    CompositeEntityNode: {
      graphTemplate: null,
      displayNodeName: 'Dummy Node',
      order: null,
      configObjectId: '5c134a06-465e-11ea-9337-0ebe64e900df',
      addToParentDisplay: false,
      addToParentGrid: false,
      addToParentEditForm: false,
      addToParentInsertForm: false,
      accessibilityRegex: null,
      editabilityRegex: null,
      expressionAvailable: false,
      name: 'Dummy Node',
      configObjectType: 'CompositeEntityNode',
      itemDescription: null,
      combinedNodes: null,
      projectId: 0,
      entity: {
        name: 'Dummy Entity',
        configObjectId: '756a50ed-465e-11ea-9337-0ebe64e900df',
        configObjectType: 'LogicalEntity',
        projectId: 0,
        dbTypeName: 'Dummy Entity',
        logicalColumns: [],
        __typename: 'LogicalEntity',
      },
      insertForm: {},
      editForm: null,
      dataGrid: {},
      __typename: 'CompositeEntityNode',
    },
  },
};
