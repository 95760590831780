export default {
  header: {
    logo: 'ઇન્ફો બ ઇ ',
    login: 'લોગીન ',
    signup: 'સાઈન  અપ ',
  },
  signin: {
    signin: 'લોગીન',
    signup: 'સાઈન  અપ ',
    forgotpassword: 'ફોરગોટ પાસવર્ડ ',
    login: 'લોગીન',
    email: 'ઈમેલ *',
    password: 'પાસવર્ડ*',
    inlineMsgEmail: 'ઈમેલ આવશ્યક છે',
    inlineMsgPwd: 'પાસવર્ડ ફરજિયાત છે',
  },
  signup: {
    headSignup: 'સાઈન  અપ ',
    email: 'ઈમેલ*',
    inlineMsgEmail: 'ઈમેલ આવશ્યક છે',
    password: 'પાસવર્ડ*',
    inlineMsgPwd: 'પાસવર્ડ ફરજિયાત છે',
    pwdInfoMsg:
      'પાસવર્ડમાં ઓછામાં ઓછા ૮  અને મહત્તમ ૨૦ અક્ષરો હોવા આવશ્યક છે.' +
      'તેમાં ઓછામાં ઓછું એક અપરકેસ અને એક લોઅરકેસ અક્ષર હોવું આવશ્યક છે.' +
      'તેમાં ઓછામાં ઓછો એક અંક હોવો આવશ્યક છે. તેમાં ઓછામાં ઓછું એક વિશેષ પ્રતીક હોવું આવશ્યક છે.',
    buttonSignup: 'સાઈન  અપ',
    haveaccountCheckMessage: 'પહેલેથી ખાતું છે?',
    linkLogin: 'લોગીન',
    confirmationMsgStart: 'તપાસો ઈમેલ ',
    confirmationMsgEnd: 'ચકાસણી કોડ માટે.',
    headConfirmation: 'કોમ્પલેટ સાઈન  અપ ',
    floatingConfirmationCode: 'વેરિફિકેશન કોડે *',
    buttonConfirmSignup: 'સબમિટ ',
    errMsgEmail: 'અમાન્ય ઈમેલ',
    resend: 'રેસેન્ડ  વેરિફિકેશન કોડે',
  },
  resetPwd: {
    forgotPassword: 'ફોરગોટ પાસવર્ડ ',
    email: 'ઈમેલ *',
    pwdInfoMsg:
      'પાસવર્ડમાં ઓછામાં ઓછા ૮  અને મહત્તમ ૨૦ અક્ષરો હોવા આવશ્યક છે.' +
      'તેમાં ઓછામાં ઓછું એક અપરકેસ અને એક લોઅરકેસ અક્ષર હોવું આવશ્યક છે.' +
      'તેમાં ઓછામાં ઓછો એક અંક હોવો આવશ્યક છે. તેમાં ઓછામાં ઓછું એક વિશેષ પ્રતીક હોવું આવશ્યક છે.',
    sendResetPasscode: 'સેન્ડ  રીસેટ  પાસસકોડે ',
    backToLogin: 'લોગીન પર પાછા',
    resetpassword: 'રીસેટ પાસવર્ડ ',
    newPassword: 'ન્યૂ  પાસવર્ડ *',
    passcode: 'પાસસકોડે *',
    btnResetPassword: 'રીસેટ પાસવર્ડ',
    resend: 'રેસેન્ડ ',
    inlineMsgEmail: 'ઈમેલ આવશ્યક છે',
    newPwdIsRequired: 'નવો પાસવર્ડ આવશ્યક છે',
    inlineMsgAuth: 'ઑથ કોડ આવશ્યક છે',
    resetPwdMsgStart: 'ચેક ઈમેલ ',
    resetPwdMsgEnd: 'ફોર  પાસસકોડે .',
  },
  headerForSignIn: {
    title: 'ઇન્ફો બ ઇ ',
  },
  changePassword: {
    title: 'પાસવર્ડ બદલો',
    oldPassword: 'જુનો પાસવર્ડ*',
    inlineMsgOldPwd: 'જૂનો પાસવર્ડ આવશ્યક છે',
    newPassword: 'નવો પાસવર્ડ*',
    inlineMsgNewPwd: 'નવો પાસવર્ડ આવશ્યક છે',
    confirmPassword: 'પાસવર્ડની પુષ્ટિ કરો*',
    inlineMsgConfirm: 'પુષ્ટિ પાસવર્ડ જરૂરી છે',
    btnChangePassword: 'પાસવર્ડ બદલો ',
    passwordInfoMsg:
      'પાસવર્ડમાં ઓછામાં ઓછા ૮  અને મહત્તમ ૨૦ અક્ષરો હોવા આવશ્યક છે.' +
      'તેમાં ઓછામાં ઓછું એક અપરકેસ અને એક લોઅરકેસ અક્ષર હોવું આવશ્યક છે.' +
      'તેમાં ઓછામાં ઓછો એક અંક હોવો આવશ્યક છે. તેમાં ઓછામાં ઓછું એક વિશેષ પ્રતીક હોવું આવશ્યક છે.',
    changePasswordStart: 'પાસવર્ડ સમાપ્ત થઈ ગયો છે ',
  },
  tooltipMesg: {
    message1: 'પાસવર્ડમાં ઓછામાં ઓછા ૮  અને મહત્તમ ૨૦ અક્ષરો હોવા આવશ્યક છે.',
    message2: 'તેમાં ઓછામાં ઓછું એક અપરકેસ અક્ષર હોવું આવશ્યક છે.',
    message3: 'તેમાં ઓછામાં ઓછું એક લોઅરકેસ અક્ષર હોવું આવશ્યક છે.',
    message4: 'તેમાં ઓછામાં ઓછો એક અંક હોવો આવશ્યક છે.',
    message5: 'તેમાં ઓછામાં ઓછું એક વિશેષ પ્રતીક હોવું આવશ્યક છે.',
  },
};
