import awsexport from '../config/aws-exports';
import { AuthMessageEnum, ErrorCodeEnum } from '../constants';
import { JWTToken } from '../model';
import AuthError from '../model/auth.error';
import AuthUser from '../model/auth.user';
import { ISignUpParams, IUserInfo, UserInfo } from '../model/user.info';
import UserSession from '../model/user.session';
import { SignUpResult } from '../types';
import { CodeDeliveryInfo } from '../types/delivery';
import { CurrentUserOpts } from '../types/user.opts';
import AuthProvider from './auth.provider';

const parseCognitoUser = async (_cognitoUser: any, config: any) => {
  const { fetchUserAttributes } = await import('aws-amplify/auth');
  const userAttributes: any = await fetchUserAttributes();

  const attributesMap = config.provider.aws.userAttributeMap;

  const subUserAttrb = userAttributes['sub'];

  const usernameAttrb = userAttributes[attributesMap.preferred_username];

  if (subUserAttrb && usernameAttrb) {
    const userInfo: IUserInfo = {
      preferred_username: usernameAttrb,
      profile: 'ACTIVE',
      customAttributes: {},
    };

    Object.entries(userAttributes).forEach((key: any, value: any) => {
      if (key === 'sub') {
        return;
      }

      const keyMaps = Object.entries(attributesMap).filter(
        ([_standardKey, awsKey]) => key === awsKey
      );
      if (keyMaps.length !== 0) {
        keyMaps.forEach((keyMap) => {
          const [standardKey, _awsKey] = keyMap;
          userInfo[standardKey] = value;
        });
      } else if (userInfo.customAttributes !== undefined) {
        userInfo.customAttributes[key] = value;
      }
    });

    const authUser: AuthUser = new AuthUser(
      userInfo.preferred_username,
      new UserInfo(subUserAttrb, subUserAttrb, userInfo)
    );
    return authUser;
  }
  throw new Error('sub or userName attrb is missing');
};

const parseSignUpParams = (params: ISignUpParams, config: any) => {
  const attributesMap = config.provider.aws.userAttributeMap;

  const attributes: {
    [index: string]: string | boolean | number | undefined | object;
  } = {};

  Object.entries(params).forEach(([standardKey, value]) => {
    const attrbKey = attributesMap.hasOwnProperty(standardKey)
      ? attributesMap[standardKey]
      : standardKey;
    if (standardKey !== 'preferred_username' && standardKey !== 'password') {
      attributes[attrbKey] = value;
    }
  });

  return {
    username: String(params[attributesMap.preferred_username]),
    password: params.password,
    attributes,
  };
};

class AWSAuthProvider implements AuthProvider {
  readonly config: any;
  public constructor(config: any) {
    this.config = config;
  }

  public async currentAuthenticatedUser(
    _params?: CurrentUserOpts
  ): Promise<AuthUser | any> {
    const { getCurrentUser } = await import('aws-amplify/auth');
    try {
      const cognitoUser = await getCurrentUser();
      const user: AuthUser = await parseCognitoUser(cognitoUser, this.config);

      return user;
    } catch (e) {
      return undefined;
    }
  }

  public async signIn(
    username: string,
    password: string,
    _locale: string
  ): Promise<AuthUser | any> {
    const { signIn } = await import('aws-amplify/auth');
    const { signOut } = await import('aws-amplify/auth');
    try {
      await signOut();
    } catch (signOutError) {
      console.warn('Error signing out the current user:', signOutError);
    }
    try {
      const cognitoUser: any = await signIn({
        username,
        password,
      });
      if (cognitoUser === undefined) {
        throw new Error('Invalid Credentials');
      }
      const user: AuthUser = await parseCognitoUser(cognitoUser, this.config);
      return user;
    } catch (e) {
      switch ((e as { code?: string }).code) {
        case 'NotAuthorizedException':
          throw new AuthError(
            AuthMessageEnum.INVALID_CREDENTIAL,
            ErrorCodeEnum.INVALID_CREDENTIAL
          );
        case 'UserNotConfirmedException':
          throw new AuthError(
            AuthMessageEnum.PENDING_VERIFICATION,
            ErrorCodeEnum.PENDING_VERIFICATION
          );
        case ErrorCodeEnum.PENDING_CHANGE_PASSWORD:
          throw new AuthError(
            AuthMessageEnum.PENDING_VERIFICATION,
            ErrorCodeEnum.PENDING_CHANGE_PASSWORD
          );
        case ErrorCodeEnum.MISSING_PARAMETER:
          throw new AuthError(
            AuthMessageEnum.MISSING_PARAMETER,
            ErrorCodeEnum.PENDING_CHANGE_PASSWORD
          );
        case ErrorCodeEnum.LOCKED:
          throw new AuthError(AuthMessageEnum.LOCKED, ErrorCodeEnum.LOCKED);
        default:
          throw e;
      }
    }
  }

  public async signOut(): Promise<any> {
    const { signOut } = await import('aws-amplify/auth');
    const result = signOut();
    return result!;
  }

  public async signUp(params: ISignUpParams): Promise<SignUpResult> {
    const { signUp } = await import('aws-amplify/auth');
    const awsSignupParam = parseSignUpParams(params, this.config);
    let awsResult: any;
    try {
      awsResult = await signUp(awsSignupParam);
    } catch (e) {
      switch ((e as { code?: string }).code) {
        case 'UsernameExistsException':
          throw new AuthError(
            AuthMessageEnum.USER_ALREADY_EXISTS,
            ErrorCodeEnum.USER_ALREADY_EXISTS
          );
        case 'InvalidPasswordException':
          throw new AuthError(
            AuthMessageEnum.WEAK_PASSWORD,
            ErrorCodeEnum.WEAK_PASSWORD
          );
        case 'CodeDeliveryFailureException':
          throw new AuthError(
            AuthMessageEnum.INVALID_EMAIL_FORMAT,
            ErrorCodeEnum.INVALID_EMAIL_FORMAT
          );
        case 'InvalidParameterException':
          if (
            e instanceof Error &&
            e.message === 'Invalid email address format.'
          ) {
            throw new AuthError(
              AuthMessageEnum.INVALID_EMAIL_FORMAT,
              ErrorCodeEnum.INVALID_EMAIL_FORMAT
            );
          }
          if (
            e instanceof Error &&
            e.message === 'Invalid phone number format.'
          ) {
            throw new AuthError(
              AuthMessageEnum.INVALID_PHONE_NUMBER_FORMAT,
              ErrorCodeEnum.INVALID_PHONE_NUMBER_FORMAT
            );
          }
          if (e instanceof Error && e.message.includes("Value at 'password'")) {
            throw new AuthError(
              AuthMessageEnum.WEAK_PASSWORD,
              ErrorCodeEnum.WEAK_PASSWORD
            );
          }
          break;
        default:
          throw e;
      }
    }

    const signupResult: SignUpResult = {
      sub: awsResult.userSub,
      preferred_username: awsResult.user.getUsername(),
      isUserConfirmed: awsResult.userConfirmed,
      codeDelivery: [] as CodeDeliveryInfo[],
    };
    return signupResult;
  }

  public async confirmSignUp(
    username: string,
    confirmationCode: string,
    _transactionId: string
  ): Promise<any> {
    const { confirmSignUp } = await import('aws-amplify/auth');
    try {
      const result = await confirmSignUp({ username, confirmationCode });
      return result!;
    } catch (e) {
      switch ((e as { code?: string }).code) {
        case 'CodeMismatchException':
          throw new AuthError(
            AuthMessageEnum.INVALID_PWD_RESET_CODE,
            ErrorCodeEnum.INVALID_PWD_RESET_CODE
          );
        default:
          throw e;
      }
    }
  }

  public async forgotPassword(userName: any): Promise<CodeDeliveryInfo> {
    const { resetPassword } = await import('aws-amplify/auth');
    const result = await resetPassword(userName);
    const { attributeName, deliveryMedium, destination } =
      result.nextStep.codeDeliveryDetails;
    const delivery: CodeDeliveryInfo = {
      attributeName: attributeName!,
      deliveryMedium: deliveryMedium!,
      destination: destination!,
    };
    return delivery;
  }

  public async resendSignUp(_userName: string): Promise<CodeDeliveryInfo> {
    const delivery: CodeDeliveryInfo = {
      attributeName: 'email',
      deliveryMedium: 'EMAIL',
      destination: 's**#gamil.com',
    };
    return delivery;
  }

  public async changePassword(
    _user: string,
    oldPassword: string,
    newPassword: string
  ): Promise<any> {
    const { updatePassword } = await import('aws-amplify/auth');

    const result = await updatePassword({
      oldPassword,
      newPassword,
    });
    return result!;
  }

  public async forgotPasswordSubmit(
    username: string,
    confirmationCode: string,
    newPassword: string
  ): Promise<string> {
    const { confirmResetPassword } = await import('aws-amplify/auth');
    try {
      await confirmResetPassword({ username, newPassword, confirmationCode });
    } catch (e) {
      switch ((e as { code?: string }).code) {
        case 'CodeMismatchException':
          throw new AuthError(
            AuthMessageEnum.INVALID_PWD_RESET_CODE,
            ErrorCodeEnum.INVALID_PWD_RESET_CODE
          );
        case 'InvalidPasswordException':
          throw new AuthError(
            AuthMessageEnum.WEAK_PASSWORD,
            ErrorCodeEnum.WEAK_PASSWORD
          );
        case 'InvalidParameterException':
          throw new AuthError(
            AuthMessageEnum.WEAK_PASSWORD,
            ErrorCodeEnum.WEAK_PASSWORD
          );
        default:
          throw e;
      }
    }
    return 'SUCCESS';
  }

  public async currentSession(): Promise<UserSession> {
    const { fetchAuthSession } = await import('aws-amplify/auth');
    const session: any = await fetchAuthSession();

    if (session?.tokens?.idToken && session?.tokens?.accessToken) {
      const idToken = session.tokens.idToken.toString();
      const accessToken = session.tokens.accessToken.toString();
      return new UserSession(
        new JWTToken(idToken),
        new JWTToken(accessToken),
        ' '
      );
    } else {
      throw new AuthError(
        AuthMessageEnum.USER_NOT_LOGGEDIN,
        ErrorCodeEnum.USER_NOT_LOGGEDIN
      );
    }
  }

  public async updateUserAttributes(
    _user: AuthUser,
    _attributes: object
  ): Promise<string> {
    return 'SUCCESS';
  }
}

let awsAuthProvider: AWSAuthProvider;

const createAWSProvider = (config: any) => {
  if (awsAuthProvider === undefined) {
    configureAWS(config);
    awsAuthProvider = new AWSAuthProvider(config);
  }
  return awsAuthProvider;
};

const configureAWS = async (_config: any) => {
  const { Amplify } = await import('aws-amplify');
  Amplify.configure(awsexport);
};

export default createAWSProvider;
