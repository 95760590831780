import gql from 'graphql-tag';

export const Applications = gql`
  query Applications {
    Applications {
      aeApplicationUuid
      authAppId
      applicationId
      applicationName
      description
      status
      defaultApplication
      tenantId
      aeInsertId
      aeUpdateId
      aeInsertTs
      aeUpdateTs
      aeTransactionId
    }
  }
`;
