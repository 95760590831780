import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const ParentWrapperContainer: any = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 15px;
`;

export const ChangePasswordContainer: any = styled.div`
  width: 100%;
  min-height: 50vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #fff;
`;

export const ChangePasswordStartMsgOuterContainer: any = styled.div`
  flex-wrap: wrap;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: flex;
`;

export const WrapperContainer: any = styled.div`
  width: auto;
  background: #fff;
  border-radius: 6px;
  border: 1px solid #84898e69;
  overflow: hidden;
  padding: 20px 35px;
  display: flex;
  flexwrap: wrap;
  justify-content: space-between;
`;

export const Form: any = styled.form`
  width: 320px !important;
`;
export const ChangePasswordMsgContainer: any = styled.span`
  color: #d9534f;
  background-color: #e350503d;
  border: 1px solid;
  padding: 10px 50px 10px 50px;
  border-color: #d9534f;
  border-radius: 4px;
`;

export const ChangePassTitleSpanContainer: any = styled.span`
  font-family: sans-serif;
  font-size: 23px;
  color: #838284;
  line-height: 1.2;
  text-align: center;
  width: 100% !important;
  display: block;
  padding-bottom: ${(p: any) => (p.showMessage ? '40px' : '0px')};
`;

export const CustomButton: any = styled(Button)`
  margin-bottom: 20px;
  margin-top: 13px;
  width: 100%;
`;

export const RedirectContainer: any = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LinkSpan: any = styled.span`
  font-weight: 550;
  color: ${(p) => p.theme.color};
  cursor: pointer;
`;

export const LinkComponent: any = styled(Link)`
  text-decoration: none;
`;
