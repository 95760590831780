import gql from 'graphql-tag';
export const PortalForm = gql`
  query PortalForm($id: ID!) {
    PortalForm(id: $id) {
      configObjectId
      name
      configObjectType
      createdBy
      isDeleted
      itemDescription
      insert_ts
      projectId
      updatedBy
      update_ts
      deletionDate
      nodeId
      ceId
      logicalEntityId
      formId
      mode
      privileges {
        privilegeId
        privilegeType
        itemId
        roleId
        createdBy
        isDeleted
        insert_ts
        updatedBy
        update_ts
        deletionDate
      }
    }
  }
`;
