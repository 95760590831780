import React, { useContext, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useConfigQuery } from '../custom-hooks/useConfigQuery';
import { useFetchAppFormData } from '../custom-hooks/useFetchAppFormData';
import SkeletonCore from '../components/SkeletonCore';
import {
  getAllChildFormList,
  getPrimaryDBCode,
  getDispalyColumn,
  getForm,
} from '../utils/ConfigUtils';
import Form from '../composite-entity/form/FormGenerator';
import { FormErrorContainers } from 'info-ui-library';
import { ThemeContext } from '../theme/ThemeContext';
import { UserContext } from '../user-context/UserContext';
import { createStorage } from '../storage';
import {
  ROUTE_STATE_LOCAL_STORAGE_KEY,
  PATHNAME,
} from '../constants/appeng.enum';
import { useDispatch } from 'react-redux';
import { getUserProfile } from '../actions';

interface IParentForm {
  mode: string;
  parentFormId: string;
  modalRequired?: boolean;
  ceId?: string;
  nodeId?: string;
  nodeType?: string;
  primaryDBCode?: string;
  data?: any;
  formType?: string;
  formId?: string;
  configProp?: any;
  occuranceNumber?: number;
  privilegesMap?: any;
  parentEntityId?: any;
  childEntityId?: any;
  location?: any;
  intl?: any;
  referralId?: any;
}

const MyProfileForm: React.FC<IParentForm> = (props: IParentForm) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const storage = createStorage();

  if (location.state) {
    storage.setItem(
      ROUTE_STATE_LOCAL_STORAGE_KEY,
      JSON.stringify(location.state)
    );
  } else {
    const routeState = storage.getItem(ROUTE_STATE_LOCAL_STORAGE_KEY);
    location.state = routeState ? JSON.parse(routeState) : {};
  }

  const themeContext = useContext(ThemeContext);
  const userContext: any = useContext(UserContext);
  const formId = props.parentFormId;
  const nodeId = props.nodeId ? props.nodeId : '';
  const ceId = props.ceId ? props.ceId : '';
  const nodeType = props.nodeType ? props.nodeType : '';
  const referralData = props.data;
  const primaryDBCode = props.primaryDBCode ? props.primaryDBCode : '';
  const formType = props.formType;

  const { loading, error, data } = useConfigQuery('NodeQuery', nodeId);
  const { formLoading, appData } = useFetchAppFormData(
    nodeId,
    props.mode,
    nodeType,
    ceId,
    referralData,
    formId,
    primaryDBCode,
    formId,
    true,
    navigate,
    location.pathname,
    '',
    ''
  );

  useEffect(() => {
    if (
      Object.entries(appData).length > 0 &&
      userContext.getUserDetail()['APP_LOGGED_IN_PROFILE_STATUS'] !==
        appData[0]['USER_STATUS']
    ) {
      dispatch(
        getUserProfile(
          userContext.getUserDetail()['APP_LOGGED_IN_USER_ID'],
          appData[0]['USER_STATUS'],
          location
        )
      );
    }
  }, [appData, dispatch, userContext]);

  if (loading || formLoading) return <SkeletonCore count={1} />;

  if (appData) {
    try {
      const childFormList = getAllChildFormList(
        data.CompositeEntityNode.combinedNodes,
        data.CompositeEntityNode.combinedNodes,
        formType,
        props.mode
      );

      const keys: any = {};
      const primaryDBCode = getPrimaryDBCode(
        data.CompositeEntityNode.entity.logicalColumns
      );
      const displayColumn = getDispalyColumn(
        data.CompositeEntityNode.entity.logicalColumns
      );
      const parentForm = getForm(props.mode, data.CompositeEntityNode);
      const modeForButtonPanel = props.mode === 'Insert' ? 'INSERT' : 'EDIT';
      const buttonPanel = parentForm.buttonPanels.filter(
        (buttonPanel: any) => buttonPanel.mode === modeForButtonPanel
      );
      keys[primaryDBCode] = referralData ? referralData[primaryDBCode] : null;
      const routerParameter = { ...location.state };
      routerParameter[PATHNAME] = location.pathname;

      const newProps: any = {
        parentForm: parentForm,
        entityName: data.CompositeEntityNode.entity.name,
        entityId: data.CompositeEntityNode.entity.configObjectId,
        nodeId: data.CompositeEntityNode.configObjectId,
        childFormList: childFormList,
        nodeName: data.CompositeEntityNode.displayNodeName,
        mode: props.mode,
        keys: keys,
        compositeEntityKey: referralData ? referralData[primaryDBCode] : null,
        ceId: ceId,
        logicalColumns: data.CompositeEntityNode.entity.logicalColumns,
        displayName: displayColumn ? displayColumn.dbCode : primaryDBCode,
        combinedNodes: data.CompositeEntityNode.combinedNodes,
        buttonPanel: buttonPanel[0],
        nodeEditabilityRegex: data.CompositeEntityNode.editabilityRegex,
        nodeExpressionAvailble: data.CompositeEntityNode.expressionAvailable,
        gridId: data.CompositeEntityNode.dataGrid.configObjectId,
        modalRequired: props.modalRequired,
        referralId: parentForm.configObjectId,
        routerParameter: routerParameter,
        resetForm: true,
        portalForm: false,
        setCardButtons: undefined,
        setCardLabel: undefined,
      };
      return <Form {...newProps} />;
    } catch {
      const error = ['Error occurred in Parent Form'];
      return (
        <FormErrorContainers
          ioTheme={themeContext.ioTheme}
          ioMode={themeContext.ioMode}
          styleName={'danger'}
          lists={error}
        />
      );
    }
  } else {
    return <div>Form Data does not exist</div>;
  }
};

export default MyProfileForm;
