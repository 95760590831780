import React, { useContext, useState, useEffect } from 'react';
import { LabelComponent, FormErrorContainers } from 'info-ui-library';
import { ThemeContext } from '../theme/ThemeContext';
import { injectIntl } from 'react-intl';
import { useFormData } from '../custom-hooks/useFormData';
import { ILabelComponent } from './interfaces/index';

const LabelCore: React.FC<ILabelComponent> = (props: ILabelComponent) => {
  const dbCode = props.configProp.logicalColumn
    ? props.configProp.logicalColumn.dbCode
    : props.configProp.dbCode;
  let labelData = useFormData(
    props.formId,
    props.occuranceNumber,
    dbCode,
    props.parentFormId,
    props.referralId
  );
  const themeContext = useContext(ThemeContext);
  let supportingLabel =
    !props.configProp.hasSupportingLabel && dbCode
      ? labelData
      : props.configProp.supportingLabel;

  try {
    return (
      <LabelComponent
        styleName={themeContext.styleName}
        ioMode={themeContext.ioMode}
        ioTheme={themeContext.ioTheme}
        label={props.configProp.label}
        fontSize={props.configProp.fontSize}
        hasSupportingLabel={props.configProp.hasSupportingLabel}
        hasValueInSupportingLabel={props.configProp.hasValueInSupportingLabel}
        supportingLabel={supportingLabel}
        supportingLabelFontSize={props.configProp.supportingLabelFontSize}
        labelColor={props.configProp.labelColor}
      />
    );
  } catch {
    const error = ['Error occurred in Label Component'];
    return (
      <FormErrorContainers
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};

export default React.memo(injectIntl(LabelCore));
