import React, { useContext } from 'react';
import { injectIntl } from 'react-intl';
import { Button, FormErrorContainers } from 'info-ui-library';

import { ThemeContext } from '../theme/ThemeContext';
import { extractLabel } from '../utils/intlutils';
import { IButtonCore } from './interfaces/index';

const ButtonCore: React.FC<IButtonCore> = (props: IButtonCore) => {
  const themeContext = useContext(ThemeContext);
  try {
    const handleClick = () => {
      props.handleClick();
    };
    return (
      <Button
        styleName={themeContext.styleName}
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        id={props.button.configObjectId}
        disabled={!props.isEditable || props.loading}
        name={extractLabel(props.button.label, props.intl.locale)}
        onClick={() => handleClick()}
      >
        {props.showloadingSymbol && (
          <i className="fa fa-refresh fa-spin" style={{ marginRight: '5px' }} />
        )}
      </Button>
    );
  } catch {
    const error = ['Error occurred in TextBox'];
    return (
      <FormErrorContainers
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};

export default React.memo(injectIntl(ButtonCore));
