import React from 'react';
import SideNavigationButton from '../composite-entity/grid/SideNavigationButton';
import { useConfigQuery } from '../custom-hooks/useConfigQuery';
import SkeletonCore from '../components/SkeletonCore';
import { FormErrorContainers } from 'info-ui-library';

interface IFormButton {
  nodeId: string;
  mode: string;
  displayName: string;
  nodeType: string;
  keys: any;
  data: any;
  ceId: string;
  themeContext: any;
}
interface IFormButtonComponent extends IFormButton {
  isNodeTreeRequired: boolean;
}

const FormButton: React.FC<IFormButton> = (props) => {
  const { loading, error, data } = useConfigQuery('CEQuery', props.ceId);
  if (loading) return <SkeletonCore count={1} />;
  try {
    const newProps = {
      ...props,
      isNodeTreeRequired: data.CompositeEntity.isNodeTreeRequired,
    };
    return <FormButtonComponent {...newProps} />;
  } catch {
    return (
      <FormErrorContainers
        ioTheme={props.themeContext.ioTheme}
        ioMode={props.themeContext.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};

const FormButtonComponent: React.FC<IFormButtonComponent> = (props: any) => {
  return (
    <React.Fragment>
      {props.isNodeTreeRequired &&
      props.location.pathname.includes('/app/ce/form') ? (
        <SideNavigationButton />
      ) : null}
    </React.Fragment>
  );
};

export default FormButton;
