import React from 'react';
import { DateTimePicker, Button } from 'info-ui-library';
import moment from 'moment';
import styled from 'styled-components';
const StyledButton = styled(Button)`
  &.btn {
    position: relative;
    top: 4px;
  }
`;

function DatePickerFilter({
  column: {
    filterValue,
    preFilteredRows,
    setFilter,
    styleName,
    ioMode,
    ioTheme,
    dateFormat,
    width,
  },
}) {
  const count = preFilteredRows ? preFilteredRows.length : 0;
  const [value, setValue] = React.useState(undefined);

  const onChange = (e) => {
    if (e) {
      setFilter(moment.utc(e).toISOString());
    } else {
      setFilter(undefined);
    }
    setValue(e);
  };
  let dateFormatReplace = dateFormat
    ? dateFormat.replaceAll('D', 'd').replaceAll('Y', 'y')
    : null;
  let [dateFormatValue, timeFormatValue] = dateFormatReplace.split(' ');
  let utcToLocalDate = value
    ? moment
        .utc(value)
        .local()
        .format(dateFormat ? dateFormat.toUpperCase() : 'DD-MMM-YYYY')
    : null;

  return (
    <div style={{ display: 'flex', width: '100%' }}>
      <DateTimePicker
        placeholderText={'Please select a date'}
        styleName={styleName}
        ioTheme={ioTheme}
        ioMode={ioMode}
        label={''}
        displayType={'SingleLine'}
        showTimeSelect={false}
        showTimeSelectOnly={false}
        dateFormat={dateFormatValue ? dateFormatValue : 'yyyy-MM-dd'}
        onChange={(e) => onChange(e)}
        withPortal
        widthCell={width}
        selected={utcToLocalDate ? new Date(utcToLocalDate) : null}
        isInlineGridComponent={true}
        className={'inlineGrid'}
      />
      {value ? (
        <StyledButton
          onClick={() => onChange(undefined)}
          disabled={false}
          styleName={styleName}
          ioTheme={ioTheme}
          ioMode={ioMode}
          className="fa fa-times-circle"
          name={''}
          title={'Clear Filter'}
          placeHolder={`Search records...`}
        />
      ) : (
        ''
      )}
    </div>
  );
}

export default DatePickerFilter;
