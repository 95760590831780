import { resolveColor } from '../../theme/ThemeOptions';
import styled from 'styled-components';

export const StyledSimpleFormDiv: any = styled.div`
border-color: ${ (p: any) =>
                resolveColor(p.$ioTheme, p.$ioMode, p.$styleName, 'borderColor') +
                '!important'};
border-style: inset;
padding: 10px;
border: 1px solid ;
margin-left: 8px;
margin-right: 8px;
border-radius: 10px;
`;

export const StyledSimpleFormButtonWrapper: any = styled.div`
  float: right;
  margin-right: 25px;
`;

export const StyledSimpleFormSpan: any = styled.span`
  color: #696969 !important;
  font-weight: bold;
  margin-left: 20px;
`;

export const StyledComplexFormDiv: any = styled.div`
border-color: ${ (p: any) =>
                resolveColor(p.$ioTheme, p.$ioMode, p.$styleName, 'borderColor') +
                '!important'};
border-style: inset;
padding: 10px;
border: 1px solid ;
min-height: 70vh;
margin-left: 8px;
margin-right: 8px;
border-radius: 10px;
`;

export const StyledConfirModalDiv: any = styled.div`
text-align: center;
    width: 500px;
    padding: 40px;
    background: ${ (p: any) =>
        resolveColor(p.$ioTheme, p.$ioMode, p.$styleName, 'modalBGColor') + '!important'};
        resolveColor(p.$ioTheme, p.$ioMode, p.$styleName, 'modalBGColor') + '!important'};
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
`;

export const StyledWarningModalDiv: any = styled.div`
    position: fixed;
    z-index:1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    align-self: center;
    text-align: center;
    width: 500px;
    padding: 40px;
    background: ${(p: any) =>
        resolveColor(p.$ioTheme, p.$ioMode, p.$styleName, 'modalBGColor') + '!important'};
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
`;

export const StyledIdleTimeoutModalDiv: any = styled.div`
    text-align: center;
    width: 520px;
    padding: 40px;
    background: ${ (p: any) =>
        resolveColor(p.$ioTheme, p.$ioMode, p.$styleName, 'modalBGColor') + '!important'};
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
`;

export const StyledComplexFormButtonWrapper: any = styled.div`
  float: right;
  margin-right: 25px;
`;
export const StyledComplexFormSpan: any = styled.span`
color: ${ (p: any) =>
                resolveColor(p.$ioTheme, p.$ioMode, p.$styleName, 'formSectionColor') + '!important'};
font-weight: bold;
font-size: 20px;
margin-left: 20px;
`;
