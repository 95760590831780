import React, { useEffect } from 'react';
import { TextBox } from 'info-ui-library';

function DefaultColumnFilter({
  column: {
    filterValue,
    preFilteredRows,
    setFilter,
    styleName,
    ioMode,
    ioTheme,
    id,
  },
}) {
  const count = preFilteredRows ? preFilteredRows.length : 0;
  const onChange = (e) => {
    setFilter(e || undefined);
  };

  const handleBlur = (e) => {
    setFilter(e || undefined);
  };
  return (
    <TextBox
      value={filterValue || ''}
      handleChange={(e) => onChange(e)}
      handleBlur={(e) => handleBlur(e)}
      styleName={styleName}
      ioTheme={ioTheme}
      ioMode={ioMode}
      label={''}
      displayType={'SingleLine'}
      placeHolder={`Search records...`}
      isInlineGridComponent={true}
      isFilterComponent={true}
      height="40px"
      configId={'grid_filter_' + id}
    />
  );
}

export default DefaultColumnFilter;
