import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { FormErrorContainers } from 'info-ui-library';
import { injectIntl } from 'react-intl';

import { generateSimpleFormSectionCards } from './CardGenerators';
import { ThemeContext } from '../../theme/ThemeContext';
import { IFormSection } from '../interfaces/index';
import { UserContext } from '../../user-context/UserContext';
import { IState } from '../../store/index';

const FormSectionGenerator: React.FC<IFormSection> = (props: IFormSection) => {
  const themeContext = useContext(ThemeContext);
  const userContext = useContext(UserContext);

  const formDataObject = useSelector(
    (state: any) => state.appState.cardsData[props.referralId]
  );
  const formSections = props.formSections.filter(
    (formSection: any) => formSection.formFields
  );
  try {
    const formSectionCards = generateSimpleFormSectionCards(
      formSections,
      props.formId,
      themeContext,
      props.parentFormId,
      props.mode,
      props.occuranceNumber,
      props.nodeId,
      formDataObject.data[0],
      userContext,
      props.formPrivilege,
      props.isFormEditable,
      props.parentEntityId,
      props.intl.locale,
      props.referralId
    );
    return <React.Fragment>{formSectionCards}</React.Fragment>;
  } catch {
    const error = ['Error occurred in Form Section Generator'];
    return (
      <FormErrorContainers
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};

export default React.memo(injectIntl(FormSectionGenerator));
