const awsmobile = {
  aws_project_region: 'us-east-1',
  aws_cognito_identity_pool_id: '',
  aws_cognito_region: 'us-east-1',
  aws_user_pools_id: 'us-east-1_7lxwNQGRF',
  aws_user_pools_web_client_id: '6pn2volhdllvtkfp9et6p786ir',
  oauth: {},
};

export default awsmobile;
