import { put, all, fork, takeEvery, call, select } from 'redux-saga/effects';
import * as base from '../base';
import { CardData } from '../../models';
import {
  setFormData,
  setToggleMessages,
  removeRowFromGrid,
  removeCardDataByReferralId,
  setStore,
  GET_FORMDATA_USING_FORMCONFIG,
} from '../../actions';
import { CardType, NodeType } from '../../constants/appeng.enum';
import { getPrimaryDBCode, getDispalyColumn } from '../../utils/ConfigUtils';
import cloneDeep from 'lodash/cloneDeep';
import { executeFormPreprocessor } from '../executer/preprocessorExecuter';
import { getDynamicOptionParentFFList } from '../utilitySags';
const getCard = (state: any) => state.appState.cardsData;
const getToggleMessages = (state: any) => state.appState.toggleMessages;
export default function* getFormDataUsingFormConfig() {
  yield all([fork(watchGetFormDataUsingFormConfig)]);
}

function* watchGetFormDataUsingFormConfig() {
  yield takeEvery(
    GET_FORMDATA_USING_FORMCONFIG,
    getFormDataUsingFormConfigSaga
  );
}

function* getFormDataUsingFormConfigSaga(
  action: any
): Generator<any, any, any> {
  const toggleMessages = yield select(getToggleMessages);
  if (toggleMessages.length) yield put(setToggleMessages([]));
  const { data } = yield call(base.getConfigData, 'FormQuery', action.formId);

  let existingCardData = yield select(getCard);

  let primaryDBCode = '';
  let displayColumn: any;
  let displayName = '';
  if (data.Form.logicalEntity) {
    primaryDBCode = getPrimaryDBCode(data.Form.logicalEntity.logicalColumns);
    displayColumn = getDispalyColumn(data.Form.logicalEntity.logicalColumns);
    displayName = displayColumn ? displayColumn.dbCode : primaryDBCode;
  }

  let primaryKey = action.primaryKey;
  if (action.rowData && primaryKey === undefined && primaryDBCode) {
    primaryKey = action.rowData[primaryDBCode];
  }
  const formId = data.Form.configObjectId;

  let formData: any = {};
  const userSessionData = base.getUserDetail();

  if (!primaryKey) {
    formData = base.getDefaultFormData(data, action.referenceData);
    primaryKey = formData[0][primaryDBCode];
  }

  if (data.Form.logicalEntity) {
    if (primaryKey) {
      let gridList =
        data.Form.parentRelations.length > 0
          ? data.Form.parentRelations.filter((rel: any) => {
              return (
                rel.relationType === 'DataGrid_Form' &&
                rel.parentItemId == action.referenceData.gridId
              );
            })
          : [];

      if (
        action.parentCardId &&
        action.referenceData.gridId &&
        gridList.length > 0 &&
        action.referenceData.actionType &&
        action.referenceData.actionType === 'UPSERT'
      ) {
        formData[0] = yield call(
          base.getFormData,
          action.referenceData.gridId,
          primaryKey,
          action.referenceData
        );
      } else {
        formData = yield call(
          base.getFormDataUsingLEId,
          data.Form.logicalEntity.name,
          primaryKey
        );
      }

      if (formData && formData.errors && formData.code === 512) {
        const messages: string[] = [];
        messages.push(formData.errors);
        yield put(setToggleMessages(messages));
      }
      if (Object.keys(formData).length === 0) {
        formData = base.getDefaultFormData(data);
      } else {
        formData[0].child = {};
      }
      Object.assign(formData[0], userSessionData);
    }
  } else {
    const dataToRefer: any = {};
    Object.assign(
      dataToRefer,
      existingCardData,
      { formData: cloneDeep(formData) },
      { SubComponentId: action.referralId.split('_')[0] + '_' }
    );
    formData = yield call(
      executeFormPreprocessor,
      data.Form,
      dataToRefer,
      true,
      [],
      action.referralId
    );
  }

  let existingCardDetails = yield select(getCard);
  let currentCardDetails = cloneDeep(existingCardDetails[action.parentCardId]);

  if (
    action.referenceData.actionType &&
    action.referenceData.actionType === 'UPSERT' &&
    formData[0][action.referenceData.primaryDBCode] === undefined &&
    currentCardDetails
  ) {
    let indexRemove = 0;
    currentCardDetails.data.map((dataObj: any, index: any) => {
      if (dataObj[action.referenceData.primaryDBCode] === action.primaryKey) {
        indexRemove = index;
      }
    });

    const messages: string[] = [];
    messages.push('Data is no longer present in this Grid');
    yield all([
      put(
        removeRowFromGrid(
          indexRemove,
          action.parentCardId,
          indexRemove.toString()
        )
      ),
      put(removeCardDataByReferralId(action.referralId)),
      put(removeCardDataByReferralId(action.parentId)),
      put(setToggleMessages(messages)),
    ]);
  } else {
    formData = yield call(
      executeFormPreprocessor,
      data.Form,
      formData,
      true,
      [],
      action.referralId
    );

    if (formData.errors) {
      const messages: string[] = [];
      messages.push(formData.errors);

      yield put(setToggleMessages(messages));
    } else {
      const existingOptions = existingCardData[action.referralId]
        ? { ...existingCardData[action.referralId].options }
        : {};

      yield call(
        getDynamicOptionParentFFList,
        existingCardData[action.referralId],
        [data.Form],
        formData,
        existingOptions
      );

      let referenceData = existingCardData[action.referralId]
        ? existingCardData[action.referralId].referenceData
        : null;
      let rd =
        referenceData || action.referenceData
          ? { ...referenceData, ...action.referenceData }
          : null;
      Object.assign(rd, { mode: primaryKey ? 'Edit' : 'Insert' });

      let neededData = {
        primaryDBCode,
        insertFormId: formId,
        editFormId: formId,
        cardId: action.referralId,
        displayName: displayName,
        nodeId: undefined,
        ceId: undefined,
        componentType:
          action.nodeType == undefined ||
          action.nodeType === '' ||
          action.nodeType === NodeType.PARENTNODE
            ? 'ParentForm'
            : 'ChildForm',
        data: formData[0],
        nodeType: action.nodeType,
        mode: primaryKey ? 'Edit' : 'Insert',
      };
      let updatedCardData = yield select(getCard);
      if (
        !action.usePortalReferenceData &&
        existingCardData[action.referralId]
      ) {
        Object.assign(rd, neededData);
        const cardData = new CardData(
          action.referralId,
          CardType.FORM,
          {
            0: formData[0],
            attachmentData:
              updatedCardData[action.referralId]?.data?.attachmentData,
          },
          action.portalId,
          action.parentId,
          rd,
          updatedCardData[action.referralId]?.errorData,
          existingOptions,
          {},
          false,
          false,
          {},
          action.tabGroupId,
          action.entityName,
          action.tabPortalId,
          action.entityId,
          action.parentCardId
        );

        let currentCard = cloneDeep(existingCardData[action.parentCardId]);
        const cardsData = cloneDeep(existingCardData);

        if (
          action.referenceData.actionType &&
          action.referenceData.actionType === 'UPSERT' &&
          action.parentCardId &&
          formData[0][action.referenceData.primaryDBCode] &&
          cardsData[action.parentCardId] &&
          cardsData[action.parentCardId].data
        ) {
          cardsData[action.parentCardId].data.map((dataObj: any) => {
            if (
              dataObj[action.referenceData.primaryDBCode] === action.primaryKey
            ) {
              for (const key of Object.keys(dataObj)) {
                if (formData[0].hasOwnProperty(key)) {
                  dataObj[key] = formData[0][key];
                }
              }
            }
          });
        }
        if (
          action.referenceData.actionType &&
          action.referenceData.actionType === 'UPSERT' &&
          formData[0][action.referenceData.primaryDBCode] &&
          JSON.stringify(currentCard) !==
            JSON.stringify(cardsData[action.parentCardId])
        ) {
          const currentCardData = cardsData[action.referralId];
          currentCardData.buttonClicked = '';
          Object.assign(currentCardData, cardData);
          currentCardData.errorData = {};
          const updateStore: any = {
            cardsData: cardsData,
          };

          yield put(setStore(updateStore));
        } else if (
          formData[0][action.referenceData.primaryDBCode] ||
          action.mode === 'Insert'
        ) {
          if (
            existingCardData[action.referralId]?.data?.attachmentData !==
            undefined
          ) {
            let attachmentList: any = [];
            existingCardData[action.referralId].data.attachmentData.map(
              (attachments: any) => {
                attachments.attachment[0]['ATTACHMENT_MODE'] = 'saved';
                attachmentList.push(attachments);
              }
            );
            let updatedCard: any = { ...cardData };
            if (
              action.referenceData.actionType &&
              action.referenceData.actionType === 'UPSERT'
            ) {
              updatedCard.errorData = {};
            }
            updatedCard.data = {
              ...updatedCard.data,
              attachmentData: attachmentList,
            };

            yield put(
              setFormData(updatedCard, action.referralId, false, false, false)
            );
          } else {
            let updatedCard: any = { ...cardData };
            if (
              action.referenceData.actionType &&
              action.referenceData.actionType === 'UPSERT'
            ) {
              updatedCard.errorData = {};
            }

            yield put(
              setFormData(updatedCard, action.referralId, false, false, false)
            );
          }
        } else {
          const messages: string[] = [];
          messages.push('Data is corrupted. Please refresh the Grid');
          yield put(setToggleMessages(messages));
        }
      } else if (existingCardData[action.referralId]) {
        let updatedCardData = yield select(getCard);
        const cardData = new CardData(
          action.referralId,
          CardType.FORM,
          formData,
          action.portalId,
          action.parentId,
          rd,
          updatedCardData[action.referralId].errorData,
          existingOptions,
          {},
          false,
          false,
          {},
          action.tabGroupId,
          action.entityName,
          action.tabPortalId,
          action.entityId,
          action.parentCardId
        );
        const previousData = existingCardData[action.parentId]
          ? existingCardData[action.parentId].referenceData
          : {};
        Object.assign(previousData, neededData);
        const portalData = new CardData(
          action.parentId,
          CardType.PORTAL,
          {},
          action.portalId,
          action.parentId,
          previousData,
          {},
          existingOptions
        );
        yield all([
          put(setFormData(cardData, action.referralId, false, false, false)),
          put(setFormData(portalData, action.parentId, false, false, false)),
        ]);
      }
    }
  }
}
