import gql from 'graphql-tag';

export const FormField = gql`
  query FormField($id: ID!) {
    FormField(id: $id) {
      mandatoryIconAccessibilityRegex
      height
      editorType
      formatDate
      isApplyDateFormat
      dbCode
      label
      order
      defaultValue
      type
      multivalueList
      resetOnRefresh
      isMandatory
      selectItemsReferenceID
      isAsyncOption
      isMulti
      placeHolder
      expressionAvailable
      refreshFormOnChange
      accessibilityRegex
      editabilityRegex
      previewAccessibilityRegex
      previewEditabilityRegex
      deleteAccessibilityRegex
      deleteEditabilityRegex
      configObjectId
      showYearDropdown
      showMonthDropdown
      maxDate
      minDate
      dropdownMode
      timeFormat
      showTimeSelect
      showTimeSelectOnly
      timeCaption
      timeIntervals
      isClearable
      privileges {
        privilegeType
        itemId
        roleId
      }
      name
      logicalColumn {
        dbCode
      }
    }
  }
`;
