import React, { useContext } from 'react';
import { FormErrorContainers } from 'info-ui-library';
import { QueryType } from '../constants/appeng.enum';
import SkeletonCore from '../components/SkeletonCore';
import { useConfigQuery } from '../custom-hooks/useConfigQuery';
import { ThemeContext } from '../theme/ThemeContext';

import PortalGridGenerator from './PortalGridGenerator';
import WrapperPortalGridCard from './WrapperPortalGridCard';
const PortalGridCard: React.FC<any> = (props: any) => {
  const themeContext = useContext(ThemeContext);

  const queryType =
    props.nodeId && props.nodeId !== ''
      ? QueryType.COMPOSITE_ENTITY_NODE_QUERY
      : QueryType.DATA_GRID_QUERY;
  const configId =
    props.nodeId && props.nodeId !== ''
      ? props.nodeId
      : props.portalGrid.gridId;
  const { loading, error, data } = useConfigQuery(queryType, configId);
  if (loading) return <SkeletonCore count={1} />;
  if (
    props.portalGrid.gridId !== data?.CompositeEntityNode?.dataGrid?.configObjectId
  ) {
    return <WrapperPortalGridCard {...props}/>
  } else {
    
    try {
      return <PortalGridGenerator {...props} compositeEntityNodeData={data} />;
    } catch {
      return (
        <FormErrorContainers
          ioTheme={themeContext.ioTheme}
          ioMode={themeContext.ioMode}
          styleName={'danger'}
          lists={error}
        />
      );
    }
  }
};


function isPropsAreEqual(prevProps: any, nextProps: any) {
  return (
    JSON.stringify(prevProps.referenceData) ===
      JSON.stringify(nextProps.referenceData) &&
    prevProps.editableGridEnable === nextProps.editableGridEnable
  );
}
export default React.memo(PortalGridCard, isPropsAreEqual);
