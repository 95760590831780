import React from 'react';
import moment from 'moment';
import { DateTimePicker } from 'info-ui-library';
import DataGridToolTip from './DataGridToolTip';
import { Tooltip as ReactTooltip } from 'react-tooltip';

const DatePickerCell = ({ cell, row, column: { id }, updateMyData }) => {
  const [value, setValue] = React.useState(cell.value);
  const [isEditable, setEditable] = React.useState(false);

  let errorData = cell.column.useErrorDataUsingDBCode
    ? cell.column.useErrorDataUsingDBCode(
        row.id.toString(),
        id,
        cell.column.referralId,
        true
      )
    : null;

  React.useEffect(() => {
    if (
      errorData &&
      errorData.dbCode === id &&
      errorData.path === row.id.toString()
    ) {
      setEditable(true);
    } else {
      setEditable(false);
    }
  }, [errorData]);

  const handleClick = () => {
    if (errorData || (errorData && errorData.path !== row.id.toString())) {
      setEditable(false);
    } else {
      setEditable(true);
    }
  };

  const onChange = (e) => {
    let localToUtc = e ? moment(e).local().format('YYYY-MM-DD') : null;
    setValue(localToUtc);
    if (value !== localToUtc && updateMyData) {
      updateMyData(row.index, id, localToUtc, row.id);
    }
    setEditable(false);
  };

  const onBlur = () => {
    setEditable(false);
  };

  React.useEffect(() => {
    setValue(cell.value);
  }, [cell.value, value]);

  let valueArray =
    value && typeof value === 'string' && value.includes('sEpErAtOr')
      ? value.split('sEpErAtOr')
      : undefined;
  let utcToLocalDate = value
    ? cell.column.isTimeStamp
      ? moment
          .utc(valueArray ? valueArray[0] : value)
          .local()
          .format(cell.column.dateFormat)
      : moment
          .utc(valueArray ? valueArray[0] : value)
          .format(cell.column.dateFormat)
    : null;
  let tooltipID = 'datePickerError_' + id + '_' + row.id.toString();

  return cell.column.editEnable && isEditable && cell.column?.changeEnable ? (
    <React.Fragment>
      <div
        style={{
          border:
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? '1px solid red'
              : 'none',
          borderRadius: '5px',
        }}
        data-tooltip-id={errorData?.message}
        data-tooltip-content={errorData?.message}
      >
        <DateTimePicker
          styleName={cell.column.styleName}
          ioTheme={cell.column.ioTheme}
          ioMode={cell.column.ioMode}
          label={''}
          displayType={'SingleLine'}
          showTimeSelect={false}
          showTimeSelectOnly={false}
          dateFormat={'dd-MMM-yyyy'}
          onChange={(e) => onChange(e)}
          selected={utcToLocalDate ? new Date(utcToLocalDate) : null}
          withPortal
          isInlineGridComponent={true}
          widthCell={cell.column.width}
          className={errorData ? 'inlineGridError' : 'inlineGrid'}
        />
        <ReactTooltip
          place={'bottom'}
          style={{
            zIndex: 9999,
            color: 'white !important',
            backgroundColor:
              errorData && errorData['dbCode'] && errorData['dbCode'] === id
                ? 'rgb(255, 0, 0)'
                : 'none',
          }}
          id={
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? errorData.message
              : ''
          }
        />
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      {cell.isRepeatedValue !== true &&
      value !== undefined &&
      value !== null &&
      value !== '' ? (
        <div
          onBlur={() => onBlur()}
          onClick={() => handleClick()}
          style={{
            padding: '10px 10px',
            //width: cell.column.width + 'px', position: 'absolute',
            height: '100%',
          }}
        >
          <DataGridToolTip
            width={cell.column.width}
            tooltipValue={
              valueArray ? utcToLocalDate + valueArray[1] : utcToLocalDate
            }
          />
        </div>
      ) : (
        <div
          dangerouslySetInnerHTML={{ __html: '&nbsp;' }}
          onBlur={() => onBlur()}
          onClick={() => handleClick()}
        ></div>
      )}
    </React.Fragment>
  );
};

export default React.memo(DatePickerCell);
