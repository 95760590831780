import 'rc-menu/assets/index.css';
import { lazy } from 'react';
import { getIconDef } from './bootstrap-components/components/BTButton';

const TopHeader = lazy(
  () => import('./infoui-components/components/TopHeader')
);
const SplitButton = lazy(
  () => import('./infoui-components/components/SplitButton')
);
const TextBox = lazy(() => import('./infoui-components/components/TextBox'));
const PhoneNumberInput = lazy(
  () => import('./infoui-components/components/PhoneNumberInput')
);
const TextArea = lazy(() => import('./infoui-components/components/TextArea'));
const Skeletons = lazy(() => import('./infoui-components/components/Skeleton'));
const RadioButton = lazy(
  () => import('./infoui-components/components/RadioButton')
);
const CheckBox = lazy(() => import('./infoui-components/components/CheckBox'));
const LeftMenu = lazy(() => import('./infoui-components/components/LeftMenu'));
const Button = lazy(() => import('./infoui-components/components/Button'));
const Menu = lazy(() => import('./infoui-components/components/TopMenu'));
const SubMenu = lazy(() => import('./infoui-components/components/SubMenu'));
const SideMenu = lazy(() => import('./infoui-components/components/SideMenu'));
const MenuItem = lazy(() => import('./infoui-components/components/MenuItem'));
const SettingMenu = lazy(
  () => import('./infoui-components/components/SettingMenu')
);
const AppName = lazy(() => import('./infoui-components/components/AppName'));
const Table = lazy(() => import('./infoui-table-components/components/Table'));
const Tbody = lazy(() => import('./infoui-table-components/components/Tbody'));
const Td = lazy(() => import('./infoui-table-components/components/Td'));
const Tfoot = lazy(() => import('./infoui-table-components/components/Tfoot'));
const Th = lazy(() => import('./infoui-table-components/components/Th'));
const Thead = lazy(() => import('./infoui-table-components/components/Thead'));
const Tr = lazy(() => import('./infoui-table-components/components/Tr'));
const FullSizeModal = lazy(
  () => import('./infoui-components/components/FullSizeModal')
);
const Charts = lazy(() => import('./infoui-components/components/Charts'));
const StarRating = lazy(
  () => import('./infoui-components/components/StarRating')
);
const Loading = lazy(
  () => import('./infoui-components/components/LoadingComponent')
);
const DataSharingComponent = lazy(
  () => import('./infoui-components/components/DataSharingComponent')
);
const Form = lazy(() => import('./infoui-components/components/Form'));
const FormSection = lazy(
  () => import('./infoui-components/components/FormSection')
);
const DateTimePicker = lazy(
  () => import('./infoui-components/components/DateTimePicker')
);
const TabGroup = lazy(() => import('./infoui-components/components/Tab'));
const SelectOption = lazy(
  () => import('./infoui-components/components/SelectOption')
);
const Autocomplete = lazy(
  () => import('./infoui-components/components/Autocomplete')
);
const Modal = lazy(() => import('./infoui-components/components/Modal'));
const RightMenu = lazy(
  () => import('./infoui-components/components/RightMenu')
);
const Footer = lazy(() => import('./infoui-components/components/Footer'));
const InfoBootstrapTable = lazy(
  () => import('./infoui-bootstrap-table/components/BootstrapTable')
);
const TableHeaderColumnComponent = lazy(
  () => import('./infoui-bootstrap-table/components/TableHeaderColumnComponent')
);
const Languages = lazy(
  () => import('./infoui-components/components/Languages')
);
const ReactToast = lazy(
  () => import('./infoui-components/components/ReactToast')
);
const FormErrorContainers = lazy(
  () => import('./infoui-components/components/ListContainer')
);
const Profile = lazy(() => import('./infoui-components/components/Profile'));
const Attachment = lazy(
  () => import('./infoui-components/components/Attachment')
);
const Dropzone = lazy(() => import('./attachment/Attachment'));

const DraftEditor = lazy(
  () => import('./infoui-components/components/DraftEditor')
);
const AceEditor = lazy(
  () => import('./infoui-components/components/AceEditor')
);
const ReactToggleSwitch = lazy(
  () => import('./infoui-components/components/ReactToggleSwitch')
);
const ToggleSwitch = lazy(
  () => import('./infoui-components/components/ToggleSwitch')
);
const ProgressBar = lazy(
  () => import('./infoui-components/components/ProgressBar')
);
const Bar = lazy(() => import('./infoui-components/components/Bar'));
const CardsContainer = lazy(
  () => import('./infoui-components/components/CardsContainer')
);
const FloatingLabelInput = lazy(
  () => import('./infoui-components/components/FloatingLabelInput')
);
const InputMask = lazy(
  () => import('./infoui-components/components/InputMask')
);
const ToolTip = lazy(() => import('./infoui-components/components/ToolTip'));
const SparkLine = lazy(
  () => import('./infoui-components/components/SparkLine')
);
const CardGroup = lazy(
  () => import('./infoui-components/components/CardGroup')
);
const HeaderCard = lazy(
  () => import('./infoui-components/components/HeaderCard')
);
const StatusContainer = lazy(
  () => import('./infoui-components/components/StatusContainer')
);

const CardsAndFilter = lazy(
  () => import('./infoui-components/components/CardsAndFilter')
);
const CardWithCustomlist = lazy(
  () => import('./infoui-components/components/CardWithCustomList')
);
const BreadCrumb = lazy(
  () => import('./infoui-components/components/BreadCrumb')
);
const SingleRowColCard = lazy(
  () => import('./infoui-components/components/SingleRowColCard')
);
const TextContainer = lazy(
  () => import('./infoui-components/components/TextContainer')
);
const Accordion = lazy(
  () => import('./infoui-components/components/Accordion')
);

const LabelComponent = lazy(
  () => import('./infoui-components/components/LabelComponent')
);
const LabelLinkableComponent = lazy(
  () => import('./infoui-components/components/LabelLinkableComponent')
);
const ContextMenu = lazy(
  () => import('./infoui-components/components/ContextMenu')
);
const DataGridContextMenu = lazy(
  () => import('./infoui-components/components/DataGridContextMenu')
);
const Timeline = lazy(() => import('./infoui-components/components/Timeline'));
const FloatingButton = lazy(
  () => import('./infoui-components/components/FloatingButton')
);

const QueryBuilderData = lazy(()=>import('./infoui-components/components/QueryBuilderData'));

export {
  getIconDef,
  Modal,
  LeftMenu,
  Menu,
  SettingMenu,
  AppName,
  SubMenu,
  MenuItem,
  TopHeader,
  Table,
  Tbody,
  Td,
  Tfoot,
  Th,
  Thead,
  Tr,
  Button,
  SparkLine,
  Form,
  FormSection,
  DateTimePicker,
  TextBox,
  TextArea,
  RadioButton,
  TabGroup,
  SelectOption,
  Autocomplete,
  RightMenu,
  CheckBox,
  Skeletons,
  InfoBootstrapTable,
  Footer,
  TableHeaderColumnComponent,
  Languages,
  ReactToast,
  FormErrorContainers,
  Profile,
  FullSizeModal,
  Attachment,
  DraftEditor,
  AceEditor,
  Charts,
  ReactToggleSwitch,
  ProgressBar,
  Bar,
  StarRating,
  CardsContainer,
  ToggleSwitch,
  Loading,
  FloatingLabelInput,
  InputMask,
  ToolTip,
  HeaderCard,
  CardGroup,
  CardsAndFilter,
  SideMenu,
  BreadCrumb,
  CardWithCustomlist,
  StatusContainer,
  SingleRowColCard,
  TextContainer,
  Accordion,
  LabelLinkableComponent,
  LabelComponent,
  DataSharingComponent,
  ContextMenu,
  SplitButton,
  PhoneNumberInput,
  Timeline,
  FloatingButton,
  DataGridContextMenu,
  Dropzone,
  QueryBuilderData
};
