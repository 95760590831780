import React, { useCallback, useContext } from 'react';
import { injectIntl } from 'react-intl';
import { PhoneNumberInput, FormErrorContainers } from 'info-ui-library';
import { useDispatch } from 'react-redux';
import { ThemeContext } from '../theme/ThemeContext';
import { extractLabel } from '../utils/intlutils';
import { changeFormData } from '../actions/index';
import { useFormData } from '../custom-hooks/useFormData';
import { useErrorData } from '../custom-hooks/useErrorData';
import { IPhoneNumberInputCore } from './interfaces/index';
import { processErrorData } from './ComponentsCommonUtils';
import { useLocation } from 'react-router';

const PhoneNumberInputCore: React.FC<IPhoneNumberInputCore> = (
  props: IPhoneNumberInputCore
) => {
  const currentLocation = useLocation();

  const initialvalue = useFormData(
    props.formId,
    props.occuranceNumber,
    props.configProp.logicalColumn.dbCode,
    props.parentFormId,
    props.referralId
  );

  const errorData: any = useErrorData(props.location, props.referralId);

  const parsedError = processErrorData(errorData, props.intl.locale);

  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const handleChange = useCallback(
    (updatedValue: any) => {
      dispatch(
        changeFormData(
          props.formId,
          props.occuranceNumber,
          props.configProp.logicalColumn.dbCode,
          updatedValue ? updatedValue : '',
          props.parentFormId,
          props.mode,
          props.nodeId,
          props.configProp.configObjectId,
          props.configProp.refreshFormOnChange,
          props.configProp.resetOnRefresh,
          props.configProp.placeHolder,
          props.referralId,
          currentLocation
        )
      );
    },
    [currentLocation, props]
  );
  const handleBlur = useCallback(
    (updatedValue: any) => {
      if (updatedValue !== initialvalue) {
        dispatch(
          changeFormData(
            props.formId,
            props.occuranceNumber,
            props.configProp.logicalColumn.dbCode,
            updatedValue ? updatedValue : '',
            props.parentFormId,
            props.mode,
            props.nodeId,
            props.configProp.configObjectId,
            props.configProp.refreshFormOnChange,
            props.configProp.resetOnRefresh,
            props.configProp.placeHolder,
            props.referralId,
            currentLocation
          )
        );
      }
    },
    [currentLocation, props]
  );
  try {
    return (
      <PhoneNumberInput
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={themeContext.styleName}
        label={extractLabel(props.configProp.label, props.intl.locale)}
        key={props.configProp.configObjectId}
        value={initialvalue}
        displayType={themeContext.componentType}
        handleChange={handleChange}
        handleBlur={(updatedValue: any) => {
          handleBlur(updatedValue);
        }}
        errorMessage={parsedError}
        hasError={errorData ? true : false}
        mandatory={
          props.configProp.isMandatory && props.mandatoryIconAccessibilityRegex
        }
        disabled={!props.privilegesMap.isEditable}
        configId={props.configProp.configObjectId}
        occuranceNumber={props.occuranceNumber}
      />
    );
  } catch {
    const error = ['Error occurred in PhoneNumberInput'];
    return (
      <FormErrorContainers
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};

export default React.memo(injectIntl(PhoneNumberInputCore));
