import React from 'react';
import { Form, FormErrorContainers } from 'info-ui-library';
import { extractLabel } from '../utils/intlutils';
import { StyledDiv } from './PortalStyle';
import FormSectionGenerator from '../composite-entity/form/FormSectionGenerator';
import {
  isConfigObjectAccessible,
  isConfigObjectEditable,
} from '../composite-entity/form/CardGenerators';
import { NOPRIVILEGE, EDIT } from '../constants/appeng.enum';
import { getPrivilege } from '../utils/ClientUtils';

export const getFormValidationMessage = (
  location: any,
  errorDataFromStore: any,
  locale: any
) => {
  return extractLabel(
    errorDataFromStore.errorData.formValidation[location],
    locale
  );
};

export const getComponent = (
  propsToPass: any,
  formPrivilege: any,
  isFormEditable: any,
  themeContext: any,
  existingErrorData: any,
  locale: any,
  userContext: any
) => {
  let errorData: string[] = [];

  if (
    existingErrorData.errorData &&
    Object.entries(existingErrorData.errorData.formValidation).length > 0
  ) {
    errorData.push(
      getFormValidationMessage(
        propsToPass.formId + '=>' + 0,
        existingErrorData,
        locale
      )
    );
  }
  let error;
  if (errorData.length > 0) {
    error = (
      <FormErrorContainers
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={themeContext.styleName}
        lists={errorData}
      />
    );
  }
  return (
    <React.Fragment>
      <StyledDiv
        styleName={themeContext.styleName}
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
      >
        {error}
        <Form
          id={propsToPass.parentForm.configObjectId}
          styleName={themeContext.styleName}
          ioTheme={themeContext.ioTheme}
          ioMode={themeContext.ioMode}
        >
          <FormSectionGenerator
            mode={propsToPass.mode}
            parentFormId={undefined}
            formId={propsToPass.parentForm.configObjectId}
            formSections={propsToPass.parentForm.formSections}
            occuranceNumber={0}
            nodeId={''}
            formPrivilege={formPrivilege}
            isFormEditable={isFormEditable}
            parentEntityId={''}
            referralId={propsToPass.referralId}
          />
        </Form>
      </StyledDiv>
    </React.Fragment>
  );
};

export const processPortalPrivilege = (
  userContext: any,
  props: any,
  formData: any
) => {
  let isFormAccessible = true;
  let isFormEditable = true;
  let formPrivilege = EDIT;
  const currentRole = userContext.getCurrentRole();
  formPrivilege = getPrivilege(props.parentForm.privileges, currentRole);
  if (formPrivilege !== NOPRIVILEGE && props.parentForm.expressionAvailable) {
    let updatedFormData = { ...formData };
    Object.assign(updatedFormData, userContext.getUserDetail());
    isFormAccessible = isConfigObjectAccessible(
      props.parentForm.accessibilityRegex,
      updatedFormData
    );
    isFormEditable = isConfigObjectEditable(
      isFormAccessible,
      formPrivilege,
      props.parentForm.editabilityRegex,
      updatedFormData,
      formPrivilege,
      isFormEditable
    );
  }
  return { formPrivilege, isFormAccessible, isFormEditable };
};

export const parseReferenceData = (
  portalDataSharing: any,
  cardLayout: any,
  subComponentId: string
) => {
  let referenceData = '';

  if (portalDataSharing) {
    let portalDataSharings = portalDataSharing.split(';');

    for (let portaldatasharing of portalDataSharings) {
      if (portaldatasharing) {
        let portalDataSharingList = portaldatasharing.split(',');

        if (
          cardLayout.configObjectId ==
          subComponentId + '_' + portalDataSharingList[2].split(':')[1]
        ) {
          if (referenceData == '') {
            referenceData =
              subComponentId +
              '_' +
              portalDataSharingList[0].split(':')[1] +
              '&' +
              portalDataSharingList[1].split(':')[1] +
              '&' +
              portalDataSharingList[3].split(':')[1];
          } else {
            referenceData =
              referenceData +
              ';' +
              subComponentId +
              '_' +
              portalDataSharingList[0].split(':')[1] +
              '&' +
              portalDataSharingList[1].split(':')[1] +
              '&' +
              portalDataSharingList[3].split(':')[1];
          }
        } else if (
          cardLayout.configObjectId == portalDataSharingList[2].split(':')[1]
        ) {
          if (referenceData == '') {
            referenceData =
              portalDataSharingList[0].split(':')[1] +
              '&' +
              portalDataSharingList[1].split(':')[1] +
              '&' +
              portalDataSharingList[3].split(':')[1];
          } else {
            referenceData =
              referenceData +
              ';' +
              portalDataSharingList[0].split(':')[1] +
              '&' +
              portalDataSharingList[1].split(':')[1] +
              '&' +
              portalDataSharingList[3].split(':')[1];
          }
        }
      }
    }
  }

  return referenceData;
};
