import React, { useContext } from 'react';
import { FormErrorContainers } from 'info-ui-library';
import { QueryType } from '../constants/appeng.enum';
import SkeletonCore from '../components/SkeletonCore';
import { useConfigQuery } from '../custom-hooks/useConfigQuery';
import { ThemeContext } from '../theme/ThemeContext';

import PortalGridGenerator from './PortalGridGenerator';
const WrapperPortalGridCard: React.FC<any> = (props: any) => {
  const themeContext = useContext(ThemeContext);

  const { loading, error, data } = useConfigQuery(
    QueryType.DATA_GRID_QUERY,
    props.portalGrid.gridId
  );

  if (loading) return <SkeletonCore count={1} />;
  try {
    return <PortalGridGenerator {...props} compositeEntityNodeData={data} />;
  } catch {
    return (
      <FormErrorContainers
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};

function isPropsAreEqual(prevProps: any, nextProps: any) {
  return (
    JSON.stringify(prevProps.referenceData) ===
      JSON.stringify(nextProps.referenceData) &&
    prevProps.editableGridEnable === nextProps.editableGridEnable
  );
}
export default React.memo(WrapperPortalGridCard, isPropsAreEqual);
