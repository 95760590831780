import { IPortalGridDetails } from '../interface/index';
export const processPortalDatagridDataParser = (data: any, props: any) => {
  let datagridDetails: any = {
    ceId: data.PortalDataGrid.ceId,
    gridId: data.PortalDataGrid.dataGridId,
    nodeId: data.PortalDataGrid.nodeId,
    portalGridData: {
      componentId: props.componentId,
      referenceData: props.referenceData,
      referralId: props.referralId,
      refreshCardData: props.refreshCardData,
    },
    subPortals: data.PortalDataGrid.subPortals,
  };
  return datagridDetails;
};
