import gql from 'graphql-tag';

export const Widget = gql`
  query Widget($id: ID!) {
    Widget(id: $id) {
      configObjectId
      widgetType
      name
      configObjectType
      datasetquery
      widgetClickable
      defaultSelected
      filterOption
      widgetStyle
      filterRequired
      searchRequired
      cardsPerRow
      dataParser
      xlabel
      formId
      chartClickOnRefresh
      ylabel
      childRelations {
        relationType
        parentItemId
        childItemId
      }
      rows {
        order
        accessibilityRegex
        columns {
          accessibilityRegex
          order
          columnAlignment
          privileges {
            privilegeId
            privilegeType
            itemId
            roleId
          }
          formfields {
            mandatoryIconAccessibilityRegex
            formatDate
            isApplyDateFormat
            entityName
            parentDBCode
            dbCode
            icon
            layout
            fontSize
            hasSupportingLabel
            supportingLabel
            supportingLabelFontSize
            hasValueInSupportingLabel
            label
            label
            order
            defaultValue
            colspan
            columnDataPreprocessors {
              configObjectId
              name
              configObjectType
              preProcessorBean
              isMultiValue
              excecutionType
              jsCode
              actionFlows {
                configObjectId
                name
                configObjectType
              }
            }
            type
            multivalueList
            resetOnRefresh
            isMandatory
            selectItemsReferenceID
            placeHolder
            expressionAvailable
            refreshFormOnChange
            accessibilityRegex
            editabilityRegex
            configObjectId
            showYearDropdown
            showMonthDropdown
            maxDate
            minDate
            dropdownMode
            timeFormat
            showTimeSelect
            showTimeSelectOnly
            timeCaption
            timeIntervals

            privileges {
              privilegeId
              privilegeType
              itemId
              roleId
            }
            name
            configObjectType
            itemDescription
            projectId
            logicalColumn {
              isPrimaryKey
              dataType
              isVerticalFieldKey
              dbCode
              length
              dbType
              isMandatory
              jsonName
              mode
              isUnique
              isDerived
              configObjectId
              name
              configObjectType
              projectId
            }
          }
          buttons {
            label
            order
            buttonClass
            warningMessage
            icon
            iconFirst
            portalId
            dbCode
            buttonAlignment
            accessibilityRegex
            editabilityRegex
            expressionAvailable
            configObjectId
            name
            configObjectType
            projectId
            modalCss
            transactionName
            childRelations {
              relationType
              parentItemId
              childItemId
            }
            privileges {
              privilegeId
              privilegeType
              itemId
              roleId
            }
          }
          menuButtons {
            defaultType
            menuLabel
            position
            buttonClass
            accessibilityRegex
            editabilityRegex
            expressionAvailable
            order
            transactionName
            modalRequired
            portalId
            configObjectId
            name
            configObjectType
            privileges {
              privilegeId
              privilegeType
              itemId
              roleId
            }
            childRelations {
              relationType
              parentItemId
              childItemId
            }
            buttons {
              label
              order
              buttonClass
              portalId
              buttonAlignment
              accessibilityRegex
              editabilityRegex
              expressionAvailable
              modalRequired
              configObjectId
              name
              configObjectType
              projectId
              modalCss
              transactionName
              childRelations {
                relationType
                parentItemId
                childItemId
              }
              privileges {
                privilegeId
                privilegeType
                itemId
                roleId
              }
            }
          }
        }
      }
    }
  }
`;
