import {
  ADD_MODALS_SAGA,
  IAddModalsSaga,
  addModalsSaga,
  ADD_MODALS,
  IAddModals,
  addModals,
  CLOSE_MODALS,
  ICloseModals,
  closeModals,
  CLOSE_MODALS_SAGA,
  ICloseModalsSaga,
  closeModalsSaga,
  SET_OPEN_MODAL,
  IOpenModal,
  setOpenModal,
  SET_OPEN_MODAL_SAGA,
  IOpenModalSaga,
  setOpenModalSaga,
  ADD_MODAL_RELOAD,
  IAddModalReload,
  addModalReload,
  RELOAD_CLEAN_MODAL_DATA,
  IReloadCleanModalData,
  reloadCleanModalData,
} from './modal/modal';

import {
  UPLOAD_ATTACHMENT,
  IUploadAttachment,
  uploadAttachment,
  DELETE_ATTACHMENT,
  IDeleteAttachment,
  deleteAttachment,
  FETCH_ATTACHMENT,
  IFetchAttachment,
  fetchAttachment,
  SAVE_ATTACHMENT,
  ISaveAttachment,
  saveAttachment,
  REMOVE_ATTACHMENT,
  IRemoveAttachment,
  removeAttachment,
  CHANGE_ATTACHMENT_TYPE,
  IChangeAttachmentType,
  changeAttachmentType,
  DOWNLOAD_ATTACHMENT,
  IDownloadAttachment,
  downloadAttachment,
} from './attachment/attachment';

import {
  GET_GRID_DATA,
  IGetGridData,
  getGridData,
  SET_GRID_DATA,
  ISetGridData,
  setGridData,
  GET_PORTAL_GRID_SELECTED_ROW_DATA,
  IGetPortalGridSelectedRowData,
  getPortalGridSelectedRowData,
  SET_PORTAL_GRID_SELECTED_ROW_DATA,
  ISetPortalGridSelectedRowData,
  setPortalGridSelectedRowData,
  DOWNLOAD_GRID_REPORT,
  IDownloadGridReport,
  downloadGridReport,
  SET_GRID_PAGE_INDEX,
  ISetGridPageIndex,
  setGridPageIndex,
  SET_EDITABLE_GRID_STATUS,
  IEditableGridStatus,
  setEditableGridStatus,
  SET_EDITABLE_GRID_STATUS_SAGA,
  IEditableGridStatusSaga,
  setEditableGridStatusSaga,
  ADD_ROW_IN_GRID,
  IAddRowInGrid,
  addRowInGrid,
  REMOVE_ROW_FROM_GRID,
  IRemoveRowFromGrid,
  removeRowFromGrid,
  SET_FILTER_GRID_STATUS,
  setGridFilterStatus,
  GET_GRID_COLUMN_OPTIONS,
  IGetGridColumnOptions,
  getGridColumnOptions,
  DOWNLOAD_REPORT,
  IDownloadReport,
  downloadReport,
  UPDATE_INLINE_RECORD,
  IUpdateInlineRecord,
  updateInlineRecord,
} from './dataGrid/dataGrid';

import {
  GATEWAY_TO_GET_FORMDATA,
  IGateWayToGetFormData,
  gateWayToGetFormData,
  GATEWAY_FOR_UPSERT_OPERATION,
  IGateWayForUpsertOperation,
  gateWayForUpsertOperation,
} from './gateWay/gateWay';

import {
  SET_FORM_DATA,
  ISetFormData,
  setFormData,
  CHANGE_FORM_DATA,
  IChangeFormData,
  changeFormData,
  GET_FORM_DATA,
  IGetFormData,
  getFormData,
  GET_REPORT_FORM_DATA,
  IReportFormData,
  getReportFormData,
  GET_FORMDATA_USING_FORMCONFIG,
  IGetFormDataUsingFormConfig,
  getFormDataUsingFormConfig,
  UPSERT_FORM_RECORD,
  IUpsertFormRecord,
  upsertFormRecord,
  SET_ROUTER_FORM_DATA,
  ISetRouterFormData,
  setRouterAndFormData,
  UPSERT_ENTITY_RECORD,
  IUpsertEntityRecord,
  upsertEntityRecord,
  UPSERT_ENTITY_USING_LE,
  IUpsertEntityUsingLE,
  upsertEntityUsingLE,
  HANDLE_REPEATABLE,
  IHandleRepeatable,
  handleRepeatable,
  DELETE_RECORD,
  IDeleteRecord,
  deleteRecord,
  EXECUTE_ACTION_FLOW,
  IExecuteActionFlow,
  executeActionFlow,
  GET_OPTIONS,
  IGetOption,
  getOptions,
  SET_OPTIONS,
  ISetOption,
  setOptions,
  SET_OPTIONS_IN_BULK,
  ISetOptionInBulk,
  setOptionsInBulk,
  CUSTOM_FILTER,
  ICustomFilter,
  customFilter,
  SUBMIT_ACTION,
  ISubmitAction,
  submitAction,
} from './form/form';

import {
  SET_LEFT_MENU_INDICATOR,
  ISetLeftMenuIndicator,
  setLeftMenuIndicator,
} from './menu/menu';

import {
  GET_CHART_DATA,
  IGetChartData,
  getChartData,
  SET_CHART_DATA,
  ISetChartData,
  setChartData,
} from './chart/chart';

import {
  UPDATE_PORTAL_CARD,
  IUpdatePortalCard,
  updatePortalCard,
  SET_PORTAL_DETAILS,
  IsetPortalDetails,
  setPortalDetails,
  SET_PORTAL_LAYOUT,
  ISetPortalLayout,
  setPortalLayout,
  HANDLE_PORTAL_LAYOUT_CHANGE,
  IHandlePortalLayoutChange,
  handlePortalLayoutChange,
  GET_OPTIONS_IN_PORTAL_CARD,
  IGetOptionsInPortalCard,
  getOptionsInPortalCard,
  HANDLE_RELOAD,
  IHandleReload,
  handleReload,
  RESET_CARD_DATA,
  IResetCardData,
  resetCardData,
  RESET_CARD_REFERENCE_DATA,
  IResetCardReferenceData,
  resetCardReferenceData,
  REMOVE_CARD_DATA_BY_REFERRAL_ID,
  IRemoveCardDataByReferralId,
  removeCardDataByReferralId,
  REMOVE_ACCORDION_CARDS,
  IRemoveAccordionCards,
  removeAccordionCards,
} from './portalAndPortalCard/portalAndportalCard';

import {
  ACTION_FLOW_ROUTING,
  IActionFlowRouting,
  actionFlowRouting,
  GO_TO_PREVIOUS_ROUTE,
  IGoToPreviousRoute,
  goToPreviousRoute,
} from './actionFlowRouting/actionFlowRouting';

import {
  GET_USER_PROFILE,
  IGetUserProfile,
  getUserProfile,
  SET_USER_PROFILE,
  ISetUserProfile,
  setUserProfile,
  SWITCH_LANGUAGE,
  ISwitchLanguage,
  switchLanguage,
  SET_CURRENT_FINANCIAL_YEAR,
  ISetCurrentFinancialYear,
  setCurrentFinancialYear,
  SWITCH_PROJECT,
  ISwicthProject,
  switchProject,
  LOG_OUT,
  ILogout,
  logOut,
  CHANGE_USER_CONTEXT_KEY,
  IGetChangeUserContextKey,
  changeUserContextKey,
} from './userContext/userContext';

import {
  SET_TOGGLE_MESSAGES,
  ISetToggleMessages,
  setToggleMessages,
  SET_WARNING_MESSAGES,
  IWarningMessages,
  setWarningMessages,
} from './upsertMessage/upsertMessage';

import {
  SET_TREE_DATA,
  ISetTreeData,
  setTreeData,
} from './treeStructure/treeStructure';

import {
  SET_CURRENT_ROOT_NODE,
  ISetRootNode,
  setCurrentRootNode,
  SET_ERROR_DATA,
  ISetErrorData,
  setErrorData,
  SET_STORE,
  ISetStore,
  setStore,
  SET_FILE_DROPPABLE_INDICATOR,
  ISetFileDroppableIndicator,
  setFileDroppableIndicator,
  GET_MY_TASK_DATA,
  IGetMyProcessInstanceData,
  getMyTaskData,
  GET_MY_PROCESS_INSTANCE_DATA,
  IGetMyTaskData,
  getMyProcessInstanceData,
  GET_SEND_FEEDBACK_DATA,
  IGetSendFeedbackData,
  getSendFeedBackData,
  UPDATE_WORKFLOW_STATUS,
  IUpdateWorkflowStatus,
  updateWorkflowStatus,
  GET_CONFIGURATION_STRUCTURE,
  IGetConfiguarationStructure,
  getConfiguarationStructure,
} from './basicAction/basicAction';

export {
  ADD_MODALS_SAGA,
  addModalsSaga,
  ADD_MODALS,
  addModals,
  CLOSE_MODALS,
  closeModals,
  CLOSE_MODALS_SAGA,
  closeModalsSaga,
  SET_OPEN_MODAL,
  setOpenModal,
  SET_OPEN_MODAL_SAGA,
  setOpenModalSaga,
  ADD_MODAL_RELOAD,
  addModalReload,
  RELOAD_CLEAN_MODAL_DATA,
  reloadCleanModalData,
  UPLOAD_ATTACHMENT,
  uploadAttachment,
  DELETE_ATTACHMENT,
  deleteAttachment,
  FETCH_ATTACHMENT,
  fetchAttachment,
  SAVE_ATTACHMENT,
  saveAttachment,
  REMOVE_ATTACHMENT,
  removeAttachment,
  CHANGE_ATTACHMENT_TYPE,
  changeAttachmentType,
  DOWNLOAD_ATTACHMENT,
  downloadAttachment,
  GET_GRID_DATA,
  getGridData,
  SET_GRID_DATA,
  setGridData,
  GET_PORTAL_GRID_SELECTED_ROW_DATA,
  getPortalGridSelectedRowData,
  SET_PORTAL_GRID_SELECTED_ROW_DATA,
  setPortalGridSelectedRowData,
  DOWNLOAD_GRID_REPORT,
  downloadGridReport,
  SET_GRID_PAGE_INDEX,
  setGridPageIndex,
  SET_EDITABLE_GRID_STATUS,
  setEditableGridStatus,
  SET_EDITABLE_GRID_STATUS_SAGA,
  setEditableGridStatusSaga,
  ADD_ROW_IN_GRID,
  addRowInGrid,
  REMOVE_ROW_FROM_GRID,
  removeRowFromGrid,
  SET_FILTER_GRID_STATUS,
  setGridFilterStatus,
  GET_GRID_COLUMN_OPTIONS,
  getGridColumnOptions,
  GATEWAY_TO_GET_FORMDATA,
  gateWayToGetFormData,
  GATEWAY_FOR_UPSERT_OPERATION,
  gateWayForUpsertOperation,
  SET_FORM_DATA,
  setFormData,
  CHANGE_FORM_DATA,
  changeFormData,
  GET_FORM_DATA,
  getFormData,
  GET_REPORT_FORM_DATA,
  getReportFormData,
  GET_FORMDATA_USING_FORMCONFIG,
  getFormDataUsingFormConfig,
  UPSERT_FORM_RECORD,
  upsertFormRecord,
  SET_ROUTER_FORM_DATA,
  setRouterAndFormData,
  SET_LEFT_MENU_INDICATOR,
  setLeftMenuIndicator,
  UPSERT_ENTITY_RECORD,
  upsertEntityRecord,
  UPSERT_ENTITY_USING_LE,
  upsertEntityUsingLE,
  GET_CHART_DATA,
  getChartData,
  SET_CHART_DATA,
  setChartData,
  HANDLE_REPEATABLE,
  handleRepeatable,
  DELETE_RECORD,
  deleteRecord,
  UPDATE_PORTAL_CARD,
  updatePortalCard,
  SET_PORTAL_DETAILS,
  setPortalDetails,
  SET_PORTAL_LAYOUT,
  setPortalLayout,
  HANDLE_PORTAL_LAYOUT_CHANGE,
  handlePortalLayoutChange,
  GET_OPTIONS_IN_PORTAL_CARD,
  getOptionsInPortalCard,
  HANDLE_RELOAD,
  handleReload,
  RESET_CARD_DATA,
  resetCardData,
  RESET_CARD_REFERENCE_DATA,
  resetCardReferenceData,
  REMOVE_CARD_DATA_BY_REFERRAL_ID,
  removeCardDataByReferralId,
  REMOVE_ACCORDION_CARDS,
  removeAccordionCards,
  ACTION_FLOW_ROUTING,
  actionFlowRouting,
  EXECUTE_ACTION_FLOW,
  executeActionFlow,
  GET_OPTIONS,
  getOptions,
  SET_OPTIONS,
  setOptions,
  SET_OPTIONS_IN_BULK,
  setOptionsInBulk,
  GO_TO_PREVIOUS_ROUTE,
  goToPreviousRoute,
  DOWNLOAD_REPORT,
  downloadReport,
  CUSTOM_FILTER,
  customFilter,
  UPDATE_INLINE_RECORD,
  updateInlineRecord,
  GET_USER_PROFILE,
  getUserProfile,
  SET_USER_PROFILE,
  setUserProfile,
  SWITCH_LANGUAGE,
  switchLanguage,
  SET_CURRENT_FINANCIAL_YEAR,
  setCurrentFinancialYear,
  SWITCH_PROJECT,
  switchProject,
  LOG_OUT,
  logOut,
  CHANGE_USER_CONTEXT_KEY,
  changeUserContextKey,
  SET_TOGGLE_MESSAGES,
  setToggleMessages,
  SET_WARNING_MESSAGES,
  setWarningMessages,
  SUBMIT_ACTION,
  submitAction,
  SET_TREE_DATA,
  setTreeData,
  SET_CURRENT_ROOT_NODE,
  setCurrentRootNode,
  SET_ERROR_DATA,
  setErrorData,
  SET_STORE,
  setStore,
  SET_FILE_DROPPABLE_INDICATOR,
  setFileDroppableIndicator,
  GET_MY_TASK_DATA,
  getMyTaskData,
  GET_MY_PROCESS_INSTANCE_DATA,
  getMyProcessInstanceData,
  GET_SEND_FEEDBACK_DATA,
  getSendFeedBackData,
  UPDATE_WORKFLOW_STATUS,
  updateWorkflowStatus,
  GET_CONFIGURATION_STRUCTURE,
  getConfiguarationStructure,
};

export type {
  IAddModalsSaga,
  IAddModals,
  ICloseModals,
  ICloseModalsSaga,
  IOpenModal,
  IOpenModalSaga,
  IAddModalReload,
  IReloadCleanModalData,
  IUploadAttachment,
  IDeleteAttachment,
  IFetchAttachment,
  ISaveAttachment,
  IRemoveAttachment,
  IChangeAttachmentType,
  IDownloadAttachment,
  IGetGridData,
  ISetGridData,
  IGetPortalGridSelectedRowData,
  ISetPortalGridSelectedRowData,
  IDownloadGridReport,
  ISetGridPageIndex,
  IEditableGridStatus,
  IEditableGridStatusSaga,
  IAddRowInGrid,
  IRemoveRowFromGrid,
  IGetGridColumnOptions,
  IGateWayToGetFormData,
  IGateWayForUpsertOperation,
  ISetFormData,
  IChangeFormData,
  IGetFormData,
  IReportFormData,
  IGetFormDataUsingFormConfig,
  IUpsertFormRecord,
  ISetRouterFormData,
  ISetLeftMenuIndicator,
  IUpsertEntityRecord,
  IUpsertEntityUsingLE,
  IGetChartData,
  ISetChartData,
  IHandleRepeatable,
  IDeleteRecord,
  IUpdatePortalCard,
  IsetPortalDetails,
  ISetPortalLayout,
  IHandlePortalLayoutChange,
  IGetOptionsInPortalCard,
  IHandleReload,
  IResetCardData,
  IResetCardReferenceData,
  IRemoveCardDataByReferralId,
  IRemoveAccordionCards,
  IActionFlowRouting,
  IExecuteActionFlow,
  IGetOption,
  ISetOption,
  ISetOptionInBulk,
  IGoToPreviousRoute,
  IDownloadReport,
  ICustomFilter,
  IUpdateInlineRecord,
  IGetUserProfile,
  ISetUserProfile,
  ISwitchLanguage,
  ISetCurrentFinancialYear,
  ISwicthProject,
  ILogout,
  IGetChangeUserContextKey,
  ISetToggleMessages,
  IWarningMessages,
  ISubmitAction,
  ISetTreeData,
  ISetRootNode,
  ISetErrorData,
  ISetStore,
  ISetFileDroppableIndicator,
  IGetMyProcessInstanceData,
  IGetMyTaskData,
  IGetSendFeedbackData,
  IUpdateWorkflowStatus,
  IGetConfiguarationStructure,
};

export const NAVIGATION_COMPLETED = 'NAVIGATION_COMPLETED';
export const NAVIGATION_REQUESTED = 'NAVIGATION_REQUESTED';

export function setNavigationCompleted() {
  return {
    type: NAVIGATION_COMPLETED,
  };
}
