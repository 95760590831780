import { CardData } from '../../models';

export const GET_GRID_DATA = 'GET_GRID_DATA';
export const SET_GRID_DATA = 'SET_GRID_DATA';
export const GET_PORTAL_GRID_SELECTED_ROW_DATA =
  'GET_PORTAL_GRID_SELECTED_ROW_DATA';
export const SET_PORTAL_GRID_SELECTED_ROW_DATA =
  'SET_PORTAL_GRID_SELECTED_ROW_DATA';
export const DOWNLOAD_GRID_REPORT = 'DOWNLOAD_GRID_REPORT';
export const SET_GRID_PAGE_INDEX = 'SET_GRID_PAGE_INDEX';
export const SET_EDITABLE_GRID_STATUS = 'SET_EDITABLE_GRID_STATUS';
export const SET_EDITABLE_GRID_STATUS_SAGA = 'SET_EDITABLE_GRID_STATUS_SAGA';
export const ADD_ROW_IN_GRID = 'ADD_ROW_IN_GRID';
export const REMOVE_ROW_FROM_GRID = 'REMOVE_ROW_FROM_GRID';
export const SET_FILTER_GRID_STATUS = 'SET_FILTER_GRID_STATUS';
export const GET_GRID_COLUMN_OPTIONS = 'GET_GRID_COLUMN_OPTIONS';
export const DOWNLOAD_REPORT = 'DOWNLOAD_REPORT';
export const UPDATE_INLINE_RECORD = 'UPDATE_INLINE_RECORD';

export interface IGetGridData {
  type: string;
  gridId: string;
  data: any;
  referralId: string;
  resetOtherGrids: boolean;
  parentId: string;
  portalId: string;
  tabRefresh?: boolean;
  path?: string;
  pageIndex?: number;
  pageSize?: number;
}

export interface ISetGridData {
  type: string;
  cardData: CardData;
  resetOtherGrids: boolean;
  isReloadRequired: boolean;
}

export interface IGetPortalGridSelectedRowData {
  type: string;
  cardId: string;
  data: any;
  usePortalReferenceData?: boolean;
  parentId?: any;
  portalId?: any;
}

export interface ISetPortalGridSelectedRowData {
  type: string;
  cardData: any;
  cardId: string;
}

export interface IDownloadGridReport {
  type: string;
  primaryKey: string;
  templateName: string;
  currentLanguage: string;
  primaryDBCode: string;
  location: any;
}

export interface ISetGridPageIndex {
  type: string;
  referralId: string;
  pageIndex: number;
  isRemove: boolean;
}

export interface IEditableGridStatus {
  type: string;
  cardId: string;
  cardData: any;
}

export interface IEditableGridStatusSaga {
  type: string;
  cardId: string;
  isEditEnable?: boolean;
}

export interface IAddRowInGrid {
  type: string;
  cardId: string;
  gridId: string;
}

export interface IRemoveRowFromGrid {
  type: string;
  rowIndex: number;
  cardId: string;
  path?: string;
}

export interface IGetGridColumnOptions {
  type: string;
  parentFormId: string;
  formId: string;
  occuranceNumber: number;
  formFieldConfiData: any;
  nodeId: string;
  referralId: string;
  location: any;
  referenceData: any;
}

export interface IDownloadReport {
  type: string;
  formId: string | undefined;
  location: any;
}

export interface IUpdateInlineRecord {
  type: string;
  primaryKey: string;
  logicalEntityId: string;
  nodeId: string;
  ceId: string;
  data: any;
  referralId: string;
  actionName: string;
  setSelectedPath: any;
  datagrid: any;
}

export function getGridData(
  gridId: string,
  data: any,
  referralId: string,
  resetOtherGrids: boolean,
  parentId: string,
  portalId: string,
  tabRefresh?: boolean,
  path?: string,
  pageIndex?: number,
  pageSize?: number
): IGetGridData {
  return {
    type: GET_GRID_DATA,
    gridId: gridId,
    data: data,
    referralId: referralId,
    resetOtherGrids: resetOtherGrids,
    parentId: parentId,
    portalId: portalId,
    tabRefresh: tabRefresh,
    path: path,
    pageIndex: pageIndex,
    pageSize: pageSize
  };
}

export function setGridData(
  cardData: CardData,
  resetOtherGrids: boolean,
  isReloadRequired: boolean
): ISetGridData {
  return {
    type: SET_GRID_DATA,
    cardData: cardData,
    resetOtherGrids: resetOtherGrids,
    isReloadRequired: isReloadRequired,
  };
}

export function getPortalGridSelectedRowData(
  cardId: string,
  data: any,
  usePortalReferenceData?: boolean,
  parentId?: string,
  portalId?: string
): IGetPortalGridSelectedRowData {
  return {
    type: GET_PORTAL_GRID_SELECTED_ROW_DATA,
    cardId: cardId,
    data: data,
    usePortalReferenceData: usePortalReferenceData,
    parentId: parentId,
    portalId: portalId,
  };
}

export function setPortalGridSelectedRowData(
  cardData: any,
  cardId: string
): ISetPortalGridSelectedRowData {
  return {
    type: SET_PORTAL_GRID_SELECTED_ROW_DATA,
    cardData: cardData,
    cardId: cardId,
  };
}

export const downloadGridReport = (
  primaryKey: string,
  templateName: string,
  currentLanguage: string,
  primaryDBCode: string,
  location: any
): IDownloadGridReport => {
  return {
    type: DOWNLOAD_GRID_REPORT,
    primaryKey: primaryKey,
    templateName: templateName,
    currentLanguage: currentLanguage,
    primaryDBCode: primaryDBCode,
    location: location,
  };
};

export const setGridPageIndex = (
  referralId: string,
  pageIndex: number,
  isRemove: boolean
): ISetGridPageIndex => {
  return {
    type: SET_GRID_PAGE_INDEX,
    referralId: referralId,
    pageIndex: pageIndex,
    isRemove: isRemove,
  };
};

export function setEditableGridStatus(
  cardId: string,
  cardData: any
): IEditableGridStatus {
  return {
    type: SET_EDITABLE_GRID_STATUS,
    cardId: cardId,
    cardData: cardData,
  };
}

export function setEditableGridStatusSaga(
  cardId: string,
  isEditEnable?: boolean
): IEditableGridStatusSaga {
  return {
    type: SET_EDITABLE_GRID_STATUS_SAGA,
    cardId: cardId,
    isEditEnable: isEditEnable,
  };
}

export function addRowInGrid(cardId: string, gridId: string): IAddRowInGrid {
  return {
    type: ADD_ROW_IN_GRID,
    cardId: cardId,
    gridId: gridId,
  };
}

export function removeRowFromGrid(
  rowIndex: number,
  cardId: string,
  path?: string
): IRemoveRowFromGrid {
  return {
    type: REMOVE_ROW_FROM_GRID,
    rowIndex: rowIndex,
    cardId: cardId,
    path: path,
  };
}

export function setGridFilterStatus(
  cardId: string,
  isEditEnable?: boolean
): IEditableGridStatusSaga {
  return {
    type: SET_FILTER_GRID_STATUS,
    cardId: cardId,
    isEditEnable: isEditEnable,
  };
}

export function getGridColumnOptions(
  parentFormId: string,
  formId: string,
  formFieldConfiData: any,
  occuranceNumber: number,
  nodeId: string,
  referralId: string,
  location: any,
  referenceData?: any
): IGetGridColumnOptions {
  return {
    type: GET_GRID_COLUMN_OPTIONS,
    parentFormId: parentFormId,
    formId: formId,
    formFieldConfiData: formFieldConfiData,
    occuranceNumber: occuranceNumber,
    nodeId: nodeId,
    referralId: referralId,
    location: location,
    referenceData: referenceData,
  };
}

export const downloadReport = (
  formId: string | undefined,
  location: any
): IDownloadReport => {
  return {
    type: DOWNLOAD_REPORT,
    formId: formId,
    location: location,
  };
};

export const updateInlineRecord = (
  primaryKey: string,
  logicalEntityId: string,
  nodeId: string,
  ceId: string,
  data: any,
  referralId: string,
  actionName: string,
  setSelectedPath?: any,
  datagrid?: any
): IUpdateInlineRecord => {
  return {
    type: UPDATE_INLINE_RECORD,
    primaryKey: primaryKey,
    logicalEntityId: logicalEntityId,
    nodeId: nodeId,
    ceId: ceId,
    data: data,
    referralId: referralId,
    actionName: actionName,
    setSelectedPath: setSelectedPath,
    datagrid: datagrid,
  };
};
