export const refreshAwsToken = async (config: any) => {
  const { fetchAuthSession } = await import('aws-amplify/auth');
  const session: any = await fetchAuthSession();
  if (config.storage.type === 'session') {
    sessionStorage.setItem(
      'REFRESH_AUTH_TOKEN',
      session.tokens.accessToken.toString()
    );
  } else if (config.storage.type === 'window') {
    localStorage.setItem(
      'REFRESH_AUTH_TOKEN',
      session.tokens.accessToken.toString()
    );
  }
};
