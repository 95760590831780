import { useState, useContext } from 'react';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { InfoAuth } from '../auth';
import { toast, ToastContainer } from 'react-toastify';
import { FloatingLabelInput } from './FloatingLabelInput';
import ErrorMsgComponent from './ErrorMessageComponent';
import CommonHeader from './CommonHeader';
import { FormattedMessage, injectIntl } from 'react-intl';
import ReactPasswordStrength from './ReactPasswordStrength';
import styled from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';
import {
  ChangePassTitleSpanContainer,
  CustomButton,
  ForgotPasswordContainer,
  Form,
  LinkComponent,
  LinkSpan,
  ParentWrapperContainer,
  RedirectContainer,
  WrapperContainer,
} from './ForgotPasswordStyle';
import { LanguageContext } from './App';
const img = require('../greenBackground.jpg');

interface IResetPassword {
  locale: any;
  email: string;
  setMessages: string;
  message: any;
  intl: any;
}

const ToastStyle = styled.div`
  background-color: rgb(227, 252, 239) !important;
  box-shadow: rgba(0, 0, 0, 0.176) 0px 3px 8px !important;
  color: rgb(0, 102, 68) !important;
  transform: translate3d(0px, 0px, 0px);
  border-radius: 4px;
  border-left: 5px solid rgb(54, 179, 126) !important;
  transition: transform 220ms cubic-bezier(0.2, 0, 0, 1) 0s;
`;

const ResetPassword: React.FC<IResetPassword> = (props: IResetPassword) => {
  const context = useContext(LanguageContext);
  const navigate = useNavigate();
  const [preferred_username, setPreferredUsername] = useState(
    props.email ? props.email : ''
  );
  const [password, setPassword] = useState('');
  const [authCode, setAuthCode] = useState('');
  const [inlineErrorPassword, setInlineErrorPassword] = useState(false);
  const [inlineErrorAuthCode, setInlineErrorAuthCode] = useState(false);
  const [pass, setPass] = useState(false);
  const [showMessage, setshowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const updatePassword = async () => {
    if (password === '') {
      setInlineErrorPassword(true);
      setPass(false);
      setshowMessage(false);
      setMessage('');
      setLoading(false);
    }
    if (authCode === '') {
      setInlineErrorAuthCode(true);
      setPass(false);
      setshowMessage(false);
      setMessage('');
      setLoading(false);
    }
    if (password !== '' && authCode !== '') {
      try {
        setLoading(true);
        await InfoAuth.forgotPasswordSubmit(
          preferred_username,
          authCode,
          password
        );
        setshowMessage(false);
        toast.success('Password Changed Successfully!', {
          autoClose: 15000,
          className: ToastStyle,
        });
        setTimeout(function () {
          navigate('/login');
        }, 5000);
      } catch (err) {
        setLoading(false);
        if (err.message != null) {
          setPass(false);
          setshowMessage(true);
          setMessage(err.message);
        }
      }
    }
  };

  const resend = () => {
    setPass(false);
    setshowMessage(false);
    setMessage('');
    setInlineErrorPassword(false);
    setInlineErrorAuthCode(false);
    setPreferredUsername('');
    navigate('/forgotPassword');
  };

  return (
    <div>
      <ToastContainer hideProgressBar={true} />
      <CommonHeader setLanguage={context.setLanguage} {...props} />
      <ParentWrapperContainer image={img}>
        <ForgotPasswordContainer>
          <WrapperContainer>
            <Form>
              <ChangePassTitleSpanContainer showMessage={showMessage}>
                <strong>
                  <FormattedMessage id="resetPwd.resetpassword" />
                </strong>
              </ChangePassTitleSpanContainer>
              <ErrorMsgComponent
                pass={pass}
                showMessage={showMessage}
                message={message}
              />

              <FloatingLabelInput
                id="authcode"
                dispalyLabel={<FormattedMessage id="resetPwd.passcode" />}
                onChange={setAuthCode}
                value={authCode}
                inlineError={inlineErrorAuthCode}
                setInLineError={setInlineErrorAuthCode}
                type="text"
                inlineMsg={<FormattedMessage id="resetPwd.inlineMsgAuth" />}
              />
              <ReactPasswordStrength
                {...props}
                intl={props.intl}
                id="Password"
                dispalyLabel={<FormattedMessage id="resetPwd.newPassword" />}
                onChange={setPassword}
                value={password}
                inlineError={inlineErrorPassword}
                setInLineError={setInlineErrorPassword}
                type="password"
                inlineMsg={<FormattedMessage id="resetPwd.newPwdIsRequired" />}
              />

              <CustomButton
                size="lg"
                onClick={updatePassword}
                loading={loading}
              >
                <FormattedMessage id="resetPwd.btnResetPassword" />
              </CustomButton>

              <RedirectContainer>
                <LinkComponent to="/login">
                  <LinkSpan showMessage={showMessage}>
                    <FormattedMessage id="resetPwd.backToLogin" />
                  </LinkSpan>
                </LinkComponent>
                <LinkSpan showMessage={showMessage} onClick={resend}>
                  <FormattedMessage id="resetPwd.resend" />
                </LinkSpan>
              </RedirectContainer>
            </Form>
          </WrapperContainer>
        </ForgotPasswordContainer>
      </ParentWrapperContainer>
    </div>
  );
};

export default injectIntl(ResetPassword);
