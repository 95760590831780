import English from '../lang/English';
import Hindi from '../lang/Hindi';
import Gujarati from '../lang/Gujarati';

export const extractLabel = (configLabel, locale) => {
  let label = configLabel;
  if (configLabel !== null) {
    if (typeof configLabel === 'string' || configLabel instanceof String) {
      if (configLabel.includes('-!sEpArAToR!-')) {
        const labelWithLang = configLabel.split('-!sEpArAToR!-');
        labelWithLang.some(function (item) {
          const data = item.split('-!lAnGuAge!-');
          if (data[0] === locale) {
            label = data[1];
            return true;
          } else {
            return false;
          }
        });
        if (label.includes('-!sEpArAToR!-')) {
          label = labelWithLang[0].split('-!lAnGuAge!-')[1];
        }
      } else if (configLabel.includes('-!lAnGuAge!-')) {
        label = configLabel.split('-!lAnGuAge!-')[1];
      } else if (configLabel.includes(';')) {
        let labelWithLang = configLabel.split(';');
        labelWithLang.some(function (item) {
          const data = item.includes(':') ? item.split(':') : item.split('--');
          switch (data[0]) {
            case 'English':
              if ('en' === locale) {
                label = data[1];
                return true;
              }
              break;
            case 'ગુજરાતી':
              if ('gu' === locale) {
                label = data[1];
                return true;
              }
              break;
            case 'हिंदी':
              if ('hi' === locale) {
                label = data[1];
                return true;
              }
              break;
          }
          return null;
        });
        if (label.includes(';')) {
          const labelSeprated = label.split(';');
          labelSeprated.some(function (item) {
            const labelsplited = item.includes(':')
              ? item.split(':')
              : item.split('--');
            if (labelsplited[0] === 'English') {
              label = labelsplited[1];
              return true;
            }
          });
        }
      } else if (configLabel.includes(':')) {
        label = configLabel.split(':')[1];
      } else if (configLabel.includes('--')) {
        label = configLabel.split('--')[1];
      }
      return label;
    }
  }
  return configLabel;
};

export const flattenMessages = (nestedMessages, prefix = '') => {
  if (nestedMessages === null) {
    return {};
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === 'string') {
      Object.assign(messages, { [prefixedKey]: value });
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

export const getLocaleMessages = (locale) => {
  // eslint-disable-next-line
  switch (locale) {
    case 'en':
      return flattenMessages(English);
    case 'hi':
      return flattenMessages(Hindi);
    case 'gu':
      return flattenMessages(Gujarati);
  }
};
