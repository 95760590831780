import { all, fork } from 'redux-saga/effects';
import basicSaga from './basicSaga';
import userContextSaga from './userContextSaga';
import gridSaga from './dataGridSaga/gridSaga';
import modalSaga from './modal/modalSaga';
import attachmentSaga from './attachment/attachmentSaga';
import inlineGridSaga from './inlineGrid/inlineGridSaga';
import getFormDataSaga from './form/getFormDataSaga';
import changeFormDataSaga from './form/changeFormDataSaga';
import handleRepeatableSaga from './form/handleRepeatableSaga';
import upsertEntityRecord from './form/upsertEntityRecordSaga';
import gateWayToGetFormDataSaga from './gateWay/gateWayToGetFormDataSaga';
import deleteRecordsSaga from './form/deleteRecordSaga';
import actionFlowRoutingSaga from './actionFlowRouting/actionFlowRoutingSaga';
import gateWayForUpsertOperationSaga from './gateWay/gateWayForUpsertOperationSaga';
import portalAndPortalCardSaga from './portalAndPortalCard/portalAndPortalCardSaga';
import getFormDataUsingFormConfig from './form/getFormDataUsingFormConfigSaga';
import upsertFormRecord from './form/upsertFormRecordSaga';
import upsertEntityUsingLogicalEntitySaga from './form/upsertEntityUsingLogicalEntity';
import submitActionSaga from './form/upsertEntityUsingLogicalEntity';
import executeActionFlowSaga from './form/executeActionFlowSaga';

export default function* rootSaga() {
  yield all([
    fork(basicSaga),
    fork(userContextSaga),
    fork(gridSaga),
    fork(modalSaga),
    fork(attachmentSaga),
    fork(inlineGridSaga),
    fork(getFormDataSaga),
    fork(changeFormDataSaga),
    fork(handleRepeatableSaga),
    fork(upsertEntityRecord),
    fork(deleteRecordsSaga),
    fork(actionFlowRoutingSaga),
    fork(gateWayToGetFormDataSaga),
    fork(gateWayForUpsertOperationSaga),
    fork(portalAndPortalCardSaga),
    fork(getFormDataUsingFormConfig),
    fork(upsertFormRecord),
    fork(upsertEntityUsingLogicalEntitySaga),
    fork(submitActionSaga),
    fork(executeActionFlowSaga),
  ]);
}
