import gql from 'graphql-tag';
export const PortalDataGrid = gql`
  query PortalDataGrid($id: ID!) {
    PortalDataGrid(id: $id) {
      configObjectId
      name
      configObjectType
      createdBy
      isDeleted
      itemDescription
      insert_ts
      projectId
      updatedBy
      update_ts
      deletionDate
      nodeId
      ceId
      logicalEntityId
      dataGridId
      subPortals {
        configObjectId
        name
        configObjectType
        portalType
        archived
        favorite
        modalRequired
        subPortalType
        portalDataSharing
        tabGroup
        order
        accessibilityRegex
      }
    }
  }
`;
