import {
  put,
  all,
  fork,
  takeLatest,
  takeEvery,
  call,
  select,
} from 'redux-saga/effects';
import {
  setToggleMessages,
  ADD_MODALS_SAGA,
  addModals,
  CLOSE_MODALS_SAGA,
  closeModals,
  ADD_MODAL_RELOAD,
  reloadCleanModalData,
  SET_OPEN_MODAL_SAGA,
  setOpenModal,
} from '../../actions';
import { UpsertMessage } from '../../constants/appeng.enum';
import * as base from '../base';
import { v4 as uuid } from 'uuid';
import CardData from '../../models/carddata.model';
const getOpenModals = (state: any) => state.appState.openModals;
const getCard = (state: any) => state.appState.cardsData;

export default function* modalSaga() {
  yield all([
    fork(watchAddModalsSaga),
    fork(watchCloseModalsSaga),
    fork(watchAddModalReload),
    fork(watchOpenModalSaga),
  ]);
}

function* watchAddModalsSaga() {
  yield takeEvery(ADD_MODALS_SAGA, addModalsSaga);
}

function* watchCloseModalsSaga() {
  yield takeEvery(CLOSE_MODALS_SAGA, closeModalsSaga);
}

function* watchAddModalReload() {
  yield takeEvery(ADD_MODAL_RELOAD, addModalReloadSaga);
}

function* watchOpenModalSaga() {
  yield takeEvery(SET_OPEN_MODAL_SAGA, openModalSaga);
}

function* addModalsSaga(action: any): Generator<any, any, any> {
  try {
    yield put(addModals(action.modalName));
  } catch {
    const messages: string[] = [];
    messages.push(UpsertMessage.UNSUCCESSFUL);
    yield put(setToggleMessages(messages));
  }
}

function* closeModalsSaga(action: any): Generator<any, any, any> {
  try {
    const { data } = yield call(base.getConfigData, 'NodeQuery', action.nodeId);
    switch (action.modalType) {
      case 'AddForm':
        yield put(
          closeModals(
            action.occuranceNumber,
            data.CompositeEntityNode.insertForm.configObjectId
          )
        );
        break;
      default:
    }
  } catch {
    const messages: string[] = [];
    messages.push(UpsertMessage.UNSUCCESSFUL);
    yield put(setToggleMessages(messages));
  }
}

function* addModalReloadSaga(action: any): Generator<any, any, any> {
  const existingOpenedModals = yield select(getOpenModals);
  const existingCardsMap = yield select(getCard);
  const uuidKeep = uuid();
  const ts = new Date().getTime();
  const modalDetails = existingOpenedModals[action.portalId];
  const cardsToReload: any = {};
  if (
    modalDetails &&
    (modalDetails.refreshData.length > 0 || modalDetails.cleanData.length > 0)
  ) {
    modalDetails.refreshData.map((cardId: any) => {
      const existingCard = existingCardsMap[cardId];
      if (existingCard) {
        const rd = { ...existingCard.referenceData };
        Object.assign(rd, {
          AE_RELOAD: {
            ID: uuidKeep,
            TS: ts,
            STATUS: 'R',
          },
        });
        const cardData = new CardData(
          cardId,
          existingCard.type,
          existingCard.data,
          existingCard.portalId,
          existingCard.parentId,
          rd,
          existingCard.errorData,
          existingCard.options,
          existingCard.datagridErrorData,
          existingCard.toggleTabRefresh
        );
        cardsToReload[cardId] = cardData;
      }
    });
    yield put(
      reloadCleanModalData(
        cardsToReload,
        modalDetails.cleanData,
        action.portalId
      )
    );
  }
}

function* openModalSaga(action: any): Generator<any, any, any> {
  let dataMap: any = {};
  dataMap['refreshData'] = action.refreshData;
  dataMap['cleanData'] = action.cleanData;
  dataMap['referenceData'] = {};
  if (action.referenceData) {
    dataMap['referenceData'][action.portalId] = action.referenceData;
  }
  if (action.cssAttribute) {
    dataMap['cssAttribute'] = action.cssAttribute;
  }
  yield put(setOpenModal(action.portalId, action.isRemove, dataMap));
}

export function* openPortalInModal(
  attributes: any,
  cssAttribute: any
): Generator<any, any, any> {
  if (attributes && attributes.navigateToPortal) {
  } else {
    const existingOpenedModals = yield select(getOpenModals);
    Object.keys(existingOpenedModals).map((key) => {
      if (existingOpenedModals[key].modalRequired) {
        existingOpenedModals[key]['currentModal'] = false;
      }
    });
    let refreshData = attributes.refreshData
      ? attributes.refreshData.split(',')
      : [];
    let cleanData = attributes.cleanData ? attributes.cleanData.split(',') : [];
    const supportingParameter = {
      modalRequired: true,
      currentModal: true,
      supportingParameter: attributes,
      cssAttribute: attributes.cssAttribute
        ? attributes.cssAttribute
        : cssAttribute,
      refreshData: refreshData,
      cleanData: cleanData,
    };
    yield put(
      setOpenModal(
        attributes.routeStateParams.portalId,
        false,
        supportingParameter
      )
    );
  }
}
