interface IJWTToken {
  iat: string;
  exp: number;
}

export default class JWTToken {
  readonly token: string;
  private payLoad: IJWTToken;

  constructor(token: string) {
    this.token = token || '';
    this.payLoad = this.decodePayload();
  }

  get expiration() {
    return this.payLoad.exp;
  }

  get issuedAt() {
    return this.payLoad.iat;
  }

  private decodePayload(): IJWTToken {
    const tokenData = this.token.split('.')[1];
    try {
      return JSON.parse(atob(tokenData));
    } catch (err) {
      return {
        iat: '',
        exp: 0,
      };
    }
  }
}
