import { resolveColor } from '../../theme/ThemeOptions';
import styled from 'styled-components';

export const StyledGridConfigButtonSpan: any = styled.span`
  color: ${(p: any) =>
    resolveColor(p.ioTheme, p.ioMode, p.styleName, 'gridcolor') + '!important'};
  font-weight: bold;
  font-size: 20px;
`;

export const StyledGridConfigButtonWrapper: any = styled.div`
  float: right;
`;
