import { combineReducers } from 'redux';
import counterReducer from './index';

const createRootReducer = () =>
  combineReducers({
    appState: counterReducer,
  });

export interface State {
  appState: any;
}

export default createRootReducer;
