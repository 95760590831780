import { useState, useEffect } from 'react';
import * as React from 'react';
import { FloatingLabelInput } from './FloatingLabelInput';
import CheckBoxBuilder from './CheckBoxBuilder';
import ToolTipComp from './ToolTipComp';
interface ContextProps {
  dispalyLabel: any;
  onChange: any;
  value: string;
  inlineError: boolean;
  setInLineError: any;
  locale: any;
  intl: any;
  id: any;
  type: string;
  inlineMsg: any;
}
const isTooShort = (password: any) =>
  !(password.length < 8) && !(password.length > 20);
const ReactPasswordStrength: React.FC<ContextProps> = (props: ContextProps) => {
  const [passwordprops, setPasswordprops] = useState({
    score: 0,
    isValid: false,
    password: '',
  });

  const [max8digit, setMax8digitCondition] = useState(
    <CheckBoxBuilder
      intl={props.intl}
      label="tooltipMesg.message1"
      checked={false}
    />
  );
  const [upperCase, setUpperCaseCondition] = useState(
    <CheckBoxBuilder
      intl={props.intl}
      label="tooltipMesg.message2"
      checked={false}
    />
  );
  const [lowerCase, setLowerCaseCondition] = useState(
    <CheckBoxBuilder
      intl={props.intl}
      label="tooltipMesg.message3"
      checked={false}
    />
  );
  const [digits, setDigitsCondition] = useState(
    <CheckBoxBuilder
      intl={props.intl}
      label="tooltipMesg.message4"
      checked={false}
    />
  );
  const [specialSymbol, setSpecialSymbolCondition] = useState(
    <CheckBoxBuilder
      intl={props.intl}
      label="tooltipMesg.message5"
      checked={false}
    />
  );

  const handleChange = (password: any) => {
    let score = 0;
    let result;
    if (isTooShort(password)) {
      setMax8digitCondition(
        <CheckBoxBuilder
          intl={props.intl}
          label="tooltipMesg.message1"
          checked={true}
        />
      );
    } else {
      setMax8digitCondition(
        <CheckBoxBuilder
          intl={props.intl}
          label="tooltipMesg.message1"
          checked={false}
        />
      );
    }
    if (validateSpecialCharacter(password)) {
      setSpecialSymbolCondition(
        <CheckBoxBuilder
          intl={props.intl}
          label="tooltipMesg.message5"
          checked={true}
        />
      );
    } else {
      setSpecialSymbolCondition(
        <CheckBoxBuilder
          intl={props.intl}
          label="tooltipMesg.message5"
          checked={false}
        />
      );
    }
    if (validateCapitalCharacter(password)) {
      setUpperCaseCondition(
        <CheckBoxBuilder
          intl={props.intl}
          label="tooltipMesg.message2"
          checked={true}
        />
      );
    } else {
      setUpperCaseCondition(
        <CheckBoxBuilder
          intl={props.intl}
          label="tooltipMesg.message2"
          checked={false}
        />
      );
    }
    if (validateSmallCharacter(password)) {
      setLowerCaseCondition(
        <CheckBoxBuilder
          intl={props.intl}
          label="tooltipMesg.message3"
          checked={true}
        />
      );
    } else {
      setLowerCaseCondition(
        <CheckBoxBuilder
          intl={props.intl}
          label="tooltipMesg.message3"
          checked={false}
        />
      );
    }
    if (validateDigit(password)) {
      setDigitsCondition(
        <CheckBoxBuilder
          intl={props.intl}
          label="tooltipMesg.message4"
          checked={true}
        />
      );
    } else {
      setDigitsCondition(
        <CheckBoxBuilder
          intl={props.intl}
          label="tooltipMesg.message4"
          checked={false}
        />
      );
    }
  };
  const validateSpecialCharacter = (password: string) => {
    const regEx = /[!@#$%^&*(),.?":{}|<>]/;
    return regEx.test(password);
  };
  const validateCapitalCharacter = (password: string) => {
    const regEx = /[A-Z]/;
    return regEx.test(password);
  };
  const validateSmallCharacter = (password: string) => {
    const regEx = /[a-z]/;
    return regEx.test(password);
  };
  const validateDigit = (password: string) => {
    const regEx = /[0-9]/;
    return regEx.test(password);
  };
  let component = (
    <FloatingLabelInput
      id="password"
      dispalyLabel={props.dispalyLabel}
      onChange={props.onChange}
      value={props.value}
      inlineError={props.inlineError}
      setInLineError={props.setInLineError}
      type="password"
      inlineMsg="Password is required"
      handleChange={handleChange}
    />
  );
  return (
    <ToolTipComp
      intl={props.intl}
      max8digit={max8digit}
      component={component}
      upperCase={upperCase}
      lowerCase={lowerCase}
      digits={digits}
      specialSymbol={specialSymbol}
    />
  );
};
export default ReactPasswordStrength;
