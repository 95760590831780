import {
  ACCESS_TOKEN_KEY,
  ID_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
} from '../constants';
import api from '../info-auth-api/api';
import { JWTToken, UserSession } from '../model';
import IStorage from '../storage/storage.interface';

export interface TokenData {
  id?: string | null;
  access?: string | null;
  refresh?: string | null;
  language?: string | null;
}

const createSession = (storage: IStorage) => {
  const tokens: TokenData = getTokensFromStorage(storage);
  if (tokens.id && tokens.access) {
    return new UserSession(
      new JWTToken(tokens.id),
      new JWTToken(tokens.access),
      tokens.language ? tokens.language : 'en'
    );
  }
};

const getTokensFromStorage = (storage: IStorage): TokenData => {
  return {
    id: storage.getItem(ID_TOKEN_KEY),
    access: storage.getItem(ACCESS_TOKEN_KEY),
    refresh: storage.getItem(REFRESH_TOKEN_KEY),
    language: storage.getItem('language'),
  };
};

const setTokensToStorage = (token: TokenData, storage: IStorage) => {
  storage.clear();
  storage.setItem(ID_TOKEN_KEY, token.id ? token.id : null);
  storage.setItem(ACCESS_TOKEN_KEY, token.access ? token.access : null);
  storage.setItem(REFRESH_TOKEN_KEY, token.refresh ? token.refresh : null);
  storage.setItem('language', token.language ? token.language : 'en');
};

export class Credentials {
  private storage: IStorage;
  private userSession?: UserSession;
  private config: any;

  constructor(storage: IStorage, config: any) {
    this.storage = storage;
    this.config = config;
  }

  public setCredential(tokens: TokenData) {
    setTokensToStorage(tokens, this.storage);
    this.userSession = createSession(this.storage);
  }

  public removeCredential() {
    this.storage.removeItem(ID_TOKEN_KEY);
    this.storage.removeItem(ACCESS_TOKEN_KEY);
    this.storage.removeItem(REFRESH_TOKEN_KEY);
    this.userSession = undefined;
  }
  public isExpired(): boolean {
    return !(this.userSession && this.userSession.isValid());
  }

  public async getSession() {
    if (!this.userSession) {
      this.userSession = createSession(this.storage);
    }

    if (this.userSession && this.userSession.isValid()) {
      return this.userSession;
    } else {
      await this.refresh();
      return this.userSession;
    }
  }

  public async refresh() {
    try {
      const tokens: TokenData = getTokensFromStorage(this.storage);
      if (tokens.refresh) {
        const postData = {
          token: tokens.refresh,
        };
        const userDetails = await api.refresh(
          this.config.provider.infoauth.redirectUri,
          postData
        );
        const refreshTokens: TokenData = {
          id: userDetails.id_token,
          access: userDetails.access_token,
          refresh: userDetails.refresh_token,
        };
        this.setCredential(refreshTokens);
      }
    } catch (e) {
      this.userSession = undefined;
    }
  }
}
