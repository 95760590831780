export default {
  advanceSearch: {
    search: 'खोज',
    reset: 'रीसेट',
    close: 'बंद करे',
  },
  mandatory: 'कृपया अनिवार्य फ़ील्ड भरें',
  invalid: 'कृपया निम्न अमान्य डेटा को ठीक करें',
  addForm: 'फॉर्म जोड़ें',
  reportForm: 'रिपोर्ट का फॉर्म',
  save: 'सेव',
  pleaseWait: 'कृपया प्रतीक्षा करें...',
  filterForm: 'फ़िल्टर फॉर्म',
  filter: 'फ़िल्टर',
  select: '-सिलेक्ट-',
  chooseOption: 'एक विकल्प चुनें ...',
  uploadClick: 'अपलोड करने के लिए यहां क्लिक करें',
  resize: 'रिसाइज',
  action: 'एक्शन',
  history: 'इतिहास',
  editor: 'संपादक',
  totalRecords:
    '{recordCount} {recordCount, plural,one {रिकॉर्ड} other {रेकार्ड}}',
};
