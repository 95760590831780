import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useConfigQuery } from '../custom-hooks/useConfigQuery';
import SkeletonCore from '../components/SkeletonCore';
import { createStorage } from '../storage';
import PortalLayout from './PortalLayout';
import { IPortal } from './interfaces';
import { QueryType } from '../constants/appeng.enum';
import Card from 'react-bootstrap/Card';
import { useCleanPortalData } from '../custom-hooks/useCleanPortalData';
import {
  setFormData,
  setOpenModalSaga,
  setPortalDetails,
  actionFlowRouting,
  setRouterAndFormData,
} from '../actions';
import {
  CardType,
  FormNavigationType,
  ActionType,
} from '../constants/appeng.enum';
import CardData from '../models/carddata.model';
import { parseStringToObject } from '../utils/ClientUtils';
import { IConfigButtons } from '../composite-entity/interfaces/index';
import { getPrivilege } from '../../src/utils/ClientUtils';
import { UserContext } from '../../src/user-context/UserContext';
import { getMenuButtons } from '../../src/composite-entity/CompositeEntityCommonUtils';
import { ThemeContext } from '../../src/theme/ThemeContext';
import PortalModalGenerator from './PortalModalGenerator';
import { getCardModalButtonsInHeader } from './GridComponent';
import cloneDeep from 'lodash/cloneDeep';
import { v4 as uuid } from 'uuid';
import NavigationHandler from '../components/NavigationHandler';
import { useLocation } from 'react-router';
import styled from 'styled-components';
const StyledHeader = styled(Card.Header)`
  padding: 8px !important;
  background-color: rgb(255, 255, 255) !important;
  font-size: 1.5vw !important;
  @media (max-width: 700px) {
    font-size: 18px !important;
  }
  text-align: center !important;
`;

const Portal: React.FC<any> = (props: IPortal & IConfigButtons) => {
  const userContext = useContext(UserContext);
  const currentLocation = useLocation();
  const themeContext = useContext(ThemeContext);
  const dispatch = useDispatch();
  const portalDetails = useSelector(
    (state: any) => state.appState.portalDetails
  );
  const formDataObject = useSelector(
    (state: any) => state.appState.cardsData[props.referralId]
  );
  const openCardModals = useSelector((state: any) => state.appState.openModals);
  const existingCardsData = useSelector(
    (state: any) => state.appState.cardsData
  );
  const [cardPortalId, setCardPortalId] = useState('');

  const menuGroupId = useConfigQuery('ConfigItemByType', 'MenuGroup');

  const configObjectId = menuGroupId.data.ConfigItemByType
    ? menuGroupId.data.ConfigItemByType[0].configObjectId
    : '';
  const menuData = useConfigQuery('MenuQuery', configObjectId);

  let portalId: any = '';
  let openCardPortal_Id = '';
  let queryID = '';
  if (!props.isSubPortal) {
    portalId = getPortalId(
      props.routerParameter.state
        ? props.routerParameter.state['portalId']
        : portalDetails.portalId
    );
    queryID = QueryType.PORTAL_QUERY;
  } else {
    portalId = props.subportalId;
    queryID = QueryType.SUBPORTAL_QUERY;
  }

  useEffect(() => {
    if (
      props.isSubPortal &&
      props.subportalId &&
      !existingCardsData.hasOwnProperty(props.subportalId)
    ) {
      const subportalId = props.subportalId.includes('_')
        ? props.subportalId
        : props.subComponentParentId + props.subportalId;
      const cardData = new CardData(
        subportalId,
        CardType.SUBPORTAL,
        {},
        props.portalId ? props.portalId : '',
        subportalId,
        props.routerParameter.state[subportalId],
        {},
        {},
        {},
        false,
        false,
        {},
        '',
        '',
        '',
        '',
        props.parentCardId
      );
      dispatch(setFormData(cardData, subportalId, false, false, false));
    } else if (!existingCardsData.hasOwnProperty(props.subportalId)) {
      const modifiedPortalId = portalId.includes('_')
        ? portalId
        : props.subComponentParentId + portalId;
      const cardData = new CardData(
        modifiedPortalId,
        CardType.PORTAL,
        {},
        modifiedPortalId,
        modifiedPortalId,
        props.routerParameter && props.routerParameter.state
          ? props.routerParameter.state[modifiedPortalId]
          : {}
      );

      dispatch(
        setRouterAndFormData(props.routerParameter, cardData, modifiedPortalId)
      );
    }
  }, [portalId]);

  const { loading, error, data } = useConfigQuery(queryID, portalId);
  const { resetting } = useCleanPortalData(portalId, props.isSubPortal);

  if (loading || resetting) return <SkeletonCore count={1} />;

  const handleClick = (button: any) => {
    if (button.modalRequired) {
      const id = props.subComponentParentId + button.portalId;
      setCardPortalId(button.portalId);
      let existingCardList: any = [];
      existingCardList.push(
        props.subComponentParentId + data.Portal.configObjectId
      );
      const modalAttribute =
        button.modalCss == 'Left Align Full Vertical View'
          ? 'left:0;right:auto;width:65%'
          : button.modalCss == 'Right Align Full Vertical View'
            ? 'left:auto;right:0;width:65%'
            : button.modalCss == 'Center Align Partial View'
              ? 'left:10%;right:10%;width:80%;height:80%;margin-top:3.8%'
              : '';
      dispatch(
        setOpenModalSaga(
          id,
          false,
          true,
          existingCardList,
          [id],
          ' ',
          modalAttribute
        )
      );
    } else if (button.portalId) {
      const routerParam: any = {
        url: '/app/ce/portal/' + uuid(),
        buttonType: FormNavigationType.PORTAL_FORM,
        resetReferenceData: false,
        routeStateParams: {},
      };

      const subComponentId = portalId.includes('_')
        ? portalId.split('_')[0] + '_'
        : 0 + '_';
      routerParam.routeStateParams['portalId'] =
        subComponentId + button.portalId;
      routerParam.routeStateParams['parentId'] =
        subComponentId + button.portalId;
      routerParam.routeStateParams['referralId'] =
        subComponentId + button.portalId;
      routerParam.routeStateParams[subComponentId + button.portalId] =
        props.routerParameter.referenceData;
      const details = {
        portalId: button.portalId,
        data: props.routerParameter.referenceData,
        loading: true,
      };
      dispatch(setPortalDetails(details));
      dispatch(actionFlowRouting(ActionType.BUTTON_CLICK, routerParam));
    }
  };

  const buttonPanelPrivilege = getPrivilege(
    menuData.data.MenuGroup && menuData.data.MenuGroup.buttonPanels
      ? menuData.data.MenuGroup.buttonPanels[0].privileges
      : [],
    userContext.getCurrentRole()
  );

  const menuGroupButtons =
    menuData.data.MenuGroup && menuData.data.MenuGroup.buttonPanels
      ? menuData.data.MenuGroup.buttonPanels[0].menuButtons
      : [];
  const portalButtons =
    data.Portal &&
    data.Portal.buttonPanels &&
    data.Portal.buttonPanels[0].menuButtons
      ? data.Portal.buttonPanels[0].menuButtons[0].buttons
      : [];
  let menuButtonList = cloneDeep(menuGroupButtons);

  if (menuButtonList.length > 0) {
    let ne_buttonList = menuButtonList[0].buttons
      ? menuButtonList[0].buttons.concat(portalButtons)
      : data.Portal && data.Portal.buttonPanels
        ? data.Portal.buttonPanels[0].menuButtons[0]
        : [];
    menuButtonList[0].buttons = ne_buttonList;
  }

  const menuButtons = getMenuButtons(
    themeContext,
    menuData.data.MenuGroup && menuData.data.MenuGroup.buttonPanels
      ? menuButtonList
      : [],
    handleClick,
    userContext,
    formDataObject,
    buttonPanelPrivilege,
    props,
    loading
  );

  const chartModalHeader = getCardModalButtonsInHeader(
    themeContext,
    cardPortalId,
    setOpenModalSaga,
    dispatch,
    portalId,
    props.subComponentParentId
  );
  let chartModalHeader_1 = {};

  const openModalRouteParam: any = {};
  Object.assign(openModalRouteParam, props.routerParameter);
  if (
    openCardModals.hasOwnProperty(props.subComponentParentId + cardPortalId) &&
    openCardModals[props.subComponentParentId + cardPortalId].referenceData[
      props.subComponentParentId + cardPortalId
    ]
  ) {
    openModalRouteParam['state'][props.subComponentParentId + cardPortalId] =
      openCardModals[props.subComponentParentId + cardPortalId].referenceData[
        props.subComponentParentId + cardPortalId
      ];
  }

    Object.keys(openCardModals).map(key => {
      if(openCardModals[key].supportingParameter && openCardModals[key].supportingParameter.hasOwnProperty("MODAL_PATH")){
        openCardPortal_Id = key;
      }
    });
    if (openCardPortal_Id) {
        if (openCardModals[openCardPortal_Id].referenceData) {
            openModalRouteParam['state'][openCardPortal_Id] = openCardModals[openCardPortal_Id].referenceData[openCardPortal_Id];
        }
        chartModalHeader_1 = getCardModalButtonsInHeader(themeContext, openCardPortal_Id, setOpenModalSaga, dispatch, portalId, "");
    }

    //if portal id is same as that of the portal configuration generate portal
    if (!props.isSubPortal && isSamePortal(props.isSubPortal, data, portalId)) {
        let portalStyle = "";
        if (data.Portal.portalStyle) {
            portalStyle = parseStringToObject(data.Portal.portalStyle)
        }
        return (
            <React.Fragment>
                <NavigationHandler/>
                {openCardPortal_Id &&
                    <PortalModalGenerator
                        header={chartModalHeader_1}
                        subportalId={openCardPortal_Id}
                        portalId={openCardPortal_Id}
                        key={"_"+configObjectId+openCardPortal_Id}
                        routerParameter={openModalRouteParam}
                        subComponentParentId={props.subComponentParentId}
                        modalAttribute={openCardModals[openCardPortal_Id].cssAttribute} />}
                {openCardModals.hasOwnProperty(props.subComponentParentId + cardPortalId) &&
                    <PortalModalGenerator
                        header={chartModalHeader}
                        subportalId={cardPortalId}
                        key={"_"+ configObjectId}
                        portalId={cardPortalId}
                        routerParameter={openModalRouteParam}
                        subComponentParentId={props.subComponentParentId}
                        modalAttribute={openCardModals[props.subComponentParentId + cardPortalId].cssAttribute} />}

                <PortalLayout
                    portalId={props.subComponentParentId + data.Portal.configObjectId}
                    isSubPortal={props.isSubPortal}
                    routerParameter={props.routerParameter}
                    portalCards={data.Portal.portalCards}
                    portalDataSharing={data.Portal.portalDataSharing}
                    key={props.subComponentParentId + data.Portal.configObjectId + "_"}
                    parentId={props.subComponentParentId + data.Portal.configObjectId}
                    layoutType={data.Portal.layoutType ? data.Portal.layoutType : "Default"}
                    portalStyle={portalStyle}
                    subComponentParentId={props.subComponentParentId}
                    tabGroupId={props.tabGroupId}
                    tabPortalId={props.tabPortalId}
                    parentCardId={props.parentCardId}
                />
                {menuButtons}

            </React.Fragment>
        )
    } else if (props.isSubPortal && isSamePortal(props.isSubPortal, data, portalId)) {
        let portalStyle = "";
        if (data.SubPortal.portalStyle) {
            portalStyle = parseStringToObject(data.SubPortal.portalStyle)
        }
        return (<React.Fragment>
            <NavigationHandler/>
            {openCardModals.hasOwnProperty(props.subComponentParentId + cardPortalId) &&
                <PortalModalGenerator
                    header={chartModalHeader}
                    subportalId={cardPortalId}
                    portalId={cardPortalId}
                    key={configObjectId + "_key"}
                    routerParameter={openModalRouteParam}
                    subComponentParentId={props.subComponentParentId}
                    modalAttribute={openCardModals[props.subComponentParentId + cardPortalId].cssAttribute} />}

            <PortalLayout
                key={props.subComponentParentId + data.SubPortal.configObjectId + "_"}
                isSubPortal={props.isSubPortal}
                portalId={props.portalId ? props.portalId :
                    props.routerParameter.state.portalId.includes('_') ?
                        props.routerParameter.state.portalId :
                        props.subComponentParentId + props.routerParameter.state.portalId}
                routerParameter={props.routerParameter}
                portalCards={data.SubPortal.portalCards}
                portalStyle={portalStyle}
                portalDataSharing={data.SubPortal.portalDataSharing}
                parentId={props.subComponentParentId + data.SubPortal.configObjectId}
                layoutType={data.SubPortal.layoutType ? data.SubPortal.layoutType : "Default"}
                headerButtons={props.headerButtons}
                subComponentParentId={props.subComponentParentId}
                tabGroupId={props.tabGroupId}
                tabPortalId={props.tabPortalId}
                parentCardId={props.parentCardId}
            />

        </React.Fragment>
        )
    } else {
        return (<Card>
            <StyledHeader>Something is coming soon</StyledHeader></Card>)
    }
}

const isSamePortal = (isSubPortal: any, data: any, portalId: any) => {
  return !isSubPortal
    ? data.Portal && portalId.includes(data.Portal.configObjectId)
    : data.SubPortal && portalId.includes(data.SubPortal.configObjectId);
};

export const getPortalId = (portalId: string) => {
  const storage = createStorage();
  if (portalId) {
    storage.setItem('portalId', portalId);
  } else {
    return storage.getItem('portalId');
  }
  return portalId;
};

export default Portal;
