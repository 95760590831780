import gql from 'graphql-tag';
export const PortalFilterForm = gql`
  query PortalFilterForm($id: ID!) {
    PortalFilterForm(id: $id) {
      configObjectId
      name
      configObjectType
      createdBy
      isDeleted
      itemDescription
      insert_ts
      projectId
      updatedBy
      update_ts
      deletionDate
      nodeId
      ceNodeId
      logicalEntityId
      formId
    }
  }
`;
