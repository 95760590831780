import React, { Suspense, lazy } from 'react';
import { Provider } from 'react-redux';

import { makeStore } from './store/index';
import { client } from './utils/ClientUtils';
import { createStorage } from './storage';

import ReactDOM from 'react-dom/client';

import { getLocaleMessages } from './utils/intlutils';
// import './index.css';
// import '../src/components/styles.css';
import { IntlProvider } from 'react-intl';

const CoreApp = lazy(() => import('./CoreApp'));

const locale = 'en';
const localeData = {
  en: () => import('@formatjs/intl-pluralrules/locale-data/en'),
  gu: () => import('@formatjs/intl-pluralrules/locale-data/gu'),
  hi: () => import('@formatjs/intl-pluralrules/locale-data/hi'),
};

const loadLocaleData = async (locale) => {
  if (localeData[locale]) {
    await localeData[locale]();
  }
};

const storage = createStorage();
const localLang = getLocaleMessages(locale);
const { ApolloProvider } = await import('@apollo/client');

const preLoadedState = {
  addModals: [],
  cardsData: {},
  treeDatas: {},
  leftMenuIndicator: '',
  droppableIndicator: false,
  rootNodeId: '',
  options: {},
  errorData: {},
  toggleMessages: [],
  locale: 'en',
  messages: localLang,
  userDetails: '',
  currentFinancialYear: '',
  portalDetails: {},
  gridIndexes: {},
  portalLayout: {},
  applications: [],
  currentProject: '',
  openModals: {},
  warningMessages: {},
};

const store = makeStore({ appState: preLoadedState });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

loadLocaleData(locale).then(() => {
  root.render(
    <ApolloProvider client={client}>
      <Provider store={store}>
        <IntlProvider locale={locale} messages={localLang}>
          <CoreApp />
        </IntlProvider>
      </Provider>
    </ApolloProvider>
  );
});
