export const SET_LEFT_MENU_INDICATOR = 'SET_LEFT_MENU_INDICATOR';

export interface ISetLeftMenuIndicator {
  type: string;
  indicator: string;
}

export function setLeftMenuIndicator(indicator: string): ISetLeftMenuIndicator {
  return {
    type: SET_LEFT_MENU_INDICATOR,
    indicator: indicator,
  };
}
