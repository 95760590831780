export default class ReportOptions {
  public templateName: string;
  public language?: string;
  public jwt: string;
  public params: any;

  constructor(
    templateName: string,
    jwt: string,
    params: any,
    language?: string
  ) {
    this.templateName = templateName;
    this.jwt = jwt;
    this.params = params;
    this.language = language;
  }
}
