import React, { useContext } from 'react';
import UIBasicTableGenerator from '../info-data-table/components/BasicDataTable';
import { GridType, INSERT_MODE, EDIT_MODE } from '../constants/appeng.enum';
import SkeletonCore from '../components/SkeletonCore';
import { useFetchAppGridData } from '../custom-hooks/useFetchAppGridData';
import { getPrimaryDBCodeExtractor, getFormId } from '../utils/ConfigUtils';
import { PATHNAME } from '../constants/appeng.enum';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import filterFields from "./mockData/queryBuilder.json";
import { QueryBuilderData } from 'info-ui-library';
import { ThemeContext } from '../theme/ThemeContext';
import { getGridData } from '../actions';

const PortalGridGenerator: React.FC<any> = (props: any) => {
  const currentLocation = useLocation();
  const dispatch = useDispatch();
  const themeContext = useContext(ThemeContext);
  const routerId =
    props.referenceData &&
    props.referenceData[PATHNAME] &&
    props.referenceData[PATHNAME].split('/')[
    props.referenceData[PATHNAME].split('/').length - 1
    ];

  const { loadingApp, appData } = useFetchAppGridData(
    props.portalGrid.gridId,
    props.referenceData,
    '/app/ce/grid/' + routerId,
    props.nodeId,
    props.portalGrid.ceId,
    props.referralId,
    props.refreshCardData,
    false,
    props.parentId,
    props.portalId,
    props.referenceData.AE_RELOAD,
    props.tabRefresh
  );

  const portalLoading = useSelector(
    (state: any) => state.appState.portalDetails.loading,
    (prev, next) => prev === next
  );
  let cardsData = useSelector(
    (state: any) => state.appState.cardsData[props.referralId]
  );
  if (loadingApp || portalLoading) return <SkeletonCore count={1} />;
  try {
    const primaryDBCode = getPrimaryDBCodeExtractor(
      props.compositeEntityNodeData
    );

    const insertFormId = props.compositeEntityNodeData.CompositeEntityNode
      ? getFormId(
        props.compositeEntityNodeData.CompositeEntityNode,
        INSERT_MODE
      )
      : props.compositeEntityNodeData.DataGrid.form
        ? props.compositeEntityNodeData.DataGrid.form.configObjectId
        : null;

    const editFormId = props.compositeEntityNodeData.CompositeEntityNode
      ? getFormId(props.compositeEntityNodeData.CompositeEntityNode, EDIT_MODE)
      : props.compositeEntityNodeData.DataGrid.form
        ? props.compositeEntityNodeData.DataGrid.form.configObjectId
        : null;

    const datagrid = props.compositeEntityNodeData.CompositeEntityNode
      ? props.compositeEntityNodeData.CompositeEntityNode.dataGrid
      : props.compositeEntityNodeData.DataGrid;

    const entityName = props.compositeEntityNodeData.CompositeEntityNode
      ? props.compositeEntityNodeData.CompositeEntityNode.entity.name
      : props.compositeEntityNodeData.DataGrid.form
        ? props.compositeEntityNodeData.DataGrid.form.logicalEntity
          ? props.compositeEntityNodeData.DataGrid.form.logicalEntity
            .configObjectName
          : null
        : null;

    const entityId = props.compositeEntityNodeData.CompositeEntityNode
      ? props.compositeEntityNodeData.CompositeEntityNode.entity.configObjectId
      : props.compositeEntityNodeData.DataGrid.form
        ? props.compositeEntityNodeData.DataGrid.form.logicalEntity
          ? props.compositeEntityNodeData.DataGrid.form.logicalEntity
            .configObjectId
          : null
        : null;

    const nodeId = props.compositeEntityNodeData.CompositeEntityNode
      ? props.compositeEntityNodeData.CompositeEntityNode.configObjectId
      : null;


    const handleSearch = (newQuery: any) => {
      let referenceData = props.referenceData ? props.referenceData : {};
      referenceData["advanceQueryString"] = newQuery
      dispatch(
        getGridData(
          props.portalGrid.gridId,
          referenceData,
          props.referralId,
          false,
          props.parentId,
          props.portalId,
          props.tabRefresh
        )
      );
    };

   
    const newProps = {
      ...props,
      isPortalGrid: true,
      datagrid: datagrid,
      entityName: entityName,
      entityId: entityId,
      nodeId: nodeId,
      gridType: GridType.PARENT,
      gridData: cardsData.data,
      primaryDBCode: primaryDBCode,
      insertFormId: insertFormId,
      editFormId: editFormId,
      usePortalReferenceData: props.usePortalReferenceData,
      portalId: props.portalId,
      parentId: props.parentId,
      resetOtherGrids: props.resetOtherGrids,
      location: currentLocation,
    };
    return <React.Fragment>
      {datagrid.isHavingAdvanceFilterForm && <QueryBuilderData fields={filterFields} handleSearch={handleSearch} themeContext={themeContext} />}
      <UIBasicTableGenerator {...newProps} />
    </React.Fragment>;
  } catch {
    return <div>No data available...</div>;
  }
};
function isPropsAreEqual(prevProps: any, nextProps: any) {
  return (
    JSON.stringify(prevProps.referenceData) ===
    JSON.stringify(nextProps.referenceData) &&
    prevProps.editableGridEnable === nextProps.editableGridEnable
  );
}
export default React.memo(PortalGridGenerator, isPropsAreEqual);
