import { Card, CardBody, CardHeader } from 'reactstrap';
import { resolveColor } from '../theme/ThemeOptions';
import styled from 'styled-components';
export const StyledCard: any = styled(Card)`
  width: 100%;
  height: 100%;
  margin-bottom: 4px !important;
  box-shadow: ${(p: any) =>
    p.shadow === true ? '0 1px 4px 1px rgba(0,0,0,0.10)' : 'none'} !important;
  border-radius: ${(p: any) => (p.shadow === true ? '4px' : '0px')} !important;
  border: ${(p: any) =>
    p.shadow === true ? '1px solid rgba(0,0,0,.125)' : 'none'} !important;
`;

export const StyledCardHeader: any = styled(CardHeader)`
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
`;
export const StyledChartCard: any = styled.div`
  border: none !important;
  margin-left: 0px !important;
  margin-right: 0px !important;
  margin-bottom: 2px !important;
`;
export const StyledChartBody: any = styled.div`
  padding: 1px !important;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  &::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
    background: whitesmoke !important;
  }
  &::-webkit-scrollbar-thumb {
    background: grey !important;
    border-radius: 5px;
  }
`;
export const StyledCardBody: any = styled(CardBody)`
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  padding-left: 2px !important;
  padding-right: 2px !important;
  padding: 2px !important;
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 5px !important;
    height: 5px !important;
    background: whitesmoke !important;
  }
  &::-webkit-scrollbar-thumb {
    background: grey !important;
    border-radius: 5px;
  }
`;

export const StyledDiv: any = styled.div`
  border-color: ${(p: any) =>
    resolveColor(p.ioTheme, p.ioMode, p.styleName, 'borderColor') +
    '!important'};
  padding: 2px;
  margin: 5px;
`;
