import { Form } from 'react-bootstrap';
import styled from 'styled-components';

export interface InputProps {
  fieldActivated: boolean;
  inlineError: boolean;
}

export interface Props {
  id: string;
  dispalyLabel: any;
  onChange: any;
  value: string;
  inlineError: boolean;
  type: string;
  inlineMsg: any;
  setInLineError: any;
  handleChange?: any;
}

export const FloatingLabelInputWrapper: any = styled(Form.Control)`
  border-width: 1.5px !important;
  border-radius: 3px !important;
  border-color: #004269 !important;
  box-sizing: border-box !important;
  font-size: 15px !important;
  font-weight: 800 !important;
  line-height: 1.5 !important;
  color: #222222 !important;
  display: block !important;
  width: 100% !important;
  background: rgba(255, 255, 255, 0.6) !important;
  height: 50px !important;
  padding: 0 10px 0 15px !important;
  box-shadow: ${(p: any) =>
    p.fieldActivated
      ? ' 0 0 0 1000px rgba(255, 255, 255, 0.98) inset !important'
      : ''} !important;
`;

export const LabelContainer: any = styled.label`
  font-family: '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen","Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",sans-serif' !important;
  display: inline-block !important;
  pointer-events: none !important;
  position: absolute !important;
  left: 15px !important;
  top: 13px !important;
  font-weight: 500 !important;
  background: #f0fbfd !important;
  border: 1px solid #004269 !important;
  transition: all 150ms ease-in !important;
  color: #004269 !important;
  width: auto !important;
  z-index: ${(p: any) => (p.fieldActivated ? '5' : '4')} !important;
  transform: ${(p: any) =>
    p.fieldActivated ? 'translateY(-24px)' : ''} !important;
  font-size: ${(p: any) => (p.fieldActivated ? '.8em' : '14px')} !important;
  margin: ${(p: any) => (p.fieldActivated ? '0px 0px' : '')} !important;
`;

export const BuilderContainer: any = styled.div`
  position: relative !important;
  width: ${(p: any) => (p.width ? p.width : '100%')} !important;
  z-index: 1 !important;
  margin-top: 13px !important;
`;
