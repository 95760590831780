import React from 'react';
import { PhoneNumberInput } from 'info-ui-library';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import DataGridToolTip from './DataGridToolTip';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons/faTriangleExclamation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const EditableCell = ({ cell, row, column: { id }, updateMyData }) => {
  const [isEditable, setEditable] = React.useState(false);
  const [value, setValue] = React.useState(cell.value);
  const [previousValue, setPreviousValue] = React.useState(cell.value);
  //WHEN INLINE EDIT IS DISABLE AND ADD NEW ROW IS ENABLE
  let cellEditable =
    row.original &&
    row.original['expander'] === 'NewRow' &&
    row.values[cell.column.primaryDBCode] === ''
      ? true
      : false;

  React.useEffect(() => {
    setValue(cell.value);
    setPreviousValue(cell.value);
  }, [cell.value]);

  const onChange = (e) => {
    // setPreviousValue(value);
    setValue(e);
  };

  let errorData = cell.column.useErrorDataUsingDBCode
    ? cell.column.useErrorDataUsingDBCode(
        row.id.toString(),
        id,
        cell.column.referralId,
        true
      )
    : null;

  React.useEffect(() => {
    if (
      errorData &&
      errorData.dbCode === id &&
      errorData.path === row.id.toString()
    ) {
      setEditable(true);
    } else {
      setEditable(false);
    }
  }, [errorData]);

  const handleBlur = () => {
    const timeout = setTimeout(() => {
      if (
        isEditable &&
        !(
          errorData &&
          errorData.dbCode === id &&
          errorData.path === row.id.toString()
        )
      ) {
        setEditable(false);
      }
      if (value !== previousValue && updateMyData) {
        updateMyData(row.index, id, value, row.id);
      }
    }, 10);
  };

  const handleClick = () => {
    if (
      !(
        errorData &&
        errorData.dbCode === id &&
        errorData.path === row.id.toString()
      )
    ) {
      if (errorData || (errorData && errorData.path !== row.id.toString())) {
        setEditable(false);
      } else if (!isEditable) {
        setEditable(true);
      }
    }
  };

  let tooltipID = 'phoneNumberError_' + id + '_' + row.id.toString();

  return (cell.column.editEnable || cellEditable) &&
    cell.column.isDefaultEditable &&
    cell.column?.changeEnable ? (
    <div
      style={{
        display: 'flex',
        'flex-wrap': 'wrap',
        width: cell.column.width + 'px',
        position: 'absolute',
      }}
    >
      {errorData && errorData['dbCode'] && errorData['dbCode'] === id ? (
        <FontAwesomeIcon
          icon={faTriangleExclamation}
          aria-hidden="true"
          data-for={tooltipID}
          data-tip={tooltipID}
          style={{
            'align-self': 'center',
            'padding-left': '3px',
            color: 'red',
          }}
        />
      ) : (
        ''
      )}
      <div
        data-tooltip-id={errorData?.message}
        data-tooltip-content={errorData?.message}
        style={{
          border:
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? '1px solid red'
              : 'none',
          width:
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? '90%'
              : '99%',
          borderRadius: '5px',
        }}
      >
        <PhoneNumberInput
          value={value}
          handleChange={(e) => onChange(e)}
          handleBlur={(e) => onChange(e)}
          styleName={cell.column.styleName}
          ioTheme={cell.column.ioTheme}
          ioMode={cell.column.ioMode}
          label={''}
          displayType={'SingleLine'}
          isInlineGridComponent={true}
          isFilterComponent={true}
          style={{ border: 'unset' }}
        />
        <ReactTooltip
          place={'bottom'}
          style={{
            color: 'white !important',
            backgroundColor:
              errorData && errorData['dbCode'] && errorData['dbCode'] === id
                ? 'rgb(255, 0, 0)'
                : 'none',
          }}
          id={
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? errorData.message
              : ''
          }
        />
      </div>
    </div>
  ) : (cell.column.editEnable || cellEditable) &&
    isEditable &&
    cell.column?.changeEnable ? (
    <React.Fragment>
      <div
        style={{
          border:
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? '1px solid red'
              : 'none',
          width: cell.column.width + 'px',
          height: '40px',
          display: 'inherit',
          borderRadius: '5px',
          border:
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? '1px solid red'
              : 'none',
        }}
        data-tooltip-id={errorData?.message}
        data-tooltip-content={errorData?.message}
      >
        <PhoneNumberInput
          value={value}
          handleChange={(e) => onChange(e)}
          handleBlur={(e) => onChange(e)}
          styleName={cell.column.styleName}
          ioTheme={cell.column.ioTheme}
          ioMode={cell.column.ioMode}
          label={''}
          displayType={'SingleLine'}
          isInlineGridComponent={true}
          isFilterComponent={true}
          style={{
            border:
              errorData && errorData['dbCode'] && errorData['dbCode'] === id
                ? 'unset'
                : '',
          }}
          errorMessage={errorData}
        />
        <ReactTooltip
          place={'bottom'}
          style={{
            color: 'white !important',
            zIndex: 9999,
            backgroundColor:
              errorData && errorData['dbCode'] && errorData['dbCode'] === id
                ? 'rgb(255, 0, 0)'
                : 'none',
          }}
          id={
            errorData && errorData['dbCode'] && errorData['dbCode'] === id
              ? errorData.message
              : ''
          }
        />
      </div>
    </React.Fragment>
  ) : (
    <React.Fragment>
      {cell.isRepeatedValue !== true &&
      value !== undefined &&
      value !== null &&
      value !== '' ? (
        <div
          onBlur={() => handleBlur()}
          onClick={() => handleClick()}
          style={{
            padding: '10px 10px',
            // width: cell.column.width + 'px',
            height: '100%',
          }}
        >
          <DataGridToolTip width={cell.column.width} tooltipValue={value} />
        </div>
      ) : (
        <div
          dangerouslySetInnerHTML={{ __html: '&nbsp;' }}
          style={{
            width: cell.column.width + 'px',
            height: '40px',
          }}
          onBlur={() => handleBlur()}
          onClick={() => handleClick()}
        />
      )}
    </React.Fragment>
  );
};

export default React.memo(EditableCell);
