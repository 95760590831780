import gql from 'graphql-tag';

export const Roles = gql`
  query Roles {
    Roles {
      aeRoleUuid
      roleId
      roleName
      description
      aeApplicationUuid
      tenantId
      aeInsertId
      aeUpdateId
      aeInsertTs
      aeUpdateTs
      aeTransactionId
    }
  }
`;
