import SelectColumnFilter from '../components/filterGridComponents/SelectColumnFilter';
import DatePickerFilter from '../components/filterGridComponents/DatePickerFilter';
import DefaultColumnFilter from '../components/filterGridComponents/DefaultColumnFilter';
import TextBoxEditableCell from '../components/editableGridComponents/TextBoxEditableCell';
import SelectOptionEditable from '../components/editableGridComponents/SelectOptionEditable';
import RadioButtonEditableCell from '../components/editableGridComponents/RadioButtonEditableCell';
import ActionButton from '../components/editableGridComponents/ActionButton';
import DatePickerCell from '../components/editableGridComponents/DatePickerCell';
import ExpanderForSubRows from '../components/dataGridComponents/ExpanderForSubRows';
import DefaultCell from '../components/editableGridComponents/DefaultCell';
import PreRenderer from '../components/editableGridComponents/PreRenderer';
import MobileViewCell from '../components/editableGridComponents/MobileViewCell';

import StarRatingComponent from '../components/editableGridComponents/StarRatingComponent';
import TextAreaEditableCell from '../components/editableGridComponents/TextAreaEditableCell';
import HyperLinkColumnCell from '../components/editableGridComponents/HyperLinkColumnCell';
import AuditColumnJsonCell from '../components/editableGridComponents/AuditColumnJsonCell';
import MultiSelectEditable from '../components/editableGridComponents/MultiSelectEditable';
import MultiSelectColumnFilter from '../components/filterGridComponents/MultiSelectColumnFilter';
import PhoneNumberEditableCell from '../components/editableGridComponents/PhoneNumberEditable';
import TimePickerCell from '../components/editableGridComponents/TimePickerCell';
import TimePickerFilter from '../components/filterGridComponents/TimePickerFilter';
import DateTimePickerCell from '../components/editableGridComponents/DateTimePickerCell';
import DateTimePickerFilter from '../components/filterGridComponents/DateTimePickerFilter';
export const columnExtracter = (formfieldType: any) => {
  switch (formfieldType) {
    case 'TextBox':
      return TextBoxEditableCell;
      break;
    case 'TextArea':
      return TextAreaEditableCell;
      break;
    case 'SelectOption':
      return SelectOptionEditable;
      break;
    case 'MultiSelect':
      return MultiSelectEditable;
      break;
    case 'PhoneNumber':
      return PhoneNumberEditableCell;
      break;
    case 'RadioButton':
      return RadioButtonEditableCell;
      break;
    case 'DatePicker':
      return DatePickerCell;
      break;
    case 'TimePicker':
      return TimePickerCell;
      break;
    case 'DateTimePicker':
      return DateTimePickerCell;
      break;
    case 'ActionButton':
      return ActionButton;
      break;
    case 'ExpandableColumn':
      return ExpanderForSubRows;

    case 'Rating':
      return StarRatingComponent;
    case 'DefaultCell':
      return PreRenderer;
    case 'HyperLink':
      return HyperLinkColumnCell;
    case 'AuditColumnJson':
      return AuditColumnJsonCell;
    default:
      return MobileViewCell;
  }
};

export const filterExtracter = (formfieldType: any) => {
  switch (formfieldType) {
    case 'SelectOption':
      return SelectColumnFilter;
      break;
    case 'MultiSelect':
      return MultiSelectColumnFilter;
      break;
    case 'DatePicker':
      return DatePickerFilter;
      break;
    case 'TimePicker':
      return TimePickerFilter;
      break;
    case 'DateTimePicker':
      return DateTimePickerFilter;
      break;
    default:
      return DefaultColumnFilter;
  }
};
