import React from 'react';
import { themes } from './ThemeOptions';
import cloneDeep from 'lodash/cloneDeep';
import { createStorage } from '../storage';
import { SELECTED_THEME_LOCAL_STORAGE_KEY } from '../constants/appeng.enum';

interface ContextProps {
  ioMode: string;
  toggleMode: (ioMode: string) => void;
  ioTheme: {};
  toggleTheme: (ioTheme: {}) => void;
  styleName: string;
  toggleStyleName: (styleName: string) => void;
  componentType: string;
}

const createDefaultTheme = (): ContextProps => ({
  ioMode: 'light',
  toggleMode: () => {},
  ioTheme: themes.filter((t) => t.name === 'default')[0],
  toggleTheme: () => {},
  styleName: 'primary',
  toggleStyleName: () => {},
  componentType: 'FloatingLabel',
});

export const ThemeContext =
  React.createContext<ContextProps>(createDefaultTheme());

export const ThemeContextProvider: React.FC<any> = (props: any) => {
  const storage = createStorage();
  const selectedTheme = storage.getItem(SELECTED_THEME_LOCAL_STORAGE_KEY);
  const [ioTheme, toggleTheme] = React.useState(
    themes.filter((t) => t.name === 'default')[0]
  );
  const [ioMode, toggleMode] = React.useState('light');
  const [styleName, toggleStyleName] = React.useState(
    selectedTheme ? selectedTheme : 'primary'
  );

  const [componentType, setComponentType] = React.useState('FloatingLabel');
  const setUserSelectedTheme = (themeName: any) => {
    const selectedTheme: any = themes.filter((t) => t.name === themeName)[0];
    const defaultTheme = themes.filter((t) => t.name === 'default')[0];
    const updatedTheme: any = cloneDeep(defaultTheme);
    Object.keys(selectedTheme.ioMode).forEach(function (key) {
      if (updatedTheme.ioMode.hasOwnProperty(key)) {
        Object.assign(updatedTheme.ioMode[key], selectedTheme.ioMode[key]);
      } else {
        updatedTheme.ioMode[key] = selectedTheme.ioMode[key];
      }
    });
    updatedTheme.name = selectedTheme.name;
    updatedTheme.uiLibrary = selectedTheme.uiLibrary;
    toggleMode('light');
    toggleTheme(updatedTheme);
  };

  const setUserSelectedMode = (ioMode: any) => {
    toggleMode(ioMode);
  };

  const setUserSelectedStyleName = (styleName: any) => {
    toggleStyleName(styleName);
  };

  return (
    <ThemeContext.Provider
      value={{
        ioMode,
        toggleMode: setUserSelectedMode,
        ioTheme,
        toggleTheme: setUserSelectedTheme,
        styleName,
        toggleStyleName: setUserSelectedStyleName,
        componentType,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};
