import { ErrorCodeEnum } from '../constants';

export default class AuthError extends Error {
  public code?: ErrorCodeEnum;
  public userId?: string;
  constructor(m?: string, code?: ErrorCodeEnum, userId?: string) {
    super(m);

    Object.setPrototypeOf(this, AuthError.prototype);
    this.code = code;
    this.userId = userId;
  }
}
