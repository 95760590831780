import * as React from 'react';
import { MessageContainer, PopUpText } from './ErrorMessageComponentStyle';

const ErrorMsgComponent: React.FC<{
  showMessage: boolean;
  pass: boolean;
  message: string;
}> = (props) => {
  return (
    <MessageContainer>
      {props.showMessage && (
        <PopUpText pass={props.pass}>{props.message}</PopUpText>
      )}
    </MessageContainer>
  );
};

export default ErrorMsgComponent;
