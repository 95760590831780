import {
  AuthProviderEnum,
  parseAuthProviderEnum,
} from '../constants/provider.enum';
import AuthProvider from './auth.provider';
import createAWSProvider from './aws.auth.provider';
import createInfoAuthProvider from './info.auth.provider';
import createOktaProvider from './okta.auth.provider';
import createMicrosoftADProvider from './microsoft-ad.auth.provider';

export { default as createInfoAuthProvider } from './info.auth.provider';
export { InfoAuthProvider } from './info.auth.provider';

export { default as createOktaProvider } from './okta.auth.provider';
export { default as createMicrosoftADProvider } from './microsoft-ad.auth.provider';
export { OktaAuthProvider } from './okta.auth.provider';

export const createProvider = (config: any): AuthProvider => {
  const providerEnum = parseAuthProviderEnum(config.provider.type);
  switch (providerEnum) {
    case AuthProviderEnum.AWS:
      return createAWSProvider(config);
    case AuthProviderEnum.INFOAUTH:
      return createInfoAuthProvider(config);
    case AuthProviderEnum.OKTA:
      return createOktaProvider(config);
    case AuthProviderEnum.MICROSOFT_AD_INFO_APPS:
    case AuthProviderEnum.MICROSOFT_AD:
      return createMicrosoftADProvider(config);
    default:
      throw new Error(
        'Invalid or Unsupported Authentication provider configuration: ' +
          providerEnum
      );
  }
};
