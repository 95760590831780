export const SET_TOGGLE_MESSAGES = 'SET_TOGGLE_MESSAGES';
export const SET_WARNING_MESSAGES = 'SET_WARNING_MESSAGES';

export interface ISetToggleMessages {
  type: string;
  messages: string[];
}

export interface IWarningMessages {
  type: string;
  messages: {};
}

export function setToggleMessages(messages: string[]): ISetToggleMessages {
  return {
    type: SET_TOGGLE_MESSAGES,
    messages: messages,
  };
}

export function setWarningMessages(messages: any): IWarningMessages {
  return {
    type: SET_WARNING_MESSAGES,
    messages: messages,
  };
}
