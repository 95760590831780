export const SET_TREE_DATA = 'SET_TREE_DATA';

export interface ISetTreeData {
  type: string;
  nodeId: string;
  treeData: any;
}

export function setTreeData(nodeId: string, treeData: any): ISetTreeData {
  return {
    type: SET_TREE_DATA,
    nodeId: nodeId,
    treeData: treeData,
  };
}
