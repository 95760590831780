import React, { useCallback, useContext, useState } from 'react';
import { injectIntl } from 'react-intl';
import { FormErrorContainers, SplitButton } from 'info-ui-library';
import { ThemeContext } from '../theme/ThemeContext';
import { extractLabel } from '../utils/intlutils';

export interface ISplitButtonCore {
  handleClick: any;
  intl: any;
  styleName: any;
  ioTheme: any;
  ioMode: any;
  configId: any;
  name: any;
  disabled: any;
  buttonClass: any;
  buttons: any[];
  showloadingSymbol: boolean;
  childRelations: any;
  disableMainMenuButton: any;
  modalRequired?: any;
  portalId?: string;
}

const SplitButtonCore: React.FC<ISplitButtonCore> = (
  props: ISplitButtonCore
) => {
  const themeContext = useContext(ThemeContext);
  try {
    const handleClick = useCallback(
      (
        buttonClass: string,
        lable: string,
        childRelations: any[],
        configObjectId: string,
        buttonDetails: any
      ) => {
        props.handleClick(
          buttonClass,
          lable,
          false,
          childRelations,
          configObjectId,
          buttonDetails
        );
      },
      []
    );

    return (
      <SplitButton
        menuLabel={extractLabel(props.name, props.intl.locale)}
        menuButtons={props.buttons}
        styleName={themeContext.styleName}
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        configId={props.configId}
        disabled={props.disabled}
        handleClick={handleClick}
        buttonClass={props.buttonClass}
        showloadingSymbol={props.showloadingSymbol}
        childRelations={props.childRelations}
        disableMainMenuButton={props.disableMainMenuButton}
        modalRequired={props.modalRequired}
        portalId={props.portalId}
      />
    );
  } catch {
    const error = ['Error occurred in TextBox'];
    return (
      <FormErrorContainers
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};

export default React.memo(injectIntl(SplitButtonCore));
