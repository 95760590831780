import IStorage from './storage.interface';

let memoryCache = new Map<string, string>();

export class SessionStorage implements IStorage {
  private config: any;

  constructor(config: any) {
    this.config = config;
  }

  public setItem(key: string, value: any | null): any | null {
    if (!(value == null || value.length === 0)) {
      window.sessionStorage.setItem(key, value);
      return window.sessionStorage.getItem(key);
    }
    return null;
  }

  public getItem(key: string): any | null {
    return window.sessionStorage.getItem(key);
  }

  public removeItem(key: string): boolean {
    if (this.getItem(key)) {
      window.sessionStorage.removeItem(key);
      return true;
    } else {
      return false;
    }
  }

  public clear(): {} {
    memoryCache = new Map<string, string>();
    return {};
  }
}
