import { ROOT_NODE_ID, CardType } from '../../../constants/appeng.enum';
import { call, put } from 'redux-saga/effects';
import {
  executeJavascriptPreprocessor,
  sortConfigObjects,
} from '../../../utils/ClientUtils';
import {
  executeDatabasePreProcessors,
  executeDatabasePreProcessorsList,
} from './executeDatabasePreprocessor';
import { executeActionFlowPreprocessor } from './executeActionFlowPreprocessor';
import CardData from '../../../models/carddata.model';
import { setFormData, setToggleMessages } from '../../../actions';

export function* preprocessorExecuter(
  action: any,
  formData: any,
  configData: any,
  existingCardData: any
): Generator<any, any, any> {
  const cardData = new CardData(
    action.referralId,
    CardType.FORM,
    formData,
    existingCardData[action.referralId].portalId,
    existingCardData[action.referralId].parentId,
    existingCardData[action.referralId].referenceData,
    existingCardData[action.referralId].errorData,
    existingCardData[action.referralId].options,
    existingCardData[action.referralId].datagridErrorData,
    existingCardData[action.referralId].toggleTabRefresh,
    existingCardData[action.referralId].editableGrid,
    existingCardData[action.referralId].workflowActions,
    existingCardData[action.referralId].tabGroupId,
    existingCardData[action.referralId].entityName,
    existingCardData[action.referralId].tabPortalId,
    existingCardData[action.referralId].entityId,
    existingCardData[action.referralId].parentCardId,
    existingCardData[action.referralId].enableFilterInGrid,
    existingCardData[action.referralId].buttonClicked,
    true,
    existingCardData[action.referralId].isAttachTypeApplicable
  );
  yield put(setFormData(cardData, action.referralId, false, false, false));
  let resetFFList = action.resetOnRefresh
    ? action.resetOnRefresh.split(',')
    : [];
  let preprocessorFFList = resetFFList;
  preprocessorFFList.push(action.formFieldId);

  if (action.nodeId && action.nodeId !== ROOT_NODE_ID) {
    formData = yield call(
      executeCompositeEntityPreprocessor,
      configData,
      formData,
      action.mode,
      false,
      preprocessorFFList,
      action.referralId
    );
  } else if (configData.Form.logicalEntity) {
    formData = yield call(
      executeFormPreprocessor,
      configData.Form,
      formData,
      false,
      preprocessorFFList,
      action.referralId
    );
  }
  return formData;
}

export const getForm = (node: any, mode: any) => {
  return mode === 'Edit'
    ? node.editForm
      ? node.editForm
      : node.insertForm
    : node.insertForm
      ? node.insertForm
      : node.editForm;
};

export function* executeFormPreprocessor(
  parentForm: any,
  formData: any,
  isExecuteAll: any,
  preprocessorFFList: any,
  referralId: any
): Generator<any, any, any> {
  formData = yield call(
    executePreProcessors,
    parentForm.columnDataPreprocessors,
    formData,
    parentForm.configObjectId,
    'Form',
    referralId
  );

  if (isExecuteAll) {
    formData = yield call(
      excuteFormFieldOnLoadPreprocessor,
      parentForm.formSections,
      formData,
      isExecuteAll,
      preprocessorFFList,
      referralId
    );
  } else {
    formData = yield call(
      excuteFormFieldPreprocessor,
      parentForm.formSections,
      formData,
      isExecuteAll,
      preprocessorFFList,
      referralId
    );
  }

  return formData;
}

export function* executeCompositeEntityPreprocessor(
  data: any,
  formData: any,
  mode: any,
  isExecuteAll: any,
  preprocessorFFList: any,
  referralId: any
): Generator<any, any, any> {
  const parentForm = getForm(data.CompositeEntityNode, mode);
  formData = yield call(
    executePreProcessors,
    parentForm.columnDataPreprocessors,
    formData,
    parentForm.configObjectId,
    'Form',
    referralId
  );

  if (isExecuteAll) {
    formData = yield call(
      excuteFormFieldOnLoadPreprocessor,
      parentForm.formSections,
      formData,
      isExecuteAll,
      preprocessorFFList,
      referralId
    );
  } else {
    formData = yield call(
      excuteFormFieldPreprocessor,
      parentForm.formSections,
      formData,
      isExecuteAll,
      preprocessorFFList,
      referralId
    );
  }
  if (data.CompositeEntityNode.combinedNodes) {
    for (const node of data.CompositeEntityNode.combinedNodes) {
      const childForm = getForm(node, mode);
      formData = yield call(
        executePreProcessors,
        childForm.columnDataPreprocessors,
        formData,
        childForm.configObjectId,
        'Form',
        referralId
      );
      if (isExecuteAll) {
        formData = yield call(
          excuteFormFieldOnLoadPreprocessor,
          childForm.formSections,
          formData,
          isExecuteAll,
          preprocessorFFList,
          referralId
        );
      } else {
        formData = yield call(
          excuteFormFieldPreprocessor,
          childForm.formSections,
          formData,
          isExecuteAll,
          preprocessorFFList,
          referralId
        );
      }
    }
  }
  return formData;
}

function* excuteFormFieldOnLoadPreprocessor(
  formSections: any,
  formData: any,
  isExecuteAll: any,
  preprocessorFFList: any,
  referralId: any
): Generator<any, any, any> {
  let configIds: any = [];
  for (const formSection of formSections) {
    for (const ff of formSection.formFields) {
      if (
        isExecuteAll ||
        (!isExecuteAll && preprocessorFFList.includes(ff.configObjectId))
      ) {
        if (ff.columnDataPreprocessors) {
          const customPreprocessor = ff.columnDataPreprocessors.find(
            (processor: any) =>
              !processor.excecutionType || processor.excecutionType === 'Custom'
          );
          const isCustomPreprocessorPresent = customPreprocessor ? true : false;
          if (isCustomPreprocessorPresent) {
            configIds.push(ff.configObjectId);
          } else {
            formData = yield call(
              executePreProcessors,
              ff.columnDataPreprocessors,
              formData,
              ff.configObjectId,
              'FormField',
              referralId
            );
          }
        }
      }
    }
  }
  if (configIds.length) {
    formData = yield call(
      executeDatabasePreProcessorsList,
      formData,
      configIds,
      'FormField'
    );
  }
  return formData;
}

function* excuteFormFieldPreprocessor(
  formSections: any,
  formData: any,
  isExecuteAll: any,
  preprocessorFFList: any,
  referralId: any
): Generator<any, any, any> {
  for (const formSection of formSections) {
    for (const ff of formSection.formFields) {
      if (
        isExecuteAll ||
        (!isExecuteAll && preprocessorFFList.includes(ff.configObjectId))
      ) {
        formData = yield call(
          executePreProcessors,
          ff.columnDataPreprocessors,
          formData,
          ff.configObjectId,
          'FormField',
          referralId
        );
      }
    }
  }
  return formData;
}

function* executePreProcessors(
  columnDataPreprocessors: any,
  formData: any,
  configId: any,
  configType: any,
  referralId: any
): Generator<any, any, any> {
  if (columnDataPreprocessors) {
    const customPreprocessor = columnDataPreprocessors.find(
      (processor: any) =>
        !processor.excecutionType || processor.excecutionType === 'Custom'
    );
    const isCustomPreprocessorPresent = customPreprocessor ? true : false;
    if (isCustomPreprocessorPresent) {
      formData = yield call(
        executeDatabasePreProcessors,
        formData,
        configId,
        configType
      );
    }
    sortConfigObjects(columnDataPreprocessors);
    for (const cdp of columnDataPreprocessors) {
      switch (cdp.excecutionType) {
        case 'javaScript': {
          let response = executeJavascriptPreprocessor(cdp.jsCode, formData);
          if (response && response.errors) {
            const messages: string[] = [];
            messages.push(cdp && cdp.name ? cdp.name + response.errors : '');
            yield put(setToggleMessages(messages));
          }
          break;
        }
        case 'ActionFlow': {
          const actionFlowID = cdp.actionFlows
            ? cdp.actionFlows[0].configObjectId
            : '';
          if (actionFlowID) {
            formData = yield call(
              executeActionFlowPreprocessor,
              actionFlowID,
              formData,
              cdp,
              referralId
            );
          }
          break;
        }
      }
    }
  }
  return formData;
}
