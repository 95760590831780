import React, { useCallback } from 'react';
import { Bar } from 'info-ui-library';
import { Charts } from 'info-ui-library';
import { removeCardDataByReferralId } from '../../../actions';
const ExpandableSubComponents = (props) => {
  const row = props.row;
  var dataModifier = useCallback(() => {
    if (row.isExpanded) {
      props.dispatch(
        removeCardDataByReferralId(
          row.original[props.pkDBCode] + '_' + props.editFormId
        )
      );
    }
    row.toggleRowExpanded();
  }, []);
  const newProps = {
    formType: 'Edit',
    ceId: props.ceId,
    nodeId: props.nodeId,
    referralId: props.editFormId,
    primaryDBCode: props.pkDBCode,
    insertFormId: props.insertFormId,
    editFormId: props.editFormId,
    data: row.original,
  };

  const ParentForm = props.parentForm;
  return (
    <React.Fragment>
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'stretch',
          justifyContent: 'space-around',
        }}
      >
        <Bar
          styleName={props.styleName}
          ioTheme={props.ioTheme}
          ioMode={props.ioMode}
        />
        {props.parentForm ? (
          <div style={{ width: '100%' }}>
            <ParentForm
              mode={newProps.formType}
              resetForm={false}
              parentFormId={newProps.editFormId}
              modalRequired={false}
              routerParameter={newProps}
              referralId={
                row.original[props.pkDBCode] + '_' + newProps.referralId
              }
              portalForm={true}
              handleClick={dataModifier}
            />
          </div>
        ) : (
          <div style={{ width: '100%' }}>
            <div
              style={{
                padding: '10px',
                float: 'left',
                width: '400px',
              }}
            >
              <div
                style={{
                  fontWeight: 'bold',
                }}
              >
                Details
              </div>
              <div>{'- First Name : ' + row.values.firstName}</div>
              <div>{'- Last Name  : ' + row.values.lastName}</div>
              <div>{'- Age        : ' + row.values.age}</div>
              <div>{'- Status        : ' + row.values.status}</div>
              <div>{'- Visits        : ' + row.values.visits}</div>
            </div>
            <div
              style={{
                padding: '10px',
                float: 'left',
                width: '400px',
              }}
            >
              <div
                style={{
                  fontWeight: 'bold',
                }}
              >
                Address
              </div>
              <div>{'- 20 Soojian Dr'}</div>
              <div>{'- North Attleboro MA 2760,'}</div>
              <div>{'- Topeka, KS – 66603, USA '}</div>
            </div>
            <div
              style={{
                float: 'left',
                width: '200px',
              }}
            >
              <Charts
                ioTheme={props.ioTheme}
                ioMode={props.ioMode}
                styleName={props.styleName}
                width={150}
                series={[76]}
                label="Profile Completion"
                valueFontSize={15}
                labelFontSize={10}
              />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default React.memo(ExpandableSubComponents);
