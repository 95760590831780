export const themes = [
  {
    name: 'default',
    ioMode: {
      dark: {
        primary: {
          background: '#0073aa',
          color: 'white',
          borderColor: '#0073aa',
          hoverBGColor: '#fdfdfdf7',
          tableBorderColor: '1px solid white',
          tableBackground: '#ffffff',
          tableHeaderColor: '#20a8d8',
          tableAccentColor: '#bce6f5',
          tableHoverColor: '#f2f2f2',
          menubackground: '#0073aa',
          menucolor: 'white',
          menuhoverbgcolor: '',
          menuhovercolor: '',
          footerbackground: '#0073aa',
          leftmenubackgroundlevel1: '#21DDE4',
          leftmenubackgroundlevel2: '#5B65C3',
          leftmenubackgroundlevel3: '#98A0E9',
          leftmenubackgroundlevel4: 'purple',
          leftmenubackgroundlevel5: 'purple',
          leftmenucolor: 'black',
          leftmenuhoverbgcolor: '#B4F1F7',
          leftmenuhovercolor: 'black',
          leftmenuParentOpenedBackground: 'blue',
          leftmenuSelectedBackground: '#16575E',
          leftmenuSelectedColor: 'white',
          leftmenuarrowcolor: '%2374461f',
          leftmenuicon: 'white',
          active: '#112469',
          inkbar: 'deepskyblue',
          textcolor: 'gray',
          inlineForm: 'white!important',
          inlineRowSelected: '#17a2b8!important',
          inlineGridSuccess: '#4dff88!important',
          inlineGridDanger: '#e60000!important',
          attachmentBackground: '#f8fdff',
          borderLeft: '#1ea2b8',
        },
        secondary: {
          background: '#868e96',
          color: 'white',
          borderColor: '#868e96',
          hoverBGColor: '#fdfdfdf7',
          tableBorderColor: '1px solid White',
          tableBackground: '#ffffff',
          tableHeaderColor: '#394047',
          tableAccentColor: '#ffffff',
          tableHoverColor: '#f2f2f2',
          menubackground: '#868e96',
          menucolor: 'white',
          menuhoverbgcolor: '#fdfdfdf7',
          menuhovercolor: '',
          leftmenubackground: '#4E4B4B',
          leftmenucolor: 'red',
          leftmenuhoverbgcolor: '#fdfdfdf7',
          leftmenuhovercolor: 'black',
          leftmenuParentOpenedBackground: 'black',
          leftmenuSelectedBackground: '#2C2626',
          leftmenuSelectedColor: 'white',
          leftmenuarrowcolor: '%2374401f',
          leftmenuicon: 'black',
          active: '#595A5C',
          inkbar: 'deepskyblue',
          textcolor: 'gray',
          inlineForm: 'white!important',
          inlineRowSelected: '#17a2b8!important',
          inlineGridSuccess: '#4dff88!important',
          inlineGridDanger: '#e60000!important',
          attachmentBackground: '#fafafa',
          borderLeft: '#1ea2b8',
        },
        success: {
          background: '#28a745',
          color: 'white',
          borderColor: '#28a745',
          hoverBGColor: '#fdfdfdf7',
          tableBorderColor: '1px solid White',
          tableBackground: '#ffffff',
          tableHeaderColor: '#30824d',
          tableAccentColor: '#daf1e2',
          tableHoverColor: '#f2f2f2',
          menubackground: '#28a745',
          menucolor: 'white',
          menuhoverbgcolor: '#fdfdfdf7',
          menuhovercolor: '',
          leftmenubackground: '#145E23',
          leftmenucolor: 'white',
          leftmenuhoverbgcolor: '#B6FAC4',
          leftmenuhovercolor: 'black',
          leftmenuParentOpenedBackground: '#053A10',
          leftmenuSelectedBackground: '#053A10',
          leftmenuSelectedColor: 'white',
          active: 'green',
          inkbar: 'deepskyblue',
          textcolor: 'gray',
          inlineForm: 'white!important',
          inlineRowSelected: '#17a2b8!important',
          inlineGridSuccess: '#4dff88!important',
          inlineGridDanger: '#e60000!important',
          attachmentBackground: '#e8faec',
          borderLeft: '#1ea2b8',
        },
        info: {
          background: '#1997c6',
          color: 'white',
          borderColor: '#1997c6',
          hoverBGColor: '#fdfdfdf7',
          tableBorderColor: '1px solid white',
          tableBackground: '#ffffff',
          tableHeaderColor: '#004269',
          tableAccentColor: '#e6fff2',
          tableHoverColor: '#f2f2f2',
          menubackground: '#1997c6',
          menucolor: 'white',
          menuhoverbgcolor: '#fdfdfdf7',
          menuhovercolor: '',
          leftmenubackgroundlevel1: '#1976D2',
          leftmenubackgroundlevel2: '#303F9F',
          leftmenubackgroundlevel3: '#42A5F5',
          leftmenubackgroundlevel4: '#64B5F6',
          leftmenubackgroundlevel5: 'purple',
          leftmenucolor: 'white',
          leftmenuhoverbgcolor: '#B6FAC4',
          leftmenuhovercolor: 'black',
          leftmenuParentOpenedBackground: 'blue',
          leftmenuSelectedBackground: '#053A10',
          leftmenuSelectedColor: 'white',
          leftmenuarrowcolor: '%2372170f',
          active: '#112469',
          inkbar: 'deepskyblue',
          textcolor: 'gray',
          inlineForm: 'white!important',
          inlineRowSelected: '#17a2b8!important',
          inlineGridSuccess: '#4dff88!important',
          inlineGridDanger: '#e60000!important',
          attachmentBackground: '#fafcfd',
          borderLeft: '#1ea2b8',
        },
        warning: {
          background: '#fdff00',
          color: 'white',
          borderColor: '#fdff00',
          hoverBGColor: '#fdfdfdf7',
          tableBorderColor: '1px solid white',
          tableBackground: '#ffffff',
          tableHeaderColor: '#cc9900',
          tableAccentColor: '#ffecb3',
          tableHoverColor: '#f2f2f2',
          menubackground: '#fdff00',
          menucolor: 'black',
          menuhoverbgcolor: '#fdfdfdf7',
          menuhovercolor: '',
          leftmenubackgroundlevel1: '#FB8C00',
          leftmenubackgroundlevel2: '#FFC107',
          leftmenubackgroundlevel3: '#FFEB3B',
          leftmenubackgroundlevel4: '#64B5F6',
          leftmenubackgroundlevel5: 'purple',
          leftmenucolor: 'white',
          leftmenuhoverbgcolor: '#B6FAC4',
          leftmenuhovercolor: 'black',
          leftmenuParentOpenedBackground: '#339900',
          leftmenuSelectedBackground: '#E65100',
          leftmenuSelectedColor: 'white',
          leftmenuarrowcolor: '%2372170f',
          active: '#BCCA18',
          inkbar: 'deepskyblue',
          textcolor: 'gray',
          inlineForm: 'white!important',
          inlineRowSelected: '#17a2b8!important',
          inlineGridSuccess: '#4dff88!important',
          inlineGridDanger: '#e60000!important',
          attachmentBackground: '#ffffeb',
          borderLeft: '#1ea2b8',
        },
        danger: {
          background: '#dc3545',
          color: '#dc3545',
          borderColor: '#dc3545',
          hoverBGColor: '#fdfdfdf7',
          tableBorderColor: '1px solid white',
          tableBackground: '#ffffff',
          tableHeaderColor: '#c30909',
          tableAccentColor: '#fee7e7',
          tableHoverColor: '#f2f2f2',
          menubackground: '#dc3545',
          menucolor: 'black',
          menuhoverbgcolor: 'red',
          menuhovercolor: 'blue',
          leftmenubackgroundlevel1: '#EE2E2E',
          leftmenubackgroundlevel2: '#FF0066',
          leftmenubackgroundlevel3: '#FF66FF',
          leftmenubackgroundlevel4: '#FF9999',
          leftmenubackgroundlevel5: 'purple',
          leftmenucolor: 'black',
          leftmenuhoverbgcolor: '#CAA4A4',
          leftmenuParentOpenedBackground: '#841518',
          leftmenuSelectedBackground: '#66FF33',
          leftmenuSelectedColor: 'white',
          leftmenuarrowcolor: '%2372170f',
          active: '#F9B89D',
          inkbar: 'deepskyblue',
          textcolor: 'gray',
          inlineForm: 'white!important',
          inlineRowSelected: '#17a2b8!important',
          inlineGridSuccess: '#4dff88!important',
          inlineGridDanger: '#e60000!important',
          attachmentBackground: '#fcf1f0',
          borderLeft: '#1ea2b8',
        },
        link: {
          background: 'blue',
          color: 'white',
          borderColor: 'blue',
          hoverBGColor: '#fdfdfdf7',
          menubackground: 'blue',
          menucolor: 'white',
          menuhoverbgcolor: '#fdfdfdf7',
          menuhovercolor: '',
        },
      },
      light: {
        primary: {
          background: '#004269',
          color: 'white',
          modalBGColor: '#0073aa',
          formSectionColor: '#004269',
          gridcolor: '#004269',
          borderColor: '#004269',
          hoverBGColor: '#fdfdfdf7',
          tableBorderColor: '1px solid white',
          tableBackground: '#ffffff',
          tableHeaderColor: '#004269',
          tableAccentColor: '#ffffff',
          tableHoverColor: '#f2f2f2',
          menubackground: '#004269',
          submenubackground: 'transparent',
          menucolor: 'white',
          menuhoverbgcolor: 'white',
          menuhovercolor: 'white',
          menuitemselectedbgcolor: '#0f2d46',
          menuitemselectedcolor: 'white',
          footerbackground: '#004269',
          scrollbarcolor: '#004269',
          scrollbarbgcolor: '#e3dfdf',
          leftmenubackgroundlevel1: '#5cbdcd',
          leftmenubackgroundlevel2: '#8bd0db',
          leftmenubackgroundlevel3: '#b9e3e9',
          leftmenubackgroundlevel4: '#d0ecf0',
          leftmenubackgroundlevel5: '#e7f5f7',
          leftmenuminimizerbuttoncolor: '#4E747F',
          leftmenucolor: 'black',
          leftmenuhoverbgcolor: '#fdfdfdf7',
          leftmenuhovercolor: 'black',
          leftmenusideinkbarcolor: '#525b63',
          leftmenuParentOpenedBackground: '#0d616e',
          leftmenuSelectedBackground: '#1491a5',
          leftmenuSelectedColor: 'black',
          leftmenuarrowcolor: 'black',
          leftmenuicon: 'black',
          active: '#112469',
          inkbar: 'deepskyblue',
          textcolor: '#0c0c0d',
          inlineForm: 'white!important',
          inlineRowSelected: '#17a2b8!important',
          inlineGridSuccess: '#4dff88!important',
          inlineGridDanger: '#e60000!important',
          attachmentBackground: '#fafcfd',
          borderLeft: '#004269',
        },
        secondary: {
          background: '#c8ced3',
          color: '#5b6667',
          formSectionColor: 'gray',
          gridcolor: 'gray',
          borderColor: '#c8ced3',
          hoverBGColor: '#fdfdfdf7',
          tableBorderColor: '1px solid White',
          tableBackground: '#ffffff',
          tableHeaderColor: '#c8ced3',
          tableAccentColor: '#ffffff',
          tableHoverColor: '#f2f2f2',
          menubackground: '#c8ced3',
          submenubackground: 'transparent',
          menucolor: '#6c757d',
          menuhoverbgcolor: '#5b6667',
          menuhovercolor: '#5b6667',
          menuitemselectedbgcolor: '#8d9292d9',
          menuitemselectedcolor: '#475152',
          footerbackground: '#c8ced3',
          scrollbarcolor: '#8d9292d9',
          scrollbarbgcolor: '#e3dfdf',
          leftmenubackgroundlevel1: '#d9dcde',
          leftmenubackgroundlevel2: '#c3c6ca',
          leftmenubackgroundlevel3: '#E8E7E5',
          leftmenubackgroundlevel4: '#F1F0EE',
          leftmenubackgroundlevel5: '#F1F0EE',
          leftmenuminimizerbuttoncolor: '#a9b3b3e8',
          leftmenucolor: '#475152',
          leftmenuhoverbgcolor: '#8d9292d9',
          leftmenuhovercolor: '#475152',
          leftmenusideinkbarcolor: '#525b63',
          leftmenuParentOpenedBackground: '#8d9292d9',
          leftmenuSelectedBackground: '#8d9292d9',
          leftmenuSelectedColor: '#475152',
          leftmenuarrowcolor: 'black',
          leftmenuicon: 'black',
          active: '#595A5C',
          inkbar: '#525b63',
          textcolor: 'gray',
          inlineForm: 'white!important',
          inlineRowSelected: '#17a2b8!important',
          inlineGridSuccess: '#4dff88!important',
          inlineGridDanger: '#e60000!important',
          attachmentBackground: '#fafafa',
          borderLeft: '#1ea2b8',
        },
        success: {
          background: '#4CAF50',
          color: '#0f0f0f',
          modalBGColor: '#08590b',
          formSectionColor: 'darkgreen',
          gridcolor: 'darkgreen',
          borderColor: '#4CAF50',
          hoverBGColor: '#fdfdfdf7',
          tableBorderColor: '1px solid white',
          tableBackground: '#ffffff',
          tableHeaderColor: '#4CAF50',
          tableAccentColor: '#8DCC8F',
          tableHoverColor: '#f2f2f2',
          menubackground: '#4CAF50',
          submenubackground: 'transparent',
          menucolor: '#0c0c0d',
          menuhoverbgcolor: '#0f0f0f',
          menuhovercolor: '#0f0f0f',
          menuitemselectedbgcolor: '#3D8C40',
          menuitemselectedcolor: '#0d120d',
          footerbackground: '#4CAF50',
          scrollbarcolor: '#4CAF50',
          scrollbarbgcolor: '#e3dfdf',
          leftmenubackgroundlevel1: '#70BF73',
          leftmenubackgroundlevel2: '#8DCC8F',
          leftmenubackgroundlevel3: '#A4D6A5',
          leftmenubackgroundlevel4: '#B6DEB7',
          leftmenubackgroundlevel5: '#B6DEB7',
          leftmenuminimizerbuttoncolor: '#3D8C40',
          leftmenucolor: '#0c0c0d',
          leftmenuhoverbgcolor: '#49ab4f',
          leftmenuhovercolor: '#090a0a',
          leftmenusideinkbarcolor: '#0d0e0f',
          leftmenuParentOpenedBackground: '#3D8C40',
          leftmenuSelectedBackground: '#4CAF50',
          leftmenuSelectedColor: '#0e0f0f',
          leftmenuarrowcolor: 'black',
          leftmenuicon: '#2f2f30',
          active: '#49ab4f',
          inkbar: '#2f2f30',
          textcolor: '#0c0c0d',
          inlineForm: 'white!important',
          inlineRowSelected: '#2E8B57!important',
          inlineGridSuccess: '#4dff88!important',
          inlineGridDanger: '#e60000!important',
          attachmentBackground: '#e8faec',
          borderLeft: '#28a745',
        },
        info: {
          background: '#004269',
          color: 'white',
          formSectionColor: '#004269',
          gridcolor: '#004269',
          borderColor: '#004269',
          hoverBGColor: '#fdfdfdf7',
          tableBorderColor: '1px solid white',
          tableBackground: '#ffffff',
          tableHeaderColor: '#004269',
          tableAccentColor: '#e6fff2',
          tableHoverColor: '#f2f2f2',
          menubackground: '#004269',
          submenubackground: 'transparent',
          menucolor: 'black',
          menuhoverbgcolor: 'black',
          menuhovercolor: 'black',
          menuitemselectedbgcolor: '#004269',
          menuitemselectedcolor: 'white',
          footerbackground: '#004269',
          scrollbarcolor: '#004269',
          scrollbarbgcolor: '#e3dfdf',
          leftmenubackgroundlevel1: '#5cbdcd',
          leftmenubackgroundlevel2: '#8bd0db',
          leftmenubackgroundlevel3: '#b9e3e9',
          leftmenubackgroundlevel4: '#d0ecf0',
          leftmenubackgroundlevel5: '#e7f5f7',
          leftmenuminimizerbuttoncolor: '#4E747F',
          leftmenucolor: 'black',
          leftmenuhoverbgcolor: '#fdfdfdf7',
          leftmenuhovercolor: 'black',
          leftmenusideinkbarcolor: '#525b63',
          leftmenuParentOpenedBackground: '#0d616e',
          leftmenuSelectedBackground: '#1491a5',
          leftmenuSelectedColor: 'black',
          leftmenuarrowcolor: 'black',
          leftmenuicon: 'black',
          active: '#112469',
          inkbar: 'deepskyblue',
          textcolor: '#0c0c0d',
          inlineForm: 'white!important',
          inlineRowSelected: '#17a2b8!important',
          inlineGridSuccess: '#4dff88!important',
          inlineGridDanger: '#e60000!important',
          attachmentBackground: '#fafcfd',
          borderLeft: '#1ea2b8',
        },
        warning: {
          background: '#F1C232',
          color: 'white',
          formSectionColor: '#cc9900',
          gridcolor: '#cc9900',
          borderColor: '#F1C232',
          hoverBGColor: '#fdfdfdf7',
          tableBorderColor: '1px solid white',
          tableBackground: '#ffffff',
          tableHeaderColor: '#cc9900',
          tableAccentColor: '#ffecb3',
          tableHoverColor: '#f2f2f2',
          menubackground: '#F1C232',
          submenubackground: 'transparent',
          menucolor: 'black',
          menuhoverbgcolor: 'black',
          menuhovercolor: 'black',
          menuitemselectedbgcolor: '#d6b247f0',
          menuitemselectedcolor: '#475152',
          footerbackground: '#F1C232',
          scrollbarcolor: '#F1C232',
          scrollbarbgcolor: '#e3dfdf',
          leftmenubackgroundlevel1: '#F4CE5B',
          leftmenubackgroundlevel2: '#F9E6AB',
          leftmenubackgroundlevel3: '#F4CE5B',
          leftmenubackgroundlevel4: '#F9E6AB',
          leftmenubackgroundlevel5: '#F9E6AB',
          leftmenuminimizerbuttoncolor: '#C19B28',
          leftmenucolor: 'black',
          leftmenuhoverbgcolor: '#fdfdfdf7',
          leftmenuhovercolor: 'black',
          leftmenusideinkbarcolor: '#525b63',
          leftmenuParentOpenedBackground: '#f2e0aa',
          leftmenuSelectedBackground: '#f7d057',
          leftmenuSelectedColor: 'black',
          leftmenuarrowcolor: 'black',
          leftmenuicon: '#6c757d',
          active: '#BCCA18',
          inkbar: 'white',
          textcolor: '#0c0c0d',
          inlineForm: 'white!important',
          inlineRowSelected: '#17a2b8!important',
          inlineGridSuccess: '#4dff88!important',
          inlineGridDanger: '#e60000!important',
          attachmentBackground: '#ffffeb',
          borderLeft: '#1ea2b8',
        },
        danger: {
          background: '#eb4242',
          asteriskColor: '#eb4242',
          fontColor: '#eb4242',
          color: '#fff',
          formSectionColor: '#eb4242',
          gridcolor: '#eb4242',
          borderColor: '#eb4242',
          hoverBGColor: '#fdfdfdf7',
          tableBorderColor: '1px solid white',
          tableBackground: '#ffffff',
          tableHeaderColor: '#eb4242',
          tableAccentColor: '#EEACAC',
          tableHoverColor: '#fff',
          menubackground: '#eb4242',
          submenubackground: 'transparent',
          menucolor: '#fff',
          menuhoverbgcolor: '#fff',
          menuhovercolor: '#fff',
          menuitemselectedbgcolor: '#A30000',
          menuitemselectedcolor: '#FFF',
          footerbackground: '#eb4242',
          scrollbarcolor: '#fff',
          scrollbarbgcolor: '#fff',
          leftmenubackgroundlevel1: '#D63333',
          leftmenubackgroundlevel2: '#DE5C5C',
          leftmenubackgroundlevel3: '#E57D7D',
          leftmenubackgroundlevel4: '#EA9797',
          leftmenubackgroundlevel5: '#EA9797',
          leftmenuminimizerbuttoncolor: '#A30000',
          leftmenucolor: '#fff',
          leftmenuhoverbgcolor: '#A30000',
          leftmenuhovercolor: '#FFF',
          leftmenusideinkbarcolor: '#0d0e0f',
          leftmenuParentOpenedBackground: '#A30000',
          leftmenuSelectedBackground: '#ed2b2b',
          leftmenuSelectedColor: '#0e0f0f',
          leftmenuarrowcolor: 'white',
          leftmenuicon: '#FFF',
          active: '#A30000',
          inkbar: 'white',
          textcolor: 'white',
          inlineForm: 'white!important',
          inlineRowSelected: '#17a2b8!important',
          inlineGridSuccess: '#4dff88!important',
          inlineGridDanger: '#e60000!important',
          attachmentBackground: '#fcf1f0',
          borderLeft: '#1ea2b8',
        },
        link: {
          background: 'blue',
          color: 'white',
          hoverBGColor: '#fdfdfdf7',
        },
      },
    },
    uiLibrary: 'bootstrap',
  },

  {
    name: 'mugreen',
    ioMode: {
      light: {
        primary: {
          background: 'green',
          color: 'white',
        },
      },
      light1: {
        primary: {
          background: '#6f42c1',
          color: 'red',
        },
      },
    },
    uiLibrary: 'materialui',
  },
];

export interface IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink {
  background: string;
  color: string;
  borderColor: string;
}

export interface IDark {
  primary: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
  secondary: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
  success: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
  info: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
  warning: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
  danger: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
  link: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
}

export interface ILink {
  background: string;
  color: string;
}

export interface ILight {
  primary: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
  secondary: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
  success: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
  info: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
  warning: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
  danger: IPrimaryOrSecondaryOrSuccessOrInfoOrWarningOrDangerOrLink;
  link: ILink;
}

export interface IMode {
  dark: IDark;
  light: ILight;
}

export interface ITheme {
  name: string;
  ioMode: IMode;
  uiLibrary: string;
}

export const resolveColor = (
  iotheme: any,
  ioMode: string,
  stylename: string,
  attribute: string
) => {
    return iotheme?.ioMode[ioMode][stylename ? stylename : 'primary'][attribute];
};
