import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { v4 as uuidV4 } from 'uuid';
import MediaQuery from 'react-responsive';
const ellipsisDefaultStyle = {
  overflow: 'hidden',
  overflowWrap: 'break-word',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  wordBreak: 'break-all',
};

class EllipisWithTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasOverflowingChildren: false,
      text: undefined,
      prevPropsChildren: props.children,
    };
    this.updateOverflow = this.updateOverflow.bind(this);
  }

  updateOverflow(e) {
    const el = e.target;
    const { hasOverflowingChildren, text } = this.state;

    if (!hasOverflowingChildren && el.scrollWidth > el.clientWidth) {
      this.setState({ hasOverflowingChildren: true });
      if (el.textContent !== text) {
        this.setState({ text: el });
      }
    } else {
      this.setState({ hasOverflowingChildren: false });
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.children === state.prevPropsChildren) return null;
    return {
      hasOverflowingChildren: false,
      prevPropsChildren: props.children,
    };
  }

  render() {
    const {
      placement = 'top',
      style = {
        maxWidth: this.props.cell.column.width,
        padding: '10px 10px',
        'line-height': 'normal',
      },
      delayShow,
      delayHide,
      children,
      cell,
      row,
      column: { id },
    } = this.props;
    const { hasOverflowingChildren, text } = this.state;

    const tooltip = <Tooltip id={`tooltip-${uuidV4()}`}>{cell.value}</Tooltip>;

    const ellipsisStyle = { ...ellipsisDefaultStyle, ...style };

    return hasOverflowingChildren ? (
      <div>
        {' '}
        <MediaQuery query="(max-width: 900px)">
          <OverlayTrigger
            trigger="click"
            placement={placement}
            overlay={tooltip}
            delayShow={delayShow}
            delayHide={delayHide}
          >
            <div style={ellipsisStyle}>{cell.value}</div>
          </OverlayTrigger>
        </MediaQuery>
        <MediaQuery query="(min-width: 900px)">
          <OverlayTrigger
            placement={placement}
            overlay={tooltip}
            delayShow={delayShow}
            delayHide={delayHide}
          >
            <div style={ellipsisStyle}>{cell.value}</div>
          </OverlayTrigger>
        </MediaQuery>
      </div>
    ) : (
      <div style={ellipsisStyle}>{cell.value}</div>
    );
  }
}

EllipisWithTooltip.propTypes = {
  placement: PropTypes.string,
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  delayShow: PropTypes.number,
  delayHide: PropTypes.number,
};

// EllipisWithTooltip.defaultProps = {
//   placement: undefined,
//   style: undefined,
//   delayHide: undefined,
//   delayShow: undefined,
// };

export default React.memo(EllipisWithTooltip);
