import { parseStorageEnum, StorageEnum } from '../constants/appeng.enum';
import { CookieStorage } from './cookie.storage';
import { LocalStorage } from './local.storage';
import { MemoryStorage } from './memory.storage';
import IStorage from './storage.interface';
import { SessionStorage } from './session.storage';

const createStorage = (): IStorage => {
  if (!JSON.parse(process.env.REACT_APP_AUTH_DETAILS!).storage.type) {
    throw new Error('config.storage.type is not present in configuration');
  }
  const storageEnum: StorageEnum = parseStorageEnum(
    JSON.parse(process.env.REACT_APP_AUTH_DETAILS!).storage.type
  );
  switch (storageEnum) {
    case StorageEnum.COOKIE:
      return new CookieStorage(
        JSON.parse(process.env.REACT_APP_AUTH_DETAILS!).storage.cookie
      );
    case StorageEnum.MEMORY:
      return new MemoryStorage(
        JSON.parse(process.env.REACT_APP_AUTH_DETAILS!).storage.memory
      );
    case StorageEnum.WINDOW:
      return new LocalStorage(
        JSON.parse(process.env.REACT_APP_AUTH_DETAILS!).storage.window
      );
    case StorageEnum.SESSION:
      return new SessionStorage(
        JSON.parse(process.env.REACT_APP_AUTH_DETAILS!).storage.window
      );
    default:
      throw new Error(
        'Invalid or unsuported storage type ' +
          JSON.parse(process.env.REACT_APP_AUTH_DETAILS!).storage.type
      );
  }
};

export { createStorage };
