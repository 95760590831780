export const GATEWAY_TO_GET_FORMDATA = 'GATEWAY_TO_GET_FORMDATA';
export const GATEWAY_FOR_UPSERT_OPERATION = 'GATEWAY_FOR_UPSERT_OPERATION';

export interface IGateWayToGetFormData {
  type: string;
  nodeId: string;
  mode: string;
  nodeType: string;
  ceId: string;
  primaryKey: any;
  referralId: string;
  resetForm: boolean;
  location: any;
  formId: string;
  referenceData?: any;
  usePortalReferenceData?: boolean;
  portalId: string;
  rowData?: any;
  parentId: string;
  tabGroupId?: string;
  entityName?: string;
  tabPortalId?: string;
  entityId?: string;
  parentCardId?: string;
}

export interface IGateWayForUpsertOperation {
  type: string;
  nodeId: string;
  actionName: string;
  keys: any;
  referenceData: any;
  displayName: string;
  nodeType: string;
  ceId: string;
  mode: string;
  location: any;
  modalRequired: boolean;
  referralId: string;
  resetForm: boolean;
  portalForm: boolean;
  parentFormId: string;
  usePortalReferenceData?: boolean;
  portalId: string;
  parentId: string;
}

export function gateWayToGetFormData(
  nodeId: string,
  mode: string,
  nodeType: string,
  ceId: string,
  primaryKey: any,
  referralId: string,
  resetForm: boolean,
  location: any,
  formId: string,
  parentId: string,
  portalId: string,
  referenceData?: any,
  usePortalReferenceData?: boolean,
  rowData?: any,
  tabGroupId?: string,
  entityName?: string,
  tabPortalId?: string,
  entityId?: string,
  parentCardId?: string
): IGateWayToGetFormData {
  return {
    type: GATEWAY_TO_GET_FORMDATA,
    nodeId: nodeId,
    mode: mode,
    nodeType: nodeType,
    ceId: ceId,
    primaryKey: primaryKey,
    referralId: referralId,
    resetForm: resetForm,
    location: location,
    formId: formId,
    referenceData: referenceData,
    usePortalReferenceData: usePortalReferenceData,
    portalId: portalId,
    rowData: rowData,
    parentId: parentId,
    tabGroupId: tabGroupId,
    entityName: entityName,
    tabPortalId: tabPortalId,
    entityId: entityId,
    parentCardId: parentCardId,
  };
}

export function gateWayForUpsertOperation(
  nodeId: string,
  actionName: string,
  keys: any,
  referenceData: any,
  displayName: string,
  nodeType: string,
  ceId: string,
  mode: string,
  location: any,
  modalRequired: boolean,
  referralId: string,
  resetForm: boolean,
  portalForm: boolean,
  parentFormId: string,
  portalId: string,
  parentId: string,
  usePortalReferenceData?: boolean
): IGateWayForUpsertOperation {
  return {
    type: GATEWAY_FOR_UPSERT_OPERATION,
    nodeId: nodeId,
    actionName: actionName,
    keys: keys,
    referenceData: referenceData,
    displayName: displayName,
    nodeType: nodeType,
    ceId: ceId,
    mode: mode,
    location: location,
    modalRequired: modalRequired,
    referralId: referralId,
    resetForm: resetForm,
    portalForm: portalForm,
    parentFormId: parentFormId,
    usePortalReferenceData: usePortalReferenceData,
    portalId: portalId,
    parentId: parentId,
  };
}
