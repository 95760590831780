export const ACTION_FLOW_ROUTING = 'ACTION_FLOW_ROUTING';
export const GO_TO_PREVIOUS_ROUTE = 'GO_TO_PREVIOUS_ROUTE';

export interface IActionFlowRouting {
  type: string;
  attributes: any;
  actionType: string;
}

export interface IGoToPreviousRoute {
  type: string;
}

export const actionFlowRouting = (
  actionType: string,
  attributes: any
): IActionFlowRouting => {
  return {
    type: ACTION_FLOW_ROUTING,
    actionType: actionType,
    attributes: attributes,
  };
};

export const goToPreviousRoute = (): IGoToPreviousRoute => {
  return {
    type: GO_TO_PREVIOUS_ROUTE,
  };
};
