import { AuthMessageEnum, ErrorCodeEnum } from '../constants';
import AuthError from '../model/auth.error';
import AuthUser from '../model/auth.user';
import { ISignUpParams, IUserInfo, UserInfo } from '../model/user.info';
import { JWTToken, UserSession } from '../model';
import { SignUpResult } from '../types';
import { CodeDeliveryInfo } from '../types/delivery';
import { CurrentUserOpts } from '../types/user.opts';
import AuthProvider from './auth.provider';

const createAuthUser = (data: any, config: any): AuthUser => {
  const claims = data.claims;

  const attributesMap = config.provider.okta.userAttributeMap;
  const preferredUsername: string =
    claims[String(attributesMap.preferred_username)];

  if (claims.sub && preferredUsername) {
    const userInfo: IUserInfo = {
      preferred_username: preferredUsername,
      profile: 'ACTIVE',
      customAttributes: {},
    };

    for (const [key, value] of Object.entries(claims)) {
      if (key === 'sub') {
        continue;
      }

      const keyMaps = Object.entries(attributesMap).filter(
        ([standardKey, oktaKey]) => key === oktaKey
      );

      if (keyMaps.length !== 0) {
        keyMaps.forEach((keyMap) => {
          const [standardKey, oktaKey] = keyMap;
          userInfo[standardKey] = value as any;
        });
      } else if (userInfo.customAttributes !== undefined) {
        userInfo.customAttributes[key] = value;
      }
    }

    const authUser: AuthUser = new AuthUser(
      userInfo.preferred_username,
      new UserInfo(claims.sub, claims.sub, userInfo)
    );
    return authUser;
  }
  throw new Error('sub or userName attrb is missing');
};

export class OktaAuthProvider implements AuthProvider {
  private config: any;
  private authClient: any;

  constructor(config: any) {
    this.config = config;
  }

  public async signIn(
    userName: string,
    password: string,
    locale: string
  ): Promise<AuthUser | any> {
    try {
      const transaction: any = await this.authClient.signIn({
        username: userName,
        password: password,
      });

      if (transaction.status === 'SUCCESS') {
        const res = await this.authClient.token.getWithoutPrompt({
          responseType: ['id_token', 'token'],
          scopes: ['openid', 'email', 'profile'],
          sessionToken: transaction.sessionToken,
        });

        this.authClient.tokenManager.add('idToken', res.tokens.idToken);
        this.authClient.tokenManager.add('accessToken', res.tokens.accessToken);

        return createAuthUser(
          await this.authClient.tokenManager.get('idToken'),
          this.config
        );
      } else {
        throw new Error('Invalid Credentials');
      }
    } catch (e) {
      switch (e.errorCode) {
        case 'E0000004':
          throw new AuthError(
            AuthMessageEnum.INVALID_CREDENTIAL,
            ErrorCodeEnum.INVALID_CREDENTIAL
          );
        case 'UserNotConfirmedException':
          throw new AuthError(
            AuthMessageEnum.PENDING_VERIFICATION,
            ErrorCodeEnum.PENDING_VERIFICATION
          );
        case ErrorCodeEnum.PENDING_CHANGE_PASSWORD:
          throw new AuthError(
            AuthMessageEnum.PENDING_VERIFICATION,
            ErrorCodeEnum.PENDING_CHANGE_PASSWORD
          );
        case ErrorCodeEnum.MISSING_PARAMETER:
          throw new AuthError(
            AuthMessageEnum.MISSING_PARAMETER,
            ErrorCodeEnum.PENDING_CHANGE_PASSWORD
          );
        case ErrorCodeEnum.LOCKED:
          throw new AuthError(AuthMessageEnum.LOCKED, ErrorCodeEnum.LOCKED);
        default:
          throw e;
      }
    }
  }

  public async signUp(params: ISignUpParams): Promise<SignUpResult> {
    try {
      const res: SignUpResult = {
        sub: '',
        preferred_username: '',
        isUserConfirmed: false,
      };
      return res;
    } catch (e) {
      const apiError =
        e.errorResponse.errors && e.errorResponse.errors.length
          ? e.errorResponse.errors[0]
          : undefined;
      if (!apiError) {
        throw e;
      }
      switch (apiError.reason) {
        case ErrorCodeEnum.INVALID_EMAIL_FORMAT:
          throw new AuthError(
            apiError.message,
            ErrorCodeEnum.INVALID_EMAIL_FORMAT
          );
        case ErrorCodeEnum.WEAK_PASSWORD:
          throw new AuthError(apiError.message, ErrorCodeEnum.WEAK_PASSWORD);
        default:
          throw new AuthError(e.message, ErrorCodeEnum.SYSTEM_ERROR);
      }
      throw e;
    }
  }

  public async signUpUserProfile(params: ISignUpParams, res: SignUpResult) {
    try {
    } catch (e) {
      throw e;
    }
  }

  public async confirmSignUp(
    userName: string,
    code: string,
    transactionId: string
  ): Promise<string> {
    try {
      return 'SUCCESS';
    } catch (e) {
      throw e;
    }
  }

  public async resendSignUp(username: string): Promise<CodeDeliveryInfo> {
    try {
      const delivery: CodeDeliveryInfo = {
        attributeName: '',
        deliveryMedium: '',
        destination: '',
      };
      return delivery;
    } catch (e) {
      throw e;
    }
  }

  public async signOut(): Promise<string> {
    this.authClient.signOut();
    return 'SUCCESS';
  }

  public async changePassword(
    userName: string,
    oldPassword: string,
    newPassword: string
  ): Promise<string> {
    try {
      return 'SUCCESS';
    } catch (e) {
      throw e;
    }
    return 'SUCCESS';
  }

  public async forgotPassword(userName: string): Promise<CodeDeliveryInfo> {
    try {
      const delivery: CodeDeliveryInfo = {
        attributeName: '',
        deliveryMedium: '',
        destination: '',
      };
      return delivery;
    } catch (e) {
      throw e;
    }
  }

  public async forgotPasswordSubmit(
    userName: string,
    code: string,
    password: string
  ): Promise<string> {
    try {
      return 'SUCCESS';
    } catch (e) {
      throw e;
    }
  }

  public async currentAuthenticatedUser(
    params?: CurrentUserOpts
  ): Promise<AuthUser | any> {
    return createAuthUser(
      await this.authClient.tokenManager.get('idToken'),
      this.config
    );
  }

  public async currentSession(): Promise<UserSession> {
    if (await this.authClient.session.exists()) {
      const idToken = await this.authClient.tokenManager.get('idToken');
      const accessToken = await this.authClient.tokenManager.get('accessToken');
      return new UserSession(
        new JWTToken(idToken.value),
        new JWTToken(accessToken.value),
        ' '
      );
    } else {
      throw new AuthError(
        AuthMessageEnum.USER_NOT_LOGGEDIN,
        ErrorCodeEnum.USER_NOT_LOGGEDIN
      );
    }
  }

  public async updateUserAttributes(
    user: AuthUser,
    attributes: object
  ): Promise<string> {
    return 'SUCCESS';
  }
}

let oktaProvider: OktaAuthProvider;

const createOktaProvider = (config: any): OktaAuthProvider => {
  if (oktaProvider === null || oktaProvider === undefined) {
    oktaProvider = new OktaAuthProvider(config);
  }
  return oktaProvider;
};

const configureOkta = (config: any) => {};

export default createOktaProvider;
