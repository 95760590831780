import gql from 'graphql-tag';

export const PortalCard = gql`
  mutation createPortalCard($input: PortalCardInput) {
    createPortalCard(input: $input) {
      w
      h
      x
      y
      configObjectId
      responsiveLayout
    }
  }
`;
