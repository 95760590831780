import {
  put,
  all,
  fork,
  takeLatest,
  takeEvery,
  call,
  select,
} from 'redux-saga/effects';
import * as base from '../base';
import { CardData } from '../../models';
import {
  setToggleMessages,
  GATEWAY_TO_GET_FORMDATA,
  getFormData,
  getFormDataUsingFormConfig,
} from '../../actions';
import { UpsertMessage, ROOT_NODE_ID } from '../../constants/appeng.enum';

export default function* gateWayToGetFormDataSaga() {
  yield all([fork(watchGateWayToGetFormData)]);
}

function* watchGateWayToGetFormData() {
  yield takeEvery(GATEWAY_TO_GET_FORMDATA, gateWayToGetFormData);
}

function* gateWayToGetFormData(action: any): Generator<any, any, any> {
  try {
    if (action.nodeId && action.nodeId !== ROOT_NODE_ID) {
      yield put(
        getFormData(
          action.nodeId,
          action.mode,
          action.nodeType,
          action.ceId,
          action.primaryKey,
          action.referralId,
          action.resetForm,
          action.location,
          action.parentId,
          action.portalId,
          action.referenceData,
          action.usePortalReferenceData,
          action.parentCardId
        )
      );
    } else {
      yield put(
        getFormDataUsingFormConfig(
          action.formId,
          action.mode,
          action.nodeType,
          action.primaryKey,
          action.referralId,
          action.resetForm,
          action.location,
          action.parentId,
          action.portalId,
          action.referenceData,
          action.usePortalReferenceData,
          action.rowData,
          action.tabGroupId,
          action.entityName,
          action.tabPortalId,
          action.entityId,
          action.parentCardId
        )
      );
    }
  } catch (e) {
    console.log('getqYTO GET FORM DATASaga===========', e);
  }
}
