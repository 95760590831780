import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faLanguage } from '@fortawesome/free-solid-svg-icons/faLanguage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const StyleDropdownToggle = styled(Dropdown.Toggle)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  padding-top: 12px !important;
  padding-right: 8px !important;
`;

const StyleDropdownMenu = styled(Dropdown.Menu)`
  background: transparent !important;
`;

const StyleDropdownItem = styled(Dropdown.Item)``;

let languages = [
  {
    ID: 1,
    CODE: 'en',
    NAME: 'English',
  },
  {
    ID: 2,
    CODE: 'hi',
    NAME: 'हिंदी',
  },
  {
    ID: 3,
    CODE: 'gu',
    NAME: 'ગુજરાતી',
  },
];

interface ILanguageLink {
  locale: any;
  setLanguage: any;
  setMessages: any;
}

const LanguageLink: React.FC<ILanguageLink> = (props: ILanguageLink) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = async () => {
    setDropdownOpen(!dropdownOpen);
  };

  const switchLanguage = async (code: any) => {
    props.setLanguage(code);
    props.setMessages(code);
    setDropdownOpen(false);
  };

  return (
    <div>
      <Dropdown show={dropdownOpen} onToggle={toggleDropdown}>
        <StyleDropdownToggle onClick={toggleDropdown} id="dropdown-basic">
          <FontAwesomeIcon icon={faLanguage} style={{ paddingRight: '4px' }} />
          {props.locale.toUpperCase()}
          <FontAwesomeIcon
            icon={faChevronDown}
            style={{ paddingLeft: '5px', fontSize: '0.5em' }}
          />
        </StyleDropdownToggle>

        {dropdownOpen ? (
          <StyleDropdownMenu>
            {languages.map((lang) => (
              <StyleDropdownItem
                key={lang.CODE}
                onClick={() => switchLanguage(lang.CODE)}
              >
                {lang.NAME}{' '}
                {lang.CODE === props.locale ? (
                  <span style={{ fontSize: '15px' }}>&#10003;</span>
                ) : null}
              </StyleDropdownItem>
            ))}
          </StyleDropdownMenu>
        ) : null}
      </Dropdown>
    </div>
  );
};

export default LanguageLink;
