import {
  put,
  all,
  fork,
  takeLatest,
  takeEvery,
  call,
  select,
} from 'redux-saga/effects';
import {
  CHANGE_USER_CONTEXT_KEY,
  GET_USER_PROFILE,
  SWITCH_PROJECT,
  LOG_OUT,
  SET_ROUTER_FORM_DATA,
  setStore,
  setToggleMessages,
  switchLanguage,
  setFormData,
} from '../actions/index';
import {
  LANGUAGE_OPTIONS_KEY,
  UpsertMessage,
  USER_SESSION_LOCAL_STORAGE_KEY,
  SECRET_KEY,
  USER_SESSION_JWT,
} from '../constants/appeng.enum';
import { createStorage } from '../storage';
import cloneDeep from 'lodash/cloneDeep';
import * as base from './base';
import ApplicationRole from '../models/applicationRole.model';
import { sortConfigObjects } from '../utils/ClientUtils';
import { v4 as uuid } from 'uuid';
import { router } from '../routes/Routes';
import { SignJWT } from 'jose';

const getApplications = (state: any) => state.appState.applications;
const getLocale = (state: any) => state.appState.locale;

export default function* userContextSaga() {
  yield all([
    fork(watchGetUserProfile),
    fork(watchChangeUserContext),
    fork(watchLogoutSaga),
    fork(watchSwitchProjectSaga),
    fork(watchSetRouterAndFormData),
  ]);
}

function* watchGetUserProfile() {
  yield takeLatest(GET_USER_PROFILE, getUserProfile);
}

function* watchChangeUserContext() {
  yield takeLatest(CHANGE_USER_CONTEXT_KEY, changeUserContext);
}

function* watchSwitchProjectSaga() {
  yield takeEvery(SWITCH_PROJECT, switchProjectSaga);
}

function* watchLogoutSaga() {
  yield takeEvery(LOG_OUT, logoutSaga);
}

function* watchSetRouterAndFormData() {
  yield takeLatest(SET_ROUTER_FORM_DATA, setRouterAndFormDataSaga);
}

function* getUserProfile(action) {
  try {
    const storage = createStorage();
    let userProfile = storage.getItem(USER_SESSION_LOCAL_STORAGE_KEY);
    let userSession: any = {};
    userSession.AdditionalDetails = {};
    userSession.AdditionalDetails['UserContext'] = {};
    let financialYearList: any = [];
    let languagesList: any = [];
    const authProvider: string = JSON.parse(process.env.REACT_APP_AUTH_DETAILS!)
      .provider.type;
    let loggedInUserId = '';
    const userDetailsMap = JSON.parse(userProfile ? userProfile : '{}');
    const isUserLoggedIn =
      userDetailsMap &&
      userDetailsMap.APP_LOGGED_IN_ROLE_ID &&
      userDetailsMap.AdditionalDetails &&
      userDetailsMap.AdditionalDetails.UserContext &&
      userDetailsMap.AdditionalDetails.UserContext.APP_LOGGED_IN_ROLE_ID
        ? true
        : false;

    let currentApplicationAD = '';
    let appId = '';
    switch (authProvider) {
      case 'aws': {
        loggedInUserId = action.user.userInfo.oid;
        if (action.location.search && action.location.search.includes('&')) {
          let searchURL = action.location.search.replace('?', '');
          let pathList = searchURL.split('&');
          pathList.map((pathOptions: any) => {
            let path = pathOptions.split('=');
            if (
              path[0] &&
              path[0] === 'TENANT_UUID' &&
              !userSession.TENANT_UUID &&
              !appId
            ) {
              userSession.TENANT_UUID = path[1].replace('#');
            } else if (
              path[0] &&
              path[0] === 'APPLICATION_UUID' &&
              userSession.TENANT_UUID &&
              !appId
            ) {
              appId = path[1].replace('#');
            } else {
              userSession.AdditionalDetails.UserContext[path[0]] =
                path[1].replace('#');
              if (
                userDetailsMap.hasOwnProperty('AdditionalDetails') &&
                userDetailsMap.AdditionalDetails.UserContext
              ) {
                userDetailsMap.AdditionalDetails.UserContext[path[0]] =
                  path[1].replace('#');
              }
            }
          });
        }
        break;
      }
      case 'okta': {
        const userInfo = yield call(base.getOktaUserInfo);
        Object.assign(userSession, userInfo);
        loggedInUserId = action.user.userInfo.oid;
        userSession.TENANT_UUID = userSession.tenantId;
        break;
      }
      case 'microsoft-ad-info-apps':
        loggedInUserId = action.user.userInfo.oid;
        if (action.location.search && action.location.search.includes('&')) {
          let searchURL = action.location.search.replace('?', '');
          let pathList = searchURL.split('&');
          pathList.map((pathOptions: any) => {
            let path = pathOptions.split('=');
            if (
              path[0] &&
              path[0] === 'TENANT_UUID' &&
              !userSession.TENANT_UUID &&
              !appId
            ) {
              userSession.TENANT_UUID = path[1].replace('#');
            } else if (
              path[0] &&
              path[0] === 'APPLICATION_UUID' &&
              userSession.TENANT_UUID &&
              !appId
            ) {
              appId = path[1].replace('#');
            } else {
              userSession.AdditionalDetails.UserContext[path[0]] =
                path[1].replace('#');
              if (
                userDetailsMap.hasOwnProperty('AdditionalDetails') &&
                userDetailsMap.AdditionalDetails.UserContext
              ) {
                userDetailsMap.AdditionalDetails.UserContext[path[0]] =
                  path[1].replace('#');
              }
            }
          });
        }
        break;
      case 'microsoft-ad': {
        loggedInUserId = action.user.userInfo.oid;
        currentApplicationAD = action.user.userInfo.customAttributes.appId;
        if (!isUserLoggedIn) {
          Object.entries(action.user.userInfo.customAttributes).forEach(
            ([key, value]: [any, any]) => {
              if (key !== 'appId') {
                userSession.AdditionalDetails.UserContext[key] = value;
                userSession.AdditionalDetails.UserContext[key + '_LIST'] =
                  value;
              }
            }
          );
        }
        break;
      }
      default: {
        loggedInUserId = action.userId;
      }
    }
    const authRole = userSession.groups
      ? userSession.groups
      : action.user
        ? action.user.userInfo.role
        : undefined;

    const [data, projectList, roleList] = yield all([
      call(base.getConfigData, 'ConfitItemType', 'Languages'),
      call(
        getProject,
        authProvider,
        userSession.aeRoleApplication,
        loggedInUserId,
        currentApplicationAD
      ),
      call(getRole, authProvider, loggedInUserId, authRole),
    ]);

    if (!userSession.TENANT_UUID && roleList.length > 0) {
      userSession.TENANT_UUID = roleList[0].tenantId;
    }

    const allLanguages = data.Languages ? data.Languages : [];
    const financialYears: any = [];

    let userDetails: any = {};

    financialYearList = getYearOrLanguageList(financialYears);
    languagesList = getYearOrLanguageList(allLanguages);

    const currentYear = getAndSetCurrentFinanacialYear(
      financialYears,
      userSession,
      isUserLoggedIn
    );
    const currentLanguage = getAndSetCurrentLanguage(
      allLanguages,
      userSession,
      isUserLoggedIn,
      userDetailsMap
    );

    if (action.location.pathname.includes('portal')) {
      let extracedtPortalIdList = action.location.pathname.split('/');
      const portalConfig = yield call(
        base.getConfigItem,
        extracedtPortalIdList[extracedtPortalIdList.length - 1]
      );
      if (portalConfig.data.length > 0) {
        const currentProjectMap = projectList.filter(
          (project: any) =>
            project.applicationId == portalConfig.data[0].PROJECTID
        );
        if (currentProjectMap.length > 0) {
          appId = currentProjectMap[0]['aeApplicationUuid'];
        }
      }
    }

    if (!isUserLoggedIn && !appId) {
      appId = getAppId(action.user, authProvider);
    } else if (isUserLoggedIn) {
      userDetails = action.user ? action.user.userInfo : {};
      if (!Object.keys(action.user)) {
      } else if (appId) {
        userSession = cloneDeep(userDetailsMap);
      } else {
        const currentProjectMap = projectList.filter(
          (project: any) =>
            project.applicationId === userDetailsMap.APP_LOGGED_IN_PROJECT_ID
        );
        appId =
          currentProjectMap.length > 0
            ? currentProjectMap[0]['aeApplicationUuid']
            : getAppId(action.user, authProvider);
        userSession = cloneDeep(userDetailsMap);
      }
    }

    userSession['CONFIG_RELEASE_NAME'] = 'infoapps';
    userDetails['APP_LOGGED_IN_PROFILE_STATUS'] = action.user
      ? action.user.userInfo.profile
      : action.userStatus;

    const defaultProject = yield call(
      getDefaultProject,
      authProvider,
      userDetailsMap.aeRoleApplication,
      userDetailsMap.tenantId,
      loggedInUserId,
      roleList,
      appId,
      projectList
    );

    let currentLogoURL = '';
    const logoList = yield call(
      base.fetchApplicationLogo,
      defaultProject.aeApplicationUuid,
      'AE_APPLICATION',
      'INFO_AUTHORIZATION'
    );
    if (logoList.length > 0) {
      currentLogoURL = logoList[0].info4;
    }

    userProfile = JSON.stringify(userSession);
    storage.setItem(USER_SESSION_LOCAL_STORAGE_KEY, userProfile);

    const currentProjectMap = projectList.filter(
      (project: any) =>
        project.aeApplicationUuid === defaultProject.aeApplicationUuid
    );
    if (
      currentProjectMap[0] &&
      currentProjectMap[0].isCustomDataNeeded === 'Yes'
    ) {
      const userAppSpecialAttribute = yield call(
        getUserApplicationSpecialAttributes,
        authProvider,
        loggedInUserId,
        defaultProject.aeApplicationUuid,
        currentProjectMap[0].applicationParameter
      );
      setSpecialmapping(userAppSpecialAttribute, userSession);
    }

    setDefaultProjectAndRole(
      defaultProject,
      roleList,
      userDetails,
      userSession,
      projectList,
      action.user,
      loggedInUserId
    );

    userSession.AdditionalDetails.UserContext['APP_LOGGED_IN_PROFILE_STATUS'] =
      action.user
        ? action.user.userInfo.profile
        : (action.userSession.AdditionalDetails.UserContext[
            'APP_LOGGED_IN_PROFILE_STATUS'
          ] = action.user ? action.user.userInfo.profile : action.userStatus);

    setUserNameAfterLogin(
      authProvider,
      userSession,
      action.user,
      defaultProject,
      userDetails,
      storage,
      isUserLoggedIn
    );

    userProfile = JSON.stringify(userSession);
    storage.setItem(USER_SESSION_LOCAL_STORAGE_KEY, userProfile);
    userSession.APP_CURRENT_DATE = new Date();
    userSession.sub = 'dummy';
    const secretKeyUint8Array = new TextEncoder().encode(SECRET_KEY);
    const jwtToken: string = yield new SignJWT(userSession)
      .setProtectedHeader({ alg: 'HS256' })
      .sign(secretKeyUint8Array);
    storage.setItem(USER_SESSION_JWT, jwtToken);

    const updateOptions: any = {};
    updateOptions[LANGUAGE_OPTIONS_KEY] = languagesList;
    let parsedProjectList = projectParser(projectList);
    const locale = yield select(getLocale);

    const updateStore: any = {
      currentProject: userSession['APP_LOGGED_IN_PROJECT_NAME'],
      userDetails: userProfile,
      options: updateOptions,
      applications: parsedProjectList,
      currentLogoURL: currentLogoURL,
    };
    yield put(setStore(updateStore));
    if (locale !== currentLanguage) yield put(switchLanguage(currentLanguage));
    if (projectList.length === 0 && roleList.length === 0) {
      const messages: string[] = ['Application and Role not assigned'];
      yield put(setToggleMessages(messages));
    }
  } catch (e) {
    console.log('exception getUserProfile', e);
    const messages: string[] = [];
    messages.push(UpsertMessage.UNSUCCESSFUL);
    yield put(setToggleMessages(messages));
  }
}

function* changeUserContext(action: any): Generator<any, any, any> {
  try {
    const storage = createStorage();
    let userProfile = storage.getItem(USER_SESSION_LOCAL_STORAGE_KEY);
    const userDetailsMap = JSON.parse(userProfile ? userProfile : '{}');
    let userSession = cloneDeep(userDetailsMap);
    userSession.AdditionalDetails.UserContext[action.keyName] = action.keyValue;
    userProfile = JSON.stringify(userSession);
    storage.setItem(USER_SESSION_LOCAL_STORAGE_KEY, userProfile);
    const projectList = yield select(getApplications);
    const currentProjectMap = projectList.filter(
      (project: any) =>
        project.aeApplicationUuid === userSession.APP_LOGGED_IN_PROJECT_UUID
    );
    let applicationParameter = currentProjectMap[0].applicationParameter;
    var applicationParameterDetails = JSON.parse(applicationParameter);
    if (applicationParameterDetails.hasOwnProperty('Queries')) {
      for (const key in applicationParameterDetails.Queries) {
        let returndata: any = yield call(
          base.getDataViaQuery,
          applicationParameterDetails.Queries[key].DATASOURCE,
          applicationParameterDetails.Queries[key].Query
        );
        if (returndata.length > 0) {
          userSession.AdditionalDetails.UserContext[
            applicationParameterDetails.Queries[key].KEY_NAME
          ] = returndata[0][applicationParameterDetails.Queries[key].KEY_NAME];
        }
      }
    }
    userProfile = JSON.stringify(userSession);
    storage.setItem(USER_SESSION_LOCAL_STORAGE_KEY, userProfile);
    const messages: string[] = [];
    yield put(setToggleMessages(messages));

    const routerParam: any = {};
    Object.assign(routerParam, action.location.state);
    routerParam['AE_RELOAD'] = {
      ID: uuid(),
      TS: new Date().getTime(),
      STATUS: 'R',
    };
    if (action.location.state) {
      Object.keys(action.location.state).map((key) => {
        let keyList = key.split('_');
        const regexExp =
          /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
        const isQueryGetFire = regexExp.test(keyList[keyList.length - 1]);
        if (isQueryGetFire) {
          routerParam[key]['AE_RELOAD'] = {
            ID: uuid(),
            TS: new Date().getTime(),
            STATUS: 'R',
          };
        }
      });
    }

    yield router.navigate(action.location.pathname, {
      state: routerParam,
      replace: true,
    });
    const updateStore: any = {
      userDetails: userProfile,
    };
    yield put(setStore(updateStore));
  } catch {
    const messages: string[] = [];
    messages.push(UpsertMessage.UNSUCCESSFUL);
    yield put(setToggleMessages(messages));
  }
}

function* switchProjectSaga(action: any): Generator<any, any, any> {
  try {
    const storage = createStorage();
    let userProfile = storage.getItem(USER_SESSION_LOCAL_STORAGE_KEY);
    const projectList = yield select(getApplications);
    const userDetailsMap = JSON.parse(userProfile ? userProfile : '{}');
    let userSession = cloneDeep(userDetailsMap);
    let roleList: any = yield call(
      getRole,
      JSON.parse(process.env.REACT_APP_AUTH_DETAILS!).provider.type,
      userDetailsMap.APP_LOGGED_IN_USER_ID,
      userSession.groups
    );

    const defaultProject = yield call(
      getDefaultProject,
      JSON.parse(process.env.REACT_APP_AUTH_DETAILS!).provider.type,
      userSession.aeRoleApplication,
      userSession.tenantId,
      userDetailsMap.APP_LOGGED_IN_USER_ID,
      roleList,
      action.projectId,
      projectList
    );

    setDefaultProjectAndRole(
      defaultProject,
      roleList,
      userDetailsMap,
      userSession,
      projectList,
      action.user,
      userDetailsMap.APP_LOGGED_IN_USER_ID
    );
    userProfile = JSON.stringify(userSession);
    storage.setItem(USER_SESSION_LOCAL_STORAGE_KEY, userProfile);
    const messages: string[] = [];
    yield put(setToggleMessages(messages));
    const updateStore: any = {
      currentProject:
        Object.keys(userDetailsMap).length > 0
          ? userDetailsMap['APP_LOGGED_IN_PROJECT_NAME']
          : projectList[0].applicationName,
      userDetails: userProfile,
      toggleMessages: messages,
    };
    yield put(setStore(updateStore));
    yield router.navigate('/app/ce');
    window.location.reload();
  } catch {
    const messages: string[] = [];
    messages.push(UpsertMessage.UNSUCCESSFUL);
    yield put(setToggleMessages(messages));
  }
}

function* logoutSaga(action: any): Generator<any, any, any> {
  const messages: string[] = [];
  yield put(setToggleMessages(messages));
  const storage = createStorage();
  storage.setItem(USER_SESSION_LOCAL_STORAGE_KEY, '');
  yield router.navigate('/app/logout');
}

function* setRouterAndFormDataSaga(action: any): Generator<any, any, any> {
  if (action.routerParameter.state) {
    let routerParam: any = {};
    console.log('inside user context ====>> action = ', action);

    Object.assign(routerParam, action.routerParameter.state);
    routerParam = action.routerParameter.state;

    yield router.navigate(action.routerParameter.pathname, {
      state: routerParam,
      replace: true,
    });
  }
  yield put(setFormData(action.formData, action.formId, false, false, false));
}

const getAppId = (user: any, authProvider: any) => {
  switch (authProvider) {
    case 'okta': {
      return undefined;
    }
    case 'microsoft-ad-info-apps': {
      return undefined;
    }
    case 'microsoft-ad': {
      return user.userInfo.customAttributes.appId;
    }
    default: {
      return undefined;
    }
  }
};

function* getRole(
  provider: string,
  userId: string,
  groups?: any
): Generator<any, any, any> {
  let roleList: any = [];
  switch (provider) {
    case 'okta': {
      const allRoles = yield call(base.getAllRoleOption);
      allRoles.map((role: any) => {
        if (groups && groups.includes(role.roleName)) {
          roleList.push(role);
        }
      });
      break;
    }
    case 'microsoft-ad-info-apps':
      roleList = yield call(base.getRoleOption, userId);
      break;
    case 'microsoft-ad': {
      const { data } = yield call(base.getConfigData, 'RoleQuery', '');
      const allRoles = data.Roles;
      allRoles.map((role: any) => {
        if (
          role.roleName &&
          groups &&
          (groups.includes(role.roleName.replace(/ +/g, '')) ||
            groups.includes(role.aeRoleUuid))
        ) {
          roleList.push(role);
        }
      });
      break;
    }
    default: {
      roleList = yield call(base.getRoleOption, userId);
    }
  }
  return roleList;
}

function* getProject(
  provider: string,
  aeRoleApplication: any,
  userId: string,
  appId?: string
): Generator<any, any, any> {
  let projectList: any = [];
  switch (provider) {
    case 'okta': {
      if (aeRoleApplication) {
        let aeapplicationsList: any = [];
        for (let i = 0; i < aeRoleApplication.length; i++) {
          let aeapp: any = aeRoleApplication[i].split(':');
          aeapplicationsList.push(aeapp[1]);
        }
        const allApplications = yield call(base.getAllApplications);
        allApplications.map((application: any) => {
          if (aeapplicationsList.includes(application.aeApplicationUuid)) {
            projectList.push(application);
          }
        });
      }
      break;
    }
    case 'microsoft-ad-info-apps':
      projectList = yield call(base.getProjectOption, userId);
      break;
    case 'microsoft-ad': {
      const responser = yield call(base.applicationRoleMicrosoftAd);
      const { data } = yield call(base.getConfigData, 'ApplicationQuery', '');
      data.Applications.map((application: any) => {
        const currentAplication = responser.value.filter(
          (applicationRole: any) =>
            applicationRole.resourceId === application.authAppId &&
            application.aeApplicationUuid === appId
        );
        if (currentAplication[0]) {
          projectList.push(application);
        }
      });
      break;
    }
    default: {
      projectList = yield call(base.getProjectOption, userId);
    }
  }
  return projectList;
}

const getAndSetCurrentFinanacialYear = (
  financialYears: any,
  userSession: any,
  isUserLoggedIn: any
) => {
  let currentYear = '';
  if (!isUserLoggedIn && financialYears.length > 0) {
    const currentYearRecord: any = getCurrentYearOrLanguage(financialYears);
    currentYear = currentYearRecord.code;
    userSession.AdditionalDetails.UserContext.APP_LOGGED_IN_YEAR = currentYear;
    userSession.AdditionalDetails.UserContext.APP_LOGGED_IN_YEAR_STATUS =
      currentYearRecord.APP_LOGGED_IN_YEAR_STATUS;
  } else {
    currentYear = '';
  }
  return currentYear;
};

const getAndSetCurrentLanguage = (
  allLanguages: any,
  userSession: any,
  isUserLoggedIn: any,
  userDetailsMap: any
) => {
  let currentLanguage = '';
  if (!isUserLoggedIn && allLanguages.length > 0) {
    const currentLanguageRecord: any = getCurrentYearOrLanguage(allLanguages);
    currentLanguage = currentLanguageRecord.code;
    userSession.APP_CURRENT_LANGUAGE = currentLanguage;
  } else {
    currentLanguage = userDetailsMap.APP_CURRENT_LANGUAGE
      ? userDetailsMap.APP_CURRENT_LANGUAGE
      : 'en';
  }
  return currentLanguage;
};

function* getDefaultProject(
  provider: string,
  aeRoleApplication: any,
  tenantId: string,
  userId: string,
  roleList: any,
  projectId?: string,
  projectList?: any
): Generator<any, any, any> {
  let project: any = {};
  switch (provider) {
    case 'okta': {
      if (aeRoleApplication) {
        let aeapplicationsList: any = [];
        let aeRoleList: any = [];
        for (let i = 0; i < aeRoleApplication.length; i++) {
          let aeapp: any = aeRoleApplication[i].split(':');
          if (projectId && projectId === aeapp[1]) {
            aeRoleList.push(aeapp[0]);
            aeapplicationsList.push(aeapp[1]);
          } else if (projectId === undefined) {
            aeRoleList.push(aeapp[0]);
            aeapplicationsList.push(aeapp[1]);
          }
        }
        let roleId = '';
        roleList.map((role: any) => {
          if (aeRoleList[0] == role.roleName) {
            roleId = role.aeRoleUuid;
          }
        });
        project.aeApplicationUuid = aeapplicationsList[0];
        project.aeInsertId = null;
        project.aeInsertTs = null;
        project.aeRoleUuid = roleId;
        project.aeTransactionId = null;
        project.aeUpdateId = null;
        project.aeUpdateTs = null;
        project.aeUserAuthUuid = userId;
        project.aeUserMRoleUuid = '094f3437-c74a-11ea-994f-12eaac2dc7ag';
        project.isDefault = 1;
        project.isdeleted = 0;
        project.tenantId = tenantId;
      }
      break;
    }
    case 'microsoft-ad-info-apps':
      project = yield call(
        base.getDefaultProjectAndRoleFromUserId,
        userId,
        projectId
      );
      break;
    case 'microsoft-ad': {
      if (projectId && projectList.length > 0) {
        const currentAplication = projectList.filter(
          (application: any) => application.aeApplicationUuid === projectId
        );
        project = new ApplicationRole(
          currentAplication[0].authAppId,
          currentAplication[0].aeInsertId,
          currentAplication[0].aeInsertTs,
          roleList[0].aeRoleUuid,
          currentAplication[0].aeTransactionId,
          currentAplication[0].aeUpdateId,
          currentAplication[0].aeUpdateTs,
          userId,
          roleList[0].aeRoleUuid,
          1,
          0,
          currentAplication[0].tenantId
        );
      }
      break;
    }
    default: {
      project = yield call(
        base.getDefaultProjectAndRoleFromUserId,
        userId,
        projectId
      );
    }
  }
  return project;
}

const setDefaultProjectAndRole = (
  defaultProject: any,
  roleList: any,
  userDetails: any,
  userSession: any,
  projectList: any,
  user: any,
  userId: any
) => {
  if (Object.keys(defaultProject).length) {
    const currentRoleMap = roleList.filter(
      (role: any) => role.aeRoleUuid === defaultProject.aeRoleUuid
    );

    let mappedRoleList = defaultProject.aeRoleUuid.split(',');
    let extractedRoleName = '';
    let extractedRoleId = '';
    roleList.map((role: any) => {
      if (
        mappedRoleList.includes(role.aeRoleUuid) &&
        !extractedRoleName.includes(role.roleName)
      ) {
        extractedRoleName = extractedRoleName + role.roleName + ',';
        extractedRoleId = extractedRoleId + role.roleId + ',';
      }
    });
    extractedRoleName = extractedRoleName.slice(0, -1);
    extractedRoleId = extractedRoleId.slice(0, -1);
    const currentProjectMap = projectList.filter(
      (project: any) =>
        project.aeApplicationUuid === defaultProject.aeApplicationUuid
    );
    if (extractedRoleName) {
      userDetails['APP_LOGGED_IN_ROLENAME'] = extractedRoleName;
      userSession.AdditionalDetails.UserContext['APP_LOGGED_IN_ROLENAME'] =
        extractedRoleName;
      userSession.AdditionalDetails.UserContext.APP_LOGGED_IN_ROLE_ID =
        extractedRoleId;
      userSession.APP_LOGGED_IN_ROLE_ID = extractedRoleId;
    } else if (roleList.length) {
      userDetails['APP_LOGGED_IN_ROLENAME'] = roleList[0].roleName;
      userSession.AdditionalDetails.UserContext['APP_LOGGED_IN_ROLENAME'] =
        roleList[0].roleName;
      userSession.AdditionalDetails.UserContext.APP_LOGGED_IN_ROLE_ID =
        roleList[0].roleId ? roleList[0].roleId : roleList[0].aeRoleUuid;
      userSession.APP_LOGGED_IN_ROLE_ID = roleList[0].roleId
        ? roleList[0].roleId
        : roleList[0].aeRoleUuid;
    }
    if (currentProjectMap.length) {
      userSession.AdditionalDetails.UserContext.APP_LOGGED_IN_PROJECT_ID =
        currentProjectMap[0].hasOwnProperty('applicationId')
          ? currentProjectMap[0].applicationId
          : currentProjectMap[0].id;
      userSession.APP_LOGGED_IN_PROJECT_ID =
        currentProjectMap[0].hasOwnProperty('applicationId')
          ? currentProjectMap[0].applicationId
          : currentProjectMap[0].id;
      userSession.APP_LOGGED_IN_PROJECT_UUID =
        currentProjectMap[0].aeApplicationUuid;
      userSession.AdditionalDetails.UserContext.APP_LOGGED_IN_PROJECT_UUID =
        currentProjectMap[0].aeApplicationUuid;
      userSession.APP_LOGGED_IN_PROJECT_NAME = currentProjectMap[0]
        .applicationName
        ? currentProjectMap[0].applicationName
        : currentProjectMap[0].name;
      userSession.AdditionalDetails.UserContext['CURRENT_PROJECT'] =
        currentProjectMap[0].hasOwnProperty('applicationId')
          ? currentProjectMap[0].applicationId
          : currentProjectMap[0].id;
    } else if (projectList.length) {
      userSession.AdditionalDetails.UserContext.APP_LOGGED_IN_PROJECT_ID =
        projectList[0].hasOwnProperty('applicationId')
          ? projectList[0].applicationId
          : projectList[0].id;
      userSession.APP_LOGGED_IN_PROJECT_ID = projectList[0].hasOwnProperty(
        'applicationId'
      )
        ? projectList[0].applicationId
        : projectList[0].id;
      userSession.APP_LOGGED_IN_PROJECT_UUID = projectList[0].aeApplicationUuid;
      userSession.AdditionalDetails.UserContext.APP_LOGGED_IN_PROJECT_UUID =
        projectList[0].aeApplicationUuid;
      userSession.APP_LOGGED_IN_PROJECT_NAME = projectList[0].applicationName
        ? projectList[0].applicationName
        : projectList[0].name;
      userSession.AdditionalDetails.UserContext['CURRENT_PROJECT'] =
        projectList[0].hasOwnProperty('applicationId')
          ? projectList[0].applicationId
          : projectList[0].id;
    } else {
      userSession.APP_LOGGED_IN_PROJECT_NAME = 'Application not assigned';
    }
    const authProvider: string = JSON.parse(process.env.REACT_APP_AUTH_DETAILS!)
      .provider.type;
    userSession.APP_LOGGED_IN_USER_ID = userId;
  } else {
    userSession.APP_LOGGED_IN_PROJECT_NAME = 'Application not assigned';
  }
};

const setUserNameAfterLogin = (
  authProvider: any,
  userSession: any,
  userProfile: any,
  defaultProject: any,
  userDetails: any,
  storage: any,
  isUserLoggedIn: any
) => {
  let userName = '';
  let userEmail = '';
  switch (authProvider) {
    case 'okta': {
      userName = userSession.name;
      userEmail = userSession.email;
      break;
    }
    case 'microsoft-ad-info-apps':
    case 'microsoft-ad': {
      userName = userProfile.userInfo.name;
      userEmail = userProfile.userInfo.email;
      break;
    }
    default: {
      userName = defaultProject
        ? defaultProject.firstName + ' ' + defaultProject.lastName
        : userProfile.userInfo.name;
      userEmail = defaultProject ? defaultProject.email : '';
    }
  }
  userDetails['APP_LOGGED_IN_USER_NAME'] = userName;
  userDetails['APP_LOGGED_IN_USER_EMAIL'] = userEmail;
  Object.entries(userDetails).forEach(([key, value]) => {
    if (key.includes('APP_LOGGED_IN')) {
      userSession.AdditionalDetails.UserContext[key] = value;
    }
  });
  storage.setItem(USER_SESSION_LOCAL_STORAGE_KEY, JSON.stringify(userSession));
};

const projectParser = (projectList: any) => {
  let applications: any = [];
  projectList.map((project: any) => {
    let applicationMap: any = {};
    applicationMap['code'] = project.applicationName;
    applicationMap['name'] = project.applicationName;
    applicationMap['id'] = project.applicationId;
    applicationMap['aeApplicationUuid'] = project.aeApplicationUuid;
    applicationMap['applicationParameter'] = project.applicationParameter;
    applications.push(applicationMap);
  });
  return applications;
};

const getYearOrLanguageList = (financialYears: any) => {
  const financialYearList: any = [];
  sortConfigObjects(financialYears);
  financialYears.map((year: any) => {
    const formattedOption: any = {
      id: year.id ? year.id : year.configObjectId,
      code: year.code,
      name: year.name,
    };
    if (year.APP_LOGGED_IN_YEAR_STATUS !== undefined)
      formattedOption.status = year.APP_LOGGED_IN_YEAR_STATUS;
    financialYearList.push(formattedOption);
    return null;
  });
  return financialYearList;
};

const getCurrentYearOrLanguage = (financialYears: any) => {
  let currentYear: any = {};
  const defaultYearRecord = financialYears.find(
    (year: any) => year.defaultLanguage || year.IS_DEFAULT === '1'
  );
  if (defaultYearRecord) {
    currentYear = defaultYearRecord;
  } else {
    currentYear = financialYears[0];
  }
  return currentYear;
};

function* getUserApplicationSpecialAttributes(
  provider: string,
  userId: string,
  applicationId: string,
  applicationParameter: string
): Generator<any, any, any> {
  let userAppMapping: any = [];
  let userAppSpecialAttribute: any = [];
  switch (provider) {
    case 'aws':
    case 'microsoft-ad-info-apps':
      userAppSpecialAttribute = yield call(
        base.getUserApplicationSpecialAttributes,
        userId,
        applicationId
      );
      break;
  }
  var applicationParameterDetails = JSON.parse(applicationParameter);
  for (const key in userAppSpecialAttribute) {
    if (applicationParameterDetails.hasOwnProperty(key)) {
      for (const keyNew in applicationParameterDetails) {
        if (
          keyNew === key &&
          userAppSpecialAttribute[key].DATA_ATTRIBUTE_VALUE
        ) {
          let userContextTitle =
            userAppSpecialAttribute[key].DATA_ATTRIBUTE_LABEL;
          let userContextKey = userAppSpecialAttribute[key].DATA_ATTRIBUTE_KEY;
          let mapData: any = {};
          let returndata: any = yield call(
            base.getMappingDataFromMaster,
            applicationParameterDetails[key].DATASOURCE,
            applicationParameterDetails[key].TABLE_NAME,
            applicationParameterDetails[key].KEY_NAME,
            applicationParameterDetails[key].LABLE_NAME,
            userAppSpecialAttribute[key].DATA_ATTRIBUTE_VALUE,
            applicationParameterDetails[key].CONDITION
          );

          const optionAsList: any = base.generateoptionsInFormat(returndata);

          mapData[userContextTitle + '_LIST'] = optionAsList;
          mapData['LABEL'] = userContextTitle;
          mapData['TYPE'] =
            applicationParameterDetails[key] &&
            applicationParameterDetails[key].COMPONENT_TYPE
              ? applicationParameterDetails[key].COMPONENT_TYPE
              : 'Hidden';
          let contextData = '';
          if (
            mapData['TYPE'] !== 'SelectOption' &&
            userAppSpecialAttribute[key].DATA_ATTRIBUTE_VALUE &&
            userAppSpecialAttribute[key].DATA_ATTRIBUTE_VALUE.includes(',')
          ) {
            let contextDataList =
              userAppSpecialAttribute[key].DATA_ATTRIBUTE_VALUE.split(',');
            contextData = contextDataList.join("','");
            contextData = "'" + contextData + "'";
          } else if (
            mapData['TYPE'] === 'SelectOption' &&
            userAppSpecialAttribute[key].DATA_ATTRIBUTE_VALUE &&
            userAppSpecialAttribute[key].DATA_ATTRIBUTE_VALUE.includes(',')
          ) {
            let contextDataList =
              userAppSpecialAttribute[key].DATA_ATTRIBUTE_VALUE.split(',');
            contextData = contextDataList[0];
          } else {
            contextData = userAppSpecialAttribute[key].DATA_ATTRIBUTE_VALUE;
          }
          mapData[userContextKey] = contextData;
          mapData['KEY_TITLE'] = userContextKey;
          userAppMapping.push(mapData);
        }
      }
    } else {
      let userContextKey = userAppSpecialAttribute[key].DATA_ATTRIBUTE_KEY;
      let contextData = '';
      if (
        userAppSpecialAttribute[key].DATA_ATTRIBUTE_VALUE &&
        userAppSpecialAttribute[key].DATA_ATTRIBUTE_VALUE.includes(',')
      ) {
        let contextDataList =
          userAppSpecialAttribute[key].DATA_ATTRIBUTE_VALUE.split(',');
        contextData = contextDataList.join("','");
        contextData = "'" + contextData + "'";
      } else {
        contextData = userAppSpecialAttribute[key].DATA_ATTRIBUTE_VALUE;
      }
      let mapData: any = {};
      mapData['TYPE'] = 'Hidden';
      mapData[userContextKey] = contextData;
      mapData['KEY_TITLE'] = userContextKey;
      userAppMapping.push(mapData);
    }
  }

  if (applicationParameterDetails.hasOwnProperty('Queries')) {
    for (const key in applicationParameterDetails.Queries) {
      let returndata: any = yield call(
        base.getDataViaQuery,
        applicationParameterDetails.Queries[key].DATASOURCE,
        applicationParameterDetails.Queries[key].Query
      );
      if (returndata.length > 0) {
        let mapData: any = {};
        mapData['TYPE'] = 'Hidden';
        mapData['KEY_TITLE'] =
          applicationParameterDetails.Queries[key].KEY_NAME;
        mapData[applicationParameterDetails.Queries[key].KEY_NAME] =
          returndata[0][applicationParameterDetails.Queries[key].KEY_NAME];
        userAppMapping.push(mapData);
      }
    }
  }
  return userAppMapping;
}

const setSpecialmapping = (userAppSpecialAttribute: any, userSession: any) => {
  let userAppSpecialAttributes = userAppSpecialAttribute.filter(
    (userAppSpecialDetail: any) => userAppSpecialDetail.TYPE !== 'Hidden'
  );
  userSession['SpecialMappingDetails'] = [...userAppSpecialAttributes];
  userAppSpecialAttribute.map((attribute: any) => {
    Object.keys(attribute).map((key) => {
      if (key === 'KEY_TITLE') {
        let key1 = attribute[key];
        if (!userSession.AdditionalDetails.UserContext[key1]) {
          userSession.AdditionalDetails.UserContext[key1] = attribute[key1];
        }
      }
    });
  });
};
