import { useState, useContext } from 'react';
import * as React from 'react';
import { InfoAuth } from '../auth';
import { FloatingLabelInput } from './FloatingLabelInput';
import ErrorMsgComponent from './ErrorMessageComponent';
import CommonHeader from './CommonHeader';
import { FormattedMessage, injectIntl } from 'react-intl';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
import {
  CustomButton,
  ForgotPassTitleSpanContainer,
  ForgotPasswordContainer,
  Form,
  LinkComponent,
  LinkSpan,
  ParentWrapperContainer,
  RedirectContainer,
  WrapperContainer,
} from './ForgotPasswordStyle';
import { LanguageContext } from './App';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const img = require('../greenBackground.jpg');

const ToastStyle = styled.div`
  background-color: rgb(227, 252, 239) !important;
  box-shadow: rgba(0, 0, 0, 0.176) 0px 3px 8px !important;
  color: rgb(0, 102, 68) !important;
  transform: translate3d(0px, 0px, 0px);
  border-radius: 4px;
  border-left: 5px solid rgb(54, 179, 126) !important;
  transition: transform 220ms cubic-bezier(0.2, 0, 0, 1) 0s;
`;

interface IForgotPassword {
  locale: any;
  setMessages: any;
  message: any;
  intl: any;
}

const ForgotPassword: React.FC<IForgotPassword> = (props: IForgotPassword) => {
  const navigate = useNavigate();
  const context = useContext(LanguageContext);
  const [preferred_username, setPreferredUsername] = useState('');
  const [inlineErrorUser, setInlineErrorUser] = useState(false);
  const [pass, setPass] = useState(false);
  const [showMessage, setshowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  const handleForgotPassword = async () => {
    if (preferred_username === '') {
      setInlineErrorUser(true);
      setPass(false);
      setshowMessage(false);
      setMessage('');
      setLoading(false);
    } else {
      try {
        setLoading(true);
        await InfoAuth.forgotPassword(preferred_username);
        toast.success(
          <ToastStyle>
            Check email '{preferred_username}' for passcode.
          </ToastStyle>,
          {
            autoClose: 10000,
          }
        );
        navigate('/resetPassword', { state: { email: preferred_username } });
      } catch (err) {
        setLoading(false);
        if (err.message != null) {
          setPass(false);
          setshowMessage(true);
          setMessage(err.message);
        }
      }
    }
  };

  return (
    <div>
      <CommonHeader setLanguage={context.setLanguage} {...props} />
      <ParentWrapperContainer image={img}>
        <ForgotPasswordContainer>
          <WrapperContainer>
            <Form>
              <ForgotPassTitleSpanContainer showMessage={showMessage}>
                <strong>
                  <FormattedMessage id="resetPwd.forgotPassword" />
                </strong>
              </ForgotPassTitleSpanContainer>
              <ErrorMsgComponent
                pass={pass}
                showMessage={showMessage}
                message={message}
              />
              <FloatingLabelInput
                id="userId"
                dispalyLabel={<FormattedMessage id="resetPwd.email" />}
                onChange={setPreferredUsername}
                value={preferred_username}
                inlineError={inlineErrorUser}
                setInLineError={setInlineErrorUser}
                type="text"
                inlineMsg={<FormattedMessage id="resetPwd.inlineMsgEmail" />}
              />
              <CustomButton
                size="lg"
                loading={loading}
                onClick={handleForgotPassword}
              >
                <FormattedMessage id="resetPwd.sendResetPasscode" />
              </CustomButton>
              <RedirectContainer>
                <LinkComponent to="/login">
                  <LinkSpan showMessage={showMessage}>
                    <FormattedMessage id="resetPwd.backToLogin" />
                  </LinkSpan>
                </LinkComponent>
              </RedirectContainer>
            </Form>
          </WrapperContainer>
        </ForgotPasswordContainer>
      </ParentWrapperContainer>
      <ToastContainer hideProgressBar={true} />
    </div>
  );
};

export default injectIntl(ForgotPassword);
