import { useState, useContext, useEffect } from 'react';
import * as React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { InfoAuth } from '../auth';
import { FloatingLabelInput } from './FloatingLabelInput';
import ErrorMsgComponent from './ErrorMessageComponent';
import { FormattedMessage, injectIntl } from 'react-intl';
import CommonHeader from './CommonHeader';
import {
  ConfirmationMsg,
  ConfirmationTitleSpanContainer,
  CustomButton,
  Form,
  ConfirmPasswordStartMsgOuterContainer,
  LinkSpan,
  ParentWrapperContainer,
  StyleIcon,
  SignUpTitleSpanContainer,
  WrapperContainer,
} from './SignUpStyle';
import { LanguageContext } from './App';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { faReply } from '@fortawesome/free-solid-svg-icons/faReply';

const img = require('../greenBackground.jpg');

const ToastStyle = styled.div`
  background-color: rgb(227, 252, 239) !important;
  box-shadow: rgba(0, 0, 0, 0.176) 0px 3px 8px !important;
  color: rgb(0, 102, 68) !important;
  transform: translate3d(0px, 0px, 0px);
  border-radius: 4px;
  border-left: 5px solid rgb(54, 179, 126) !important;
  transition: transform 220ms cubic-bezier(0.2, 0, 0, 1) 0s;
`;

interface IConfirmSignUp {
  userId: string;
  email: string;
  locale: any;
  setMessages: any;
  message: any;
  intl: any;
  showToast: any;
}

const ConfirmSignUp: React.FC<IConfirmSignUp> = (props: IConfirmSignUp) => {
  const navigate = useNavigate();
  const context = useContext(LanguageContext);
  const [preferred_username, setPreferredUsername] = useState(
    props.email ? props.email : ''
  );
  const [email, setEmail] = useState(props.email ? props.email : '');
  const [userId, setUserId] = useState(props.userId ? props.userId : '');
  const [authCode, setAuthCode] = useState('');
  const [inlineAuthCode, setInlineErrorAuthCode] = useState(false);
  const [pass, setPass] = useState(false);
  const [showMessage, setshowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    props.showToast &&
      toast.success(
        <ToastStyle>User verification pending. Check email</ToastStyle>,
        {
          autoClose: 10000,
        }
      );
  }, []);

  const resend = async () => {
    try {
      await InfoAuth.resendSignUp(preferred_username);
      setshowMessage(false);
      toast.success(
        <ToastStyle>Check email '{email}' for verification code.</ToastStyle>,
        {
          autoClose: 5000,
        }
      );
    } catch (e) {
      console.log(e);
    }
  };

  const confirmSignUp = async () => {
    if (authCode === '') {
      setInlineErrorAuthCode(true);
      setPass(false);
      setshowMessage(false);
      setMessage('');
      setLoading(false);
    }
    if (authCode !== '') {
      try {
        setLoading(true);
        const transactionId: string = uuidv4();
        const result = await InfoAuth.confirmSignUp(
          email,
          authCode,
          transactionId
        );
        if (result === 'SUCCESS') {
        }
        setshowMessage(false);
        toast.success(<ToastStyle>Signed Up Successfully!</ToastStyle>, {
          autoClose: 15000,
        });
        setTimeout(function () {
          navigate('/login');
        }, 5000);
      } catch (err) {
        setLoading(false);
        if (err.message != null) {
          setPass(false);
          setshowMessage(true);
          setMessage(err.message);
        }
      }
    }
  };

  return (
    <div>
      <CommonHeader setLanguage={context.setLanguage} {...props} />
      <ToastContainer hideProgressBar={true} />
      <ParentWrapperContainer image={img}>
        <WrapperContainer>
          <Form>
            <ConfirmationTitleSpanContainer showMessage={showMessage}>
              <strong>
                <FormattedMessage id="signup.headConfirmation" />
              </strong>
            </ConfirmationTitleSpanContainer>
            <ErrorMsgComponent
              pass={pass}
              showMessage={showMessage}
              message={message}
            />

            <FloatingLabelInput
              id="authcode"
              dispalyLabel={
                <FormattedMessage id="signup.floatingConfirmationCode" />
              }
              onChange={setAuthCode}
              value={authCode}
              inlineError={inlineAuthCode}
              setInLineError={setInlineErrorAuthCode}
              type="text"
              inlineMsg="Auth Code is required"
            />
            <LinkSpan showMessage={showMessage} onClick={resend}>
              {' '}
              <StyleIcon icon={faReply} />
              <FormattedMessage id="signup.resend" />{' '}
            </LinkSpan>
            <CustomButton size="lg" loading={loading} onClick={confirmSignUp}>
              {' '}
              <FormattedMessage id="signup.buttonConfirmSignup" />{' '}
            </CustomButton>
          </Form>
        </WrapperContainer>
      </ParentWrapperContainer>
    </div>
  );
};

export default injectIntl(ConfirmSignUp);
