import React, { useContext, useState } from 'react';
import { ThemeContext } from '../theme/ThemeContext';
import { Autocomplete, FormErrorContainers } from 'info-ui-library';
import { injectIntl } from 'react-intl';
import { extractLabel } from '../utils/intlutils';

interface IAutocompleteCore {
  configProp: any;
  formId: string;
  parentFormId: string;
  mode: string;
  occuranceNumber: number;
  nodeId: string;
  privilegesMap: any;
  parentEntityId: string;
  childEntityId: undefined | string;
  location: string;
  intl: any;
}

const generateOption = (options: any) => {
  const optionAsList = options.map((option: any) => {
    const optionObject = {
      value: option.value,
      label: option.label,
    };
    return optionObject;
  });

  return optionAsList;
};

const AutocompleteCore: React.FC<IAutocompleteCore> = (
  props: IAutocompleteCore
) => {
  const themeContext = useContext(ThemeContext);
  const [value, setValue] = useState('abc');
  const [inputValue, setinputValue] = useState('abc');

  try {
    const optionParser = generateOption(props.configProp.multivalueList);
    const handleChange = (value: string, id: string) => {
      setValue(value);
    };
    const handleInputChange = (inputValue: string, id: string) => {
      setinputValue(inputValue);
    };
    return (
      <Autocomplete
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={themeContext.styleName}
        label={extractLabel(props.configProp.label, props.intl.locale)}
        key={props.configProp.configObjectId}
        options={optionParser}
        id={props.configProp.configObjectId}
        onChange={handleChange}
        isSearchable={props.configProp.isSearchable}
        isDisabled={props.configProp.isDisabled}
        isMulti={props.configProp.isMulti}
        handleInputChange={handleInputChange}
        value={value}
        inputValue={inputValue}
      />
    );
  } catch {
    const error = ['Error occurred in Autocomplete'];
    return (
      <FormErrorContainers
        ioTheme={themeContext.ioTheme}
        ioMode={themeContext.ioMode}
        styleName={'danger'}
        lists={error}
      />
    );
  }
};

export default React.memo(injectIntl(AutocompleteCore));
