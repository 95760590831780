import * as React from 'react';
import { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { InfoAuth } from '../auth';
import { ISignUpParams } from '../model/user.info';
import { SignUpResult } from '../types/sign.up';
import { FloatingLabelInput } from './FloatingLabelInput';
import ErrorMsgComponent from './ErrorMessageComponent';
import { FormattedMessage, injectIntl } from 'react-intl';
import CommonHeader from './CommonHeader';
import ReactPasswordStrength from './ReactPasswordStrength';
import config from '../config/index';
import 'react-toastify/dist/ReactToastify.css';
import { toast, ToastContainer } from 'react-toastify';
const img = require('../greenBackground.jpg');
import styled from 'styled-components';
import {
  ConfirmationMsg,
  ConfirmationTitleSpanContainer,
  CustomButton,
  Form,
  LinkComponent,
  LinkSpan,
  ParentWrapperContainer,
  RedirectContainer,
  SignUpTitleSpanContainer,
  WrapperContainer,
} from './SignUpStyle';
import { LanguageContext } from './App';

interface ISignUp {
  userName: string;
  showConfirmation: boolean;
  locale: any;
  setMessages: any;
  message: any;
  intl: any;
}

const ToastStyle = styled.div`
  background-color: rgb(227, 252, 239) !important;
  box-shadow: rgba(0, 0, 0, 0.176) 0px 3px 8px !important;
  color: rgb(0, 102, 68) !important;
  transform: translate3d(0px, 0px, 0px);
  border-radius: 4px;
  border-left: 5px solid rgb(54, 179, 126) !important;
  transition: transform 220ms cubic-bezier(0.2, 0, 0, 1) 0s;
`;

const SignUp: React.FC<ISignUp> = (props: ISignUp) => {
  const context = useContext(LanguageContext);
  const navigate = useNavigate();
  const [preferred_username, setPreferredUsername] = useState(
    props.userName ? props.userName : ''
  );
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [userId, setUserId] = useState('');
  const [authCode, setAuthCode] = useState('');
  const [inlineErrorUN, setInlineErrorUN] = useState(false);
  const [inlineErrorEM, setInlineErrorEM] = useState(false);
  const [inlineErrorPWD, setInlineErrorPWD] = useState(false);
  const [inlineErrorMBNo, setInlineErrorMBNo] = useState(false);
  const [inlineAuthCode, setInlineErrorAuthCode] = useState(false);
  const [pass, setPass] = useState(false);
  const [showMessage, setshowMessage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const signUp = async (navigate: any) => {
    if (password.trim() === '') {
      setInlineErrorPWD(true);
      setPass(false);
      setshowMessage(false);
      setMessage('');
      setLoading(false);
    }

    if (email.trim() === '') {
      setInlineErrorEM(true);
      setPass(false);
      setshowMessage(false);
      setMessage('');
      setLoading(false);
    }

    if (password.trim() !== '' && email.trim() !== '') {
      const phone_number = '9422668800';
      const params: ISignUpParams = {
        preferred_username: '',
        password: password,
        email: email,
      };

      try {
        setLoading(true);
        let result: SignUpResult = await InfoAuth.signUp(params);
        let userData: any = {};
        try {
          userData = JSON.parse(result.sub);
        } catch (e) {
          userData.aeUserAuthUuid = result.sub;
        }

        config.verificationType === 'link'
          ? navigate({
              pathname: '/login',
              state: { email: email },
            })
          : result.isUserConfirmed
            ? navigate('/login')
            : navigate({
                pathname: '/confirmSignUp',
                state: {
                  email: email,
                  userId: userData.aeUserAuthUuid,
                },
              });
        setPass(false);
        setshowMessage(false);
        setMessage('');
        toast.success(
          "Check email '" + email + "' for " + config.verificationType,
          {
            autoClose: 10000,
            className: ToastStyle,
          }
        );
      } catch (err) {
        setLoading(false);
        if (err.message != null) {
          setPass(false);
          setshowMessage(true);
          setMessage(err.message);
        }
      }
    }
  };

  const validateEmail = (email: string) => {
    const emailRegEx =
      /^[a-zA-Z0-9.!#$%&'*+=?^_`{|}~-]+@[a-zA-Z0-9]+\.[A-Za-z]{2,3}$/;
    return emailRegEx.test(email);
  };

  const validateMobileNo = (mobileNo: string) => {
    const mobileNoRegEx = /^(\+\d{1,3}[- ]?)?\d{10}$/;
    return mobileNoRegEx.test(mobileNo);
  };

  const signUpRenderer = (
    <ParentWrapperContainer image={img}>
      <WrapperContainer>
        <Form>
          <SignUpTitleSpanContainer>
            <strong>
              <FormattedMessage id="signup.headSignup" />
            </strong>
          </SignUpTitleSpanContainer>
          <ErrorMsgComponent
            pass={pass}
            showMessage={showMessage}
            message={message}
          />
          <FloatingLabelInput
            id="email"
            dispalyLabel={<FormattedMessage id="signup.email" />}
            onChange={setEmail}
            value={email}
            inlineError={inlineErrorEM}
            setInLineError={setInlineErrorEM}
            type="text"
            inlineMsg="Email is required"
          />
          <ReactPasswordStrength
            {...props}
            id="password"
            dispalyLabel={<FormattedMessage id="signup.password" />}
            onChange={setPassword}
            value={password}
            inlineError={inlineErrorPWD}
            setInLineError={setInlineErrorPWD}
            type="password"
            inlineMsg="Password is required"
          />
          <CustomButton size="lg" loading={loading} onClick={signUp}>
            {' '}
            <FormattedMessage id="signup.buttonSignup" />{' '}
          </CustomButton>
          <RedirectContainer>
            <LinkComponent to="/login">
              <LinkSpan showMessage={showMessage}>
                <FormattedMessage id="signup.haveaccountCheckMessage" />{' '}
                <u>
                  <FormattedMessage id="signup.linkLogin" />
                </u>
              </LinkSpan>
            </LinkComponent>
          </RedirectContainer>
        </Form>
      </WrapperContainer>
    </ParentWrapperContainer>
  );

  return (
    <div>
      <CommonHeader setLanguage={context.setLanguage} {...props} />
      <div>{signUpRenderer}</div>
    </div>
  );
};

export default injectIntl(SignUp);
