import React from 'react';
import {
  useTable,
  useSortBy,
  useFilters,
  useGroupBy,
  useExpanded,
  useBlockLayout,
} from 'react-table';
import { Table, Tbody, Td, Thead, Th, Tr } from 'info-ui-library';
import { filterTypes } from '../DefaultConstants';
import ExpandableSubComponents from './ExpandableSubComponents';
import DefaultColumnFilter from '../filterGridComponents/DefaultColumnFilter';
import MobileViewCell from '../editableGridComponents/MobileViewCell';
import InternalGrid from './InternalGrid.tsx';
import InfiniteScroll from 'react-infinite-scroll-component';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons/faCaretDown';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons/faCaretUp';
import { faSort } from '@fortawesome/free-solid-svg-icons/faSort';
import { faPlay } from '@fortawesome/free-solid-svg-icons/faPlay';
import { faSortDown } from '@fortawesome/free-solid-svg-icons/faSortDown';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/faChevronDown';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons/faChevronUp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function InfoTable(props) {
  let columns = props.columns;
  let data = props.data;
  let errorData = props.errorDataExtractor
    ? props.errorDataExtractor('abc', 'UUID', props.referralId, false)
    : null;
  const gridHeight = window.innerHeight > 790 ? 69 : 64;
  const updateMyData = props.updateMyData;
  const subComponentEnable = props.subComponentEnable;
  const subComponentType = props.subComponentType;
  const defaultColumn = React.useMemo(
    () => ({
      width: '250',
      Filter: DefaultColumnFilter,
      Cell: MobileViewCell,
    }),
    []
  );

  const [selectedRowPath, setSelectedRowPath] = React.useState(null);
  let cardsDataSatus = props.cardsDataSatus;

  const onClick = (row) => {
    setSelectedRowPath(row.id.toString());
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    totalColumnsWidth,
    prepareRow,
    visibleColumns,
    state: { groupBy, expanded },
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      updateMyData,
      filterTypes,
    },
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    useBlockLayout
  );

  const renderVariableHeightItem = React.useCallback(
    (index, selectedPath, errorOccurred, selectedRowPath) => {
      const row = rows[index];
      let status = 'normal';
      if (
        row.id.toString() === selectedPath &&
        errorOccurred &&
        errorOccurred[selectedPath]
      ) {
        status = 'fail';
      } else if (row.id.toString() === selectedPath) {
        status = 'success';
      } else if (row.id.toString() === selectedRowPath) {
        status = 'selected';
      }
      prepareRow(row);
      return (
        <>
          <Tr
            styleName={props.styleName}
            ioTheme={props.ioTheme}
            ioMode={props.ioMode}
            hoverEnable={props.hoverEnable}
            strippedEnable={props.strippedEnable}
            style={{ lineHeight: `40px` }}
            status={status}
            onClick={() => onClick(row)}
          >
            {row.cells.map((cell) => {
              if (cell.column.isAccessible) {
                let tdProps = cell.getCellProps();
                tdProps["style"] = {};
                tdProps.style["padding"] = "0.1rem";
                tdProps.style["minWidth"] = cell.column.width + "px";
                return (
                  <Td
                    {...tdProps}
                    styleName={props.styleName}
                    ioTheme={props.ioTheme}
                    ioMode={props.ioMode}
                    borderEnable={props.borderEnable}
                  >
                    {cell.isGrouped ? (
                      <React.Fragment>
                        {row.isExpanded ? (
                          <FontAwesomeIcon
                            {...row.getToggleRowExpandedProps()}
                            style={{ fontSize: '20px' }}
                            icon={faSortDown}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faPlay}
                            {...row.getToggleRowExpandedProps()}
                          />
                        )}
                        {' ' + cell.value + ' '} ({row.subRows.length})
                      </React.Fragment>
                    ) : cell.isAggregated && row.subRows.length === 0 ? (
                      cell.render('Aggregated')
                    ) : cell.isRepeatedValue && row.subRows.length === 0 ? (
                      cell.render(MobileViewCell)
                    ) : (
                      cell.render('Cell', {
                        editable: true,
                        subComponentEnable: subComponentEnable,
                      })
                    )}
                  </Td>
                );
              }
            })}
          </Tr>
          {/* if subrows are present*/}
          {row.isExpanded && row.subRows.length === 0 ? (
            <Tr
              styleName={props.styleName}
              ioTheme={props.ioTheme}
              ioMode={props.ioMode}
              hoverEnable={props.hoverEnable}
              strippedEnable={props.strippedEnable}
              status={'expandedRow'}
              style={{zIndex: 1, position: "relative"}}
            >
              <Td
                colSpan={visibleColumns.length}
                styleName={props.styleName}
                ioTheme={props.ioTheme}
                ioMode={props.ioMode}
                borderEnable={props.borderEnable}
              >
                {subComponentType === 'DataTable'
                  ? InternalGrid({ row })
                  : ExpandableSubComponents({ row, ...props })}
              </Td>
            </Tr>
          ) : null}
        </>
      );
    },
    [prepareRow, page]
  );
  let tableProps = { ...getTableProps() };
  tableProps['style'] = {};

  return (
    <Table
      {...tableProps}
      styleName={props.styleName}
      ioTheme={props.ioTheme}
      ioMode={props.ioMode}
      borderEnable={props.borderEnable}
      strippedEnable={props.strippedEnable}
      hoverEnable={props.hoverEnable}
    >
      <Thead
        styleName={props.styleName}
        ioTheme={props.ioTheme}
        ioMode={props.ioMode}
        borderEnable={props.borderEnable}
      >
        {headerGroups.map((headerGroup) => {
          let trProps = headerGroup.getHeaderGroupProps();
          trProps.style.width = '100%';
          return (
            <Tr {...trProps} status={'normal'}>
              {headerGroup.headers.map((column) => {
                if (column.isAccessible) {
                  let thProps = { ...column.getHeaderProps() };
                  thProps['style'] = {};
                  thProps.style['width'] = column.width + 25 + 'px';
                  thProps.style['font-size'] = '18px';
                  thProps.style['font-weight'] = 'normal';

                  return (
                    <Th
                      {...thProps}
                      styleName={props.styleName}
                      ioTheme={props.ioTheme}
                      ioMode={props.ioMode}
                      borderEnable={props.borderEnable}
                    >
                      <div>
                        {subComponentEnable === false && column.canGroupBy ? (
                          <div {...column.getGroupByToggleProps()}>
                            {column.groupByEnable ? (
                              <React.Fragment>
                                <FontAwesomeIcon
                                  style={{
                                    marginTop: '2px',
                                    float: 'left',
                                    paddingRight: '5px',
                                    fontSize: '16px',
                                  }}
                                  title="Toggle GroupBy"
                                  icon={
                                    column.isGrouped
                                      ? faChevronDown
                                      : faChevronUp
                                  }
                                />
                                <FontAwesomeIcon
                                  style={{
                                    clear: 'left',
                                    float: 'left',
                                    paddingRight: '5px',
                                    fontSize: '16px',
                                    marginTop: '-5px',
                                  }}
                                  title="Toggle GroupBy"
                                  icon={
                                    column.isGrouped
                                      ? faChevronDown
                                      : faChevronUp
                                  }
                                />
                              </React.Fragment>
                            ) : null}
                          </div>
                        ) : null}
                        <span {...column.getSortByToggleProps()}>
                          {column.render('Header')}
                          {column.Header !== '' && column.canSort ? (
                            column.isSorted ? (
                              column.isSortedDesc ? (
                                <FontAwesomeIcon
                                  style={{ paddingLeft: '5px' }}
                                  icon={faCaretDown}
                                />
                              ) : (
                                <FontAwesomeIcon
                                  style={{ paddingLeft: '5px' }}
                                  icon={faCaretUp}
                                />
                              )
                            ) : (
                              <FontAwesomeIcon
                                style={{ paddingLeft: '5px' }}
                                icon={faSort}
                              />
                            )
                          ) : (
                            ''
                          )}
                        </span>
                      </div>
                      <div>
                        {column.canFilter && (column.filterEnable || false)
                          ? column.render('Filter')
                          : null}
                      </div>
                    </Th>
                  );
                }
              })}
            </Tr>
          );
        })}
      </Thead>

      <Tbody
        {...getTableBodyProps()}
        styleName={props.styleName}
        ioTheme={props.ioTheme}
        ioMode={props.ioMode}
      >
        {cardsDataSatus === undefined ? (
          <Tr
            styleName={props.styleName}
            ioTheme={props.ioTheme}
            ioMode={props.ioMode}
            hoverEnable={props.hoverEnable}
            strippedEnable={props.strippedEnable}
            status={'normal'}
          >
            <Td
              colSpan={visibleColumns.length}
              styleName={props.styleName}
              ioTheme={props.ioTheme}
              ioMode={props.ioMode}
              borderEnable={props.borderEnable}
            >
              {'Loading...'}
            </Td>
          </Tr>
        ) : cardsDataSatus === 'empty' ? (
          <Tr
            styleName={props.styleName}
            ioTheme={props.ioTheme}
            ioMode={props.ioMode}
            hoverEnable={props.hoverEnable}
            strippedEnable={props.strippedEnable}
            status={'normal'}
          >
            <Td
              colSpan={visibleColumns.length}
              styleName={props.styleName}
              ioTheme={props.ioTheme}
              ioMode={props.ioMode}
              borderEnable={props.borderEnable}
            >
              No Data Found
            </Td>
          </Tr>
        ) : (
          <InfiniteScroll
            dataLength={rows.length}
            hasMore={false}
            height={(window.innerHeight * gridHeight) / 110}
            loader={<h4>Loading...</h4>}
            className={'info-react-table'}
          >
            {page.map((i, index) => {
              return renderVariableHeightItem(
                index,
                props.selectedPath,
                errorData,
                selectedRowPath
              );
            })}
          </InfiniteScroll>
        )}
      </Tbody>
    </Table>
  );
}
export default React.memo(InfoTable);
