export enum AuthProviderEnum {
  AWS = 'aws',
  INFOAUTH = 'infoauth',
  OKTA = 'okta',
  MICROSOFT_AD = 'microsoft-ad',
  MICROSOFT_AD_INFO_APPS = 'microsoft-ad-info-apps',
}

export const parseAuthProviderEnum = (provider: string) => {
  switch (provider) {
    case AuthProviderEnum.AWS:
      return AuthProviderEnum.AWS;
    case AuthProviderEnum.INFOAUTH:
      return AuthProviderEnum.INFOAUTH;
    case AuthProviderEnum.OKTA:
      return AuthProviderEnum.OKTA;
    case AuthProviderEnum.MICROSOFT_AD:
      return AuthProviderEnum.MICROSOFT_AD;
    case AuthProviderEnum.MICROSOFT_AD_INFO_APPS:
      return AuthProviderEnum.MICROSOFT_AD_INFO_APPS;
    default:
      throw new Error('Invalid Authentication Provider: ' + provider);
  }
};
