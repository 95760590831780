import * as React from 'react';
import { useState, useContext } from 'react';
import Header from './Header';
import { LanguageContext } from './App';

interface IAuthenticator {
  locale: any;
  setMessages: any;
  message: any;
}
const Authenticator: React.FC<IAuthenticator> = (props: IAuthenticator) => {
  const context: any = useContext(LanguageContext);
  return (
    <div>
      {}
      <Header setLanguage={context.setLanguage} {...props} />
      <div>
        <img
          src={require('../greenBackground.jpg')}
          className={'img-responsive'}
          width={'100%'}
          style={{ height: '95vh' }}
        />
      </div>
    </div>
  );
};

export default Authenticator;
