import gql from 'graphql-tag';

export const ReportForm = gql`
  query ReportForm($id: ID!) {
    Form(id: $id) {
      isRepeatable
      defaultDataString
      tabRequiredInFormsection
      columnDataPreprocessors {
        configObjectId
        name
        configObjectType
        preProcessorBean
        isMultiValue
        excecutionType
        jsCode
        createdBy
        isDeleted
        itemDescription
        creationDate
        projectId
        updatedBy
        updationDate
        deletionDate
        actionFlows {
          configObjectId
          name
          configObjectType
        }
      }
      tab
      order
      formType
      buttonPanels {
        configObjectId
        defaultType
        buttonPanelPosition
        mode
        name
        projectId
        configObjectType
        privileges {
          privilegeId
          privilegeType
          itemId
          roleId
          createdBy
          isDeleted
          creationDate
          updatedBy
          updationDate
          deletionDate
        }
        buttons {
          label
          order
          buttonClass
          warningMessage
          portalId
          buttonAlignment
          accessibilityRegex
          editabilityRegex
          expressionAvailable
          configObjectId
          name
          configObjectType
          projectId
          modalCss
          transactionName
          privileges {
            privilegeId
            privilegeType
            itemId
            roleId
            createdBy
            isDeleted
            creationDate
            updatedBy
            updationDate
            deletionDate
          }
        }
      }
      privileges {
        privilegeId
        privilegeType
        itemId
        roleId
        createdBy
        isDeleted
        creationDate
        updatedBy
        updationDate
        deletionDate
      }
      expressionAvailable
      accessibilityRegex
      editabilityRegex
      formLabel
      maxRepeatation
      defaultRepeatation
      repeatationStyle
      isDeletionAllowed
      configObjectId
      name
      configObjectType
      projectId
      formSections {
        configObjectId
        headerLabel
        order
        displayName
        accessibilityRegex
        editabilityRegex

        expressionAvailable
        tabGroup
        requiredFormfields
        componentsPerRow
        privileges {
          privilegeId
          privilegeType
          itemId
          roleId
          createdBy
          isDeleted
          creationDate
          updatedBy
          updationDate
          deletionDate
        }
        name
        configObjectType
        projectId
        formFields {
          mandatoryIconAccessibilityRegex
          entityName
          parentDBCode
          layout
          label
          label
          order
          defaultValue
          columnDataPreprocessors {
            configObjectId
            name
            configObjectType
            preProcessorBean
            isMultiValue
            excecutionType
            jsCode
            createdBy
            isDeleted
            itemDescription
            creationDate
            projectId
            updatedBy
            updationDate
            deletionDate
            actionFlows {
              configObjectId
              name
              configObjectType
            }
          }
          type
          multivalueList
          resetOnRefresh
          isMandatory
          selectItemsReferenceID
          placeHolder
          expressionAvailable
          refreshFormOnChange
          accessibilityRegex
          editabilityRegex
          configObjectId
          showYearDropdown
          showMonthDropdown
          maxDate
          minDate
          dropdownMode
          timeFormat
          showTimeSelect
          showTimeSelectOnly
          timeCaption
          timeIntervals

          privileges {
            privilegeId
            privilegeType
            itemId
            roleId
            createdBy
            isDeleted
            creationDate
            updatedBy
            updationDate
            deletionDate
          }
          name
          configObjectType
          itemDescription
          projectId
          logicalColumn {
            isPrimaryKey
            dataType
            isVerticalFieldKey
            dbCode
            length
            dbType
            isMandatory
            jsonName
            mode
            isUnique
            isDerived
            configObjectId
            name
            configObjectType
            projectId
          }
          buttons {
            label
            order
            type
            dbCode
            toolTip
            buttonStyle
            icon
            modalRequired
            componentId
            buttonClass
            warningMessage
            portalId
            buttonAlignment
            accessibilityRegex
            editabilityRegex
            expressionAvailable
            configObjectId
            name
            configObjectType
            projectId
            modalCss
            transactionName
            childRelations {
              relationType
              parentItemId
              childItemId
            }
            privileges {
              privilegeId
              privilegeType
              itemId
              roleId
            }
          }
          fontSize
          hasSupportingLabel
          hasValueInSupportingLabel
          supportingLabel
          supportingLabelFontSize
          labelColor
        }
      }
    }
  }
`;
