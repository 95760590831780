import { InfoAuth } from 'info-auth-client';
import React, { useState, useEffect } from 'react';
import { AUTH_TOKEN } from '../constants/appeng.enum';
import { createStorage } from '../storage';

export const useFetchInfoAuth = () => {
  let user: any = {};
  const loginStatusInitial = {
    isAuthenticated: false,
    loading: true,
    user: user,
  };
  const [loginStatus, setLoginStatus] = useState(loginStatusInitial);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storage = createStorage();
        if (
          storage.getItem(AUTH_TOKEN) &&
          JSON.parse(storage.getItem('accessDetails'))
        ) {
          setLoginStatus({
            isAuthenticated: true,
            loading: false,
            user: JSON.parse(storage.getItem('accessDetails')),
          });
        } else {
          const userDetails = await InfoAuth.currentAuthenticatedUser();
          if (userDetails) {
            const currentSession = await InfoAuth.currentSession();
            storage.setItem(AUTH_TOKEN, currentSession.accessToken.token);
            setLoginStatus({
              isAuthenticated: true,
              loading: false,
              user: userDetails,
            });
          } else {
            setLoginStatus({
              isAuthenticated: false,
              loading: false,
              user: {},
            });
          }
        }
      } catch (e) {
        setLoginStatus({ isAuthenticated: false, loading: false, user: {} });
      }
    };
    fetchData();
  }, []);
  return loginStatus;
};

export const fetchAuthData = async () => {
  try {
    const storage = createStorage();
    if (
      storage.getItem(AUTH_TOKEN) &&
      JSON.parse(storage.getItem('accessDetails'))
    ) {
      return {
        isAuthenticated: true,
        loading: false,
        user: JSON.parse(storage.getItem('accessDetails')),
      };
    } else {
      const userDetails = await InfoAuth.currentAuthenticatedUser();
      if (userDetails) {
        const currentSession = await InfoAuth.currentSession();
        storage.setItem(AUTH_TOKEN, currentSession.accessToken.token);
        return { isAuthenticated: true, loading: false, user: userDetails };
      } else {
        return { isAuthenticated: false, loading: false, user: {} };
      }
    }
  } catch (e) {
    return { isAuthenticated: false, loading: false, user: {} };
  }
};
