import { put } from 'redux-saga/effects';
import { Workbook } from "exceljs";
import { setGridData, setToggleMessages } from '../../actions';
import cloneDeep from 'lodash/cloneDeep';

const { default: XLSX } = await import('xlsx');
// export function* generateExcelDocument(
//   documentData: any,
//   responseMessage: any,
//   documentName: any
// ): Generator<any, any, any> {
//   const workbook = XLSX.utils.book_new();

//   for (const sheetName in documentData) {
//     if (documentData.hasOwnProperty(sheetName)) {
//       const sheetData = documentData[sheetName];
//       const worksheet = XLSX.utils.aoa_to_sheet(sheetData);
//       XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
//     }
//   }
//   const blob = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });

//   const link = document.createElement('a');
//   link.href = window.URL.createObjectURL(new Blob([blob]));
//   link.download =
//     (documentName
//       ? documentName.replace(/\./g, '')
//       : Object.keys(documentData)[0].replace(/\./g, '')) + '.xlsx';

//   document.body.appendChild(link);
//   link.click();

//   document.body.removeChild(link);
//   if (responseMessage) {
//     const messages: string[] = [];
//     typeof responseMessage === 'string'
//       ? messages.push(responseMessage)
//       : messages.push(responseMessage.errors);
//     yield put(setToggleMessages(messages));
//   }
// }

const transposeMatrix = (values: any) => {
  let maxLen = -1;
  for (let i = 0; i < values.length; i++) {
    if (values[i].length > maxLen) maxLen = values[i].length;
  }

  const transpose = [];
  for (let i = 0; i < maxLen; i++) {
    transpose.push(new Array(values.length).fill(""));
  }

  for (let i = 0; i < values.length; i++) {
    for (let j = 0; j < values[i].length; j++) {
      transpose[j][i] = values[i][j];
    }
  }

  return transpose;
};

const MASTER_DATA: any[][] = [];
const fillExcelSheet = (workSheet : any, headerData : any[], documentData : any[]) => {
  const headerNames : {header : string, key : string}[] = [];
  let charCode = 65;

  headerData.forEach((headerObj, index) => {
    headerNames.push({
      header: headerObj.NAME,
      key: headerObj.NAME,
    });

    if (headerObj.TYPE == "LIST" || headerObj.TYPE == "MULTILIST") {
      headerNames.push({ header: "", key: "" });
    }
  });

  workSheet.columns = headerNames;
  if (documentData && documentData.length) {
    workSheet.addRows(documentData);
  }

  headerData.forEach((headerObj, index) => {

    if (
      headerObj.TYPE &&
      (headerObj.TYPE.toUpperCase() === "MULTILIST" ||
        headerObj.TYPE.toUpperCase() === "LIST") &&
      headerObj.MASTER_DATA_LABELS &&
      headerObj.MASTER_DATA_LABELS.length > 0
    ) {
      //   if (headerObj.TYPE.toUpperCase() === "MULTILIST") {
      //     MASTER_DATA.push(generateCombinations(headerObj.MASTER_DATA_LABELS));
      //     if (
      //       headerObj.MASTER_DATA_VALUES &&
      //       headerObj.MASTER_DATA_VALUES.length
      //     ) {
      //       MASTER_DATA.push(
      //         generateCombinations(headerObj.MASTER_DATA_VALUES)
      //       );
      //     } else {
      //       MASTER_DATA.push(
      //         generateCombinations(headerObj.MASTER_DATA_LABELS)
      //       );
      //     }
      //   } else

      if (headerObj.TYPE.toUpperCase() === "LIST") {
        MASTER_DATA.push(headerObj.MASTER_DATA_LABELS);

        if (
          headerObj.MASTER_DATA_VALUES &&
          headerObj.MASTER_DATA_VALUES.length
        ) {
          MASTER_DATA.push(headerObj.MASTER_DATA_VALUES);
        } else {
          MASTER_DATA.push(headerObj.MASTER_DATA_LABELS);
        }
      }
      for (let i = 2; i <= 250; i++) {
        workSheet.getCell(
          `${String.fromCharCode(charCode)}${i}`
        ).dataValidation = {
          type: "list",
          allowBlank: true,
          formulae: [
            `'MASTERDATA(DO_NOT_EDIT)'!${String.fromCharCode(
              63 + MASTER_DATA.length
            )}:${String.fromCharCode(63 + MASTER_DATA.length)}`,
          ],
        };

        workSheet.getCell(`${String.fromCharCode(charCode + 1)}${i}`).value = {
          formula: `=IFERROR(VLOOKUP(${String.fromCharCode(
            charCode
          )}${i},'MASTERDATA(DO_NOT_EDIT)'!${String.fromCharCode(
            63 + MASTER_DATA.length
          )}:${String.fromCharCode(64 + MASTER_DATA.length)},2,FALSE), "")`,
        };
      }
      charCode++;
      workSheet.getColumn(charCode - 64).hidden = true;
    }

    charCode++;
  });
};

export function* generateExcelDocument(headerData : any[], documentData : any[], documentName : string, responseMessage : string | {errors : string}) : Generator<any, any, any>{
  let workbook = new Workbook();

  for (const sheetName in headerData) {
    if (headerData.hasOwnProperty(sheetName)) {
      let workSheet = workbook.addWorksheet(sheetName, {
        properties: { defaultColWidth: 30 },
      });
      fillExcelSheet(workSheet, headerData[sheetName], documentData && documentData[sheetName] ? documentData[sheetName] : null);
    }
  }

  let MASTER_DATA_SHEET = workbook.addWorksheet("MASTERDATA(DO_NOT_EDIT)", {
    properties: { defaultColWidth: 30, tabColor: { argb: "FFC0000" } },
  });
  MASTER_DATA_SHEET.state = "hidden";
  MASTER_DATA_SHEET.addRows(transposeMatrix(MASTER_DATA));

  workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = documentName + ".xlsx";
    // Append the link to the document and click it
    document.body.appendChild(link);
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  });

  // Remove the link from the document
  const messages: string[] = [];
  typeof (responseMessage) === 'string' ? messages.push(responseMessage) : messages.push(responseMessage.errors);
  yield put(setToggleMessages(messages));
}

export function* generateTemplateFile(
  documentDetails: any,
  responseMessage: any,
  documentName: any,
  existingCardData: any,
  attributeReffrelId: any
): Generator<any, any, any> {
  const binaryDataBuffer = documentDetails.data;
  const bufferArray = new Uint8Array(binaryDataBuffer).buffer;
  const blob = new Blob([bufferArray], {
    type: 'application/vnd.ms-excel.sheet.macroEnabled.12',
  });
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = documentName;
  a.click();
  const cardsData = cloneDeep(existingCardData);
  const currentCardData = cardsData[attributeReffrelId];
  currentCardData.buttonClicked = '';
  yield put(setGridData(currentCardData, false, true));
  if (responseMessage) {
    const messages: string[] = [];
    typeof responseMessage === 'string'
      ? messages.push(responseMessage)
      : messages.push(responseMessage.errors);
    yield put(setToggleMessages(messages));
  }
}

export function* generateConvertPDFTemplateFile(
  documentDetails: any,
  responseMessage: any,
  documentName: any
): Generator<any, any, any> {
  const binaryDataBuffer = documentDetails.data;
  const data = new Uint8Array(binaryDataBuffer).buffer;
  const workbook = XLSX.read(data, { type: 'array' });
  const jsPDF = (yield import('jspdf')).jsPDF;
  const pdf: any = new jsPDF();

  workbook.SheetNames.forEach((sheetName, index) => {
    const worksheet = workbook.Sheets[sheetName];

    const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
    const filteredData = jsonData.filter((row: any) =>
      row.some(
        (cell: any) => cell !== null && cell !== undefined && cell !== ''
      )
    );

    if (filteredData.length > 0) {
      if (index > 0) {
        pdf.addPage();
      }

      let tableHtml = '<table>';
      filteredData.forEach((row: any) => {
        tableHtml += '<tr>';
        row.forEach((cell: any) => {
          tableHtml += `<td>${cell !== undefined ? cell : ''}</td>`;
        });
        tableHtml += '</tr>';
      });
      tableHtml += '</table>';

      pdf.fromHTML(tableHtml, 20, 20);
    }
  });

  const pdfBlob = pdf.output('blob');
  const pdfUrl = URL.createObjectURL(pdfBlob);
  const a = document.createElement('a');
  a.href = pdfUrl;
  a.download = 'converted.pdf';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(pdfUrl);
  if (responseMessage) {
    const messages: string[] = [];
    typeof responseMessage === 'string'
      ? messages.push(responseMessage)
      : messages.push(responseMessage.errors);
    yield put(setToggleMessages(messages));
  }
}

export function* generateWordDocument(
  documentDetails: any,
  responseMessage: any,
  documentName: any,
  existingCardData: any,
  attributeReffrelId: any
): Generator<any, any, any> {
  const binaryDataBuffer1 = documentDetails.data;
  const data1 = new Uint8Array(binaryDataBuffer1).buffer;
  const blob = new Blob([data1], { type: 'application/octet-stream' });
  const link = document.createElement('a');

  link.href = window.URL.createObjectURL(new Blob([blob]));
  link.download = documentName.replace(/\./g, '') + '.docx';

  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  const cardsData = cloneDeep(existingCardData);
  const currentCardData = cardsData[attributeReffrelId];
  currentCardData.buttonClicked = '';
  yield put(setGridData(currentCardData, false, true));
  if (responseMessage) {
    const messages: string[] = [];
    typeof responseMessage === 'string'
      ? messages.push(responseMessage)
      : messages.push(responseMessage.errors);
    yield put(setToggleMessages(messages));
  }
}
