import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setNavigationCompleted } from '../actions';

const NavigationHandler = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigation = useSelector((state: any) => state.navigation);

  useEffect(() => {
    if (navigation) {
      navigate(navigation.url, navigation.state);

      dispatch(setNavigationCompleted);
    }
  }, [navigation, navigate, dispatch]);

  return null;
};

export default React.memo(NavigationHandler);
