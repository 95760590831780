import DOMPurify from 'dompurify';

const sanitize = (value) => {
  if (typeof value === 'string') {
    return DOMPurify.sanitize(value);
  } else if (typeof value === 'object' && value !== null) {
    const sanitizedObject = {};
    for (const key in value) {
      if (Object.hasOwnProperty.call(value, key)) {
        sanitizedObject[key] = sanitize(value[key]);
      }
    }
    return sanitizedObject;
  }
  return value;
};

export const voidRestHttp = async (url: string, config: any) => {
  const res = await fetch(url, config);
};

export const restHttp = async (url: string, config: any) => {
  const sanitizedConfig = sanitize(config);
  const sanitizedURL = DOMPurify.sanitize(url);
  return await fetch(sanitizedURL, config)
    .then((response) => response.json())
    .then((body) => {
      return body;
    });
};

export const restHttpPagination = async (
  url: string,
  config: any,
  page: number,
  limit: number
) => {
  const sanitizedConfig = sanitize(config);
  const sanitizedURL = DOMPurify.sanitize(url);
  const queryParams: any = {
    page: page ? page : 1,
    limit: limit ? limit : 10,
  };

  // Convert query parameters to a URL-encoded string
  const queryString = new URLSearchParams(queryParams).toString();
  return await fetch(`${url}?${queryString}`, sanitizedConfig).then(
    async (response) => {
      // Extract headers
      const headers = {};
      response.headers.forEach((value, key) => {
        if (['page', 'limit', 'ae_pagination_total_count'].includes(key)) {
          headers[key] = value;
        }
      });

      // Parse the body
      const body = await response.json();

      // Return both headers and body
      return { headers, body };
    }
  );
};

export const restHttpBlob = async (url: string, config: any) => {
  const sanitizedConfig = sanitize(config);
  const sanitizedURL = DOMPurify.sanitize(url);
  return await fetch(sanitizedURL, config)
    .then((response) => response)
    .then((body) => {
      return body;
    });
};
